import axios from 'axios';
import { useHistory } from 'react-router-dom';
import helpers from '../utils/helpers';
import storage from "../utils/storageFactory.js";
import unregister from '../registerServiceWorker';
const API = '/api';

function userToken() {
  return storage.getItem('token');
}

function commonHeaders() {
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${userToken()}`
  };
}

const headers = () => Object.assign(commonHeaders(), { 'Content-Type': 'application/json' });
const multipart_headers = () => Object.assign(commonHeaders(), { 'Content-Type': 'multipart/form-data' });

function queryString(params) {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
  return `${query.length ? '?' : ''}${query}`;
}

function fetch(url, params = {}) {
  return axios.get(`${API}/${url}${queryString(params)}`, { headers: headers() });
}

function timedCached(url, staleSeconds, mapResponse) {
  const cacheString = sessionStorage.getItem(url);
  let o = cacheString ? JSON.parse(cacheString) : null;
  const nowTime = new Date().getTime();
  if (o && o.data && (nowTime - o.lastUpdatedAt) < (staleSeconds * 1000)) {
    // console.log('cached home data')
    return new Promise(((resolve, reject) => {
      resolve(o.data);
    }));
  } else {
    return fetch(url).then(r => {
      // console.log('fresh home data')
      if (o === null) o = {};
      o.lastUpdatedAt = nowTime;
      o.data = mapResponse(r);
      sessionStorage.setItem(url, JSON.stringify(o));
      return o.data;
    });
  }
}

axios.interceptors.response.use(
  function (response) {
    const clientVersion = storage.getItem("app-version");
    const serverVersion = response.headers["x-peak-version"];
    if (clientVersion === null || clientVersion !== serverVersion) {
      if (clientVersion !== undefined && serverVersion !== undefined) {
        storage.setItem("app-version", serverVersion);
        console.warn("This page is about to reload with an updated version...");
        setTimeout(function () {
          try {
            unregister();
            window.location.reload(true);
          } catch {
            window.location.reload(true);
          }
        }, 300);
      }
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      console.warn("Unauthorized - redirecting to login");
      // storage.removeItem("loggedOnAt");
      storage.removeItem("currentUser");
      storage.removeItem("token");
      setTimeout(function () {
        const loginRoutes = ['/Public/LogOn', '/login', '/'];
        if (!loginRoutes.includes(window.location.pathname)) {
          try {
            const history = useHistory();
            history.push("/");
          } catch {
            console.error('failed to redirect in axios interceptor');
          }
        }
      }, 500);
    }
    if (error.response && error.response.status === 403) {
      console.error(
        "Your user account does not have the permissions to take this action"
      );
    }
    if (error.response && error.response.status === 503) {
      console.error(
        "Site is being updated. Please check back in just a couple of minutes"
      );
    }
    return error;
  }
);

const mapUserHomeData = (r) => {
  if (!r || !r.data)
    return null;
  return {
    message: r.data.message,
    panelList: r.data.panelList,
    labFormDataId: r.data.labFormDataId,
    completedHealthUpdate: r.data.completedHealthUpdate,
    hasLabCorpResults: r.data.hasLabCorpResults,
    accountSummary: r.data.accountSummary,
    latestVisitDetail: r.data.latestVisitDetail,
    enrollmentInfo: r.data.enrollmentInfo,
    isEnrolled: r.data.isEnrolled
  };
};

export default {
  fetch(url, params = {}) {
    return fetch(url, params);
  },

  upload(verb, url, data) {
    switch (verb.toLowerCase()) {
      case 'post':
        return axios.post(`${API}/${url}`, data, { headers: headers() });
      case 'put':
        return axios.put(`${API}/${url}`, data, { headers: headers() });
      case 'patch':
        return axios.patch(`${API}/${url}`, data, { headers: headers() });
      default:
        return axios.get(`${API}/${url}${queryString(data)}`, { headers: headers() });
    }
  },

  post(url, data) {
    return axios.post(`${API}/${url}`, data, { headers: headers() });
  },

  put(url, data) {
    return axios.put(`${API}/${url}`, data, { headers: headers() });
  },

  patch(url, data) {
    return axios.patch(`${API}/${url}`, data, { headers: headers() });
  },

  delete(url) {
    return axios.delete(`${API}/${url}`, { headers: headers() });
  },

  post_form_data(url, formData) {
    return axios.post(`${API}/${url}`, formData, { headers: multipart_headers() });
  },

  put_form_data(url, formData) {
    return axios.put(`${API}/${url}`, formData, { headers: multipart_headers() });
  },

  downloadPDF(url) {
    axios(`${API}/${url}`, { method: 'GET', responseType: 'blob', headers: headers() }).then(r => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(r.data, 'pdf-file.pdf');
      }
      else
      {      
        const file = new Blob([r.data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    }).catch(helpers.catchHandler);
  },

  getUserHomeData(noCache = false) {
    if (noCache) {
      return fetch('Home').then(r => {
        return mapUserHomeData(r);
      });
    } else {
      return timedCached('Home', 10, mapUserHomeData);
    }
  },

  serverLogoff: function() {
    const options = { 
      headers: { 
        Accept: 'application/json', 
        'Content-Type': 'application/json' 
      } 
    };
    axios.get('/api/Public/LogOff', {}, options);
    if (storage.getItem('TruistSSO')) {
      axios.post('/SSO/LogOff', {}, options);
    }
    // _.each(["ASP.NET_SessionId", ".ASPXAUTH"], ck => {
    //   // setting to nothing first with an old date
    //   Cookies.set(ck, "", { domain: 'peak-health.net', expires: new Date('1970-12-17T03:24:00') });
    //   Cookies.remove(ck);
    //   Cookies.remove(ck, { domain: 'peak-health.net' });
    // });
  },

  getUserVisitData() {
    return timedCached('Visit', 30, r => {
      return {
        heredityRiskCodes: r.data.heredityRiskCodeList,
        activityCodes: r.data.activityCodeList,
        controllableRiskCodes: r.data.controllableRiskCodeList,
        latestVisitDetail: r.data.latestVisitDetail,
        visitHistoryList: r.data.visitHistoryList
      };
    });
  },

  userToken() {
    return userToken();
  },
};

