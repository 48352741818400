import React, { Component } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Container, Button, Card, CardBody, Col, Row, CardHeader, Input, 
  Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SingleDatePicker } from '../components/index';
import Select from 'react-select';
import _ from 'lodash';
import api from '../utils/api';
import helpers from '../utils/helpers';
import date_helpers from '../utils/date_helpers';

const AUTO_SAVE_THRESHOLD_CHANGE_COUNT = 20;
const DB_DATE_FORMAT = 'YYYY-MM-DD';
const getQuestionIdFromName = (name) => {
  return parseInt(name.substring(2), 10);
};

const QUESTION_TYPES = {
  BOOL: 1,
  DATE: 8,
  TEXT: 2,
  SSN: 11,
  RACE: 12,
  NUMERIC: 3,
  DAYS_PER_WEEK: 15,
  ACTIVITY: 4,
  SCREENING: 5,
  MEDICAL_CONDITIONS: 10,
  MEDICATION_LIST: 13,
  CANCER_TYPE: 14,
  SMOKING: 6,
  ALCOHOL: 7,
  GENDER: 9
};

const SURVEY_TYPE = {
  DEFAULT: 1,
  BBT: 2,
  TEL: 3,
  CONEXUS: 4,
  NONE: 5,
  CRANE: 6,
  SUNTRUST: 7
};

const SECTION = {
  PARTICIPANTS: "participant Information",
  PERSONAL_MEDICAL_HISTORY: "personal Medical History",
  FAMILY_HISTORY: "family History",
  FEMALES: "females Only",
  MALES: "males Only",
  OVER_50: "over age 50",
  MEDICATIONS: "medications",
  HEALTH_HABITS: "health Habits",
  JOIN_REASONS: "please list your reason(s) for joining the program",

  PREVENTATIVE_HEALTH_EXAMS: "preventative Health Exams",
  LIFESTYLE_QUESTIONS: "lifestyle Questions",

  PREFERRED_CONTACT_INFO: "preferred Contact Information"
};

const genderList = () => {
  return [
    {label: '(No Answer)', value: ''},
    {label: 'Male', value: 'M'},
    {label: 'Female', value: 'F'}
  ];
};

const boolList = () => {
  return [
    {label: 'No', value: false},
    {label: 'Yes', value: true}
  ];
};

const smokingList = () => {
  return [
    {label: 'None', value: 'None'},
    {label: 'Less than 10', value: 'Less than 10'},
    {label: '10-19', value: '10-19'},
    {label: '20-39', value: '20-39'},
    {label: '40 or more', value: '40 or more'}
  ];
};

const alcoholCountList = () => {
  return [
    {label: 'None', value: 'None'},
    {label: '1-2', value: '1-2'},
    {label: '3-6', value: '3-6'},
    {label: '7 or more', value: '7 or more'}
  ];
};

const screenTypeList = () => {
  return [
    {label: 'Stool Hemocult', value: 'Stool Hemocult'},
    {label: 'Sigmoidoscopy', value: 'Sigmoidoscopy'},
    {label: 'Colonoscopy', value: 'Colonoscopy'}
  ];
};

// const daysPerWeekList = () => {
//   return [
//     {label: '0', value: '0'},
//     {label: '1', value: '1'},
//     {label: '2', value: '2'},
//     {label: '3', value: '3'},
//     {label: '4', value: '4'},
//     {label: '5', value: '5'},
//     {label: '6', value: '6'},
//     {label: '7', value: '7'}
//   ];
// };

const allRequiredQuestionsHaveAnswers = (questionList) => {
  let requiredQuestions = [];
  for(let sectionKey in questionList) {
    for(let i=0; i < questionList[sectionKey].length; i++) {
      if (questionList[sectionKey][i].isRequired) {
        requiredQuestions.push(questionList[sectionKey][i]);
      }
    }
  }
  return _.every(requiredQuestions, q => {return q.answer !== null && q.answer !== ''; })
};

const starSpan = () => (<span className="asterisk" title="Required">*</span>);

const getQuestionName = (q) => {
  return `q-${q.id}`; 
};

const getQuestion = (questionList, sectionKey, questionKey) => {
  return _.find(questionList[sectionKey], q => q.description === questionKey);
};

const isQuestionChecked = (questionList, sectionKey, questionKey) => {
  const q = _.find(questionList[sectionKey], q => q.description === questionKey);
  return q.answer;
};

const questionAnswerIs = (questionList, sectionKey, questionKey, answer) => {
  const q = _.find(questionList[sectionKey], q => q.description === questionKey);
  return q.answer && q.answer === answer;
};

const getQuestionById = (questionList, qId) => {
  const qIdInt = (typeof qId === "number") ? qId : parseInt(qId, 10);
  for(let sectionName in questionList) {
    for(let i=0; i < questionList[sectionName].length; i++) {
      if (questionList[sectionName][i].id === qIdInt) {
        return questionList[sectionName][i];
      }
    }
  }
  console.warn('failed to find question id: "' + qId + '"');
  return null;
};

const applyAnswersToQuestions = (questions, answers) => {
  for(let qName in answers) {
    // qId = parseInt(qId, 10); // for...in converts keys to string!
    for(const section in questions) {
      const sectionQuestions = questions[section];
      const qIdx = _.findIndex(sectionQuestions, sq => {return sq.id === getQuestionIdFromName(qName);});
      if (qIdx === -1) continue;
      switch(sectionQuestions[qIdx].typeOfSurveyQuestion) {
        case QUESTION_TYPES.BOOL:
          questions[section][qIdx].answer = (answers[qName].value === 'true' || answers[qName].value === true);
          break;
        case QUESTION_TYPES.DATE:
          questions[section][qIdx].answer = date_helpers.getMomentFromString(answers[qName].value, DB_DATE_FORMAT);
          // console.log('applied [' + answers[qName].value + '] to ' + qName);
          break;
        case QUESTION_TYPES.TEXT:
        case QUESTION_TYPES.SSN:
        case QUESTION_TYPES.RACE:
        case QUESTION_TYPES.NUMERIC:
        case QUESTION_TYPES.DAYS_PER_WEEK:
        case QUESTION_TYPES.ACTIVITY:
        case QUESTION_TYPES.SCREENING:
        case QUESTION_TYPES.MEDICAL_CONDITIONS:
        case QUESTION_TYPES.MEDICATION_LIST:
        case QUESTION_TYPES.CANCER_TYPE:
        case QUESTION_TYPES.SMOKING:
        case QUESTION_TYPES.ALCOHOL:
        case QUESTION_TYPES.GENDER:
        default:
          questions[section][qIdx].answer = answers[qName].value;
          break;
      }
      break; // found the question, no need to iterate through the other sections
    }
  }
  return questions;
};

const applyDefaultAnswers = (questions, answers) => {
  for(let section in questions) {
    let sectionQuestions = questions[section];
    for(let i=0; i < sectionQuestions.length; i++) {
      const qIdInt = parseInt(sectionQuestions[i].id, 10);
      const qName = 'q-' + qIdInt;
      if (sectionQuestions[i].answer !== undefined || answers[qName] !== undefined) continue;
      switch(sectionQuestions[i].typeOfSurveyQuestion) {
        case QUESTION_TYPES.BOOL:
          answers[qName] = {qId: qIdInt, value: false};
          break;
        case QUESTION_TYPES.TEXT:
        case QUESTION_TYPES.SSN:
        case QUESTION_TYPES.RACE:
          answers[qName] = {qId: qIdInt, value: ''};
          break;
        case QUESTION_TYPES.NUMERIC:
        case QUESTION_TYPES.DAYS_PER_WEEK:
          answers[qName] = {qId: qIdInt, value: 0};
          break;
        case QUESTION_TYPES.ACTIVITY:
        case QUESTION_TYPES.SCREENING:
        case QUESTION_TYPES.MEDICAL_CONDITIONS:
        case QUESTION_TYPES.MEDICATION_LIST:
        case QUESTION_TYPES.CANCER_TYPE:
        case QUESTION_TYPES.SMOKING:
        case QUESTION_TYPES.ALCOHOL:
          answers[qName] = {qId: qIdInt, value: ""}; // really an array but we treat it like a csv
          break;
        case QUESTION_TYPES.DATE:
          if (qIdInt === 20) { // date-of-survey question, default to today
            answers[qName] = {qId: qIdInt, value: moment().format(DB_DATE_FORMAT)};
          } else {
            answers[qName] = {qId: qIdInt, value: null};
          }
          break;
        case QUESTION_TYPES.GENDER:
        default:
          answers[qName] = {qId: qIdInt, value: null};
          break;
      }
    }
  }
  return answers;
};

const initializeAnswers = (questions, answers) => {
  answers = applyDefaultAnswers(questions, answers);
  // answers will exist but may need to be cast for use in the UI
  for(let qName in answers) {
    // qId = parseInt(qId, 10); // for...in converts keys to string!
    for(let section in questions) {
      const sectionQuestions = questions[section];
      const qIdx = _.findIndex(sectionQuestions, q => {return q.id === getQuestionIdFromName(qName);});
      if (qIdx === -1) continue;
      switch(sectionQuestions[qIdx].typeOfSurveyQuestion) {
        case QUESTION_TYPES.BOOL:
          answers[qName].value = (answers[qName].value === 'true' || answers[qName].value === true);
          break;
        case QUESTION_TYPES.DATE:
          const m = date_helpers.getMomentFromString(answers[qName].value, DB_DATE_FORMAT);
          answers[qName].value = m && m.isValid ? m : null;
          break;
        case QUESTION_TYPES.CANCER_TYPE:
        case QUESTION_TYPES.MEDICATION_LIST:
          // array, should be deserialized ok without intervention
          break;
        case QUESTION_TYPES.TEXT:
        case QUESTION_TYPES.SSN:
        case QUESTION_TYPES.RACE:
        case QUESTION_TYPES.NUMERIC:
        case QUESTION_TYPES.DAYS_PER_WEEK:
        case QUESTION_TYPES.ACTIVITY:
        case QUESTION_TYPES.SCREENING:
        case QUESTION_TYPES.MEDICAL_CONDITIONS:
        case QUESTION_TYPES.SMOKING:
        case QUESTION_TYPES.ALCOHOL:
        case QUESTION_TYPES.GENDER:
        default:
          // answers[qName].value = // is itself, nothing to do;
          break;
      }
      break; // found the question, no need to iterate through the other sections
    }
  }
  return answers;
};

class QuestionSection extends Component {
  render() {
    return (
    <fieldset>
      <legend>{this.props.Title}</legend>
      {this.props.children}
    </fieldset>);
  }
} 

class DateQuestion extends Component {
constructor(props) {
  super(props);
  this.state = {
    value: props.question.answer
  };
  this.onDateChange = this.onDateChange.bind(this);
}  

onDateChange(selection) {
  this.setState({value: selection});
  this.props.onTextChange({target: {name: getQuestionName(this.props.question), value: selection.date.format(DB_DATE_FORMAT) }});
}

  render() {
    return (
      <Row className="mb-1">
        <Col sm={this.props.captionColSize} className="survey-prompt">{this.props.question.description}
          {this.props.question.isRequired ? starSpan() : null}
        </Col>
        <div style={{width: "130px", paddingLeft: "15px"}}>
          <SingleDatePicker
            onChangeCallback={this.onDateChange}
            value={this.state.value}
          />
        </div>
      </Row>
    );    
  }  
}

class CheckboxQuestion extends Component {
constructor(props) {
  super(props);
  this.state = {
    value: props.question.answer
  };
  this.onChange = this.onChange.bind(this);
}  

onChange(event) {
  // let newValue = !this.state.value;
  this.setState({value: event.target.checked});
  this.props.onChange({target: {name: getQuestionName(this.props.question), value: event.target.checked }});
}

  render() {
    return (
      <label className="checkbox">
        <input type="checkbox" 
          onChange={this.onChange} 
          name={getQuestionName(this.props.question)} 
          checked={this.state.value} /> {this.props.question.description}
      </label>
    );    
  }  
}

class CancerTypesQuestion extends Component {
constructor(props) {
  super(props);
  this.state = {
    cancerTypeList: ['Breast Cancer', 'Cervical/Uterine/Ovarian', 'Colon/Rectum', 'Prostate', 'Other Cancer'],
    selectedList: props.question.answer ? props.question.answer : []
  };
  this.onChange = this.onChange.bind(this);
}  

onChange(event) {
  let list = this.state.selectedList.slice();
  let idx = _.findIndex(list, n => { return n === event.target.name; });
  if (idx === -1) {
    list.push(event.target.name);
  } else {
    list.splice(idx, 1);
  }
  this.setState({selectedList: list});
  this.props.onToggleListEntry({questionName: getQuestionName(this.props.question), value: event.target.checked, name: event.target.name });
}

  render() {
    return (
    <div style={{paddingLeft: "12px"}}>
    {this.state.cancerTypeList.map(ct => (
      <label className="checkbox" style={{marginLeft: "20px"}} key={`ct-${ct}`}>
        <input type="checkbox" 
          onChange={this.onChange} 
          name={ct}
          checked={this.state.selectedList.includes(ct)} /> {ct}
      </label>))
    }
    </div>);    
  }  
}

class ActivitiesQuestion extends Component {
constructor(props) {
  super(props);
  this.state = {
    activityList: ['Dance', 'Racquetball', 'Rowing', 'Basketball', 'Swimming', 'Inactive', 'Golf', 'Other', 'Walking', 'Cycling/Eliptical-Indoors',
      'Jumping Rope', 'Stairs/Steps', 'Weights', 'Softball', 'Volleyball', 'Jogging', 'Cycling-Outdoors', 'Tennis', 'Skiing', 'Toning', 'Yardwork', 'Cardio (non-specific)'],
    selectedList: props.question.answer ? props.question.answer : []
  };
  this.onChange = this.onChange.bind(this);
}  

onChange(event) {
  let list = this.state.selectedList.slice();
  let idx = _.findIndex(list, n => { return n === event.target.name; });
  if (idx === -1) {
    list.push(event.target.name);
  } else {
    list.splice(idx, 1);
  }
  this.setState({selectedList: list});
  this.props.onToggleListEntry({questionName: getQuestionName(this.props.question), value: event.target.checked, name: event.target.name });
}

  render() {
    return (
    <div style={{paddingLeft: "12px"}}>
    {this.state.activityList.map(ct => (
      <label className="checkbox" style={{marginLeft: "20px"}} key={`ct-${ct}`}>
        <input type="checkbox" 
          onChange={this.onChange} 
          name={ct}
          checked={this.state.selectedList.includes(ct)} /> {ct}
      </label>))
    }
    </div>);    
  }  
}

class TypedListQuestion extends Component {
constructor(props) {
  super(props);
  const qName = getQuestionName(this.props.question);
  const twentyBlanks = _.map(['','','','','','','','','','','','','','','','','','','',''], (x, i) => {return {idx: i, name: `${qName}-${i}`, value: x}})
  this.state = {
    list: props.question.answer ? props.question.answer : twentyBlanks
  };
  this.onChange = this.onChange.bind(this);
  this.getValue = this.getValue.bind(this);
}  

onChange(idx, value) {
  let list = Object.assign({}, this.state.list);
  list[idx].value = value;
  this.setState({list: list});
  this.props.onTypedListEntry({questionName: getQuestionName(this.props.question), value: list });
}

getValue(idx) {
  return _.find(this.state.list, x => {return x.idx === idx;}).value;
}

  render() {
    // const qName = getQuestionName(this.props.question);
    const orderedList = _.sortBy(this.state.list, x => x.idx);
    return (
    <React.Fragment>
      <Row>
        <Col>
          {this.props.question.description}
        </Col>
      </Row>
      <Row className="mb-2">
      {orderedList.map(item => (
        <Col sm="6" className="mb-1" key={item.name}>
          <input type="text" maxLength="100" className="form-control" style={{width: "85%", marginLeft: "25px"}}
            onChange={evt => this.onChange(item.idx, evt.target.value)} 
            value={this.getValue(item.idx)} />
        </Col>
      ))
      }
      </Row>
    </React.Fragment>);    
  }  
}

class SelectQuestion extends Component {
constructor(props) {
  super(props);
  let val = null;
  if (props.question.answer) {
    if (props.isBoolean) {
      val = props.question.answer === 'true';
    } else {
      val = props.question.answer;
    }
  }
  // console.log('existing value for ' + props.question.id + ' was ', val)
  let selectedOption = null;
  if (val !== undefined) {
    selectedOption = _.find(props.options, x => { return x.value === val; });
  }
  this.state = {
    value: selectedOption,
    options: props.options
  };
  this.onChange = this.onChange.bind(this);
}  

onChange(selection) {
  this.setState({value: selection});
  this.props.onChange({target: {name: getQuestionName(this.props.question), value: selection.value }});
}

  render() {
    return (
      <Row className="mb-2">
        <Col sm={this.props.captionColSize} className="survey-prompt">{this.props.question.description}
          {this.props.question.isRequired ? starSpan() : null}
        </Col>
        <Col sm={this.props.valueColSize}>
          <Select name={getQuestionName(this.props.question)}
            closeMenuOnSelect={true}
            value={this.state.value}
            isMulti={false}
            options={this.state.options}
            onChange={this.onChange}
          />
        </Col>
      </Row>
    );    
  }  
}

class TextQuestion extends Component {
constructor(props) {
  super(props);
  this.state = {
    value: props.question.answer
  };
  this.onTextChange = this.onTextChange.bind(this);
  this.onBlur = this.onBlur.bind(this);
}  

onTextChange(e) {
  this.setState({value: e.target.value});
  this.props.onTextChange(e); 
}

onBlur(e) {
  if (this.props.ensureValidDate) {
    var dateStr = date_helpers.parseDate(e.target.value);
    this.setState({value: dateStr });
    this.props.onTextChange({target: {name: e.target.name, value: dateStr }});
  }
}

  render() {
    return (
      <Row className="mb-1">
        <Col sm={this.props.captionColSize} className="survey-prompt">{this.props.noPrompt ? null : this.props.question.description}
          {this.props.question.isRequired ? starSpan() : null}
        </Col>
        <Col sm={this.props.valueColSize}>
          {this.props.multiLine
            ? (<textarea name={getQuestionName(this.props.question)} 
                onChange={this.onTextChange} 
                onBlur={this.onBlur}
                value={this.state.value} 
                maxLength="500" 
                style={{width: "100%", borderRadius: "4px", padding: "6px"}}
              />)
            : (<Input name={getQuestionName(this.props.question)} 
                onChange={this.onTextChange} 
                onBlur={this.onBlur}
                value={this.state.value} 
                maxLength="500" 
              />)
          }
        </Col>
      </Row>
    );    
  }  
}

class BBTSurvey extends Component {
constructor(props) {
  super(props);
  this.state = {};
}  

render() {
  return (
  <React.Fragment>
    <Row>
      <Col style={{padding: "8px", overflowWrap: "break-word"}}>
        <h5>Welcome to Peak Health. 
          Once you complete the online Health Assessment (HA) on CarePlusMobileHealth.com, acknowledge the notices below, and complete your labs, we will contact you with your Peak Health appointment.
        </h5>
      </Col>
    </Row>
{/*    <Row className="mb-2">
      <Col className="survey-prompt">
        *** You can leave any unknown answers blank. ***    
      </Col>
    </Row>
    <QuestionSection Title={SECTION.PARTICIPANTS}>
      <TypedListQuestion captionColSize="3" valueColSize="8" onTypedListEntry={this.props.onTypedListEntry}
        question={getQuestion(this.props.questionList, SECTION.MEDICATIONS, "I currently take the following medications")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.PREVENTATIVE_HEALTH_EXAMS}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Physical Exam Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Fecal Occult Blood Test Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Colonoscopy Date")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.FEMALES}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.FEMALES, "PAP Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.FEMALES, "Mammogram Date")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.MALES}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.MALES, "Date of last Prostate check")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.LIFESTYLE_QUESTIONS}>
      <Row className="mb-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Nutrition: I eat a variety of foods each day, such as fruits and vegetables, whole grain breads and cereals, lean meats and low-fat dairy products.")} 
          />
        </Col>
      </Row>
      <SelectQuestion captionColSize="10" valueColSize="1" onChange={this.props.onTextChange} options={daysPerWeekList()}
        question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Aerobic Exercise: How many days each week do you get at least 30 minutes of moderate to vigorous exercise? (e.g., brisk walking, running, cycling, aerobics, hard physical labor)")} 
      />
      <Row className="mb-2 mt-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Strength Exercises: I perform strength-building exercises for 15-30 minutes at least 2-3 days each week ? (e.g., weight lifting, pushups, crunches)")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Safety: I wear my seatbelt while riding in a car.")} 
          />
        </Col>
      </Row>
      <SelectQuestion captionColSize="10" valueColSize="1" onChange={this.props.onTextChange} options={alcoholCountList()}
        question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Alcohol: How many drinks do you have each week? (1 drink = 12 oz beer, 5 oz wine, or 1.5 oz liquor)")} 
      />
    </QuestionSection>*/}
  </React.Fragment>
  );
}
}

class TELSurvey extends Component {
constructor(props) {
  super(props);
  this.state = {};
}  

render() {
  return (
  <React.Fragment>
    <Row>
      <Col style={{padding: "8px", overflowWrap: "break-word"}}>
        <h5>Welcome to Peak Health. We administer TEL's Wellness Program. 
          Once you complete the online Health Assessment (HA) on CarePlusMobileHealth.com, acknowledge the notices below, and complete your labs, we will contact you with your Nurse Coaching appointment.
        </h5>
      </Col>
    </Row>
{/*
    <Row className="mb-2">
      <Col className="survey-prompt">
        *** You can leave any unknown answers blank. ***    
      </Col>
    </Row>
    <QuestionSection Title={SECTION.PARTICIPANTS}>
      <TypedListQuestion captionColSize="3" valueColSize="8" onTypedListEntry={this.props.onTypedListEntry}
        question={getQuestion(this.props.questionList, SECTION.MEDICATIONS, "I currently take the following medications")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.PREVENTATIVE_HEALTH_EXAMS}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Physical Exam Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Fecal Occult Blood Test Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Colonoscopy Date")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.FEMALES}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.FEMALES, "PAP Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.FEMALES, "Mammogram Date")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.MALES}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.MALES, "Date of last Prostate check")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.LIFESTYLE_QUESTIONS}>
      <Row className="mb-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Nutrition: I eat a variety of foods each day, such as fruits and vegetables, whole grain breads and cereals, lean meats and low-fat dairy products.")} 
          />
        </Col>
      </Row>
      <SelectQuestion captionColSize="10" valueColSize="1" onChange={this.props.onTextChange} options={daysPerWeekList()}
        question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Aerobic Exercise: How many days each week do you get at least 30 minutes of moderate to vigorous exercise? (e.g., brisk walking, running, cycling, aerobics, hard physical labor)")} 
      />
      <Row className="mb-2 mt-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Strength Exercises: I perform strength-building exercises for 15-30 minutes at least 2-3 days each week ? (e.g., weight lifting, pushups, crunches)")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Safety: I wear my seatbelt while riding in a car.")} 
          />
        </Col>
      </Row>
      <SelectQuestion captionColSize="10" valueColSize="1" onChange={this.props.onTextChange} options={alcoholCountList()}
        question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Alcohol: How many drinks do you have each week? (1 drink = 12 oz beer, 5 oz wine, or 1.5 oz liquor)")} 
      />
    </QuestionSection>*/}
    <QuestionSection Title={SECTION.PREFERRED_CONTACT_INFO}>
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREFERRED_CONTACT_INFO, "Preferred Phone")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREFERRED_CONTACT_INFO, "Secondary Phone")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREFERRED_CONTACT_INFO, "Email")} 
      />
    </QuestionSection>
  </React.Fragment>
  );
}
}

class CRANESurvey extends Component {
constructor(props) {
  super(props);
  this.state = {};
}  

render() {
  return (
  <React.Fragment>
    <Row>
      <Col style={{padding: "8px", overflowWrap: "break-word"}}>
        <h5>Welcome to Peak Health. We administer CRANE's Lifeforce Program. 
          Once you complete the online Health Assessment (HA) on CarePlusMobileHealth.com, acknowledge the notices below, and complete your labs, we will contact you with your Lifeforce appointment.
        </h5>
      </Col>
    </Row>
    <QuestionSection Title={SECTION.PREFERRED_CONTACT_INFO}>
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREFERRED_CONTACT_INFO, "Preferred Contact Number")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREFERRED_CONTACT_INFO, "Secondary Phone")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREFERRED_CONTACT_INFO, "If you prefer to be contacted via email, please provide your email address here.")} 
      />
    </QuestionSection>
  </React.Fragment>
  );
}
}

class SunTrustSurvey extends Component {
constructor(props) {
  super(props);
  this.state = {};
}  

render() {
  return (
  <React.Fragment>
    <Row>
      <Col style={{padding: "8px", overflowWrap: "break-word"}}>
        <h5>Welcome to Peak Health.
          Once you acknowledge the notices below, and complete your labs, we will contact you with your Peak Health appointment.
        </h5>
      </Col>
    </Row>
{/*    <Row className="mb-2">
      <Col className="survey-prompt">
        *** You can leave any unknown answers blank. ***    
      </Col>
    </Row>
    <QuestionSection Title={SECTION.PREVENTATIVE_HEALTH_EXAMS}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Physical Exam Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Fecal Occult Blood Test Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PREVENTATIVE_HEALTH_EXAMS, "Colonoscopy Date")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.FEMALES}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.FEMALES, "PAP Date")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.FEMALES, "Mammogram Date")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.MALES}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.MALES, "Date of last Prostate check")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.LIFESTYLE_QUESTIONS}>
      <Row className="mb-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Nutrition: I eat a variety of foods each day, such as fruits and vegetables, whole grain breads and cereals, lean meats and low-fat dairy products.")} 
          />
        </Col>
      </Row>
      <SelectQuestion captionColSize="10" valueColSize="1" onChange={this.props.onTextChange} options={daysPerWeekList()}
        question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Aerobic Exercise: How many days each week do you get at least 30 minutes of moderate to vigorous exercise? (e.g., brisk walking, running, cycling, aerobics, hard physical labor)")} 
      />
      <Row className="mb-2 mt-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Strength Exercises: I perform strength-building exercises for 15-30 minutes at least 2-3 days each week ? (e.g., weight lifting, pushups, crunches)")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Safety: I wear my seatbelt while riding in a car.")} 
          />
        </Col>
      </Row>
      <SelectQuestion captionColSize="10" valueColSize="1" onChange={this.props.onTextChange} options={alcoholCountList()}
        question={getQuestion(this.props.questionList, SECTION.LIFESTYLE_QUESTIONS, "Alcohol: How many drinks do you have each week? (1 drink = 12 oz beer, 5 oz wine, or 1.5 oz liquor)")} 
      />
    </QuestionSection>*/}
  </React.Fragment>
  );
}
}

class DefaultSurvey extends Component {
constructor(props) {
  super(props);
  this.state = {};
}  

render() {
  return (
  <React.Fragment>
    <QuestionSection Title={SECTION.PARTICIPANTS}>
      <TextQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Name")} 
      />
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Date")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Social Security Number")} 
      />
      <TextQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Home Address")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "City")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "State")} 
      />
      <TextQuestion captionColSize="2" valueColSize="1" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Zip")} 
      />
      <TextQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Work City/State Employed")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Work Phone")} 
      />
      <TextQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Work Email")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} ensureValidDate={true}
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Date of Birth")} 
      />
      <SelectQuestion captionColSize="2" valueColSize="2" onChange={this.props.onTextChange} options={genderList()}
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Gender")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Race")} 
      />
      <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.PARTICIPANTS, "Height")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.PERSONAL_MEDICAL_HISTORY}>
      <TextQuestion captionColSize="2" valueColSize="9" onTextChange={this.props.onTextChange} multiLine={true}
        question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Current Medical Problems")} 
      />
      <TextQuestion captionColSize="2" valueColSize="9" onTextChange={this.props.onTextChange} multiLine={true}
        question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Past Hospitalizations/Surgeries")} 
      />
      <Row className="mb-2">
        <Col className="survey-prompt">
          Please check if you have had any of the following conditions:
        </Col>
      </Row>
      <Row>
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Chest Pain")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Heart attack/heart disease,angina")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "High cholesterol or triglycerides")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "High blood pressure")} 
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Stroke")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Diabetes")} 
          />
          {isQuestionChecked(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Diabetes")
            ? (<TextQuestion captionColSize="5" valueColSize="3" onTextChange={this.props.onTextChange}
                question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Age of Onset")} 
              />)
            : null
          }          
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Cancer")} 
          />
          {isQuestionChecked(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Cancer")
            ? (<CancerTypesQuestion captionColSize="3" valueColSize="8" onToggleListEntry={this.props.onToggleListEntry}
                question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Type(s)?")} 
              />)
            : null
          }          
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Asthma")} 
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Thyroid problems")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Stomach problems, ulcers, chronic indigestion")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Kidney disease")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Epilepsy, seizures")} 
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Osteoporosis")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Bleeding disorders or anemia")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Migraine headaches, dizziness, fainting spells")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Back pain, joint pain or injury, arthritis")} 
          />
          {isQuestionChecked(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Back pain, joint pain or injury, arthritis")
            ? (<SelectQuestion captionColSize="8" valueColSize="3" onChange={this.props.onTextChange} options={boolList()} isBoolean={true}
                question={getQuestion(this.props.questionList, SECTION.PERSONAL_MEDICAL_HISTORY, "Does this prevent you from exercising?")} 
              />)
            : null
          }          
        </Col>
      </Row>
    </QuestionSection>
    <QuestionSection Title={SECTION.FAMILY_HISTORY}>
      <Row className="mb-2">
        <Col className="survey-prompt">
          Do you have a family history of? (Please include parents, grandparents, and siblings)<br/>
          (Optional, if incomplete the nurse may collect information during the health evaluation.)
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Heart Disease/Heart Attack?")} 
          />
          {isQuestionChecked(this.props.questionList, SECTION.FAMILY_HISTORY, "Heart Disease/Heart Attack?")
            ? (<Row><Col sm={{size: 8, offset: 2}}><CheckboxQuestion onChange={this.props.onTextChange}
                question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Over age 50?")} 
              /></Col></Row>)
            : null
          }          
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "High cholesterol/triglyceride Level?")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "High Blood Pressure?")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Stroke?")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Diabetes?")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Cancer?")} 
          />
          {isQuestionChecked(this.props.questionList, SECTION.FAMILY_HISTORY, "Cancer?")
            ? (<CancerTypesQuestion captionColSize="3" valueColSize="8" onToggleListEntry={this.props.onToggleListEntry}
                question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Type(s)?")} 
              />)
            : null
          }          
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Obesity?")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Kidney Disease?")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Gout?")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Asthma?")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Depression/Anxiety?")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Thyroid disease?")} 
          />
        </Col>
        <Col sm="3">
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.FAMILY_HISTORY, "Osteoporosis?")} 
          />
        </Col>
      </Row>
    </QuestionSection>
    {questionAnswerIs(this.props.questionList, SECTION.PARTICIPANTS, "Gender", "F")
      ? (<QuestionSection Title={SECTION.FEMALES}>
          <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
            question={getQuestion(this.props.questionList, SECTION.FEMALES, "Date of last PAP smear/pelvic exam")} 
          />
          <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
            question={getQuestion(this.props.questionList, SECTION.FEMALES, "Date of last mammogram")} 
          />
        </QuestionSection>)
      : null
    }
    {questionAnswerIs(this.props.questionList, SECTION.PARTICIPANTS, "Gender", "M")
      ? (<QuestionSection Title={SECTION.MALES}>
          <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
            question={getQuestion(this.props.questionList, SECTION.MALES, "Date of last Prostate check")} 
          />
        </QuestionSection>)
      : null
    }
    <QuestionSection Title={SECTION.OVER_50}>
      <DateQuestion captionColSize="2" valueColSize="3" onTextChange={this.props.onTextChange} 
        question={getQuestion(this.props.questionList, SECTION.OVER_50, "Date of last colon screening")} 
      />
      {isQuestionChecked(this.props.questionList, SECTION.OVER_50, "Date of last colon screening")
        ? (<SelectQuestion captionColSize="2" valueColSize="4" onChange={this.props.onTextChange} options={screenTypeList()}
            question={getQuestion(this.props.questionList, SECTION.OVER_50, "Type of screen")} 
          />)
        : null
      }
    </QuestionSection>
    <QuestionSection Title={SECTION.MEDICATIONS}>
      <Row className="mb-2">
        <Col className="survey-prompt">
          List all vitamins, nutritional supplements and medications you are currently taking, including over-the-counter products.
        </Col>
      </Row>
      <TypedListQuestion captionColSize="3" valueColSize="8" onTypedListEntry={this.props.onTypedListEntry}
        question={getQuestion(this.props.questionList, SECTION.MEDICATIONS, "Medication List")} 
      />
    </QuestionSection>
    <QuestionSection Title={SECTION.HEALTH_HABITS}>
      <Row className="mb-2">
        <Col sm={{size: 5, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I use tobacco products")} 
          />
          {isQuestionChecked(this.props.questionList, SECTION.HEALTH_HABITS, "I use tobacco products")
            ? (<React.Fragment>
                <TextQuestion captionColSize="4" valueColSize="7" onTextChange={this.props.onTextChange} 
                  question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "What type?")} 
                />
                <SelectQuestion captionColSize="4" valueColSize="7" onChange={this.props.onTextChange} options={smokingList()}
                  question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "Number per day?")} 
                />
                <Col sm="11">
                  <CheckboxQuestion onChange={this.props.onTextChange}
                    question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "If you smoke, do you want to quit smoking?")} 
                  />
                </Col>
              </React.Fragment>)
            : null
          }
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 2, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I maintain a desired weight")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I engage in a regular exercise program")} 
          />
          {isQuestionChecked(this.props.questionList, SECTION.HEALTH_HABITS, "I engage in a regular exercise program")
            ? (<React.Fragment>
                <ActivitiesQuestion captionColSize="3" valueColSize="8" onToggleListEntry={this.props.onToggleListEntry}
                  question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "Type(s)?")} 
                />
                <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
                  question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "Times per week")} 
                />
                <TextQuestion captionColSize="2" valueColSize="2" onTextChange={this.props.onTextChange} 
                  question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "Minutes each session")} 
                />
              </React.Fragment>)
            : null
          }
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I eat a variety of foods each day, such as fruits and vegetables, whole grain breads and cereals, lean meats and low-fat dairy products.")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I limit the amount of saturated fat I eat (i.e. fat cuts of meats, butter, cream).")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I limit the amount of salt I eat by cooking with only small amounts.")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I avoid eating too much sugar, especially candy and soft drinks.")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I include fiber in my diet each day (i.e. whole grain breads and cereals).")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I include calcium in my diet each day (i.e. low-fat dairy products, and dark green leafy vegetables).")} 
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I drink alcoholic beverages.")} 
          />
          {isQuestionChecked(this.props.questionList, SECTION.HEALTH_HABITS, "I drink alcoholic beverages.")
            ? (<SelectQuestion captionColSize="4" valueColSize="2" onChange={this.props.onTextChange} options={alcoholCountList()}
                question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "How many servings/drinks per week?")} 
              />)
            : null
          }
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={{size: 10, offset: 1}}>
          <CheckboxQuestion onChange={this.props.onTextChange}
            question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "I wear my seatbelt while riding in a car.")} 
          />
        </Col>
      </Row>
    {questionAnswerIs(this.props.questionList, SECTION.PARTICIPANTS, "Gender", "F")
      ? (<Row className="mb-2">
          <Col sm={{size: 10, offset: 1}}>
            <CheckboxQuestion onChange={this.props.onTextChange}
              question={getQuestion(this.props.questionList, SECTION.HEALTH_HABITS, "If female, I practice monthly breast self-examination?")} 
            />
          </Col>
        </Row>)
      : null
    }
    </QuestionSection>
    <QuestionSection Title={SECTION.JOIN_REASONS}>
      <TextQuestion captionColSize="1" valueColSize="10" onTextChange={this.props.onTextChange} noPrompt={true}
        question={getQuestion(this.props.questionList, SECTION.JOIN_REASONS, "List your reasons for joining the program")} 
      />
    </QuestionSection>
  </React.Fragment>
  );
}
}

export class Survey extends Component {
constructor(props) {
  super(props);
  this.state = {
    message: null,
    messageFlavor: null,
    title: null,
    completedAt: null,
    lastSavedAt: null,
    questions: null,
    answers: null,
    hasSeenCompanyReleaseForm: false,
    hasSeenNoticeOfPrivacy: false,
    typeOfSurvey: null,
    redirectToHome: false,
    changeCount: 0
  };
  this.onTextChange = this.onTextChange.bind(this);
  this.onBoolChange = this.onBoolChange.bind(this);
  this.onSave = this.onSave.bind(this);      
  this.onSubmit = this.onSubmit.bind(this);
  this.preparePayload = this.preparePayload.bind(this);
  this.onToggleListEntry = this.onToggleListEntry.bind(this);
  this.answersChanged = this.answersChanged.bind(this);
  this.onTypedListEntry = this.onTypedListEntry.bind(this);
}
  
componentDidMount() {
  api.fetch('Survey').then(r => {
    let answers = initializeAnswers(r.data.questions, r.data.answers);
    this.setState({
      lastSavedAt: r.data.lastSavedAt,
      completedAt: r.data.completedAt,
      title: r.data.surveyTypeName,
      questions: applyAnswersToQuestions(r.data.questions, answers),
      answers: answers,
      typeOfSurvey: r.data.typeOfSurvey
    });
  }).catch(helpers.catchHandler);
}

answersChanged(answers) {
  const questions = applyAnswersToQuestions(Object.assign({}, this.state.questions), answers);
  let changeCount = this.state.changeCount + 1;
  if (changeCount >= AUTO_SAVE_THRESHOLD_CHANGE_COUNT) {
    this.onSave();
    changeCount = 0;
  }
  this.setState({questions: questions, answers : answers, changeCount: changeCount });
}

onTextChange(event) {
  let newAnswers = Object.assign({}, this.state.answers);
  newAnswers[event.target.name] = {value: event.target.value};
  this.answersChanged(newAnswers);
}

onBoolChange(event) {
  this.setState({[event.target.name]: event.target.checked});
}

onToggleListEntry(data) {
// {questionName: getQuestionName(this.props.question), value: event.target.checked, name: event.target.name }
  let answers = Object.assign({}, this.state.answers);
  if (!answers[data.questionName].value) answers[data.questionName].value = [];
  let idx = _.findIndex(answers[data.questionName].value, n => { return n === data.name; });
  if (idx === -1) {
    answers[data.questionName].value.push(data.name);
  } else {
    answers[data.questionName].value.splice(idx, 1);
  }
  this.answersChanged(answers);
}

onTypedListEntry(data) {
// {questionName: ?, value: [] }
  let answers = Object.assign({}, this.state.answers);
  if (!answers[data.questionName].value) answers[data.questionName].value = [];
  answers[data.questionName].value = data.value;
  this.answersChanged(answers);
}

preparePayload() {
  let payloadAnswers = {};
  for(let qName in this.state.answers) {
    const qId = getQuestionIdFromName(qName);
    const q = getQuestionById(this.state.questions, qId);
    if (!q) {
      console.warn('could not find ' + qId + ' in ', this.state.questions)
      return;
    }
    if (q.typeOfSurveyQuestion === 8) { // dates must be serialized from moment
      let val = null;
      if (this.state.answers[qName].value) {
        if (moment.isMoment(this.state.answers[qName].value)) {
          val = date_helpers.getStringFromMoment(this.state.answers[qName].value);
        } else {
          val = this.state.answers[qName].value;
        }
      }
      payloadAnswers[qName] = {
        qId: qId,
        value: val
      };
    } else {
      payloadAnswers[qName] = {
        qId: qId,
        value: this.state.answers[qName].value
      };
    }
  }
  return {JSONData: JSON.stringify(payloadAnswers)};
}

onSave() {
  api.post('Survey/Save', this.preparePayload()).then(response => {
    // silent as this is an auto-save
  }).catch(helpers.catchHandler);
}

onSubmit(event) {
  event.preventDefault();
  api.post('Survey/Finalize', this.preparePayload()).then(response => {
    if (response.data.success === false) {
      this.setState({ messageFlavor: "danger", message: response.data.message });
    } else {
      this.setState({ messageFlavor: "success", message: "Thank you for completing this questionnaire!  You'll be sent to the home screen momentarily." });
      setTimeout(function(component) {
        component.setState({redirectToHome: true});
      }, 4000, this);
    }
  }).catch(helpers.catchHandler);
};

render() {
    if (this.state.redirectToHome) {
      return (<Redirect to='/home' />);
    }
    const isSubmittable = allRequiredQuestionsHaveAnswers(this.state.questions) 
      && this.state.hasSeenCompanyReleaseForm 
      && this.state.hasSeenNoticeOfPrivacy;
    return (
      <Container fluid>
        <Card>
          <CardHeader className='progressCardTitle'>Program Acknowledgement</CardHeader>
          <CardBody className="pt-0">
            {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>) }
            {this.state.typeOfSurvey === SURVEY_TYPE.DEFAULT && this.state.questions !== null
              ? <DefaultSurvey
                  questionList={this.state.questions}
                  onTextChange={this.onTextChange}
                  onToggleListEntry={this.onToggleListEntry}
                  onTypedListEntry={this.onTypedListEntry}
                />
              : null
            }
            {this.state.typeOfSurvey === SURVEY_TYPE.BBT && this.state.questions !== null
              ? <BBTSurvey 
                  questionList={this.state.questions}
                  onTextChange={this.onTextChange}
                  onTypedListEntry={this.onTypedListEntry}
                />
              : null
            }
            {this.state.typeOfSurvey === SURVEY_TYPE.TEL && this.state.questions !== null
              ? <TELSurvey 
                  questionList={this.state.questions}
                  onTextChange={this.onTextChange}
                  onTypedListEntry={this.onTypedListEntry}
                />
              : null
            }
            {this.state.typeOfSurvey === SURVEY_TYPE.CRANE && this.state.questions !== null
              ? <CRANESurvey 
                  questionList={this.state.questions}
                  onTextChange={this.onTextChange}
                  onTypedListEntry={this.onTypedListEntry}
                />
              : null
            }
            {this.state.typeOfSurvey === SURVEY_TYPE.SUNTRUST && this.state.questions !== null
              ? <SunTrustSurvey 
                  questionList={this.state.questions}
                  onTextChange={this.onTextChange}
                  onTypedListEntry={this.onTypedListEntry}
                />
              : null
            }
          <QuestionSection Title="Required Documents">
            <Row className="mb-2">
              <Col>
                <input type="checkbox" onChange={this.onBoolChange} name="hasSeenCompanyReleaseForm" />{starSpan()} I acknowledge that I have had an opportunity to review my Company's Release form 
                  <a href={`/Content/Company${this.props.currentUser.companyId}/company-release.pdf`} className="btn btn-sm xs btn-dark ml-1" title="Download Company Release Form" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon='link' />
                  </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <input type="checkbox" onChange={this.onBoolChange} name="hasSeenNoticeOfPrivacy" />{starSpan()} I acknowledge that I have had an opportunity to review Peak Health's Notice of Privacy 
                  <a href="/Content/PDF/PrivacyPolicy-Wellness-Release.pdf" className="btn btn-sm xs btn-dark ml-1" title="Download Peak Health's Notice of Privacy" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon='link' />
                  </a>
              </Col>
            </Row>
          </QuestionSection>
          <Row className='mt-3'>
            <Col>
              <Button color={isSubmittable ? "primary" : "secondary"} disabled={!isSubmittable} style={{width: "100%"}} onClick={this.onSubmit}>Submit</Button>
            </Col>
          </Row>
          </CardBody>
        </Card>
      </Container>
    );
  };
}
