import React, { Component } from 'react';
import { TemplateCollapsibleCard } from './';
import helpers from '../utils/helpers';
import moment from 'moment';
import date_helpers from '../utils/date_helpers';

export class WidgetApptRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
    this.renderEnrolledLink = this.renderEnrolledLink.bind(this);
  } 

  renderEnrolledLink() {
    const appt = helpers.getNextAppt() || this.props.nextAppt;
    const apptOn = appt
      ? date_helpers.getMomentFromString(appt.scheduledAt)
      : null;
    if (!apptOn || apptOn.isAfter(moment().add(90, 'days'))) {
      return (<span>Your nurse visit has not yet been scheduled. 
        You will receive an email notification with the date of your next scheduled appointment within 8 weeks of the actual appointment date. 
        Check back when your next appointment date is within 90 days of today's date and is no longer showing pending.</span>);
    }
    return (<a href='/appointment/calendar'>View/Reschedule Nurse Appointment</a>);
  }

  render() {
    // if (this.state.redirectToMap) {
    //   return <Redirect to='/appointment/map' />;
    // }
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
        <p>
          <img src="/Content/panels/nurse-appt.png" alt="Nurse Appointment Scheduler" style={{float: "left", marginRight: "8px"}}/>
          {this.props.isEnrolled
            ? this.renderEnrolledLink()
            : (<span>Please complete the enrollment steps to have your first appointment scheduled by Peak Health.</span>)
          }
        </p>
      </TemplateCollapsibleCard>
    );
  }
}