import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { VisitSummary, TemplateCollapsibleCard } from './';

export class WidgetVisitHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
        <VisitSummary 
          latestVisit={this.props.latestVisitDetail}
        />
      </TemplateCollapsibleCard>
    );
  }
}