import React, { Component } from "react";
import { Table } from "reactstrap";
import constants from "../utils/constants";
import _ from "lodash";

export class QuarterlyOperationalChangesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.headerContent = this.headerContent.bind(this);
    this.bodyContent = this.bodyContent.bind(this);
  }

  headerContent() {
    const data = this.props.quarterlyOperationalChangesData;
    const reportingPeriods = _.map(data, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={index}>{item.reportingPeriod}</td>
        </React.Fragment>
      );
    });

    return (
      <>
        <tr
          style={{
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          <td>Quarterly Changes</td>
          {reportingPeriods}
        </tr>
      </>
    );
  }

  bodyContent() {
    const data = this.props.quarterlyOperationalChangesData;
    const newLifeforceEnrollmentCount = _.map(data, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={index}>{item.newLifeforceEnrollmentCount}</td>
        </React.Fragment>
      );
    });
    const newLifeforceNewlyHiredCount = _.map(data, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={index}>{item.newLifeforceNewlyHiredCount}</td>
        </React.Fragment>
      );
    });
    const newLifeforceSelfTerminationCount = _.map(data, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={index}>{item.newLifeforceSelfTerminationCount}</td>
        </React.Fragment>
      );
    });
    const newCompanyTerminationCount = _.map(data, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={index}>{item.newCompanyTerminationCount}</td>
        </React.Fragment>
      );
    });
    const newPeakTerminationCount = _.map(data, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={index}>{item.newPeakTerminationCount}</td>
        </React.Fragment>
      );
    });
    const lifeforceVisitCount = _.map(data, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={index}>{item.lifeforceVisitCount}</td>
        </React.Fragment>
      );
    });
    return (
      <>
        <tr
          style={{
            backgroundColor: constants.PEAK_COLORS.green,
            color: "white",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.purple)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.green)
          }
        >
          <td style={{ fontWeight: "bold" }}># New LF Enrollments</td>
          {newLifeforceEnrollmentCount}
        </tr>
        <tr
          style={{
            backgroundColor: constants.PEAK_COLORS.blue,
            color: "white",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.purple)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = constants.PEAK_COLORS.blue)
          }
        >
          <td style={{ fontWeight: "bold" }}># New LF Newly Hired</td>
          {newLifeforceNewlyHiredCount}
        </tr>
        <tr
          style={{
            backgroundColor: constants.PEAK_COLORS.green,
            color: "white",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.purple)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.green)
          }
        >
          <td style={{ fontWeight: "bold" }}># New LF Self-Terminations</td>
          {newLifeforceSelfTerminationCount}
        </tr>
        <tr
          style={{
            backgroundColor: constants.PEAK_COLORS.blue,
            color: "white",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.purple)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = constants.PEAK_COLORS.blue)
          }
        >
          <td style={{ fontWeight: "bold" }}># New Company Termination</td>
          {newCompanyTerminationCount}
        </tr>
        <tr
          style={{
            backgroundColor: constants.PEAK_COLORS.green,
            color: "white",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.purple)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.green)
          }
        >
          <td style={{ fontWeight: "bold" }}># New Peak Termination</td>
          {newPeakTerminationCount}
        </tr>
        <tr
          style={{
            backgroundColor: constants.PEAK_COLORS.blue,
            color: "white",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.purple)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = constants.PEAK_COLORS.blue)
          }
        >
          <td style={{ fontWeight: "bold" }}>Lifeforce Visits</td>
          {lifeforceVisitCount}
        </tr>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="table-responsive">
          <Table>
            <thead>{this.headerContent()}</thead>
            <tbody>{this.bodyContent()}</tbody>
            <tfoot></tfoot>
          </Table>
        </div>
      </>
    );
  }
}
