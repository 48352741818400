import React, { Component} from 'react';
import { Container, Card, CardBody, Col, Row, CardTitle } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import {Goals} from "../components"
import api from '../utils/api';
import helpers from '../utils/helpers';
import _ from 'lodash';

export class ProgressAndGoals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latestVisitDetail: null,
            programRecom: [],
            redirectFILLIN: false,
            EducationalPDFS: [],
            recommendedText: [],
            handouts: []
            // recommendedText:['Drink plenty of water', 'Eat 4-5 fruit and vegetable servings daily', 'Eat a heart healthy diet', 'Increase  exercise/activity level', 'Limit dietary intake of sugars', 'Limit white breads, pasta, snacks', 'Watch portion sizes/eat regular meals']
        }
    }

    componentDidMount() {
        api.post('Home/GetAccountInfo', {}).then(r => {
            if(r.data.latestVisitDetail) {
            const recommendations = r.data.latestVisitDetail.recomText
                ? r.data.latestVisitDetail.recomText
                : "";
            this.setState({
                latestVisitDetail: r.data.latestVisitDetail,
                recommendedText: recommendations.split(";"),
                handouts: r.data.handouts
            });
          }
        }).catch(helpers.catchHandler);

        api.fetch('Home/ProgramRecomms').then(r => {
            if(r.data) { 
            this.setState({
                programRecom: r.data
              });
            }
          }).catch(helpers.catchHandler);
      }


    render() {
        if (this.state.redirectFILLIN) {
            return (<Redirect to="/" />);
        }
        return(
        <Container fluid className='p-0'>
            <Row>
                <Col>
                    <Card className='border-0'>
                        <CardTitle className="progressCardTitle p-2">
                          Your Goals
                        </CardTitle>
                        <CardBody className='pt-0 pl-2'>
                            <Row>
                                <Col>
                                Your nurse recommends that you pursue the following goals based on your most recent Peak Health apppointment:    
                                </Col>
                            </Row>
                            <Row>
                            <Col>
                                {this.state.latestVisitDetail
                                    ? (<Goals 
                                        latestVisit={this.state.latestVisitDetail}
                                    />)
                                    : null
                                }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className='border-0'>
                      <CardTitle className="progressCardTitle p-2">
                        Nurse Recommendations
                      </CardTitle>
                        <CardBody className='pt-0 pl-2'>
                            <Row>
                                <Col>
                                    Your nurse recommends that you take the following actions based on your most recent Peak Health Appointment:
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ul>
                                        {_.map(this.state.recommendedText, (rec, index) => {
                                            return(
                                                <li key={`rec-${index}`}>{rec}</li>
                                            );
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                <Card className='border-0'>
                    <CardTitle className="progressCardTitle p-2">
                        Recommended Programs
                    </CardTitle>
                    <CardBody className='pt-0 pl-2'>
                        <Row>
                            <Col>
                                Your nurse recommends the following programs based on your most recent Peak Health Appointment:
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="m-3">
                                    <Col xs="12" md="8" className="bg-info text-light">
                                        Program Name &amp; Website
                                    </Col>
                                    <Col xs="12" md="4" className="bg-info text-light">
                                        How To Contact
                                    </Col>
                                </Row>
                                {_.map(this.state.programRecom, (p) => 
                                  (<Row className="m-3">
                                    <Col xs="12" md="8">
                                      {p.programName}<br />
                                      <a href={p.webLink} target="_blank" rel="noopener noreferrer">{p.webLink}</a>
                                    </Col>
                                      <Col xs="12" md="4">
                                        {p.contactInfo}
                                      </Col>
                                  </Row>))
                                }
{/*                                <Table striped className="table-bordered">
                                    <thead className='thead-light'>
                                        <tr>
                                            <th>Program Name</th>
                                            <th>How To Contact</th>
                                            <th>Website</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(this.state.programRecom, (p) => {
                                            return(
                                                <tr>
                                                    <td>{p.programName}</td>
                                                    <td>{p.contactInfo}</td>
                                                    <td><a href={p.webLink} target="_blank">{p.webLink}</a></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
*/}                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className='border-0'>
                        <CardTitle className="progressCardTitle p-2">
                            Educational Handouts
                        </CardTitle>
                        <CardBody className='pt-0 pl-2'>
                             <Row>
                                <Col>
                                    Your nurse has selected the following educational handouts for you to review:
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ul className='pl-0 pt-0' style={{listStyle: "none"}}>
                                    {_.map(this.state.handouts, (h, index) => {
                                            return(
                                              <li key={`rec-${index}`}><a href={`/Content/PDF/${h.fileName}`} target="_blank" rel="noopener noreferrer">{h.description}</a></li>
                                            );
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        );
    }
}