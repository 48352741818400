import React, { Component } from "react";
import { Table } from "reactstrap";
import _ from "lodash";
import date_helpers from "../utils/date_helpers";
import constants from "../utils/constants";

export class AverageDaysNewHireEnrollmentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.headerContent = this.headerContent.bind(this);
    this.bodyContent = this.bodyContent.bind(this);
    this.averageSinceSelectedStartDate =
      this.averageSinceSelectedStartDate.bind(this);
  }

  headerContent() {
    const newHireEnrollmentsData = this.props.avgDaysNewHireEnrollmentsData;
    const yrMonthColumns = _.map(newHireEnrollmentsData, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={`${index}-yrMonths`}>{item.yrMonthHired}</td>
        </React.Fragment>
      );
    });

    return (
      <>
        <tr
          style={{
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          <td>Month</td>
          {yrMonthColumns}
        </tr>
      </>
    );
  }

  bodyContent() {
    const newHireEnrollmentsData = this.props.avgDaysNewHireEnrollmentsData;
    const counts = _.map(newHireEnrollmentsData, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={`${index}-enrolledCount`}>{item.count}</td>
        </React.Fragment>
      );
    });
    const averageDays = _.map(newHireEnrollmentsData, (item, index) => {
      return (
        <React.Fragment key={index}>
          <td key={`${index}-average-days`}>{item.avgDaysToEnroll}</td>
        </React.Fragment>
      );
    });
    return (
      <>
        <tr style={{ backgroundColor: constants.PEAK_COLORS.green }}>
          <td>
            <strong>Enrolled</strong>
          </td>
          {counts}
        </tr>
        <tr style={{ backgroundColor: constants.PEAK_COLORS.blue }}>
          <td>
            <strong>Average Days</strong>
          </td>
          {averageDays}
        </tr>
      </>
    );
  }

  averageSinceSelectedStartDate() {
    var momentStartDate = this.props.selectedStartDate;
    var startDate = date_helpers.toDateString(momentStartDate);

    const newHireEnrollmentsData = this.props.avgDaysNewHireEnrollmentsData;

    const averageDays = _.map(newHireEnrollmentsData, (item) => {
      return item.avgDaysToEnroll || 0;
    });

    const avgOfAverageDays = Math.round(_.mean(averageDays));

    return (
      <>
        <tr
          style={{
            backgroundColor: constants.PEAK_COLORS.purple,
            whiteSpace: "nowrap",
          }}
        >
          <td>
            <strong>Current Average Since {startDate}:</strong>{" "}
            {avgOfAverageDays}
          </td>
        </tr>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="table-responsive">
          <Table>
            <thead>{this.headerContent()}</thead>
            <tbody
              style={{
                color: "white",
              }}
            >
              {this.bodyContent()}
            </tbody>
            <tfoot
              style={{
                color: "white",
              }}
            >
              {this.averageSinceSelectedStartDate()}
            </tfoot>
          </Table>
        </div>
      </>
    );
  }
}
