import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Collapse, Row, Col, CardImg } from 'reactstrap';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class TemplateCollapsibleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse,
      arrowButton: props.collapse, // beginning value is equal to the collapse prop
      displayName: props.displayName,
      widgetIcon: props.widgetIcon,
      cardImage: props.cardImage,
      headerClass: props.headerClass
    };
    this.toggle = this.toggle.bind(this);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.options !== this.props.options) {
  //     this.setState({options: this.props.options});
  //   }
  // }

  toggle() {
    this.changeArrowButton();
    this.setState({ collapse: !this.state.collapse });
  }

  changeArrowButton() {
    this.setState({ 
      arrowButton: !this.state.arrowButton
    });
  }

  render() {
    return (
      <Card className="homeWidgets mb-2 mt-0 mx-0 border-0">
        <CardHeader onClick={this.toggle} className={`expand-md progressCardTitle`}>
          <Row>
            <Col xs="10">
              <p>
                {this.state.widgetIcon 
                  ? <FontAwesomeIcon icon={this.state.widgetIcon} className="mr-3"/>
                  : null
                }
                <span>{this.state.displayName}</span>
                </p>
            </Col>
            <Col xs="2" className="text-right">
            <p>
              {this.state.arrowButton 
                ? <FontAwesomeIcon icon={['fas', 'angle-up']} />
                : <FontAwesomeIcon icon={['fas', 'angle-down']} />
              }
            </p>
            </Col>
          </Row> 
        </CardHeader>
        <Collapse isOpen={!this.state.collapse}>
          <CardBody className="p-2">
            {this.state.cardImage
              ? <CardImg top width="100%" src={`${this.state.cardImage.src}`} alt={`${this.state.cardImage.alt}`} className="m-0" />
              : null
            }            
            <Row className="mb-2">
              <Col sm="12">
                <Card className="summaryCard">
                  <CardBody className="p-2">
                    {this.props.children}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>          
        </Collapse>
      </Card>
    );
  }
}