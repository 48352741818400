import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';

export class HereditaryRiskCodes extends Component {
  displayName = "Hereditary Risk Codes";
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }
  

  render() {
    return (
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm" className="my-0">
            <tbody className="my-0">
              <tr>
                <td width="10%" className="hereditaryRiskCodes topRow">
                  A
                </td>
                <td className="topRow">
                Fm Hx Heart Disease &lt; 50
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  B
                </td>
                <td>
                  Fm Hx Heart Disease &gt; 50
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  C
                </td>
                <td>
                  Elevated Blood Lipids
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  D
                </td>
                <td>
                  High Blood Pressure
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  E
                </td>
                <td>
                  Stroke
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  F
                </td>
                <td>
                  Diabetes
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  G
                </td>
                <td>
                  Breast Cancer
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  H
                </td>
                <td>
                  Cervical/Uterine/Ovarian Cancer
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  I
                </td>
                <td>
                  Colo-Rectal Cancer
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  J
                </td>
                <td>
                  Other Cancer
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  K
                </td>
                <td>
                  Obesity
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  L
                </td>
                <td>
                  Kidney Disease
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  M
                </td>
                <td>
                  Gout
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  N
                </td>
                <td>
                  Prostate Cancer
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  P
                </td>
                <td>
                  Depression/Anxiety/Stress
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  U
                </td>
                <td>
                  Thyroid disorders
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  V
                </td>
                <td>
                  Osteoporosis
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  W
                </td>
                <td>
                  Unknown or Adopted
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  X
                </td>
                <td>
                  Other
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  Y
                </td>
                <td>
                  Asthma
                </td>
              </tr>
              <tr>
                <td width="10%" className="hereditaryRiskCodes">
                  Z
                </td>
                <td>
                  None
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}