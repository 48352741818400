import React, { Component, Fragment } from 'react';
import { Container, Card, CardBody, Col, Row, CardTitle } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Goals2 } from "../components"
import api from '../utils/api';
import classnames from "classnames";
import helpers from '../utils/helpers';
import _ from 'lodash';

const SCORE_GUIDE_LIST = [
  {phase: 1, scoreRange: 'Less than 35', dsc: 'High health risk'},
  {phase: 2, scoreRange: '35-47', dsc: 'Moderate health risk'},
  {phase: 3, scoreRange: '48-69', dsc: 'Low health risk'},
  {phase: 4, scoreRange: '70-91', dsc: 'Healthy'},
  {phase: 5, scoreRange: '92-100', dsc: 'Very healthy'},
];

const ROUNDED_TOP_CORNERS = {borderRadius: "8px 8px 0 0"};

const ScoreRow = ({metricName, score, maxScore, bold = false}) => {
  const classList = classnames({"fw-bold": bold}, "px-2 py-1");
  return (<Row className="mx-3 my-0" style={{lineHeight: "18px"}}>
  <Col xs="12" md="4" className={classList}>
    {metricName}
  </Col>
  <Col xs="12" md="4" className={classList}>
    {score}
  </Col>
  <Col xs="12" md="4" className={classList}>
    {maxScore}
  </Col>
</Row>);
}

export class ProgressAndGoals2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programName: "",
            latestVisitDetail: null,
            programRecom: [],
            redirectFILLIN: false,
            EducationalPDFS: [],
            recommendedText: [],
            handouts: [],
            recentVisits: [],
            showBodyFat: props.currentUser.isBBT || props.currentUser.isTIH
        }
    }

    componentDidMount() {
        api.post('Home/GetAccountInfo', {}).then(r => {
          if(r.data.latestVisitDetail) {
            const recommendations = r.data.latestVisitDetail.recomText
                ? r.data.latestVisitDetail.recomText
                : "";
            this.setState({
              programName: r.data.accountSummary?.healthProgramName || "", // || "LifeForce",
              latestVisitDetail: r.data.latestVisitDetail,
              recommendedText: _.filter(recommendations.split(";"), r => r.length),
              handouts: r.data.handouts,
              recentVisits: _.take(_.orderBy(r.data.completedHealthEvaluations, 'visit', 'desc'), 2)
            });
          }
        }).catch(helpers.catchHandler);

        api.fetch('Home/ProgramRecomms').then(r => {
            if(r.data) { 
            this.setState({
                programRecom: r.data
              });
            }
          }).catch(helpers.catchHandler);
      }


    render() {
        if (this.state.redirectFILLIN) {
            return (<Redirect to="/" />);
        }
        return(
        <Container fluid className='p-0'>
            <Row>
              <Col>
                <Card className='border-0'>
                    <CardTitle className="progressCardTitle p-2">
                      Your Progress / Goals
                    </CardTitle>
                    <CardBody className='pt-0 pl-2 pb-1'>
                      <Row className="mb-2">
                        <Col>
                          Your nurse recommends that you pursue the following goals based on your most recent Peak Health apppointment:    
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row className="bg-info text-light mx-1 mb-1" style={ROUNDED_TOP_CORNERS}>
                            <Col sm="6">
                              Measurement
                            </Col>
                            <Col sm="2">
                              Your Value
                            </Col>
                            <Col sm="4">
                              Goal
                            </Col>
                          </Row>
                          {this.state.latestVisitDetail
                            ? (<Goals2
                                latestVisit={this.state.latestVisitDetail}
                                showBodyFat={this.state.showBodyFat}
                              />)
                            : null
                          }
                        </Col>
                      </Row>
                    </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card className='border-0'>
                  <CardTitle className="progressCardTitle p-2">
                    Your {this.state.programName} Phase
                  </CardTitle>
                  <CardBody className='pt-0 pl-2 pb-0'>
                    <Row className="bg-info text-light mx-1 mb-1" style={ROUNDED_TOP_CORNERS}>
                      <Col xs="12" md="4" className="px-2 py-1">
                        Appointment Date
                      </Col>
                      <Col xs="12" md="4" className="px-2 py-1">
                        Phase
                      </Col>
                      <Col xs="12" md="4" className="px-2 py-1">
                        Total Score
                      </Col>
                    </Row>
                  {_.map(this.state.recentVisits, (p, idx) => 
                    (<Row className="mx-1 my-0" key={p.visitOn}>
                      <Col xs="12" md="4" className="px-2 py-1">
                        {idx === 0
                          ? "Last Visit"
                          : "Previous Visit"
                        } {p.visitOn
                            ? ` (${p.visitOn})`
                            : null
                          }
                      </Col>
                      <Col xs="12" md="4" className="px-2 py-1">
                        {p.phase}
                      </Col>
                      <Col xs="12" md="4" className="px-2 py-1">
                        {p.score || "N/A"}
                      </Col>
                    </Row>))
                  }
                  {this.state.latestVisitDetail
                  ? (<Fragment>
                    <Row className="mx-1 mt-3">
                      <Col xs="12" className="bg-info text-light pt-1 px-2" style={ROUNDED_TOP_CORNERS}>
                        <h6 className="mb-0">
                          {this.state.programName} Phase Scoring Details
                        </h6>
                      </Col>
                    </Row>
                    <Row className="mx-1 my-0">
                      <Col xs="12" md="4" className="bg-info text-light px-2 py-1">
                        Metric
                      </Col>
                      <Col xs="12" md="4" className="bg-info text-light px-2 py-1">
                        Your Score (Visit)
                      </Col>
                      <Col xs="12" md="4" className="bg-info text-light px-2 py-1">
                        Max Score
                      </Col>
                    </Row>
                    <ScoreRow metricName="Tobacco Use" score={this.state.latestVisitDetail.tobPoints} maxScore="8" />
                    <ScoreRow metricName="Body Fat %/BMI" score={this.state.latestVisitDetail.bfbmiPoints} maxScore="12" />
                    <ScoreRow metricName="Cardiorespiratory Fitness" score={this.state.latestVisitDetail.fitnessPoints} maxScore="10" />
                    <ScoreRow metricName="Labs" score={this.state.latestVisitDetail.labPoints} maxScore="0" />
                    <ScoreRow metricName="Age/Gender Health Exams" score={this.state.latestVisitDetail.ageGenderPoints} maxScore="0" />
                    <ScoreRow metricName="Medical Follow-ups" score={this.state.latestVisitDetail.mdPoints} maxScore="0" />
                    <ScoreRow metricName="Fasting Glucose" score={this.state.latestVisitDetail.glucosePoints} maxScore="14" />
                    <ScoreRow metricName="Waist Circumference" score={this.state.latestVisitDetail.waistPoints} maxScore="14" />
                    <ScoreRow metricName="Triglycerides" score={this.state.latestVisitDetail.trigPoints} maxScore="14" />
                    <ScoreRow metricName="HDL Cholesterol/HDL Ratio" score={this.state.latestVisitDetail.hdlPoints} maxScore="14" />
                    <ScoreRow metricName="Blood Pressure" score={this.state.latestVisitDetail.bpPoints} maxScore="14" />
                    <ScoreRow metricName="TOTAL SCORE" score={this.state.latestVisitDetail.score} maxScore="100" bold />
                    </Fragment>)
                    : null
                  }
                    <Row className="mx-1 mt-3">
                      <Col xs="12" className="bg-info text-light pt-1 px-2" style={ROUNDED_TOP_CORNERS}>
                        <h6 className="mb-0">
                          {this.state.programName} Phase Guide
                        </h6>
                      </Col>
                    </Row>
                    <Row className="mx-1 my-0">
                      <Col xs="12" md="4" className="bg-info text-light px-2 py-1">
                        Phase
                      </Col>
                      <Col xs="12" md="4" className="bg-info text-light px-2 py-1">
                        Total Score
                      </Col>
                      <Col xs="12" md="4" className="bg-info text-light px-2 py-1">
                        General Health Status
                      </Col>
                    </Row>
                  {_.map(SCORE_GUIDE_LIST, (p) => 
                    (<Row className="mx-3 my-0" key={p.phase}>
                      <Col xs="12" md="4" className="px-2 py-1">
                        {p.phase}
                      </Col>
                      <Col xs="12" md="4" className="px-2 py-1">
                        {p.scoreRange}
                      </Col>
                      <Col xs="12" md="4" className="px-2 py-1">
                        {p.dsc}
                      </Col>
                    </Row>))
                  }
                    <Row className="m-3">
                      <Col xs="12" className="px-2 py-1">
                        <a href="/Content/PDF/LifeForce-Phase-Guide-Overview.pdf" target="_blank" rel="noreferrer">
                          Click here for more details on how your phase and total score are derived.
                        </a>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <Card className='border-0'>
                  <CardTitle className="progressCardTitle p-2">
                    Nurse Recommendations
                  </CardTitle>
                  <CardBody className='pt-0 pl-2'>
                      {this.state.recommendedText.length
                      ? (<Fragment>
                          <Row>
                            <Col>
                              Your nurse recommends that you take the following actions based on your most recent Peak Health Appointment:
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ul>
                                {_.map(this.state.recommendedText, (rec, index) => ((<li key={`rec-${index}`}>{rec}</li>)))}
                              </ul>
                            </Col>
                          </Row>
                      </Fragment>)
                  : (<Row>
                      <Col className="fst-italic">
                        You do not currently have any recommendations.
                      </Col>
                    </Row>)}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className='border-0'>
                    <CardTitle className="progressCardTitle p-2">
                      Recommended Programs
                    </CardTitle>
                    <CardBody className='pt-0 pl-2'>
                      {this.state.programRecom.length
                        ? (<Fragment>
                        <Row>
                          <Col>
                            Your nurse recommends the following programs based on your most recent Peak Health Appointment:
                          </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="m-3">
                                    <Col xs="12" md="8" className="bg-info text-light px-2 py-1">
                                      Program Name &amp; Website
                                    </Col>
                                    <Col xs="12" md="4" className="bg-info text-light px-2 py-1">
                                      How To Contact
                                    </Col>
                                </Row>
                                {_.map(this.state.programRecom, (p) => 
                                  (<Row className="m-3">
                                    <Col xs="12" md="8" className="px-2 py-1">
                                      {p.programName}<br />
                                      <a href={p.webLink} target="_blank" rel="noopener noreferrer">{p.webLink}</a>
                                    </Col>
                                    <Col xs="12" md="4" className="px-2 py-1">
                                      {p.contactInfo}
                                    </Col>
                                  </Row>))
                                }
                            </Col>
                        </Row>
                        </Fragment>)
                        : (<Row>
                          <Col className="fst-italic">
                            You do not currently have any program recommendations.
                          </Col>
                      </Row>)
                        }
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className='border-0'>
                        <CardTitle className="progressCardTitle p-2">
                          Educational Handouts
                        </CardTitle>
                        <CardBody className='pt-0 pl-2'>
                          {this.state.handouts.length
                            ? (<Fragment>
                              <Row>
                                <Col>
                                  Your nurse has selected the following educational handouts for you to review:
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <ul className='pl-0 pt-0' style={{listStyle: "none"}}>
                                  {_.map(this.state.handouts, (h, index) => ((
                                      <li key={`rec-${index}`}><a href={`/Content/PDF/${h.fileName}`} target="_blank" rel="noopener noreferrer">{h.description}</a></li>
                                    )))}
                                  </ul>
                                </Col>
                              </Row>
                            </Fragment>)
                            : (<Row>
                              <Col className="fst-italic">
                                You do not currently have any handouts to review.
                              </Col>
                          </Row>)
                          }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        );
    }
}