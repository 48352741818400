import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import _ from "lodash";
import constants from "../utils/constants";
import helpers from "../utils/helpers";

export class AnnualEligibilityPeakAreaAndLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.processPeakAreaAndLineChartData =
      this.processPeakAreaAndLineChartData.bind(this);
    this.areaAndLineChartOptions = this.areaAndLineChartOptions.bind(this);
    // this.generateTableAndChartBorderColors =
    //   this.generateTableAndChartBorderColors.bind(this);
  }

  // generateTableAndChartBorderColors(backgroundColors) {
  //   return backgroundColors.map((backgroundColor) => {
  //     const colorArray = backgroundColor.split(",");
  //     const color = colorArray[0] + ", " + colorArray[1] + ", " + colorArray[2];
  //     return color + ", 1)";
  //   });
  // }

  processPeakAreaAndLineChartData(enrollmentData) {
    const eligibleBackgroundColors = [
      constants.PEAK_COLORS.green,
      constants.PEAK_COLORS.blue,
    ];

    const eligible = _.map(enrollmentData, (item) => {
      return item.eligible;
    });

    const enrolled = _.map(enrollmentData, (item) => {
      return item.enrolled;
    });

    const labels = _.map(enrollmentData, (item) => {
      return item.reportingPeriod;
    });

    const eligibleDataLength = eligible.length;
    const repeatingEligibleBackgroundColors = Array(eligibleDataLength)
      .fill(eligibleBackgroundColors)
      .flat();

    const repeatingEligibleBorderColors =
      helpers.generateTableAndChartBorderColors(
        repeatingEligibleBackgroundColors
      );

    const stackedBarChartData = {
      labels: labels,
      datasets: [
        {
          type: "line",
          label: "Eligible",
          data: eligible,
          backgroundColor: constants.PEAK_COLORS.green,
          borderColor: helpers.generateTableAndChartBorderColorForOne(
            constants.PEAK_COLORS.green
          ),
          pointbackgroundColor: repeatingEligibleBackgroundColors,
          pointBorderColor: repeatingEligibleBorderColors,
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
          fill: false,
        },
        {
          label: "Enrolled",
          data: enrolled,
          backgroundColor: constants.PEAK_COLORS.purple,
          borderColor: helpers.generateTableAndChartBorderColorForOne(
            constants.PEAK_COLORS.purple
          ),
          pointbackgroundColor: repeatingEligibleBackgroundColors,
          pointBorderColor: repeatingEligibleBorderColors,
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
          fill: true,
        },
      ],
    };

    return stackedBarChartData;
  }

  areaAndLineChartOptions() {
    const options = {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Eligible vs Enrolled Over Time",
        fontSize: 16,
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Years",
            },
          },
        ],

        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Enrolled/Eligible",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    return options;
  }
  render() {
    return (
      <>
        <div>
          <Line
            options={this.areaAndLineChartOptions()}
            width={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.width}
            height={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.height}
            data={this.processPeakAreaAndLineChartData(
              this.props.enrollmentData
            )}
          />
        </div>
      </>
    );
  }
}
