import React, { Component } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Alert } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { SetChallengeQA } from './index';
import { ResetPasswordBody } from '../components';
// import helpers from '../utils/helpers';
// import api from '../utils/api';
// import _ from 'lodash';

const TABS = {
  PASSWORD: "1",
  CHALLENGE: "2"
};

export class UserSecurity extends Component {
  displayName = 'Forgot Password';
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      activeTab: TABS.PASSWORD,
      redirect: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  // handleEmail(event) {
  //   this.setState({ email: event.target.value });
  // }

  // isFormValid(){
  //   let warnings = [];
  //   if (!this.state.email){
  //     warnings.push("Email address required");
  //   }
  //   if (warnings.length) {
  //     this.setState({
  //       message: warnings.join(','), 
  //       messageFlavor: "danger" 
  //     });
  //   } else {
  //     this.setState({ message: null });
  //   }
  //   return warnings.length === 0;
  // }

  // handleSubmit() {
  //   if(!this.isFormValid()) return;
  //   api.post('forgot-password', {email: this.state.email})
  //     .then ((response) => {
  //       if (response.data.success === true) {
  //         this.setState({messageFlavor: "success", message: "If we have a username with that address, we've just sent a reset link!"})
  //         setTimeout(() => {
  //           this.setState({ Redirect: true});
  //         }, 8000);
  //       } else {
  //         this.setState({errorMessage: response.data.message});
  //       }
  //     }).catch(helpers.catchHandler);
  // }

  render(){
      if (this.state.redirect) {
        return <Redirect to='/' />;
      }
      return (
        <Container fluid>
          <div>
            {this.state.message !== null
              ? <Alert color={this.state.messageFlavor}>{this.state.message}</Alert>
              : null
            }
          </div>
          <Row>
            <Col sm="2">
              <Nav tabs className="flex-column nav-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === TABS.PASSWORD })}
                    onClick={() => { this.toggle(TABS.PASSWORD); }}
                  >
                  Update Password
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === TABS.CHALLENGE })}
                    onClick={() => { this.toggle(TABS.CHALLENGE); }}
                  >
                  Set Challenge Question
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col sm="10">
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId={TABS.PASSWORD}>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col sm={{size: 4, offset: 4}}>
                          <ResetPasswordBody 
                            resetToken={null} 
                            message={this.state.message}
                            messageFlavor={this.state.messageFlavor}
                            redirectOnSuccess={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TABS.CHALLENGE}>
                  <Row>
                    <Col sm="12">
                      <SetChallengeQA 
                        redirectOnSuccess={false}
                        currentUser={this.props.currentUser} 
                        setChallengeQuestion={this.props.setChallengeQuestion}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      );
    }
}