import React, { Component } from 'react';
import { Container, Card, CardBody, CardHeader, Col, Row,
  Input, InputGroup, InputGroupText, InputGroupAddon,
  Form, Alert } from 'reactstrap';
import api from '../utils/api';
// import _ from 'lodash';

const emptySecurityQuestion = {
    id: null,
    questionId: null,
    question: null,
    answer: '',
    confirmAnswer: ''
}

export class SetSecurityQuestion extends Component {
  displayName = SetSecurityQuestion.name
  constructor(props) {
    super(props);
    this.state = {
      modal: props.showModal,
      backdrop: true,
      username: props.username,
      password: '',
      item: emptySecurityQuestion,      
      question_list: props.questionList,
      errorMessage: null,
      messageFlavor: null,
    };    
    this.closeModal = this.closeModal.bind(this);
    this.changeBackdrop = this.changeBackdrop.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({modal: this.props.showModal});
    }
    if (prevProps.questionList !== this.props.questionList) {
      this.setState({question_list: this.props.questionList});
    }
    if (prevProps.itemId !== this.props.itemId) {
      if (this.props.itemId < 0 || this.props.itemId === null) {
        this.setState({ item: emptySecurityQuestion});
      } else {
        const context = this;
        api.fetch(`user-question/${this.props.itemId}`)
          .then(response => {
            let item = response.data;
            item.question = {value: item.question_id, label: item.question_name};
            context.setState({ item: item });
          })
          .catch(error => error);
      }
    }
  }

  onChangeHandler(event) {
    var newItem = Object.assign({}, this.state.item, { [event.target.name]: event.target.value });
    this.setState({ item: newItem });
  }

  handleSubmit (event) {
    event.preventDefault();
    const data = {
        id: this.state.item.id,
        questionId: this.state.item.questionId,
        answer: this.state.item.answer,
        confirmAnswer: this.state.item.confirmAnswer
    };
    api.post('set-security-question', data).then(response => {
      if (response.data.success === false) {
        this.setState({messageFlavor: "danger", errorMessage: response.data.message});
      } else {
        this.setState({ messageFlavor: "success", message: "Log-In Successful!" });
        this.props.setCurrentUser(response.data.data, response.data.meta.token);
      }
    }).catch((error) => {
      console.log('ERROR in setting security question attempt', error);
    });
  }

  render() {
    return (
      <Container fluid>
        <Card className="m-1 mt-2">
          <CardBody>
            <Row className="mt-3">
              <Col size="12">
                <Card>
                  <CardHeader>Log On</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        {this.state.errorMessage !== null
                          ? <Alert className="ml-3" color={this.state.messageFlavor}>{this.state.errorMessage}</Alert>
                          : null
                        }
                        <Form onSubmit={this.handleSubmit} >
                          <Row className="mb-2">
                            <Col sm="12">
                              <p>In order to provide enhanced protection of your personal information,<br/>
                                it is necessary for you to provide a personal answer that we can later use<br/>
                                to verify your identity.
                              </p>
                              <p>This information is indecipherable, even to Peak, and will only be used<br/>
                                your identity when you have forgotten your normal password.
                              </p>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{width: "95px"}}>Question</InputGroupText>
                                </InputGroupAddon>
                                {/* Change this Input to a Select */}
                                <Input type="text"
                                  name="username"
                                  id="username"
                                  onChange={this.onChangeHandler}
                                  value={this.state.item.username}
                                  placeholder="add questions list here"
                                />
                              </InputGroup>            
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col sm="12">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{width: "95px"}}>Answer</InputGroupText>
                                </InputGroupAddon>
                                <Input type="text"
                                  name="answer"
                                  id="answer"
                                  onChange={this.onChangeHandler}
                                  value={this.state.item.answer}
                                  placeholder="Answer"
                                />
                              </InputGroup>            
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col sm="12">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{width: "95px"}}>Confirm</InputGroupText>
                                </InputGroupAddon>
                                <Input type="text"
                                  name="confirm"
                                  id="confirm"
                                  onChange={this.onChangeHandler}
                                  value={this.state.item.confirmAnswer}
                                  placeholder=" Confirm your Answer"
                                />
                              </InputGroup>            
                            </Col>
                          </Row>
                          <Row className="expand-md">
                            <Col className="text-right">
                              <div>
                                <input
                                  type="submit"
                                  value="Log On"
                                  className="btn-sm btn-primary"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
          </CardBody>
        </Card>
      </Container>
    );
  }
}