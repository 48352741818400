import React, { Component } from 'react';
import { Input, ModalHeader, ModalBody, Alert, ModalFooter, Modal, Form, Button, ButtonGroup, Card, CardHeader, CardBody, Col, Row, Table, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';
import api from '../utils/api';
// import filter_helpers from '../utils/filter_helpers';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const emptyPanel = {
  id: 0,
  PanelType: null,
  colSeq: '',
  seq: '',
  iconKey: '',
  title: '',
  imageKey: '',
  htmlContent: '',
  companyGroupId: null
};

const PANEL_TYPE_NAME = {
  0: "Unknown",
  1: "HTML Text",
  2: "Image",
  3: "Lab Results",
  4: "Health Update",
  5: "Visit History",
  6: "Account Summary",
  7: "LabCorp Results",
  8: "Participant HTML Text",
  9: "Progress Report",
  10: "Enrollment Status",
  11: "Appointment Request",
  12: "Enrollment Status (v2)",
  13: "Reccomendations and Referrals",
  14: "Banner Images"
};

const getSelectedCompanyGroup = (companyGroupId, companyGroupList) => {
  if (!companyGroupId || !companyGroupList) return null;
  return _.find(companyGroupList, c => { return c.value === companyGroupId; });
};

export class Panel extends Component {
constructor(props) {
  super(props);
  this.state = {
    companyGroupId: null,
    cloning: false,
    editPanel: null,
    companyGroupList: [],
    panelTypeList: [],
    imageKeyList: [],
    list: [],
    message: [],
    messageFlavor: []

  };
  this.showAdd = this.showAdd.bind(this);
  this.showClone = this.showClone.bind(this);
  this.refreshList = this.refreshList.bind(this);
  this.onCancelDialog = this.onCancelDialog.bind(this);
  this.onCompanyPanelShow = this.onCompanyPanelShow.bind(this);
  this.getListOfImages = this.getListOfImages.bind(this);
}

componentDidMount() {
  api.fetch('Reference/CompanyGroups').then((result) => {
    const list = result.data.map(function (item) {
      return {
        ...item,
        label: item.name,
        value: item.id
      };
    });
    this.setState({ companyGroupList: list });
  });
  api.fetch('Reference/PanelTypes').then((result) => {
    const list = result.data.map(function (item) {
      return {
        ...item,
        label: item.name,
        value: item.id
      };
    });
    this.setState({ panelTypeList: list });
  });
  api.fetch('Reference/ImagesList').then((result) => {
    const list = result.data;
    this.setState({ imageKeyList: list });
  });
  this.refreshList();
  this.getListOfImages();
}

getListOfImages() {
  api.fetch('Reference/ImagesList').then((result) => {
    const list = result.data;
    this.setState({ imageKeyList: list });
  }).catch(helpers.catchHandler);
}

onCancelDialog() {
  this.setState({ editPanel: null, cloning: false });
}

refreshList(companyGroupId = null) {
  if (companyGroupId === null) {
    if (this.state.companyGroupId !== null) {
      companyGroupId = this.state.companyGroupId;
    } else {
      return; // no company to query by yet
    }
  }
  api.fetch(`Admin/Panels/${companyGroupId}`).then(r => {
    this.setState({
      list: r.data,
      editPanel: null,
      cloning: false,
      companyGroupId: companyGroupId
    });
  }).catch(helpers.catchHandler)
}

onCompanyPanelShow(selected) {
  this.refreshList(selected.value);
}

showAdd(e) {
  let panel = Object.assign({}, emptyPanel);
  panel.companyGroupId = this.state.companyGroupId;
  this.setState({ editPanel: panel });
}

showClone(e) {
  this.setState({ cloning: true });
}

showEdit(editPanel) {
  this.setState({editPanel: editPanel });
  this.getListOfImages();
}

handleDelete(id) {
  api.post(`Admin/DeletePanel/${id}`, {}).then(r => {
    if (r.data.success === false) {
      this.setState({message: r.data.message, messageFlavor: "danger"});
    } else {
      this.refreshList();
    }
  }).catch(helpers.catchHandler);
}

render() {
  return (
    <Card className="m-2">
      <CardHeader>
        <Row>
          <Col>
            <div className="strong-header">Company Group</div>
          </Col>
          <Col>
            <ButtonGroup className='float-right'>
              {this.state.companyGroupId !== null
                ? (<Button color="success" onClick={this.showAdd}>
                    <FontAwesomeIcon icon="plus-circle" /> Add Panel
                  </Button>)
                : null
              }
              <Button color="dark" onClick={this.showClone}>
                <FontAwesomeIcon icon="clone" /> Clone
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-2">
        <Row className="pt-0 mt-0 mb-2">
          <Col sm="4">
            <Select
              closeMenuOnSelect
              value={getSelectedCompanyGroup(this.state.companyGroupId, this.state.companyGroupList)}
              isMulti={false}
              components={makeAnimated()}
              options={this.state.companyGroupList}
              onChange={this.onCompanyPanelShow}
              placeholder="Select a Company Group"
            />
          </Col>
        </Row>
        <Row>
          <Col>
          {this.state.list &&
          <Table striped hover size="sm">
          <thead>
            <tr>
              <th>Panel Type</th>
              <th>Title</th>
              <th>Col / Row</th>
              <th>Icon Key</th>
              <th>Image Key</th>
              <th width="15%" />
            </tr>
          </thead>
          <tbody>
          {this.state.list.map(t => (
            <tr key={t.id} className="data-row">
              <td><Badge color="dark">{PANEL_TYPE_NAME[t.panelType]}</Badge></td>
              <td>{t.title}</td>
              <td>{t.colSeq} / {t.seq}</td>
              <td>{t.iconKey}</td>
              <td>{t.imageKey}</td>
              <td className="text-right">
                <ButtonGroup>
                  <Button color="dark" onClick={this.showEdit.bind(this, t)} size="sm">
                    <FontAwesomeIcon icon='edit' /> Edit
                  </Button>
                  <Button color="danger" size="sm" onClick={() => window.confirm('Are you sure you wish to delete this item?') && this.handleDelete(t.id)}>
                    <FontAwesomeIcon icon='times-circle' />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>))
          }
          </tbody>
          </Table>
          }
          </Col>
  {this.state.editPanel
    ? <AddEditPanels 
        refreshList={this.refreshList} 
        onCancel={this.onCancelDialog}
        panel={this.state.editPanel} 
        panelTypeList={this.state.panelTypeList}
        imageKeyList={this.state.imageKeyList}
        companyGroupList={this.state.companyGroupList}
      />
    : null
  }
  {this.state.cloning
    ? <CloneCompany 
        refreshList={this.refreshList} 
        onCancel={this.onCancelDialog}
        destinationCG={getSelectedCompanyGroup(this.state.companyGroupId, this.state.companyGroupList)}
        companyGroupList={this.state.companyGroupList}
      />
    : null
  }
      </Row>
    </CardBody>
  </Card>);
    }
}

class AddEditPanels extends React.Component{
constructor(props) {
  super(props);
  let panel = Object.assign({}, props.panel);
  panel.PanelType = helpers.resolveValue(panel.PanelType, panel.panelType, panel.panelTypeName);
  panel.companyGroup = helpers.resolveValue(panel.companyGroup, panel.companyGroupId, panel.companyGroupName);
  this.state = {
    errorMessage: null,
    list: [],
    panel: panel,
    message: null,
    messageFlavor: null
  };
  this.onPanelTypeSelected = this.onPanelTypeSelected.bind(this);
  this.onCompanyGroupSelected = this.onCompanyGroupSelected.bind(this);
  this.onImageKeySelected = this.onImageKeySelected.bind(this);
  this.onSubmit = this.onSubmit.bind(this);
  this.isFormValid = this.isFormValid.bind(this);
  this.getCurrentPanelTypeSelectOption = this.getCurrentPanelTypeSelectOption.bind(this);
  this.getCurrentCompanySelectOption = this.getCurrentCompanySelectOption.bind(this);
  this.getCurrentImageKeySelectOption = this.getCurrentImageKeySelectOption.bind(this);
  this.findImageValue = this.findImageValue.bind(this);

}

isFormValid(){
  let warnings =[];
  if(!this.state.panel.PanelType){
    warnings.push("Please Select a valid Panel Type")
  }
  if(!this.state.panel.companyGroup){
    warnings.push("Please select a valid company group")
  }
  if (!this.state.panel.colSeq || this.state.panel.colSeq < 0) {
    warnings.push("Col Seq number is required and must be postive numbers");
  }
  if (!this.state.panel.seq || this.state.panel.seq < 0) {
    warnings.push("Sequence number is required and must be a postive number");
  }
  // if (!this.state.panel.title) {
  //   warnings.push("Title required for this panel type");
  // }
  if (warnings.length) {
    this.setState({
      message: warnings.join(','),
      messageFlavor: "danger"
    });
  } else {
    this.setState({ message: null });
  }
  return warnings.length === 0;
}

onSubmit(e) {
  if (!this.isFormValid()) return;
  e.preventDefault();
  const payload = {
    Id: this.state.panel.id,
    PanelType: this.state.panel.PanelType.value,
    ColSeq: this.state.panel.colSeq,
    Seq: this.state.panel.seq,
    IconKey: this.state.panel.iconKey,
    Title: this.state.panel.title,
    ImageKey: this.state.panel.imageKey,
    HTMLContent: this.state.panel.htmlContent,
    CompanyGroupId: this.state.panel.companyGroup.value
  };
  api.post('Admin/SavePanel', payload).then(result => {
    if (result.data.success === false) {
      this.setState({ messageFlavor: "danger", message: result.data.message });
    } else {
      this.props.refreshList(this.state.panel.companyGroup.value);
    }
  }).catch(helpers.catchHandler);
}

onTextChange(fieldName, e) {
  let panel = Object.assign({}, this.state.panel)
  panel[fieldName] = e.target.value;
  this.setState({ panel: panel });
}

onCompanyGroupSelected(newCG){
  this.setState({
    panel: {
      ...this.state.panel,
      companyGroup: newCG,
      companyGroupId: newCG.value
    }
  });
}

onPanelTypeSelected(newPanelType){
  this.setState({
    panel: {
      ...this.state.panel,
      PanelType: newPanelType, 
      panelType: newPanelType.value
    }
  });
}

onImageKeySelected(newImageKey){
  this.setState({
    panel: {
      ...this.state.panel,
      imageKey: newImageKey.label,
      ImageKey: newImageKey.value
    }
  })
}

findImageValue() {

}

getCurrentPanelTypeSelectOption(){
  return _.find(this.props.panelTypeList, {value: this.state.panel.panelType})
}

getCurrentCompanySelectOption(){
  return _.find(this.props.companyGroupList, {value: this.state.panel.companyGroupId})
}

getCurrentImageKeySelectOption(){
  return _.find(this.props.imageKeyList, {label: this.state.panel.imageKey})
}
    
render() {
const p = this.state.panel;
return(
  <div>
  <Modal isOpen={true} toggle={this.props.onCancel} backdrop={true}>
      { this.state.message === null ? null :(<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>) }          
      <ModalHeader>
        {p.id ? 'Edit' : 'Add'} Panel 
      </ModalHeader>
      <ModalBody>
      <Form>
        <Row className="mb-2">
          <Col sm="6"> Panel Type
            <Select bsSize="sm" 
              closeMenuOnSelect={true}
              value={this.getCurrentPanelTypeSelectOption()}
              //value={p.panelType}
              isMulti={false}
              components={makeAnimated()}
              options={this.props.panelTypeList}
              onChange={this.onPanelTypeSelected}
              placeholder="Select Type"
            />
          </Col>
          <Col sm="6">Col/Seq
            <Input type="number" name="colSeq" id="colSeq" placeholder="Enter Col/Seq"
              onChange={this.onTextChange.bind(this, 'colSeq')} value={p.colSeq}>
            </Input>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm="6">Sequence
            <Input type="number" name="seq" id="seq" placeholder="Enter Sequence"
               onChange={this.onTextChange.bind(this, 'seq')} value={p.seq}>
            </Input>
          </Col> 
          <Col sm="6">Icon Key
            <Input type="text" name="iconKey" id="iconKey" placeholder="Enter Icon Key"
               onChange={this.onTextChange.bind(this, 'iconKey')} value={p.iconKey ? p.iconKey : ''}>
            </Input>
          </Col> 
        </Row>
        <Row className="mb-2">
          <Col sm="6">Title
            <Input type="text" name="title" id="title" placeholder="Enter Title"
               onChange={this.onTextChange.bind(this, 'title')} value={p.title}>
            </Input>
          </Col> 
         <Col sm="6">Image Key
            {/* <Input type="text" name="imageKey" id="imageKey" placeholder="Enter Image Key"
               onChange={this.onTextChange.bind(this, 'imageKey')} value={p.imageKey ? p.imageKey : ''}>
            </Input> */}
            <Select closeMenuOnSelect
                value={this.getCurrentImageKeySelectOption()}
                isMulti={false}
                components={makeAnimated()}
                options={this.props.imageKeyList}
                onChange={this.onImageKeySelected}
                placeholder="Select Your Image"
              />
          </Col> 
        </Row>
        <Row className="mb-2">
          <Col sm="6">HTML Content
            <Input type="textarea" name="htmlContent" id="htmlContent" placeholder="Enter HTML Content"
               onChange={this.onTextChange.bind(this, 'htmlContent')} value={p.htmlContent ? p.htmlContent : ''}>
            </Input>
          </Col> 
          <Col className="col-sm-6">Company Group
            <div style={{height: "20px"}}>
              <Select closeMenuOnSelect
                value={this.getCurrentCompanySelectOption()}
                //value={p.company}
                isMulti={false}
                components={makeAnimated()}
                options={this.props.companyGroupList}
                onChange={this.onCompanyGroupSelected}
                placeholder="Select Company Group"
              />
            </div>
          </Col>
        </Row>
        </Form>
     </ModalBody>
     <ModalFooter>
       <ButtonGroup className="btn-group btn-access pull-right">
         <Button color="primary" onClick={this.onSubmit}>Save</Button>
         <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
       </ButtonGroup>
     </ModalFooter>
  </Modal>
  </div>);
  }
}

class CloneCompany extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      sourceCG: null,
      destinationCG: props.destinationCG
    };
    this.sourceSelected = this.sourceSelected.bind(this);
    this.destinationSelected = this.destinationSelected.bind(this);
    this.onClone = this.onClone.bind(this);
    this.isFormValid  = this.isFormValid.bind(this);
  }

  sourceSelected(newCG){
    this.setState({
      sourceCG: newCG
    });
  }

  destinationSelected(newCG){
    this.setState({
      destinationCG: newCG
    });
  }

  isFormValid() {
    let warnings = [];
    if (!this.state.sourceCG) {
      warnings.push("Source company group required");
    }
    if (!this.state.destinationCG) {
      warnings.push("Destination company group required");
    }
    if (this.state.sourceCG !== null 
      && this.state.destinationCG !== null 
      && this.state.sourceCG.value === this.state.destinationCG.value) {
      warnings.push("Source and destination company groups must be different");
    }
    if (warnings.length) {
        this.setState({
            message: warnings.join(','),
            messageFlavor: "danger"
        });
    } else {
        this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  onClone(e) {
    if (!this.isFormValid()) return;
    e.preventDefault();
    api.fetch(`Admin/Panels/CloneFrom/${this.state.sourceCG.value}/To/${this.state.destinationCG.value}`).then(result => {
      if (result.data.success === false) {
        this.setState({ messageFlavor: "danger", message: result.data.message });
      } else {
        this.setState({ messageFlavor: "success", message: `Panels were cloned from ${this.state.sourceCG.label} to ${this.state.destinationCG.label}` });
        setTimeout(() => {
          this.props.refreshList(this.state.destinationCG.value);
        }, 3000);
      }
    }).catch(helpers.catchHandler);
  }
  
  render(){
  return(
  <div>
  <Modal isOpen={true} toggle={this.props.onCancel} backdrop={true}>
      {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>) }       
      <ModalHeader>Clone Company Group</ModalHeader>
      <ModalBody>
        <Form>
          <Row className="mb-2">
            <Col>Source Company Group
              <Select bsSize="sm" 
                closeMenuOnSelect={true}
                value={this.state.sourceCG}
                isMulti={false}
                components={makeAnimated()}
                options={this.props.companyGroupList}
                onChange={this.sourceSelected}
                placeholder="Select Source"
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>Destination Company Group
              <Select bsSize="sm" 
                closeMenuOnSelect={true}
                value={this.state.destinationCG}
                isMulti={false}
                components={makeAnimated()}
                options={this.props.companyGroupList}
                onChange={this.destinationSelected}
                placeholder="Select Destination"
              />
            </Col>
          </Row>
        </Form>
     </ModalBody>
     <ModalFooter>
        <ButtonGroup className="btn-group btn-access pull-right">
          <Button color="primary"  onClick={this.onClone}>Clone</Button>
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ButtonGroup>
     </ModalFooter>
  </Modal>
  </div>);
  }
}