import moment from 'moment';

const DISPLAY_DATE_FORMAT = 'MM/DD/YY';
const TIME_FORMAT = 'h:mm a';
const momentFromString = function(date, fmt = null) {
  if (date) {
    if (fmt) {
      return moment(date, fmt, true);
    } else {
      return moment(date);
    }
  }
  return null;
};

export default {
  TIME_FORMAT,
  DISPLAY_DATE_FORMAT,
  isDateValid(dateString) {
    if (!dateString) {
      return false;
    }
    if (!moment(dateString, 'MM/DD/YYYY', true).isValid()) {
      return false;
    }
    return true;
  },

  toDateString(d) {
    if (!d) return "";
    return momentFromString(d).format('ddd, MM/DD/YY');
  },

  toTimeString(d) {
    if (!d) return "";
    return momentFromString(d).format(TIME_FORMAT);
  },

  parseDate(s, fmt = "MM/DD/YYYY") {
    let test = moment(s, [ "MM/DD/YYYY", "M/D/YY", "MM/D/YY", "MM/DD/YY", "MM/D/YYYY", "M/D/YYYY", "YYYY-MM-DD" ]);
    if (test.isValid()) {
      if (test.year() > new Date().getFullYear()) {
        test.year(test.year() - 100);
      }
      return test.format(fmt);
    } else {
      return '';
    }
  },

  apiDateTimeToDisplayDate(s, fromFmt = "YYYY-MM-DDZhh:mm:ss") {
    let test = moment(s, [ fromFmt ]);
    if (test.isValid()) {
      return test.format(DISPLAY_DATE_FORMAT);
    } else {
      return '';
    }
  },

  getStringFromMoment(m) {
    if (m) {
      return m.format("YYYY-MM-DD");
    }
    return null;
  },

  getMomentFromString(date, fmt) {
    return momentFromString(date, fmt);
  },

  getTimeRange() {
    const start = moment().startOf('06:00');
    const times = 14 * 2; // 14 hours * two 30 mins sessions/hour

    for (let i = 0; i < times; i++) {
      const toPrint = moment(start)
        .add(30 * i, 'minutes')
        .format('hh:mm A');
      return toPrint;
    }
  },
};
