import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';

export class LifestyleHabits extends Component {
  displayName = "Lifestyle Habits";
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }
  

  render() {
    return (
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm">
            <tbody>
              <tr>
                <td width="40%" className="lifestyleHabits topRow">
                  Tobacco Use / per day
                </td>
                <td className="topRow">
                  10 whole packs
                </td>
              </tr>
              <tr>
                <td width="40%" className="lifestyleHabits">
                  Alcohol Use / per week
                </td>
                <td>
                  n/a
                </td>
              </tr>
              <tr>
                <td width="40%" className="lifestyleHabits">
                  Activity Type
                </td>
                <td>
                  n/a
                </td>
              </tr>
              <tr>
                <td width="40%" className="lifestyleHabits">
                  Activity Frequency
                </td>
                <td>
                  n/a
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}