import React from "react";
import Loader from "react-loader-spinner";

export class PeakSpinner extends React.Component {
  render() {
    const { on, children } = this.props;

    if (on) {
      return (
        <div className="text-center">
          <span style={{ fontSize: "1.6rem" }}>Please wait a moment...</span>
          <div style={{ display: "block", margin: "auto", width: "130px" }}>
            <Loader
              type="Circles"
              color="#00BFFF"
              height={120}
              width={120}
              timeout={30000}
            />
          </div>
        </div>
      );
    }
    return children;
  }
}
