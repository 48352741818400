const ROLE_IDs = {
  UNKNOWN: 0,
  Candidate: 1,
  Evaluator: 3,
  HR: 5,
  Administrator: 4,
  CompanyAdministrator: 7,
};

const badChars = [
  "[",
  "]",
  ";",
  "@",
  "=",
  "&",
  "<",
  ">",
  "#",
  "?",
  "{",
  "}",
  "^",
  "~",
  "`",
  "–",
  "%",
  ",",
];

const Email_Template_IDs = [
  {
    value: 1,
    label: "Create Appointment",
  },
  {
    value: 2,
    label: "Resechedule Appointment",
  },
  {
    value: 3,
    label: "Cancel Appointment",
  },
  {
    value: 4,
    label: "Calendar Entry Notifications",
  },
];

const APP_ROLE_ACCESS_REQUIREMENTS = {
  systemAdmin: {
    role: ROLE_IDs.Administrator,
    access: ["System Admin"],
  },
  companyAdmin: {
    role: ROLE_IDs.CompanyAdministrator,
    access: ["Company Admin"],
  },
  hr: {
    role: ROLE_IDs.HR,
    access: ["HR"],
  },
  evaluator: {
    role: ROLE_IDs.Evaluator,
    access: ["Evaluator"],
  },
};

const METRICS_DASHBOARD_CHART_DIMENSIONS = {
  height: 300,
  width: 200,
  borderWidth: 2,
};

const PEAK_COLORS = {
  green: "rgba(0, 148, 58, 0.75)", // Green
  blue: "rgba(0, 16, 159, 0.7)", // Blue
  grey: "rgba(128, 128, 128, 0.9)",
  purple: "rgba(152, 128, 208, 0.6)",
  orange: "rgba(199, 120, 47, 0.8)",
  brown: "rgba(47, 27, 47, 0.8)", // Grey
  lime: "rgba(0, 255, 0, 0.5)", // Lime (light green)
  navy: "rgba(0, 0, 128, 0.5)", // Navy (dark blue)
  silver: "rgba(192, 192, 192, 0.5)", // Silver (light grey)
  aqua: "rgba(0, 255, 255, 0.5)", // Aqua (green + blue)
  darkerBlue: "rgba(0, 0, 64, 0.5)", // Darker blue
  darkerGrey: "rgba(64, 64, 64, 0.5)", // Darker grey
  white: "rgba(255, 255, 255, 0.5)", // White
};

export default {
  Email_Template_IDs,
  ROLE_IDs,
  badChars,
  COMPANY_NUMBER_ITAC: "3061",
  APP_ROLE_ACCESS_REQUIREMENTS,
  METRICS_DASHBOARD_CHART_DIMENSIONS,
  PEAK_COLORS,
};
