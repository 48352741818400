import React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Button, Label, Card, CardHeader, CardBody,CardFooter, ButtonGroup, Alert} from 'reactstrap';
import api from '../utils/api';
import helpers from '../utils/helpers';

export class PrivacyPolicyAndDisclaimer extends React.Component{
constructor(props){
  super(props);
  this.state = {
    messageFlavor: null,
    message: null,
    companyReleaseFormURL: null,
    companyName: null
  }
}

componentDidMount() {
  api.fetch('Home/CompanyRelease').then(response => {
    this.setState({
      companyReleaseFormURL: response.data.url,
      companyName: response.data.companyName
    });
  }).catch(helpers.catchHandler);
}

Logout() {
  this.props.logout();
  return <Redirect to="/login" />;
}

onAccept(){   
  api.post('Public/AcceptTandC').then(response => {
    if(response.data.success === false) {
      this.setState({message: "An error occured, agreement could not be saved at this time.", messageFlavor: "danger"});
      setTimeout(() => {this.Logout()}, 3000);
    } else {
      let user = Object.assign({}, this.props.currentUser);
      user.informedConsentAt = new Date();
      this.props.setCurrentUser(user);
    }
    //scroll to top of page
    window.scroll(0,0);
  }).catch(helpers.catchHandler);
}

render() {
  return (
    <div>
      <Card className="m-4" >
          <CardHeader id="projectDetails" className="card-header">
            <Row className="expand-md">
              <Col>
                <Label className="titleDates">Privacy Policy and Disclaimer</Label>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
            {this.state.message !== null
              ? <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
              : null
            }
            <Col className="expand-md mt-2 mb-2 pb-0">
<div style={{textAlign: "center", fontSize: "16pt"}}>
  <b>PEAK HEALTH, PA<br/>NOTICE OF PRIVACY</b>
</div>
<p>This notice describes how health information about you may be used and disclosed and how you can get access to this information.</p>
<h4>OUR PLEDGE</h4>
<p>We are committed to protecting the privacy of your health information. We create a record of the care and services you receive from our
organization. We need this information to provide you with quality care and to comply with certain legal requirements. This notice will tell you how we
share your health information. We also describe your rights and certain duties we have regarding protecting your health information.</p>

<h4>OUR RESPONSIBILITY</h4>
<p>We have a legal obligation to keep your health information private. We will provide you with a notice as to our legal duties, privacy practices,
and your rights regarding your health information. We will abide by the terms of this notice and notify you if we are unable to agree to a restricted request
or to accommodate a request you may have made in regards to your health information. We reserve the right to change our practices and to make new
provisions effective for all protected health information we maintain. Should our information practices change, we will make the new notice available
upon request. We will not use or disclose your health information without your authorization, except as described in this notice.</p>

<h4>OUR SECURITY PROCEDURES</h4>
<p>We maintain safeguards to protect your health information. Our employees are bound by our organizational policies to access your information
only for legitimate purposes and to maintain the privacy of your health information. We will let you know promptly if a breach occurs that may have
compromised the privacy or security of your information.</p>

<h4>HOW WE DISCLOSE YOUR INFORMATION</h4>
<p>
  We may use and disclose your health information for <b>treatment</b>. Information obtained by members of your healthcare team will be recorded in
  your health record and will be used to provide care and services to you. We may disclose health information about you to other health care providers
  that may assist us in providing care to you. For example: You have diabetes. We may communicate with a dietitian to plan your diet.<br/>
  We may use and disclose your health information for <b>payment purposes</b>. For example: A bill may be sent to you or a third-party payer.<br/>
  We may use and disclose your health information for our <b>health care operations</b>. This might include measuring and improving quality,
  evaluating employee performance, conducting training programs, documenting outcomes, and meeting licensure requirements. For example, your health
  information may be reviewed in an effort to improve health outcomes.
</p>
<p>
  <b>Appointment Notification, Treatment Alternatives and Health Related Benefits and Services</b>: We may notify you that you have an
  appointment with us. We may communicate your appointment notice via email, mail, company interoffice mail, voice mail, personal representative, or
  work colleague. We also may use and disclose Health Information to tell you about treatment alternatives or health related benefits and services that
  may be of interest to you.
</p>
<p>
  <b>Business associates</b>: We may disclose Health Information to our business associates that perform functions on our behalf or provide us with
  services if the information is necessary for such functions or services. For example: We may contract with a computer resource company to provide
  computer support. All of our business associates are obligated to protect the privacy of your information and are not allowed to use or disclose any
  information other than as specified in our contract.
</p>
<p>
  <b>Research</b>: Under certain circumstances, we may use and disclose Health Information for research. For example, a research project may
  involve comparing the health of patients who received one treatment to those who received another, for the same condition. Before we use or disclose
  Health Information for research, the project will go through a special approval process. Even without special approval, we may permit researchers to
  look at records to help them identify patients who may be included in their research project or for other similar purposes, as long as they do not remove
  or take a copy of any Health Information.
</p>
<p>
  <b>Additional Uses and disclosures</b>. In addition to using and disclosing your health information for the above purposes, we may use and
  disclose health information for the following purposes. We may disclose to the FDA health information relative to adverse events. As required by law, we
  may disclose your health information to public health, legal authorities, law enforcement, workers compensation or other similar programs, health
  oversight agencies, or other government agencies. In addition, we may make other uses and disclosures, which occur as a byproduct of the permitted
  uses and disclosures described in this notice.
  All other uses and disclosures will be made only with your written authorization. You have the right to revoke such authorization in writing.
</p>

<h4>YOUR RIGHTS</h4>
  <p>Although your health record is our physical property, the information belongs to you. You have the right to:</p>
<ul className="normal">
    <li>
        <p>Look at or obtain a paper copy of your health information. You must make your request in writing. We may charge you related fees.</p>
    </li>
    <li>
        <p>Receive a listing of times we shared your health information for purposes other than treatment, payment, or health care operations, except as
authorized by you. You must make this request in writing.</p>
    </li>
    <li>
        <p>Request that we place additional restrictions on our use or disclosure of your health information. You must make your request in writing. We
are not required to agree to these restrictions.</p>
    </li>
    <li>
        <p>Request that we change your health information. You must make this request in writing. We may deny that request, but if we do we will
provide you a written explanation. You may respond with a statement of disagreement that will be added to the information you wanted
changed.</p>
    </li>
    <li>
        <p>Obtain a paper copy of this notice. You must make your request in writing.  You may obtain a copy of this notice at our web site,
         <a href="http://www.peak-health.net">www.peak-health.net</a></p>
    </li>
    <li>
        <p>Revoke your authorization to use or disclose health information except to the extent that action has already been taken. You must make your
request in writing.</p>
    </li>
</ul>
<h4>FOR MORE INFORMATION OR TO REPORT A PROBLEM</h4>
<p>If you have questions and would like additional information, you may contact our Privacy Officer at 252-237-5090. If you believe your rights
have been violated, you can file a complaint with our privacy officer or contact the Secretary of Health and Human Services. There will be no retaliation
for filing a complaint.</p>
<p style={{fontSize: "0.6em"}}>Orig:    02/17/03</p>
<p style={{fontSize: "0.6em"}}>Revised: 09/08/16</p>
            </Col>
            </Row>
            {this.state.companyName
              ? (<Row>
                  <Col>
                    In addition to the Peak Privacy Policy above please review and confirm acceptance of the <a href={this.state.companyReleaseFormURL} rel="noopener noreferrer" target="_blank">{this.state.companyName} agreement</a>.
                  </Col>
                </Row>)
              : null
            }
          </CardBody>
          <CardFooter>
            <ButtonGroup className="float-right">
              <Button color="success" onClick={this.onAccept.bind(this)}>I Accept</Button>
              <Button color="secondary" onClick={this.Logout.bind(this)}>Cancel</Button>
            </ButtonGroup>
          </CardFooter>
      </Card>
    </div>
  );
}

}