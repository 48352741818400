import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Row, Col, Alert, Label, Tooltip } from 'reactstrap';
import api from '../utils/api';
import helpers from '../utils/helpers';
import { PasswordRequirements } from './index';
import storage from "../utils/storageFactory.js";

export class ResetPasswordBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      oldPassword: '',
      password: '',
      password_again: '',
      message: props.message,
      messageFlavor: props.messageFlavor,
      tooltipOpen: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleOldPassword = this.handleOldPassword.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordAgain = this.handlePasswordAgain.bind(this);
  }

  handleOldPassword(event) {
    this.setState({ oldPassword: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handlePasswordAgain(event) {
    this.setState({ password_again: event.target.value });
  }

  isFormValid() {
    const warnings = [];
    if (!this.state.password) {
      warnings.push('Password fields required');
    }
    if (this.state.password !== this.state.password_again) {
      warnings.push('The password and password confirmation do not match.');
    }
    if (!this.state.password || this.state.password.length < 8) {
      warnings.push('Password is required and must be at least 8 characters long');
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(','),
        messageFlavor: 'danger',
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  handleSubmit() {
    if (!this.isFormValid()) return;
    api.post('Public/SetPassword', {
      Token: this.props.resetToken ? this.props.resetToken : null,
      OldPassword: this.state.oldPassword,
      NewPassword: this.state.password,
      BearerToken: storage.getItem('token')
    }).then(response => {
      if (response.data.success === true) {
        let newState = {messageFlavor: 'success', oldPassword: '', password: '', password_again: ''};
        if (this.props.redirectOnSuccess) {
          newState.message = "You have successfully reset your password. We're now redirecting you to the home screen.";
          this.setState(newState);
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 6000);
        } else {
          newState.message = "You have successfully reset your password.";
          this.setState(newState);
          setTimeout(() => {
            this.setState({ message: null });
          }, 4000);
        }
      } else {
        this.setState({ messageFlavor: 'alert-danger', message: response.data.message });
      }
    }).catch(helpers.catchHandler);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    return (
    <React.Fragment>
	    <div>
	      {this.state.message !== null
	        ? <Alert className={this.state.messageFlavor}>{this.state.message}</Alert>
	        : null
	      }
	    </div>
	    <div>
	      <form>
        {this.props.resetToken
          ? null
          : (<Row>
              <Col>
                <Label className="label">Old Password</Label>
                <Input
                  type="password"
                  value={this.state.oldPassword}
                  onChange={this.handleOldPassword}
                />
              </Col>
            </Row>)
        }
	        <Row className="mt-2 mb-2">
	          <Col>
	            <Label className="label">New Password</Label>
	            <span>
	              <div className="ml-2 d-inline">
	                <FontAwesomeIcon icon="info-circle" id="passwordTooltip" />
	              </div>
	              <Tooltip data-container="body" placement="right" isOpen={this.state.tooltipOpen} target="passwordTooltip"
                  toggle={() => this.setState({tooltipOpen: !this.state.tooltipOpen})}>
                  <PasswordRequirements />
	              </Tooltip>
	            </span>
	            <Input
	              type="password"
	              value={this.state.password}
	              onChange={this.handlePassword}
	              placeholder="Password"
	            />
	          </Col>
	        </Row>
	        <Row>
	          <Col>
	            <Label className="label">Confirm New Password</Label>
	            <Input
	              type="password"
	              value={this.state.password_again}
	              onChange={this.handlePasswordAgain}
	              placeholder="Re-Type Password"
	            />
	          </Col>
	        </Row>
	        <Button color="primary" onClick={this.handleSubmit} className="float-right mt-3">
	          Confirm Password Reset
	        </Button>
	      </form>
	    </div>
	  </React.Fragment>);
	  }
}