import _ from "lodash";
import storage from "../utils/storageFactory.js";
import constants from "./constants.js";

function lowerCase(str) {
  return str.toLowerCase();
}

function upperCase(str) {
  return str.toUpperCase();
}

export default {
  booleanListEntry: function (b) {
    return b === true
      ? { label: "Yes", value: true }
      : { label: "No", value: false };
  },

  resolveValue: function (obj, id, name) {
    if (obj) return obj;
    if (id === null) return null;
    return { value: id, label: name };
  },

  yesNoOptions: function () {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  },

  getNextAppt: function () {
    const apptJson = storage.getItem("nextAppt");
    return apptJson ? JSON.parse(apptJson) : null;
  },

  timeZoneList: [
    { label: "Eastern Standard Time", value: "Eastern Standard Time" },
    { label: "Central Standard Time", value: "Central Standard Time" },
    { label: "Mountain Standard Time", value: "Mountain Standard Time" },
    { label: "Pacific Standard Time", value: "Pacific Standard Time" },
  ],

  idNameToValueLabel: function (list) {
    return _.map(list, (x) => {
      return { label: x.name, value: x.id };
    });
  },

  idNameToValueLabelTemplates: function (list) {
    // function resolveLabel (num) {
    //   let label = ''
    //   switch(num)  {
    //   case 1:
    //     label = "Create Appointment";
    //     break;
    //   case 2:
    //     label = "Resechedule Appointment";
    //     break;
    //   case 3:
    //     label = "Cancel Appointment";
    //     break;
    //   case 4:
    //     label = "Calendar Entry Notifications";
    //     break;
    //   }
    //   return label;
    // }

    return _.map(list, (x) => {
      let label = _.find(constants.Email_Template_IDs, (e) => {
        return e.value === x.typeOfEmailTemplate;
      }).label;
      return {
        Id: x.typeOfEmailTemplate,
        Name: x.dsc,
        label: label,
        // resolveLabel(x.typeOfEmailTemplate),
        value: x.typeOfEmailTemplate,
      };
    });
  },

  mustChangePassword: function (user) {
    if (!user) return false;
    return user.mustChangePassword;
  },

  hasChallengeQuestion: function (user) {
    if (!user) return false;
    return user.challengeQuestionId !== null;
  },

  browserExportFile: function (
    content,
    fileTitle,
    fileExtension = "csv",
    mimeType = "text/csv"
  ) {
    var fname = fileTitle || "export." + fileExtension;
    var blob = new Blob([content], { type: mimeType + ";charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fname);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fname);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },

  getSelectedItemByValue: (list, value) => {
    const item = _.find(list, (x) => {
      return x.value === value;
    });
    return item === undefined ? null : item;
  },

  parseRequestTypeName: function (request_type_name) {
    const properCase = function (str) {
      return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
    };
    const removeUnderscore = function (str) {
      return str.replace(/_/g, " ");
    };
    const request_type_label = properCase(removeUnderscore(request_type_name));
    return request_type_label;
  },

  buildFormPayload: function (json, files) {
    let payload = new FormData();
    // only works for non-arrays, non-objects
    for (var k in json) {
      payload.append(k, json[k]);
    }
    if (files) {
      for (let i = 0; i < files.length; i++) {
        payload.append(`files[${i}]`, files[i]);
      }
    }
    return payload;
  },

  generateTableAndChartBorderColors(backgroundColors) {
    return backgroundColors.map((backgroundColor) => {
      const colorArray = backgroundColor.split(",");
      const color = colorArray[0] + ", " + colorArray[1] + ", " + colorArray[2];
      return color + ", 1)";
    });
  },

  generateTableAndChartBorderColorForOne(backgroundColor) {
    const colorArray = backgroundColor.split(",");
    const color = colorArray[0] + ", " + colorArray[1] + ", " + colorArray[2];
    return color + ", 1)";
  },

  catchHandler: (e) => {
    console.error(e);
  },
};
