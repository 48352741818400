import React, { Component } from 'react';
import { Table, CardHeader, Card, CardBody, Col, Row } from 'reactstrap';
// import { Link } from 'react-router-dom';
import { AccountSummary, Goals, TemplateCollapsibleCard, VisitSummary } from '../components/';
import api from '../utils/api';
import helpers from '../utils/helpers';
// import _ from 'lodash';

export class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestVisitDetail: null,
      accountSummary: null,
      appointmentChangeHistory: [],
      completedHealthEvaluations: [],
    };
  }

  componentDidMount() {
    api.post('Home/GetAccountInfo', {}).then(r => {
      this.setState({
        appointmentChangeHistory: r.data.appointmentChangeHistory,
        completedHealthEvaluations: r.data.completedHealthEvaluations,
        accountSummary: r.data.accountSummary,
        latestVisitDetail: r.data.latestVisitDetail
      });
    }).catch(helpers.catchHandler);
  }

  render() {
    return (
      <Row className="mb-2">
        <Col sm="12">
          <Card className="homeWidgets mb-2 mt-0 mx-0">
            <CardHeader className="expand-md">{this.props.currentUser.healthProgramName}</CardHeader>
            <CardBody className="p-2">
              <Row>
                <Col xs="12">
                  <TemplateCollapsibleCard
                    displayName={`Member information for ${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`}
                    collapse={false}
                    arrowButton={this.props.arrowButton}
                    headerClass={'statsCard visitCard'}
                  >
                    <AccountSummary summaryData={this.state.accountSummary} />
                  </TemplateCollapsibleCard>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="4">
                  <TemplateCollapsibleCard
                    displayName='Completed Health Evaluations'
                    collapse={false}
                    arrowButton={this.props.arrowButton}
                    headerClass={'statsCard visitCard'}>
                    <Row className="my-0">
                      <Col sm="12">
                        <Table size="sm" className="my-0">
                          <thead>
                            <tr>
                              <th width="40%" className="topRow">
                                Visit Date
                              </th>
                              <th className="topRow">
                                Phase
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.completedHealthEvaluations && this.state.completedHealthEvaluations.map(vh => (
                            <tr key={`vh-${vh.id}`}>
                              <td>{vh.visitOn}</td>
                              <td>{vh.phase}</td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>   
                  </TemplateCollapsibleCard>
                </Col>
                <Col xs="12" md="8">
                  <TemplateCollapsibleCard
                    displayName='Appointment Change History'
                    collapse={false}
                    arrowButton={this.props.arrowButton}
                    headerClass={'statsCard visitCard'}>
                    <Row className="my-0">
                      <Col sm="12">
                        <Table size="sm" className="my-0">
                          <thead>
                            <tr>
                              <th width="20%" className="topRow">
                                Visit Date
                              </th>
                              <th width="15%" className="topRow">
                                Time
                              </th>
                              <th className="topRow">
                                Reason
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.appointmentChangeHistory && this.state.appointmentChangeHistory.map(ch => (
                            <tr key={`ch-${ch.id}`}>
                              <td>{ch.visitOn}</td>
                              <td>{ch.visitTime}</td>
                              <td>{ch.changeType}</td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>   
                  </TemplateCollapsibleCard>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TemplateCollapsibleCard
                    displayName='Visit Summary'
                    collapse={false}
                    arrowButton={this.props.arrowButton}
                    headerClass={'statsCard visitCard'}
                  >
                  {this.state.latestVisitDetail
                    ? (<VisitSummary 
                        latestVisit={this.state.latestVisitDetail}
                      />)
                    : null
                  }
                  </TemplateCollapsibleCard>
                </Col>
                <Col>
                  <TemplateCollapsibleCard
                    displayName='Goals'
                    collapse={false}
                    arrowButton={this.props.arrowButton}
                    headerClass={'statsCard visitCard'}
                  >
                  {this.state.latestVisitDetail
                    ? (<Goals 
                        latestVisit={this.state.latestVisitDetail}
                      />)
                    : null
                  }
                  </TemplateCollapsibleCard>
                </Col>
              </Row>
            </CardBody>
          </Card>    
        </Col>
      </Row>
    );
  }
}
