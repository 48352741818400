import React, { Component } from 'react';

export class FilterBoolean extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: props.filterName,
      displayName: props.displayName,
      value: props.value,
      yesOnly: props.yesOnly,
      onChangeCallback: props.onChangeCallback,
      clearValue: props.clearValue
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clearValue !== this.props.clearValue) {
      if (this.props.clearValue === true ) {
        this.setState({ value: '' });
      }
    }
  }

  onChange(selectedOption) {
    const filteredDisplay = (selectedOption.text)
      ? `${this.state.displayName}: ${selectedOption.text}`
      : '';

    this.state.onChangeCallback({
      filterName: this.state.filterName,
      value: selectedOption.value,
      filteredDisplay,
    });

    this.setState({ value: selectedOption.value });
  }

  render() {
    return (
      <span className='filter-definition-caption'>
        {this.state.displayName}
        <select value={this.state.value} className="form-control" 
          onChange={event => this.onChange(event.target.options[event.target.selectedIndex])} >
          <option value="">(Any)</option>
          <option value="true">Yes</option>
          {this.props.yesOnly || 
            (<option value="false">No</option>)}
        </select>
      </span>
    );
  }
}
