import React, { Component } from "react";
import { Table } from "reactstrap";
import constants from "../utils/constants";
import _ from "lodash";

export class VisitsByMonthTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.visitsYrMonthTableHeader = this.visitsYrMonthTableHeader.bind(this);
    this.visitsTableContent = this.visitsTableContent.bind(this);
  }

  componentDidMount() {}

  visitsYrMonthTableHeader() {
    const table = this.props.visitsData;
    const headers =
      table !== undefined
        ? _.map(table[0], (header, index) => (
            <th
              className="text-center"
              key={index}
              style={{ whiteSpace: "nowrap" }}
            >
              {header}
            </th>
          ))
        : null;
    return headers;
  }

  visitsTableContent() {
    const rowColors = [
      constants.PEAK_COLORS.green,
      constants.PEAK_COLORS.blue,
      constants.PEAK_COLORS.purple,
      constants.PEAK_COLORS.orange,
    ];

    const table = this.props.visitsData;
    const rows = _.map(_.slice(table, 1), (row, rowIndex) => {
      const color = rowColors[rowIndex % rowColors.length];
      return (
        <tr key={rowIndex} style={{ backgroundColor: color, color: "white" }}>
          {_.map(row, (cell, cellIndex) => (
            <td
              className="text-center"
              key={cellIndex}
              style={{
                fontWeight:
                  cellIndex === 0 || // first column
                  rowIndex === table.length - 2 || // last row (-2 because we sliced the first row off and indices are 0-based)
                  cellIndex === row.length - 1 // last column
                    ? "bold"
                    : "normal",
              }}
            >
              {cell}
            </td>
          ))}
        </tr>
      );
    });
    return rows;
  }

  render() {
    return (
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>{this.visitsYrMonthTableHeader()}</tr>
          </thead>
          <tbody>{this.visitsTableContent()}</tbody>
          <tfoot></tfoot>
        </Table>
      </div>
    );
  }
}
