import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { TemplateCollapsibleCard } from './';
import api from '../utils/api';

export class WidgetLabCorpResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  onDownload(e) {
    e.preventDefault();
    api.downloadPDF('Lab/RenderLabCorpResultsReport');
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
        <img src='/Content/panels/clipboard-stat.png' alt='Lab Results' style={{float:"left", marginRight: "8px"}}/>
        <p style={{marginTop: "20px"}}>
        {this.props.hasLabCorpResults
          ? (<span>Click <Button color="link" className="pb-2" style={{padding: "4px", fontWeight: "bold", fontSize: "14pt"}} onClick={this.onDownload}>here</Button> to download your lab results.</span>)
          : (<span>There are no lab results available for you to download.</span>)
        }
        </p>
      </TemplateCollapsibleCard>
    );
  }
}