import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Card, CardImg, CardBody } from 'reactstrap';
import { ResetPasswordBody } from '../components';

export class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    const errorMessage = props.match && props.match.params && props.match.params.message
      ? props.match.params.message
      : null;
    const resetToken = props.match && props.match.params && props.match.params.resetToken
      ? props.match.params.resetToken
      : null;
    this.state = {
      message: errorMessage,
      messageFlavor: "alert-danger",
      resetToken
    };
  }

  render() {
    return (
      <Container fluid>
        <Row className="zIndexOverBackgroundImage expand-md mb-2 pb-0 align-items-center">
          <Col sm="6">
            <Row className="justify-content-center">
              <Card className="m-5" id="logoCard">
                <CardImg src="/Content/Images/peakhealth_logo_2014_large.jpg" alt="Logo" />
              </Card>
            </Row>
          </Col>
          <Col sm="6">
            <Card className="m-5">
              <CardBody>
                <div>
                  <Link to="/login">Return to Login Screen</Link>
                </div>
                <ResetPasswordBody 
                  resetToken={this.state.resetToken} 
                  message={this.state.message}
                  messageFlavor={this.state.messageFlavor}
                  redirectOnSuccess={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="homeBackgroundImage" />
        <Row className="citationFooter fixed-bottom expand-md">
          <Col className="text-center">
            <a target="_blank" id="citationURL" href="https://www.freepik.com/free-photos-vectors/background" rel="noopener noreferrer">Background photo created by mrsiraphol - www.freepik.com</a>
          </Col>
        </Row>
      </Container>
    );
  }
}