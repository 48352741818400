import React, { Component } from 'react';
import { Card, CardBody, UncontrolledCollapse,
  Row, Col, Button, Modal, ModalHeader, ModalBody,
  Alert, CardTitle, ModalFooter } from 'reactstrap';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class TemplateAttachmentModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modal: false,
        backdrop: true,
        files: [],
        adding_image: false,
        errorMessage: null,
        displayName: props.displayName
      };
      this.toggle = this.toggle.bind(this);
      this.changeBackdrop = this.changeBackdrop.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.toggleImages = this.toggleImages.bind(this);
      this.onDrop = this.onDrop.bind(this);
      this.removeFile = this.removeFile.bind(this);
    }

    componentDidMount() {

    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }
  
    onDrop(acceptedFiles, rejectedFiles) {
      if (rejectedFiles.length) {
        this.setState({ errorMessage: 'One or more files were rejected.' });
      } else {
        this.setState({ errorMessage: null });
      }
      this.props.context.onFilesAdded(acceptedFiles);
      this.setState({ files: acceptedFiles, adding_image: false });
    }
  
    toggleImages() {
      this.setState({ adding_image: !this.state.adding_image });
    }
  
    removeFile(e, file) {
      e.preventDefault();
      const currentState = this.state.files;
      const files = _.filter(currentState, f => f.name !== file.name);
      this.setState({ files });
    }
  
    changeBackdrop(e) {
      let value = e.target.value;
      if (value !== 'static') {
        value = JSON.parse(value);
      }
      this.setState({ backdrop: value });
    }

    //placeholder handleSubmit for form attachment submission
    handleSubmit(event) {
      event.preventDefault();
      // let payload = new FormData();
      // const addFilesToPayload = (payload, current_state) => {
      //   for (let i = 0; i < current_state.files.length; i++) {
      //     payload.append(`files[${i}]`, current_state.files[i]);
      //   }
      //   return payload;
      // }
      this.toggle();
    }

    render() {
      return (
        <div>
          <Button color="success" className='float-right' onClick={this.toggle}>
            <FontAwesomeIcon icon="plus-circle" /> {this.state.displayName}
          </Button>
          <Modal isOpen={this.state.modal} toggle={this.closeModal} backdrop={this.state.backdrop}>
            <ModalHeader>{this.state.displayName}</ModalHeader>
            <ModalBody>
            {
              this.state.errorMessage !== null
              ? (<Alert className="ml-3" color="danger">{this.state.errorMessage}</Alert>)
              : null
            }
            <Row className="mb-2">
              <Col>
                <div>
                  <Button color="secondary" id="dropzone-toggler" size="sm" onClick={this.toggleImages} className="mb-2">
                    {(this.state.adding_image)
                    ? (<FontAwesomeIcon icon="times-circle" />)
                    : (<FontAwesomeIcon icon="plus-circle" />)} Attachment(s)
                  </Button>
                </div>
              </Col>
            </Row>
            <UncontrolledCollapse toggler="dropzone-toggler">
              <Row>
                <Col>
                  <Card className="text-center">
                    <CardBody>
                      <CardTitle>Drop files or click on the icon below</CardTitle>
                        <Dropzone className="col-xs-4"
                          onDrop={this.onDrop}
                        >
                        {({getRootProps, getInputProps}) => (<section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p style={{ textAlign: 'center' }}><FontAwesomeIcon icon={this.state.files && this.state.files.length ? "check" : "file-upload"} size="6x" /></p>
                          </div>
                        </section>)}
                        </Dropzone>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </UncontrolledCollapse>
            </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleSubmit}>Submit</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}