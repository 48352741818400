import React, { Component } from "react";
import { Polar } from "react-chartjs-2";
import _ from "lodash";
import constants from "../utils/constants";

export class EnrollmentsByPhasePolarAreaChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.calculatePercentage = this.calculatePercentage.bind(this);
    this.generateRandomBackgroundColor =
      this.generateRandomBackgroundColor.bind(this);
    this.generateBorderColor = this.generateBorderColor.bind(this);
    this.polarAreaChartData = this.polarAreaChartData.bind(this);
  }

  componentDidMount() {}

  calculatePercentage(dividend, divisor) {
    const percentAsDecimal = dividend / divisor;
    return percentAsDecimal.toFixed(2) * 100;
  }

  generateRandomBackgroundColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgba(${red}, ${green}, ${blue}, 0.3)`;
  }

  generateBorderColor(backgroundColor) {
    const colorArray = backgroundColor.split(",");
    const color = colorArray[0] + ", " + colorArray[1] + ", " + colorArray[2];
    return color + ", 3)";
  }

  polarAreaChartData(data) {
    const labels = [
      "Lifeforce Active",
      "Phase 1",
      "Phase 2",
      "Phase 3",
      "Phase 4",
      "Phase 5",
      "Pending",
    ];

    const list = _.flatMap(data, (item) => {
      return [
        item.medicallyEnrolled,
        item.lifeforceActive,
        item.phase1,
        item.phase2,
        item.phase3,
        item.phase4,
        item.phase5,
        item.pending,
      ];
    });

    const backgroundColors = labels.map(() =>
      this.generateRandomBackgroundColor()
    );
    const borderColors = backgroundColors.map((color) =>
      this.generateBorderColor(color)
    );

    const polarAreaChartData = {
      labels: labels,
      datasets: [
        {
          label: "Patricipation By Status",
          data: list,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
        },
      ],
    };

    return polarAreaChartData;
  }

  render() {
    return (
      <>
        <div>
          <Polar
            data={this.polarAreaChartData(this.props.enrollmentsByPhaseData)}
          />
        </div>
      </>
    );
  }
}
