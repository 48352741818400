import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TemplateCollapsibleCard } from './';

export class WidgetSMSOptForm extends Component {
  displayName = "Mobile Opt-In";
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}>
            <React.Fragment>
                <p>Go <Link to='/smsconsent' style={{ fontWeight: "bold", fontSize: "14pt" }}>here</Link> to learn more and opt-in.</p>
            </React.Fragment>
      </TemplateCollapsibleCard>
    );
  }
}