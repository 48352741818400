import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';

export class FilterText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: props.filterName,
      displayName: props.displayName,
      value: props.value,
      onChangeCallback: props.onChangeCallback,
      debounceTimeout: props.debounceTimeout || 500,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.props.value});
    }
  }

  onChange(value) {
    const filteredDisplay = (value)
      ? `${this.state.displayName} starts with ${value}`
      : null;

    this.state.onChangeCallback({
      filterName: this.state.filterName,
      value,
      filteredDisplay,
    });
  }

  render() {
    return (
      <span className="filter-definition-caption">
        {this.state.displayName}
        <DebounceInput
          className="form-control"
          minLength={2}
          value={this.state.value}
          debounceTimeout={this.state.debounceTimeout}
          onChange={event => this.onChange(event.target.value)}
        />
      </span>
    );
  }
}
