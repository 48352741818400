import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';

export class Recommendations extends Component {
  displayName = "Recommendations";
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }
  

  render() {
    return (
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm">
            <thead>
              <th width="40%" className="goals topRow">Date</th>
              <th className="goals topRow">Recommendation</th>
            </thead>
            <tbody>
              <tr>
                <td width="40%" className="recommendations topRow">
                  10/23/2018
                </td>
                <td className="topRow">
                  Continue current healthy habits;Drink plenty of water;Improve body fat;Increase exercise/activity level;Limit dietary intake of sugars;Watch portion sizes/eat regular meals
                </td>
              </tr>
              <tr>
                <td width="40%" className="recommendations">
                  09/15/2018
                </td>
                <td>
                  n/a
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}