import React, { Component } from "react";
import { Table } from "reactstrap";
import constants from "../utils/constants";
import _ from "lodash";

export class EnrollmentsByPhaseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.enrollmentByPhaseTableContent =
      this.enrollmentByPhaseTableContent.bind(this);
    this.calculateParticipationPercentage =
      this.calculateParticipationPercentage.bind(this);
  }

  calculateParticipationPercentage(category, TotalEnrolled) {
    const percentAsDecimal = category / TotalEnrolled;
    if (isNaN(percentAsDecimal)) {
      return "0";
    }
    const percentage = percentAsDecimal * 100;
    return percentage.toFixed(0);
  }

  enrollmentByPhaseTableContent() {
    const enrollmentByPhaseDataToChart = this.props.enrollmentsByPhaseData;
    const tableContent = _.map(enrollmentByPhaseDataToChart, (item, index) => {
      return (
        <React.Fragment key={index}>
          <tr
            key={`${index}-medicallyEnrolled-row`}
            style={{
              backgroundColor: constants.PEAK_COLORS.purple,
              color: "white",
            }}
          >
            <td className="text-left">
              <strong>Medically Enrolled</strong>
            </td>
            <td key={`${index}-medicallyEnrolled`} className="text-left">
              {item.medicallyEnrolled}
            </td>
            <td key={`${index}-lifeforceActive`} className="text-center">
              {Number(item.lifeforceActive).toLocaleString()}
            </td>
            <td
              key={`${index}-phase1`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.green }}
            >
              {Number(item.phase1).toLocaleString()}
            </td>
            <td
              key={`${index}-phase2`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.blue }}
            >
              {Number(item.phase2).toLocaleString()}
            </td>
            <td
              key={`${index}-phase3`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.purple }}
            >
              {Number(item.phase3).toLocaleString()}
            </td>
            <td
              key={`${index}-phase4`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.orange }}
            >
              {Number(item.phase4).toLocaleString()}
            </td>
            <td
              key={`${index}-phase5`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.green }}
            >
              {Number(item.phase5).toLocaleString()}
            </td>
            <td
              key={`${index}-pending`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.blue }}
            >
              {Number(item.pending).toLocaleString()}
            </td>
          </tr>
          <tr
            key={`${index}-percentage-row`}
            style={{
              backgroundColor: constants.PEAK_COLORS.purple,
              color: "white",
            }}
          >
            <td className="text-left">
              <strong>% Participation</strong>
            </td>
            <td className="text-left"></td>
            <td
              key={`${index}-lifeforceActive-percentage`}
              className="text-center"
            >
              {this.calculateParticipationPercentage(
                item.lifeforceActive,
                item.medicallyEnrolled
              )}
              %
            </td>
            <td
              key={`${index}-phase1-percentage`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.green }}
            >
              {this.calculateParticipationPercentage(
                item.phase1,
                item.medicallyEnrolled
              )}
              %
            </td>
            <td
              key={`${index}-phase2-percentage`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.blue }}
            >
              {this.calculateParticipationPercentage(
                item.phase2,
                item.medicallyEnrolled
              )}
              %
            </td>
            <td
              key={`${index}-phase3-percentage`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.purple }}
            >
              {this.calculateParticipationPercentage(
                item.phase3,
                item.medicallyEnrolled
              )}
              %
            </td>
            <td
              key={`${index}-phase4-percentage`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.orange }}
            >
              {this.calculateParticipationPercentage(
                item.phase4,
                item.medicallyEnrolled
              )}
              %
            </td>
            <td
              key={`${index}-phase5-percentage`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.green }}
            >
              {this.calculateParticipationPercentage(
                item.phase5,
                item.medicallyEnrolled
              )}
              %
            </td>
            <td
              key={`${index}-pending-percentage`}
              className="text-center"
              style={{ backgroundColor: constants.PEAK_COLORS.blue }}
            >
              {this.calculateParticipationPercentage(
                item.pending,
                item.medicallyEnrolled
              )}
              %
            </td>
          </tr>
        </React.Fragment>
      );
    });

    return tableContent;
  }

  render() {
    return (
      <>
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <th className="text-left"></th>

                <th className="text-left">Total</th>
                <th className="text-center">Lifeforce Active</th>
                <th className="text-center">Phase 1</th>
                <th className="text-center">Phase 2</th>
                <th className="text-center">Phase 3</th>
                <th className="text-center">Phase 4</th>
                <th className="text-center">Phase 5</th>
                <th className="text-center">Pending</th>
              </tr>
            </thead>
            <tbody>{this.enrollmentByPhaseTableContent()}</tbody>
          </Table>
        </div>
      </>
    );
  }
}
