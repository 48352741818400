import React, { Component, Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { WidgetHTMLText, WidgetParticipantHTMLText, WidgetImage, WidgetAccountSummary, WidgetApptRequest,
  WidgetHealthUpdate, WidgetLabCorpResults, WidgetVisitHistory, WidgetProgressReport, 
  WidgetLabOrderForm, WidgetEnrollmentStatus, WidgetEnrollmentStatus2, WidgetRecommendationsAndReferrals,
  WidgetSMSOptForm} from '../components/';
import api from '../utils/api';
import helpers from '../utils/helpers';
import _ from 'lodash';
import storage from "../utils/storageFactory.js";
import SlideShowBanner from './SlideShowBanner';

const WIDGET_TYPE = {
  HTMLText: 1,
  Image: 2,
  LabResults: 3,
  HealthUpdate: 4,
  VisitHistory: 5,
  AccountSummary: 6,
  LabCorpResults: 7,
  ParticipantHTMLText: 8,
  ProgressReport: 9,
  EnrollmentStatus: 10,
  ApptRequest: 11,
  EnrollmentStatus2: 12,
  RecommendationsAndReferrals: 13,
  BannerImage: 14,
  SMSOptIn: 15
};

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      panelList: [],
      labFormDataId: null,
      completedHealthUpdate: false,
      hasLabCorpResults: false,
      accountSummary: null,
      latestVisitDetail: null,
      enrollmentInfo: null,
      ssoToken: props.ssoToken,
      RecommendationsAndReferrals: null,
      nextAppt: null
    };
    this.renderWidget = this.renderWidget.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    if (this.state.ssoToken) {
      // in this case we've been authenticated via SSO
      // and the session token has been passed to us
      // we just need to ask the server for our user definition
      // so that we can place it into localStorage for reference
      // storage.setItem('token', this.state.ssoToken);
      api.fetch(`Public/CompleteDelegatedSSOAuthentication/${this.props.userId}/${this.props.ssoToken}`).then(r => {
        if (r.data.success === false) {
          this.setState({messageFlavor: "danger", message: r.data.message, ssoToken: null});
        } else {
          this.setState({
            ssoToken: null,
            nextAppt: r.data.nextAppt
          });
          if (r.data.wasTruist) {
            storage.setItem('TruistSSO', '1');
          }
          this.props.setCurrentUser(r.data.user, r.data.user.token, r.data.nextAppt);
          window.location.replace(process.env.REACT_APP_BASE_URL +'/home');
        }
      }).catch(helpers.catchHandler);
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentUser 
      && (!prevProps.currentUser 
          || (prevProps.currentUser.id !== this.props.currentUser.id))) 
    {
      this.loadData();
    }
  }

  loadData() {
    // true disables caching
    api.getUserHomeData(true).then(data => {
      this.setState({
        message: data.message,
        panelList: data.panelList,
        labFormDataId: data.labFormDataId,
        completedHealthUpdate: data.completedHealthUpdate,
        hasLabCorpResults: data.hasLabCorpResults,
        accountSummary: data.accountSummary,
        latestVisitDetail: data.latestVisitDetail,
        enrollmentInfo: data.enrollmentInfo,
        isEnrolled: data.isEnrolled,
        RecommendationsAndReferrals: null
      });
    }).catch(helpers.catchHandler);
  }

  renderWidget(w) {
    switch(w.panelType) {
      case WIDGET_TYPE.RecommendationsAndReferrals:
        return (
          <WidgetRecommendationsAndReferrals
            key={w.id}
            collapse={false}
            iconKey={w.iconKey}
            title={w.title}
            recommendationsAndReferrals={this.state.RecommendationsAndReferrals}
          />);
      case WIDGET_TYPE.AccountSummary:
        return (
          <WidgetAccountSummary
            key={w.id}
            collapse={false}
            iconKey={w.iconKey}
            title={w.title}
            summaryData={this.state.accountSummary}
          />);
      case WIDGET_TYPE.HealthUpdate:
        return (
          <WidgetHealthUpdate
            key={w.id}
            collapse={false}
            iconKey={w.iconKey}
            title={w.title}
            completedHealthUpdate={this.state.completedHealthUpdate}
          />);
      case WIDGET_TYPE.VisitHistory:
        return (
          <WidgetVisitHistory
            key={w.id}
            collapse={false}
            iconKey={w.iconKey}
            title={w.title}
            latestVisitDetail={this.state.latestVisitDetail}
          />);
      case WIDGET_TYPE.LabCorpResults:
        return (
          <WidgetLabCorpResults
            key={w.id}
            collapse={false}
            hasLabCorpResults={this.state.hasLabCorpResults}
            iconKey={w.iconKey}
            title={w.title}
          />);
      case WIDGET_TYPE.LabResults:
        if (!this.props.currentUser) return (<div></div>);
        return (
          <WidgetLabOrderForm
            key={w.id}
            collapse={false}
            labFormDataId={this.state.labFormDataId}
            iconKey={w.iconKey}
            title={w.title}
            isBBT={this.props.currentUser.isBBT}
            isTEL={this.props.currentUser.isTEL}
            isTELSpouse={this.props.currentUser.isTELSpouse}
            isSunTrust={this.props.currentUser.isSunTrust}
          />);
      case WIDGET_TYPE.HTMLText:
        return (
          <WidgetHTMLText
            key={w.id}
            collapse={false}
            html={w.htmlContent}
            iconKey={w.iconKey}
            title={w.title}
          />);
      case WIDGET_TYPE.ParticipantHTMLText:
        return (
          <WidgetParticipantHTMLText
            key={w.id}
            collapse={false}
            html={w.htmlContent}
            iconKey={w.iconKey}
            title={w.title}
          />);
      case WIDGET_TYPE.Image:
        return (
          <WidgetImage
            key={w.id}
            collapse={false}
            iconKey={w.iconKey}
            title={w.title}
            imageKey={w.imageKey}
          />);
      case WIDGET_TYPE.ProgressReport:
        return (
          <WidgetProgressReport
            key={w.id}
            collapse={false}
            iconKey={w.iconKey}
            title={w.title}
          />);
      case WIDGET_TYPE.EnrollmentStatus:
        return (
          <WidgetEnrollmentStatus
            key={w.id}
            collapse={false}
            iconKey={w.iconKey}
            title={w.title}
            enrollmentInfo={this.state.enrollmentInfo}
          />);
      case WIDGET_TYPE.EnrollmentStatus2:
        return (
          <WidgetEnrollmentStatus2
            key={w.id}
            collapse={false}
            iconKey={w.iconKey}
            title={w.title}
            enrollmentInfo={this.state.enrollmentInfo}
          />);
      case WIDGET_TYPE.ApptRequest:
        if (!this.props.currentUser) return (<div></div>);
        return (
          <WidgetApptRequest
            key={w.id}
            collapse={false}
            html={w.htmlContent}
            iconKey={w.iconKey}
            title={w.title}
            nextAppt={this.state.nextAppt}
            isEnrolled={this.state.isEnrolled}
            completedHealthUpdate={this.state.completedHealthUpdate}
            currentUser={this.props.currentUser}
          />);
      case WIDGET_TYPE.SMSOptIn:
        if (!this.props.currentUser) return (<div></div>);
        return (
          <WidgetSMSOptForm
            key={w.id}
            collapse={false}
            html={w.htmlContent}
            iconKey={w.iconKey}
            title={w.title}
            currentUser={this.props.currentUser}
          />);
      default: 
        return null;
      }
  }

  render() {
    return (
    <Fragment>
        <SlideShowBanner
          images={_.filter(this.state.panelList, (p) => p.panelType === WIDGET_TYPE.BannerImage)}
        />
      <Row className="expand-md mb-5 p-0">
        <Col>
          {_.chain(this.state.panelList)
            .filter(p => p.colSeq === 1 && p.panelType !== WIDGET_TYPE.BannerImage)
            .orderBy(p => p.seq)
            .map(p => this.renderWidget(p))
            .value()}
        </Col>
        <Col>
          {_.chain(this.state.panelList)
            .filter(p => p.colSeq === 2 && p.panelType !== WIDGET_TYPE.BannerImage)
            .orderBy(p => p.seq)
            .map(p => this.renderWidget(p))
            .value()
          }
        </Col>
      </Row>
    </Fragment>
    );
  }
}
