import React, { Component, Fragment } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import date_helpers from '../utils/date_helpers';

const DATE_TIME_FORMAT = 'MMM-DD-YYYY hh:mm a';
//const DATE_FORMAT = 'MMM-DD-YYYY';
const TIME_FORMAT = 'hh:mm a';
export class AccountSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  render() {
    const data = this.props.summaryData;
    if (!data) return null;
    const apptAt = data.scheduledAtString
      ? date_helpers.getMomentFromString(data.scheduledAtString, DATE_TIME_FORMAT)
      : null;
    return (
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm">
            <tbody>
              <tr>
                <td width="40%" className="accountSummary topRow">
                  Next Appointment
                </td>
                <td className="topRow">
                  {apptAt ? apptAt.format(date_helpers.DISPLAY_DATE_FORMAT) : "Not Scheduled"}
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Appointment Time
                </td>
                <td>
                {apptAt
                  ? (<Fragment>
                    <div>{apptAt.format(TIME_FORMAT)}</div>
                    <div style={{fontStyle:"italic"}}>{data.appointmentTimezone}</div>
                    </Fragment>)
                  : "n/a"
                }
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Location
                </td>
                <td>
                  {data.appointmentLocation ? data.appointmentLocation : "n/a"}{data.isTelephonic ? " TELEPHONIC" : ""}
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Last Lab Completed
                </td>
                <td>
                  {data.lastLabDate ? date_helpers.apiDateTimeToDisplayDate(data.lastLabDate) : "n/a"}
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Next Lab Due
                </td>
                <td>
                  {data.nextLabDate ? date_helpers.apiDateTimeToDisplayDate(data.nextLabDate) : "n/a"}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Link to='/progress-goals'>
                    See My Progress &amp; Goals
                  </Link>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}