import React, { Component } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import _ from "lodash";
import { Container, Card, CardBody, Alert } from "reactstrap";
import {
  ErrorMessage,
  NotFound,
  Account,
  ForgotPassword,
  UpdatePassword,
  ResetPassword,
  Home,
  Login,
  Profile,
  ImpersonateAssignedUser,
  PrivacyPolicyAndDisclaimer,
  Visit,
  Admin,
  Impersonate,
  SetChallengeQA,
  UserSecurity,
  Survey,
  AppointmentCalendar,
  Company,
  ProgressAndGoals,
  ProgressAndGoals2,
  SMSConsent,
  MetricsDashboard,
} from "./pages";
import { Footer, NavMenu, SetSecurityQuestion } from "./components";
import SidebarMenu from "./components/SideBarMenu";
import helpers from "./utils/helpers";
import api from "./utils/api";
import storage from "./utils/storageFactory.js";
import constants from "./utils/constants";
import "./assets/index.scss";
import { NotificationFacade } from "./utils/notificationfacade.js";

const IGNORED_REQUESTED_ROUTES = [
  "/",
  "/login",
  "/sso-token",
  "/Public/LogOn",
  "/forgot-password",
  "/home",
  "/SSO/Init/420",
];

function enhanceUser(u) {
  if (u) {
    u.isAdmin =
      _.findIndex(u.roles, function (r) {
        return r === constants.ROLE_IDs.Administrator;
      }) !== -1;

    u.isCompanyAdmin =
      _.findIndex(u.roles, function (r) {
        return r === constants.ROLE_IDs.CompanyAdministrator;
      }) !== -1;
  }
  return u;
}

const LoginRoute = ({ ...extraProps }) => (
  <Route
    {...extraProps}
    render={(props) => {
      return extraProps.authenticated() ? (
        <Redirect to="/home" />
      ) : (
        <Login {...extraProps} />
      );
    }}
  />
);

const AuthRoute = ({
  component: Component,
  currentUser,
  app: rootComponent,
  ...extraProps
}) => (
  <Route
    {...extraProps}
    render={(props) => {
      if (currentUser && helpers.mustChangePassword(currentUser)) {
        return (
          <UpdatePassword
            currentUser={currentUser}
            {...extraProps}
            logout={rootComponent.Logout}
            setCurrentUser={rootComponent.SetCurrentUser}
          />
        );
      }
      if (currentUser && !helpers.hasChallengeQuestion(currentUser)) {
        return (
          <SetChallengeQA
            redirectOnSuccess={true}
            currentUser={currentUser}
            setChallengeQuestion={rootComponent.SetChallengeQuestion}
            {...extraProps}
          />
        );
      }
      if (!api.userToken()) {
        rootComponent.Logout(
          "You are now logged out. Please sign in to continue."
        );
        return <Redirect to="/login" />;
      }
      //saving route if it is not occurring during a log out
      // if(sessionStorage.getItem("loggedOut")){
      //   sessionStorage.removeItem("loggedOut")
      // }else if(!extraProps.authenticated()){
      //   getOrSetPath("originalRoute", extraProps.location.pathname);
      // }
      return rootComponent.Authenticated() ? (
        <Component
          {...extraProps}
          currentUser={currentUser}
          authenticated={rootComponent.Authenticated}
          // impersonating={this.state.impersonating}
          setImpersonation={rootComponent.SetImpersonation}
          setCurrentUser={rootComponent.SetCurrentUser}
          setChallengeQuestion={rootComponent.SetChallengeQuestion}
          logout={rootComponent.Logout}
        />
      ) : (
        <Redirect to="/login" />
      );
    }}
  />
);

const TEN_SECONDS = 10000;
const FIVE_MINUTES = 300000;
// const TEN_MINUTES = 600000;
const TWENTY_MINUTES = 1200000;

const nowTime = () => {
  return new Date().getTime();
};

let lastActivityCheckedAt = nowTime();

export default class App extends Component {
  displayName = App.name;
  constructor(props) {
    super(props);
    const token = storage.getItem("token");
    const currentUser = token
      ? JSON.parse(storage.getItem("currentUser"))
      : null;
    const loa = storage.getItem("loggedOnAt");
    const loggedOnAt = parseInt(loa) ? parseInt(loa) : 0;
    const adminToken = storage.getItem("adminToken");
    // let shortUrls = null;
    // let shortUrlRedirectTo = "";
    this.state = {
      authenticationToken: token,
      currentUser: enhanceUser(currentUser),
      loggedOnAt: loggedOnAt,
      impersonating: adminToken === null ? false : true,
      logoutMessage: null,
      shortUrlsLoadingComplete: false,
    };
    this.Logout = this.Logout.bind(this);
    this.StartTimer = this.StartTimer.bind(this);
    this.SetLastActivity = this.SetLastActivity.bind(this);
    this.SetCurrentUser = this.SetCurrentUser.bind(this);
    this.SetImpersonation = this.SetImpersonation.bind(this);
    this.onDismissSignInMessage = this.onDismissSignInMessage.bind(this);
    this.ClearImpersonation = this.ClearImpersonation.bind(this);
    this.Authenticated = this.Authenticated.bind(this);
    this.SetChallengeQuestion = this.SetChallengeQuestion.bind(this);
    this.VerifyAuthentication = this.VerifyAuthentication.bind(this);
    this.ClearImpersonationPersistence =
      this.ClearImpersonationPersistence.bind(this);
    this.NotificationFacade = new NotificationFacade();
    this.LoadShortUrls = this.LoadShortUrls.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.SetLastActivity);
    this.StartTimer();
    this.LoadShortUrls();
  }

  componentDidUpdate() {
    if (nowTime() - lastActivityCheckedAt < TEN_SECONDS) return;
    this.CompareTimerToLastActivity(this);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.SetLastActivity);
    clearInterval(this.intervalId);
  }

  ClearImpersonationPersistence() {
    if (storage.getItem("adminUser")) {
      storage.removeItem("adminToken");
      storage.removeItem("adminUser");
    }
  }

  StartTimer() {
    this.intervalId = setInterval(
      this.CompareTimerToLastActivity,
      FIVE_MINUTES,
      this
    );
    this.VerifyAuthentication();
  }

  CompareTimerToLastActivity(rootComponent) {
    lastActivityCheckedAt = nowTime();
    const lastActivity = storage.getItem("lastActivity");
    if (!lastActivity) return;
    if (nowTime() - parseInt(lastActivity) > TWENTY_MINUTES) {
      rootComponent.Logout(
        "You have been logged out due to inactivity. Please log back in to continue."
      );
    }
    rootComponent.VerifyAuthentication();
  }

  VerifyAuthentication() {
    if (
      !this.state.authenticationToken ||
      !this.state.loggedOnAt ||
      nowTime() - this.state.loggedOnAt < TWENTY_MINUTES
    )
      return; // not logged in, no need to verify anything or < 20 min since logon
    api
      .fetch("Public/AuthCheck?nocache=" + nowTime())
      .then((r) => {
        if (r.data.success === false) {
          this.Logout("Your session has timed out.  Please log in.");
        }
      })
      .catch((e) => {
        console.error("Failed auth check", e);
        this.Logout("Your session has timed out.  Please log in.");
      });
  }

  SetLastActivity() {
    if (!this.state.authenticationToken || !storage.getItem("token")) {
      if (storage.getItem("lastActivity")) {
        storage.removeItem("lastActivity");
      }
      return; // not logged in
    }
    storage.setItem("lastActivity", nowTime());
    if (this.state.logoutMessage) {
      this.setState({ logoutMessage: null });
    }
  }

  Logout(logoutMessage = null) {
    storage.removeItem("token");
    storage.removeItem("currentUser");
    storage.removeItem("nextAppt");
    storage.removeItem("loggedOnAt");
    storage.removeItem("lastActivity");
    storage.removeItem("lastRequestedRoute");
    if (storage.getItem("adminUser")) {
      storage.removeItem("adminToken");
      storage.removeItem("adminUser");
    }
    api.serverLogoff();
    storage.removeItem("TruistSSO");
    this.ClearImpersonationPersistence();
    this.setState({
      currentUser: null,
      authenticationToken: null,
      loggedOnAt: null,
      logoutMessage: logoutMessage,
    });
  }

  SetCurrentUser(user, token, nextAppt) {
    this.ClearImpersonationPersistence();
    user = enhanceUser(user);
    const now = nowTime();
    storage.setItem("currentUser", JSON.stringify(user));
    storage.setItem("loggedOnAt", now);
    let newState = {
      currentUser: user,
      loggedOnAt: now,
      logoutMessage: null,
    };
    if (token) {
      storage.setItem("token", token);
      newState.authenticationToken = token;
    }
    if (nextAppt) {
      storage.setItem("nextAppt", JSON.stringify(nextAppt));
    }
    this.setState(newState);
    this.StartTimer();
  }

  SetChallengeQuestion(questionId, question) {
    let user = JSON.parse(storage.getItem("currentUser"));
    user.challengeQuestionId = questionId;
    user.challengeQuestionDsc = question;
    storage.setItem("currentUser", JSON.stringify(user));
    this.setState({ currentUser: user });
  }

  SetImpersonation(user, token, nextAppt) {
    if (this.state.impersonating) {
      return; // do not allow impersonation a 2nd time if we're already in that mode
    }
    // remember the admin user's details
    const adminUser = JSON.parse(storage.getItem("currentUser"));
    const adminUserToken = storage.getItem("token");
    // clear storage
    storage.removeItem("token");
    storage.removeItem("currentUser");
    storage.removeItem("nextAppt");
    // set the impersonation details
    user = enhanceUser(user);
    storage.setItem("token", token);
    storage.setItem("currentUser", JSON.stringify(user));
    if (nextAppt) {
      storage.setItem("nextAppt", JSON.stringify(nextAppt));
    }
    // remember the admin details for later
    storage.setItem("adminToken", adminUserToken);
    storage.setItem("adminUser", JSON.stringify(adminUser));
    this.setState({
      impersonating: true,
      currentUser: user,
      authenticationToken: token,
    });
  }

  onDismissSignInMessage() {
    this.setState({ logoutMessage: null });
  }

  ClearImpersonation() {
    if (!this.state.impersonating) {
      return; // do not allow clear of the primary user if we're not impersonating
    }
    // get the original admin user
    const adminUser = JSON.parse(storage.getItem("adminUser"));
    const adminUserToken = storage.getItem("adminToken");
    // clear storage
    this.ClearImpersonationPersistence();
    // set the admin user back as primary
    storage.setItem("token", adminUserToken);
    storage.setItem("currentUser", JSON.stringify(adminUser));
    this.setState({
      impersonating: false,
      currentUser: adminUser,
      authenticationToken: adminUserToken,
    });
  }

  LoadShortUrls() {
    this.NotificationFacade.GetShortUrls()
      .then((r) => {
        if (!Array.isArray(r)) return;
        this.shortUrls = r;
      })
      .catch(helpers.catchHandler)
      .finally(() => {
        this.setState({ shortUrlsLoadingComplete: true });
      });
  }

  IsShortUrl(location) {
    const token = location.pathname.split("/")[1];

    if (this.shortUrls == null) return false;

    if (Array.isArray(this.shortUrls) == null) return false;

    var shortUrl = this.shortUrls.find(
      (x) => x.token.toUpperCase() === token.toUpperCase()
    );

    if (!shortUrl) return false;

    if (shortUrl.url.startsWith("/"))
      shortUrl.url = window.location.origin + shortUrl.url;

    this.shortUrlRedirectTo = shortUrl.url;

    return true;
  }

  Authenticated() {
    return this.state.authenticationToken !== null;
  }

  render() {
    if (!this.state.shortUrlsLoadingComplete) {
      return null;
    }

    const route = window.location.pathname;
    // "deep link" redirect logic
    if (this.state.currentUser) {
      const lastRequestedRoute = storage.getItem("lastRequestedRoute");
      storage.removeItem("lastRequestedRoute");
      if (lastRequestedRoute) {
        setTimeout(() => {
          window.location.replace(lastRequestedRoute);
        }, 1500);
        return <div />;
      }
    } else if (route.startsWith("/dl-truist")) {
      const reqRouteTruist = route.substring(10);
      storage.setItem("lastRequestedRoute", reqRouteTruist);
      window.location.replace("/SSO/Init/420");
      return <div />;
    } else if (route.startsWith("/dl-mhealth")) {
      const reqRouteMH = route.substring(11);
      storage.setItem("lastRequestedRoute", reqRouteMH);
      window.location.replace(
        "https://ps01.mobilehealthconsumer.com//partners/SSO/SAML20/PeakHealth/mot396k3323phxka5p3hm3zr01dtdath/"
      );
      return <div />;
    } else if (
      !IGNORED_REQUESTED_ROUTES.includes(route) &&
      !route.startsWith("/reset_password/token/")
    ) {
      storage.setItem("lastRequestedRoute", route);
    }

    return (
      <Router>
        <div>
          {route === "/login" && !this.state.currentUser ? null : (
            <NavMenu
              logout={this.Logout}
              currentUser={this.state.currentUser}
              impersonating={this.state.impersonating}
              clearImpersonation={this.ClearImpersonation}
            />
          )}
          <Container fluid className="mainBody">
            {this.state.currentUser ? (
              <SidebarMenu
                showOpen={false}
                currentUser={this.state.currentUser}
              />
            ) : null}
            <Card className="mainCard mt-2">
              <CardBody className="p-2">
                <Alert
                  color="success"
                  isOpen={this.state.logoutMessage ? true : false}
                  toggle={this.onDismissSignInMessage}
                >
                  {this.state.logoutMessage}
                </Alert>
                <Switch>
                  <Route
                    path="/sso-token"
                    render={(props) => {
                      const ssoTokenParams =
                        props.location && props.location.search
                          ? new URLSearchParams(props.location.search)
                          : null;
                      const ssoToken = ssoTokenParams
                        ? ssoTokenParams.get("token")
                        : null;
                      const preAuthUserId = ssoTokenParams
                        ? ssoTokenParams.get("userId")
                        : null;
                      return (
                        <Home
                          ssoToken={ssoToken}
                          userId={preAuthUserId}
                          authenticated={this.Authenticated}
                          logout={this.Logout}
                          currentUser={this.state.currentUser}
                          setChallengeQuestion={this.SetChallengeQuestion}
                          setCurrentUser={this.SetCurrentUser}
                        />
                      );
                    }}
                  />
                  <LoginRoute
                    exact
                    path="/"
                    authenticated={this.Authenticated}
                    setCurrentUser={this.SetCurrentUser}
                  />
                  <LoginRoute
                    exact
                    path="/login"
                    authenticated={this.Authenticated}
                    setCurrentUser={this.SetCurrentUser}
                  />
                  <LoginRoute
                    exact
                    path="/Public/LogOn"
                    authenticated={this.Authenticated}
                    setCurrentUser={this.SetCurrentUser}
                  />
                  <Route
                    path="/forgot-password"
                    render={(props) => {
                      return (
                        <ForgotPassword
                          setCurrentUser={this.SetCurrentUser}
                          username={
                            props.location && props.location.state
                              ? props.location.state.username
                              : ""
                          }
                        />
                      );
                    }}
                  />
                  <Route
                    path="/reset_password/token/:resetToken"
                    component={ResetPassword}
                  />
                  <AuthRoute
                    exact
                    path="/edit_company"
                    app={this}
                    component={Company}
                    currentUser={this.currentUser}
                  />
                  <AuthRoute
                    exact
                    path="/home"
                    app={this}
                    component={Home}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    path="/user-security"
                    app={this}
                    component={UserSecurity}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    path="/security-question"
                    app={this}
                    component={SetSecurityQuestion}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    path="/impersonate"
                    app={this}
                    component={Impersonate}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    path="/impersonate_assigned_user"
                    app={this}
                    component={ImpersonateAssignedUser}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    path="/admin"
                    app={this}
                    component={Admin}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    exact
                    path="/profile"
                    app={this}
                    component={Profile}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    path="/smsconsent"
                    app={this}
                    component={SMSConsent}
                    currentUser={this.state.currentUser}
                  />
                  <Route path="/ppd" component={PrivacyPolicyAndDisclaimer} />
                  <AuthRoute
                    exact
                    path="/survey"
                    app={this}
                    component={Survey}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    exact
                    path="/account"
                    app={this}
                    component={Account}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    exact
                    path="/visit"
                    app={this}
                    component={Visit}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    exact
                    path="/appointment/calendar"
                    app={this}
                    component={AppointmentCalendar}
                    currentUser={this.state.currentUser}
                  />
                  <AuthRoute
                    exact
                    path="/metrics-dashboard"
                    app={this}
                    component={MetricsDashboard}
                    currentUser={this.state.currentUser}
                  />
                  {this.state.currentUser && this.state.currentUser.newProgressAndGoals ? (
                    <AuthRoute
                      exact
                      path="/progress-goals"
                      app={this}
                      component={ProgressAndGoals2}
                      currentUser={this.state.currentUser}
                    />
                  ) : (
                    <AuthRoute
                      exact
                      path="/progress-goals"
                      app={this}
                      component={ProgressAndGoals}
                      currentUser={this.state.currentUser}
                    />
                  )}
                  {this.IsShortUrl(window.location) && (
                    <Route
                      render={() =>
                        window.location.replace(this.shortUrlRedirectTo)
                      }
                    />
                  )}
                  <Route component={NotFound} />
                  <ErrorMessage />
                </Switch>
              </CardBody>
            </Card>
          </Container>
          <Footer />
        </div>
      </Router>
    );
  }
}
