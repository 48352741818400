import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, Card, CardTitle, CardHeader, CardBody, Alert} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';
import Dropzone from 'react-dropzone';
import api from '../utils/api';
// import _ from 'lodash';

export class LabLocationPDF extends Component {
constructor(props) {
  super(props);
  this.state = {
    message: null,
    messageFlavor: null,
    labPDF: null
  };
  this.handleSubmit = this.handleSubmit.bind(this);
  this.onPDFDrop = this.onPDFDrop.bind(this);
}

isFormValid(){
  let warnings =[];
  if(!this.state.labPDF){
    warnings.push("Please provide a PDF to upload")
  }
  if (warnings.length) {
    this.setState({ message: warnings.join(','), messageFlavor:"danger" });
  } else {
    this.setState({ message: null});
  }
  return warnings.length === 0;
}

onPDFDrop(acceptedFiles, rejectedFiles) {
  if (rejectedFiles.length) {
    this.setState({ message: 'One or more files were rejected.', messageFlavor: "danger" });
  } else {
    this.setState({ 
      labPDF: acceptedFiles[0], 
      message: null 
    });
  }
}

handleSubmit(event) {
  event.preventDefault();
  if (!this.isFormValid()) return;
  let payload = new FormData();
  payload.append('labForm', this.state.labPDF);
  api.post_form_data('Admin/LabLocationPDF', payload).then(r => {
    if (r.data.success === false) {
      this.setState({message: r.data.message, messageFlavor: "danger"});
    } else {
      this.setState({message: "The lab locations PDF has been updated", messageFlavor: "success"});
    }
  }).catch(helpers.catchHandler);
}

  render() {
    const pendingUpload = this.state.labPDF !== null;
  return (
  <Card>
    <CardHeader className="strong-header">
      Lab Locations PDF
    </CardHeader>
    <CardBody>
      {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>) }
      <Row className="mb-2">
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="text-center">
                Drop the new lab location PDF file or click on the icon below to choose it
              </CardTitle>
                <Dropzone className="col-xs-4"
                  onDrop={this.onPDFDrop}
                  accept="application/pdf"
                >
                {({getRootProps, getInputProps}) => (<section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p style={{ textAlign: 'center' }}><FontAwesomeIcon icon={this.state.labPDF ? "check" : "file-upload"} size="6x" /></p>
                  </div>
                </section>)}
                </Dropzone>
              </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="expand-md">
        <Col className="text-right">
          <ButtonGroup>
            <Button size="lg" color={pendingUpload ? "primary" : "secondary"} disabled={!pendingUpload} onClick={this.handleSubmit}>Save</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </CardBody>
  </Card>
  );
  }
}
