import React, { Component } from 'react';
// import { Row, Col, Table } from 'reactstrap';

export class PasswordRequirements extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{textAlign: "left"}}>
        <div style={{fontWeight: "bold", fontSize: "1.1rem", textDecoration: "underline"}}>Password Requirements</div>
        <div className="passwordReq">1 Number</div>
        <div className="passwordReq">1 Capital Letter</div>
        <div className="passwordReq">Minimum 8 Characters</div>
      </div>
    );
  }
}