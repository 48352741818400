import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class SortHeader extends Component {
  constructor(props) {
    super(props);
    if (!props.callBack) {
      console.warn('Add a callBack function property on the sort header that accepts field and direction params');
    }
    if (!props.field) {
      console.warn('Add a field property on the sort header that specifies the field');
    }
    if (!props.displayName) {
      console.warn('Add a displayName property on the sort header that specifies the field');
    }
  }

  render() {
    const dir = this.props.sortDir || 'desc';
    const callBackDir = dir === 'desc' ? 'asc' : 'desc';
    return (
      <span onClick={() => this.props.callBack(this.props.field, callBackDir)} className="sort-handle">
        {this.props.displayName}
        {this.props.sorted && dir === 'asc' && <FontAwesomeIcon icon="angle-up" />}
        {this.props.sorted && dir === 'desc' && <FontAwesomeIcon icon="angle-down" />}
      </span>
    );
  }
}