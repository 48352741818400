import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';

export class ActivityCodes extends Component {
  displayName = "Activity Codes";
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }
  

  render() {
    return (
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm" className="my-0">
            <tbody className="my-0">
              <tr>
                <td width="10%" className="activityCodes topRow">
                  A
                </td>
                <td className="topRow">
                  Dance
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  B
                </td>
                <td>
                  Walking
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  C
                </td>
                <td>
                  Jogging
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  D
                </td>
                <td>
                  Racquetball
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  E
                </td>
                <td>
                  Cycling/Eliptical-Indoors
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  F
                </td>
                <td>
                  Cycling-Outdoors
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  G
                </td>
                <td>
                  Rowing
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  H
                </td>
                <td>
                  Jumping Rope
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  I
                </td>
                <td>
                  Tennis
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  J
                </td>
                <td>
                  Basketball
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  K
                </td>
                <td>
                  Stairs/Steps
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  L
                </td>
                <td>
                  Skiing
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  M
                </td>
                <td>
                  Swimming
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  N
                </td>
                <td>
                  Weights
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  O
                </td>
                <td>
                  Toning
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  P
                </td>
                <td>
                  Inactive
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  Q
                </td>
                <td>
                  Softball
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  R
                </td>
                <td>
                  Yardwork
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  S
                </td>
                <td>
                  Golf
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  T
                </td>
                <td>
                  Volleyball
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  U
                </td>
                <td>
                  Cardio-Non-specific
                </td>
              </tr>
              <tr>
                <td width="10%" className="activityCodes">
                  X
                </td>
                <td>
                  Other
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}
