import React, { Component } from "react";
import { Table } from "reactstrap";
import _ from "lodash";
import constants from "../utils/constants";

export class VisitsByPlanYearTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.bodyContent = this.bodyContent.bind(this);
  }

  bodyContent() {
    const rowColors = [constants.PEAK_COLORS.green, constants.PEAK_COLORS.blue];

    const data = this.props.visitsByPlanYearData;
    const rows = _.map(data, (item, index) => {
      const backgroundColor = rowColors[index % rowColors.length];
      return (
        <tr
          key={index}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.purple)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = backgroundColor)
          }
          style={{
            backgroundColor: backgroundColor,
            color: "white",
          }}
        >
          <td>
            <strong>{item.reportingPeriod}</strong>
          </td>
          <td>{Number(item.totalVisits).toLocaleString()}</td>
          <td>{Number(item.totalRescheduled).toLocaleString()}</td>
          <td>{Number(item.totalNoShows).toLocaleString()}</td>
        </tr>
      );
    });
    return rows;
  }
  render() {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Reporting Period</th>
            <th>Total Visits</th>
            <th>Total Rescheduled</th>
            <th>Total No-Shows</th>
          </tr>
        </thead>
        <tbody>{this.bodyContent()}</tbody>
      </Table>
    );
  }
}
