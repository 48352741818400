import React, { Component } from 'react'
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  Row,
  Col,
  Alert,
} from 'reactstrap'
import api from '../utils/api';

export class EditUserPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
        user: props.user,
        message: null,
        messageColor: "danger",
        newPassword: '',
        confirmPassword: ''
    }
    this.closeModal = this.closeModal.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  closeModal() {
    this.props.onCancel()
  }

  handleSubmit(e) {
    e.preventDefault();

    if(this.state.newPassword !== this.state.confirmPassword) {
        this.setState({message: "Passwords do not match"})
        return;
    }

    const payload = {
        UserId: this.state.user.id,
        NewPassword: this.state.newPassword,
        ConfirmPassword: this.state.newPassword
    };
    api.post('Admin/SaveNewUserPassword', payload).then(({data}) => {
        if(data.success) {
            this.setState({message: data.message, messageColor: "success"}, () => setTimeout(() => {this.closeModal()}, 2500))
        } else {
          this.setState({message: data.message, messageColor: "danger"})
        }
    })
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={true}
          toggle={this.closeModal}
          backdrop={true}
          className="modal-lg"
        >
          <ModalHeader>Edit User Password</ModalHeader>
          <ModalBody>
            {this.state.message === null ? null : (
              <Alert color={this.state.messageColor}>{this.state.message}</Alert>
            )}
            <Row>
              <Col>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>New Password
                      <Input type="password" name="newPassword" id="password" required
                      onChange={(e) => this.setState({newPassword: e.target.value})} value={this.state.newPassword} />
                    </Col>
                    <Col>Confirm Password
                      <Input type="password" name="confirmPassword" id="confirmPassword" required
                      onChange={(e) => this.setState({confirmPassword: e.target.value})} value={this.state.confirmPassword} />
                    </Col>
                  </Row>
                  <Row className="expand-md">
                    <Col className="text-right">
                      <ButtonGroup>
                        <input
                          type="submit"
                          value="Save"
                          className="btn btn-primary"
                        />
                        <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
