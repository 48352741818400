import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';
// import api from '../utils/api';
import { TemplateCollapsibleCard } from './';

export class WidgetEnrollmentStatus2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse,
      enrollmentInfo: props.enrollmentInfo
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.enrollmentInfo !== prevProps.enrollmentInfo) {
      this.setState({enrollmentInfo: this.props.enrollmentInfo});
    }
  }

  render() {
    const info = this.state.enrollmentInfo === null || this.state.enrollmentInfo === undefined
      ? {statusName: "", healthAssessmentDate: "", ackDate: "", labDate: "", contextName: ""}
      : this.state.enrollmentInfo;
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm">
            <tbody>
              <tr>
                <td width="40%" className="accountSummary topRow">
                  Status
                </td>
                <td className="topRow">
                  {info.statusName}
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Annual {info.contextName} HA Completion Date
                </td>
                <td>
                  {info.healthAssessmentDate}
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Last Lab Completion Date
                </td>
                <td>
                  {info.labDate}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      </TemplateCollapsibleCard>
    );
  }
}