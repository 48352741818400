import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
// import unregister from './registerServiceWorker';
import "./custom.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleUp,
  faAngleDown,
  faFilter,
  faExclamationTriangle,
  faSearch,
  faLink,
  faClipboardCheck,
  faArrowCircleRight,
  faCheckCircle,
  faPlusCircle,
  faEdit,
  faTimesCircle,
  faTrash,
  faRecycle,
  faUser,
  faFileUpload,
  faPrint,
  faAngleDoubleDown,
  faFileCsv,
  faComments,
  faImages,
  faSave,
  faHome,
  faEye,
  faCheck,
  faPlus,
  faBackspace,
  faMinus,
  faTimes,
  faTrashAlt,
  faInfoCircle,
  faReply,
  faSyncAlt,
  faExchangeAlt,
  faSignInAlt,
  faRetweet,
  faShare,
  faClone,
  faBars,
  faShoppingCart,
  faUserCircle,
  faCartPlus,
  faSpinner,
  faCut,
  faFileContract,
  faFileSignature,
  faPhone,
  faKey,
  faMask,
  faUnlock,
  faChartPie,
  faChartBar,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faAngleUp,
  faAngleDown,
  faFilter,
  faExclamationTriangle,
  faSearch,
  faLink,
  faClipboardCheck,
  faArrowCircleRight,
  faCheckCircle,
  faPlusCircle,
  faEdit,
  faTimesCircle,
  faTrash,
  faRecycle,
  faUser,
  faFileUpload,
  faPrint,
  faAngleDoubleDown,
  faFileCsv,
  faComments,
  faImages,
  faSave,
  faHome,
  faEye,
  faCheck,
  faPlus,
  faBackspace,
  faMinus,
  faTimes,
  faTrashAlt,
  faInfoCircle,
  faReply,
  faSyncAlt,
  faExchangeAlt,
  faSignInAlt,
  faRetweet,
  faShare,
  faClone,
  faBars,
  faShoppingCart,
  faUserCircle,
  faCartPlus,
  faSpinner,
  faCut,
  faFileContract,
  faFileSignature,
  faPhone,
  faKey,
  faMask,
  faUnlock,
  faChartPie,
  faChartBar,
  faChartLine
);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement
);

// registerServiceWorker();
try {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
      console.log("Successfully unregistered service worker");
    });
    // navigator.serviceWorker.getRegistrations().then(registrations => {
    //     console.log('unreg', registrations);
    // });
  }
} catch (e) {
  console.error("failed to unregister service worker", e);
}
