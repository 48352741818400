import React, { Component } from 'react';
import { TemplateCollapsibleCard } from './TemplateCollapsibleCard';
import api from '../utils/api'
import helpers from '../utils/helpers';
import _ from 'lodash';

export class WidgetRecommendationsAndReferrals extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            collapse: props.collapse,
            recommendedText:[],
            handouts: []
        };
      }
      componentDidMount() {
        api.fetch('Home/Account').then(r => {
          if(r.data.latestVisitDetail) {
            this.setState({
                latestVisitDetail: r.data.latestVisitDetail,
                recommendedText: (r.data.latestVisitDetail.recomText).split(";"),
                handouts: r.data.handouts
            });
          }
        }).catch(helpers.catchHandler);
      }

      
    
    render () {
        return (
            <TemplateCollapsibleCard
            widgetIcon={['fas', this.props.iconKey]}
            displayName={this.props.title}
            collapse={this.state.collaspe}
            headerClass={'widgetCard'}
          >
            <p style={{fontWeight: "bold"}}>Nurse Recommendations</p>
            <ul>
              {_.map(this.state.recommendedText, (rec, index) => {
                  return(
                      <li key={`rec-${index}`}>{rec}</li>
                  );
              })}
           </ul>
            <p className="mb-0" style={{fontWeight: "bold"}}>Educational References</p>
            <ul className='pl-4 mt-0' style={{listStyle: "none"}}>
                                        {_.map(this.state.handouts, (h, index) => {
                                            return(
                                                <li key={`rec-${index}`}><a href={`/Content/PDF/${h.fileName}`} target="_blank" rel="noopener noreferrer">{h.description}</a></li>
                                            );
                                        })}
                                    </ul>
          </TemplateCollapsibleCard>
        );
    }
}