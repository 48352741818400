import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import _ from "lodash";
import constants from "../utils/constants";
import helpers from "../utils/helpers";

export class EnrolledByPhaseDoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.calculatePercentage = this.calculatePercentage.bind(this);
    this.processPieChartData = this.processDoughnutChartData.bind(this);
  }
  calculatePercentage(dividend, divisor) {
    const percentAsDecimal = dividend / divisor;
    return percentAsDecimal.toFixed(2) * 100;
  }

  processDoughnutChartData(data) {
    const list = _.flatMap(data, (item) => {
      return [
        item.phase1,
        item.phase2,
        item.phase3,
        item.phase4,
        item.phase5,
        item.pending,
      ];
    });

    const doughnutColors = [
      constants.PEAK_COLORS.green,
      constants.PEAK_COLORS.blue,
      constants.PEAK_COLORS.purple,
      constants.PEAK_COLORS.orange,
      constants.PEAK_COLORS.green,
      constants.PEAK_COLORS.blue, // added a color for "Other"
    ];

    const pieChartData = {
      labels: ["Phase 1", "Phase 2", "Phase 3", "Phase 4", "Phase 5", "Other"],
      datasets: [
        {
          label: "Enrollments by Phase",
          data: list,
          backgroundColor: doughnutColors, // use doughnutColors array for backgroundColor
          borderColor:
            helpers.generateTableAndChartBorderColors(doughnutColors), // use doughnutColors array for borderColor
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
        },
      ],
    };
    return pieChartData;
  }

  render() {
    return (
      <>
        <div>
          <Doughnut
            data={this.processDoughnutChartData(
              this.props.enrollmentsByPhaseData
            )}
            width={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.width}
            height={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.height}
            options={{ maintainAspectRatio: false }}
          />
        </div>
      </>
    );
  }
}
