import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';
import date_helpers from '../utils/date_helpers';
// import api from '../utils/api';
import { TemplateCollapsibleCard } from './';

export class WidgetEnrollmentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse,
      enrollmentInfo: props.enrollmentInfo
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.enrollmentInfo !== prevProps.enrollmentInfo) {
      this.setState({enrollmentInfo: this.props.enrollmentInfo});
    }
  }

  render() {
    let info = this.state.enrollmentInfo === null || this.state.enrollmentInfo === undefined
      ? {statusName: "", healthAssessmentDate: "", ackDate: "", labDate: "", contextName: ""}
      : this.state.enrollmentInfo;
    if (info.healthAssessmentDateValue) {
      info.healthAssessmentDate = date_helpers.getMomentFromString(info.healthAssessmentDateValue).format(date_helpers.DISPLAY_DATE_FORMAT);
    }
    if (info.ackDateValue) {
      info.ackDate = date_helpers.getMomentFromString(info.ackDateValue).format(date_helpers.DISPLAY_DATE_FORMAT);
    }
    if (info.labDateValue) {
      info.labDate = date_helpers.getMomentFromString(info.labDateValue).format(date_helpers.DISPLAY_DATE_FORMAT);
    }
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm">
            <tbody>
              <tr>
                <td width="40%" className="accountSummary topRow">
                  Status
                </td>
                <td className="topRow">
                  {info.statusName}
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Annual {info.contextName} HA Completion Date
                </td>
                <td>
                  {info.healthAssessmentDate} <a href='https://www.mobilehealthconsumer.com/web/pages/login.html' className='nav-link' style={{display: "inline-block"}} target="_blank" rel="noopener noreferrer">CarePlus Mobile Health</a>
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Program Acknowledgement Date
                </td>
                <td>
                  {info.ackDate}
                </td>
              </tr>
              <tr>
                <td width="40%" className="accountSummary">
                  Last Lab Completion Date
                </td>
                <td>
                  {info.labDate}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      </TemplateCollapsibleCard>
    );
  }
}