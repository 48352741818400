import React, { Component} from 'react';
import { Container, ButtonGroup, Button, Card, CardBody, Form, Col, Row, CardHeader, Input, InputGroup, 
  InputGroupAddon, InputGroupText, Alert } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import api from '../utils/api';
import helpers from '../utils/helpers';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import _ from 'lodash';
import storage from "../utils/storageFactory.js";

const promptStyle = {width: "140px"};

export class Profile extends Component {
  displayName = Profile.name;
  constructor(props) {
    super(props);
    this.state = {
      user: props.currentUser,
      stateList: [],
      message: null,
      messageFlavor: null,
      redirectToHome: false,
      changePasswordRequested: false,
      changePassword: false,
      activeTab: "personal",
      questionList: []
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSelectState = this.onSelectState.bind(this);
    this.onClose = this.onClose.bind(this);   
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    api.fetch('Reference/StateList').then(r => {
      const stateList = r.data.map(s => {
        return {
          value: s.abbr,
          label: s.name
        };
      });
      this.setState({stateList: stateList});
    }).catch(helpers.catchHandler);

    api.fetch('Reference/ChallengeQuestions').then(r => {
      const questionList = r.data.map(x => { return {label: x.name, value: x.id}; });
      let newState = {questionList: questionList };
      if (this.props.currentUser && this.props.currentUser.challengeQuestionId) {
        newState.question = _.find(questionList, q => { return q.value === this.props.currentUser.challengeQuestionId; });
      }
      this.setState(newState);
    }).catch(helpers.catchHandler);
  }
  
  onChangeHandler(event) {
    var changedUser = Object.assign({}, this.state.user, { [event.target.name]: event.target.value });
    this.setState({ user: changedUser });
  }

  onSelectState(selection) {
    this.setState({ user: {...this.state.user, state: selection.value } });
  }

  onClose() {
    this.setState({redirectToHome: true});
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    if (this.state.redirectToHome) {
      return (<Redirect to="/home" />);
      }
    return (
      <Container fluid>
        <Card>
          <CardHeader>Edit My Profile</CardHeader>
          <CardBody>
            <Row className="mt-3">
{/*
              <Col sm="2">
                {this.state.message === null
                  ? null
                  : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)
                }
                <Nav tabs className="flex-column nav-pills">
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "personal" }
                      onClick={() => {
                        this.toggle("personal");
                      }}
                    >
                      Personal Information
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "securityQuestion" }
                      onClick={() => {
                        this.toggle("securityQuestion");
                      }}
                    >
                      Challenge Question
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "password" }
                      onClick={() => {
                        this.toggle("password");
                      }}
                    >
                      Password
                    </NavLink>
                  </NavItem>
                  </Nav>
              </Col>
*/}
            <Col> 
              <Card>
                <UpdateUserInformation 
                  currentUser={this.props.currentUser}
                  onChangeHandler={this.onChangeHandler}
                  stateList={this.state.stateList}
                  setCurrentUser={this.props.setCurrentUser}
                />
                {/*
                <TabContent activeTab={this.state.activeTab} className="mt-2">
                  <TabPane tabId="personal">
                  </TabPane>
                  <TabPane tabId="securityQuestion">
                    <SetChallengeQA 
                      currentUser={this.props.currentUser}
                      setCurrentUser={this.props.setCurrentUser}
                      setChallengeQuestion={this.props.setChallengeQuestion}
                      profileUpdate={true}
                    />
                  </TabPane>
                  <TabPane tabId="password">
                    <UpdatePassword
                      currentUser={this.props.currentUser}
                    />
                  </TabPane>
                </TabContent>
              */}
              </Card>
            </Col>
          </Row> 
        </CardBody>
      </Card>
    </Container>
    );
  };
}

class UpdateUserInformation extends Component{
  constructor(props){
    super(props);
    this.state={
      user: props.currentUser,
      message: null,
      messageFlavor: null
    }
    this.onSave = this.onSave.bind(this);  
    this.onChangeHandler = this.onChangeHandler.bind(this);    
  }

  onChangeHandler(event) {
    var changedUser = Object.assign({}, this.state.user, { [event.target.name]: event.target.value });
    this.setState({ user: changedUser });
  }

  onSave(event) {
    event.preventDefault();
    let data = {
      firstName: this.state.user.firstName,
      middleName: this.state.user.middleName,
      lastName: this.state.user.lastName,
      email: this.state.user.email,
      city: this.state.user.city,
      state: this.state.user.state,
      Username: this.state.user.username,
      mobilePhone: this.state.user.mobilePhone
    };
    api.post('Home/Profile', data).then(response => {
      if (response.data.success === false) {
        this.setState({ message: response.data.message, messageFlavor: "danger" });
      } else {
        let changedUser = this.props.currentUser;
        changedUser.firstName = this.state.user.firstName; 
        changedUser.middleName = this.state.user.middleName; 
        changedUser.lastName = this.state.user.lastName;
        changedUser.email = this.state.user.email; 
        changedUser.city = this.state.user.city; 
        changedUser.state = this.state.user.state;
        changedUser.mobilePhone = this.state.user.mobilePhone;
        const existingToken = storage.getItem('token');
        this.props.setCurrentUser(changedUser, existingToken);
        this.setState({ message: "Profile update successful!", messageFlavor: "success" });
      }
    }).catch(helpers.catchHandler);
  };

  render(){
    return(
        <Row className="mt-3">
          <Col>
            {this.state.message === null
              ? null
              : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)
            }
            <Form>
              <Row className="mb-2">
                <Col sm={{size: 4, offset: 4}}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={promptStyle}>First Name</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text"
                      name="firstName"
                      id="firstName"
                      maxLength="25"
                      onChange={this.onChangeHandler}
                      value={this.state.user.firstName}
                    />
                  </InputGroup>            
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm={{size: 4, offset: 4}}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={promptStyle}>Middle Name</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text"
                      name="middleName"
                      id="middleName"
                      maxLength="25"
                      onChange={this.onChangeHandler}
                      value={this.state.user.middleName}
                    />
                  </InputGroup>            
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm={{size: 4, offset: 4}}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={promptStyle}>Last Name</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text"
                      name="lastName"
                      id="lastName"
                      maxLength="25"
                      onChange={this.onChangeHandler}
                      value={this.state.user.lastName}
                    />
                  </InputGroup>            
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm={{size: 4, offset: 4}}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={promptStyle}>Email</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text"
                      name="email"
                      id="email"
                      maxLength="100"
                      onChange={this.onChangeHandler}
                      value={this.state.user.email}
                    />
                  </InputGroup>            
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm={{size: 4, offset: 4}}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={promptStyle}>City</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text"
                      name="city"
                      id="city"
                      onChange={this.onChangeHandler}
                      value={this.state.user.city}
                    />
                  </InputGroup>            
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm={{size: 4, offset: 4}}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={promptStyle}>State</InputGroupText>
                    </InputGroupAddon>
                    <div className="form-control" style={{padding: 0, borderStyle: "none"}}>
                      <Select
                        closeMenuOnSelect={true}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.props.stateList}
                        onChange={this.onSelectState}
                        value={helpers.getSelectedItemByValue(this.props.stateList, this.state.user.state)}
                        onBlurResetsInput={false}
                        onSelectResetsInput={false}
                        onCloseResetsInput={false}
                        classNamePrefix="react-select"
                      />
                    </div>
                  </InputGroup>            
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm={{size: 4, offset: 4}}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={promptStyle}>Mobile Phone</InputGroupText>
                    </InputGroupAddon>
                    <Input type="tel"
                      name="mobilePhone"
                      id="mobilePhone"
                      maxLength="100"
                      onChange={this.onChangeHandler}
                      value={this.state.user.mobilePhone}
                    />
                  </InputGroup>            
                </Col>
              </Row>
              <Row className="expand-md mb-2">
                <Col className="text-right" sm={{size: 4, offset: 4}}>
                  <ButtonGroup>
                    <Button color="primary" onClick={this.onSave}>Save</Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row> 
    );
  }
}

// class UpdatePassword extends Component{
//   constructor(props){
//     super(props);
//     this.state={
//       user: props.currentUser,
//       confirmPassword: "",
//       password: "",
//       message: null,
//       messageFlavor: null
//     }
//     this.save = this.save.bind(this);
//     this.onConfirmPasswordChangeHandler = this.onConfirmPasswordChangeHandler.bind(this);
//     this.onPasswordChangeHandler = this.onPasswordChangeHandler.bind(this);
//   }

//   save(){
//     if (this.state.password === null || this.state.password.trim() === "") {
//       this.setState({messageFlavor: "danger", message: "Please provide your new password."});
//       return;
//     }
//     if (this.state.confirmPassword === null || this.state.confirmPassword.trim() === "") {
//       this.setState({messageFlavor: "danger", message: "Please confirm your new password."});
//       return;
//     }
//     if (this.state.confirmPassword !== this.state.password) {
//       this.setState({messageFlavor: "danger", message: "Your password and confirmation password does not match."});
//       return;
//     }

//     let data = {
//       Password: this.state.password
//     }
//     api.post('Home/UpdatePassword', data).then(r => {
//       if(r.data.success === false){
//         this.setState({message: "An error occurred, password cannot be updated at this time.", messageFlavor: "danger"});
//       } else {
//         this.setState({message: "Password updated!", messageFlavor: "success"});
//       }
//     });
//   }

//   onConfirmPasswordChangeHandler(event) {
//     this.setState({ confirmPassword:   event.target.value });
//   }

//   onPasswordChangeHandler(event) {
//     this.setState({ password:   event.target.value  });
//   }

//   render(){
//     return(
//       <React.Fragment>
//         <Row className="mb-2">
//           <Col sm={{size: 4, offset: 4}}>
//             {this.state.message === null
//             ? null
//             : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)
//           }
//           </Col>
//         </Row>
//         <Row className="mb-2">
//           <Col sm={{size: 4, offset: 4}}>
//             <InputGroup>
//               <InputGroupAddon addonType="prepend">
//                 <InputGroupText style={{width: "150px"}}>Password</InputGroupText>
//               </InputGroupAddon>
//               <Input type="password"
//                 name="password" 
//                 id="password"
//                 onChange={this.onPasswordChangeHandler}
//                 value={this.state.answer}
//                 placeholder="Answer"
//               />
//             </InputGroup>
//           </Col>
//         </Row>
//         <Row className="mb-2">
//           <Col sm={{size: 4, offset: 4}}>
//             <InputGroup>
//               <InputGroupAddon addonType="prepend">
//                 <InputGroupText style={{width: "150px"}}>Confirm Password</InputGroupText>
//               </InputGroupAddon>
//               <Input type="password"
//                 name="confirm_password" 
//                 id="confirm_password"
//                 onChange={this.onConfirmPasswordChangeHandler}
//                 value={this.state.confirmPassword}
//                 placeholder="Confirm Answer"
//               />
//             </InputGroup>
//           </Col>
//         </Row>
//         <Row className="mb-2">
//           <Col sm={{size: 6, offset: 7}}>
//             <Button color="primary" onClick={this.save}>Save</Button>
//           </Col>
//         </Row>
//       </React.Fragment>
//     );
//   }
// }
