import React, { Component } from 'react';
import { Collapse, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class FilterSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: !(props.open || true),
    };
    this.toggleFilter = this.toggleFilter.bind(this);
    this.getFilterDisplay = this.getFilterDisplay.bind(this);
  }

  getFilterDisplay(filters) {
    if (!filters || !filters.length || filters.length === 0) {
      return null;
    }
    const filterDisplay = filters.map(x => ((x.value || x.values || x.startDate || x.endDate)
      ? x.filteredDisplay
      : null)).join(' | ');
    if (!filterDisplay) {
      return '';
    }
    return [<span className='title' key='applied-filter-title'>Applied Filters:</span>, filterDisplay];
  }

  toggleFilter() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    return (
      <div>
        <span title="Click here to toggle filter display" className="filter btn btn-link" onClick={this.toggleFilter}><FontAwesomeIcon icon="filter" /> Filters</span>
        <span className='applied-filter'>{this.getFilterDisplay(this.props.filters)}</span>
        <Collapse isOpen={this.state.collapse}>
          <Container className='filter-definition-row'>
            {this.props.children}
          </Container>
        </Collapse>
      </div>
    );
  }
}
