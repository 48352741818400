import React, { Component } from "react";
import _ from "lodash";
import { Table } from "reactstrap";

export class CostSavingsByRiskFactorTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.calculateImprovedPercentage =
      this.calculateImprovedPercentage.bind(this);
    this.costSavingsTableContent = this.costSavingsTableContent.bind(this);
    this.getRandomInt = this.getRandomInt.bind(this);
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  calculateImprovedPercentage(improvedValue, initialValue) {
    const percentAsDecimal = improvedValue / initialValue;
    const percentage = percentAsDecimal * 100;
    return percentage.toFixed(0);
  }

  costSavingsTableContent() {
    const costSavingsDataToChart = this.props.costSavingsData;

    const tableContent = _.map(costSavingsDataToChart, (item, index) => {
      return (
        <tr
          key={index}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = item.hoverColor)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = item.itemColor)
          }
          style={{
            backgroundColor: item.itemColor,
          }}
        >
          <td className="text-left">{item.riskFactor}</td>
          <td className="text-center">
            {Number(item.initialValue).toLocaleString()}
          </td>
          <td className="text-center">
            {Number(item.improvedValue).toLocaleString()}
          </td>
          <td className="text-center">
            {this.calculateImprovedPercentage(
              item.improvedValue,
              item.initialValue
            )}
            %
          </td>
          <td className="text-center">
            ${Number(item.estimatedCostSavings).toLocaleString()}
          </td>
        </tr>
      );
    });

    return tableContent;
  }

  render() {
    return (
      <>
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <th className="text-left">Risk Factor</th>
                <th className="text-center">Initial</th>
                <th className="text-center">Improved</th>
                <th className="text-center">% Improved</th>
                <th className="text-center">Estimated Cost Savings</th>
              </tr>
            </thead>
            <tbody>{this.costSavingsTableContent()}</tbody>
          </Table>
        </div>
      </>
    );
  }
}
