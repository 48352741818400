import React, { Component } from 'react';
import { Link, Redirect} from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

export class Footer extends Component {
  displayName = Footer.name;
  constructor(props) {
    super(props);
    this.state = {
    };
    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.logout();
    return <Redirect to="/login" />;
  }

  render() {
    return (
      <div>
        <Nav id="footerMenu" className="mx-0 justify-content-center">
          <NavItem>
            <a className='nav-link' href="http://peakhealthservices.net/about-us" target="_blank" rel="noopener noreferrer">Our Company</a>
          </NavItem>
          <NavItem>
            <a className='nav-link' href="http://peakhealthservices.net/success-stories" target="_blank" rel="noopener noreferrer">Corporate Successes</a>
          </NavItem>
          {/* <NavItem>
            <a className='nav-link' href="http://peakhealthservices.net/employment-opportunities" target="_blank" rel="noopener noreferrer">Employment</a>
          </NavItem> */}
          <NavItem>
            <NavLink tag={Link} to="/Content/PDF/PrivacyPolicy-Wellness-Release.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/Content/PDF/WellnessProgramNotice.pdf" target="_blank" rel="noopener noreferrer">Notice Regarding Wellness Program</NavLink>
          </NavItem>
          <NavItem>
            <NavLink>Copyright 2022 Peak Health</NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}
