import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// import _ from 'lodash';

export class FilterSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterName: props.filterName,
      displayName: props.displayName,
      options: props.options,
      value: props.value,
      onChangeCallback: props.onChangeCallback,
    };
    this.onChange = this.onChange.bind(this);
    // this.getValues = this.getValues.bind(this);
  }

  onChange(selection) {
    // const values = selections.length
    //   ? selections.map(x => x.value)
    //   : [selections.value];
    // const labels = selections.length
    //   ? selections.map(x => x.label)
    //   : [selections.label];
    // let valuesDisplay = '';
    // if (values && values.length) {
    //   switch (values.length) {
    //     case 1:
    //       valuesDisplay = labels[0];
    //       break;
    //     default:
    //       valuesDisplay = labels.join(', ');
    //   }
    // }
    // const filteredDisplay =
    //   values && valuesDisplay
    //     ? `${this.state.displayName}: ${valuesDisplay}`
    //     : '';
    this.setState({value: selection});
    this.state.onChangeCallback({
      filterName: this.state.filterName,
      value: selection.value,
      label: selection.label,
      filteredDisplay: `${this.state.displayName}: ${selection.label}`
    });
    // this.state.onChangeCallback({
    //   filterName: this.state.filterName,
    //   values,
    //   labels,
    //   filteredDisplay,
    // });
  }

  // getValues(values) {
  //   if (!values || !values.length || values.length === 0) {
  //     return null;
  //   }
  //   const selectedOptions = _.filter(this.state.options, x =>
  //     _.contains(values, x.value));
  //   return selectedOptions;
  // }

  render() {
    if (!this.props.options) {
      return null;
    }

    return (
      <span className="filter-definition-caption">
        {this.state.displayName}:
        <Select
          closeMenuOnSelect={true}
          isMulti={false}
          components={makeAnimated()}
          options={this.props.options || this.state.options}
          onChange={this.onChange}
          value={this.state.value}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          onCloseResetsInput={false}
          classNamePrefix="react-select"
        />
      </span>
    );
  }
}
