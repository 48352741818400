import React, { Fragment, Component } from 'react';
import { TemplateCollapsibleCard } from './';
import { Row, Col, Button, ButtonGroup, Card, CardBody,
  Modal, ModalHeader, ModalBody, Alert, CardTitle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';
import api from '../utils/api';
import Dropzone from 'react-dropzone';
import _ from 'lodash';

const labUploadTypeId = 2;

export class WidgetLabOrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        collapse: props.collapse,
        showFileUpload: false,
        files: []
      };
      this.showFileUpload = this.showFileUpload.bind(this);
      this.onCancel = this.onCancel.bind(this);
  }

  showFileUpload() {
    this.setState({ showFileUpload: !this.state.showFileUpload });
  }

  onCancel() {
      this.setState({ showFileUpload: false });
  }

  downloadLabCorpForm(nyOR) {
    api.downloadPDF('Lab/Form/' + (nyOR ? "true" : "false"));
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass="widgetCard"
      >
        <p style={{fontWeight: "bold"}}>LabCorp Order Form</p>
        <img src={`/Content/panels/clipboard.png`} alt='Lab Forms' style={{float: "left", marginRight: "8px"}}/>
        <p style={{marginTop: "15px", marginBottom: "30px"}}>Print the below form and take it with you to a Labcorp Patient Service Center, approved alternative lab site 
          (see below for locations) {this.props.isTEL ? <span>, or attend one of the onsite lab draws at a TEL location</span> : null}.
        </p>
        <div>
          <ul>
            {this.props.labFormDataId
              ? (<Fragment>
                  <li><Button className="xs" color="light" onClick={() => this.downloadLabCorpForm(false)}>Download LabCorp Form - <span style={{color: "#FF0000"}}>all except NY</span></Button></li>
                  <li><Button className="xs" color="light" onClick={() => this.downloadLabCorpForm(true)}>Download LabCorp Form - <span style={{color: "#FF0000"}}>NY</span></Button></li>
                 </Fragment>)
              : null
            }
              <li><a href='https://www.labcorp.com/labs-and-appointments' target='_blank' rel="noopener noreferrer">LabCorp Locations/Appointments</a></li>
              <li><a href="/Content/PDF/Lab_Locations.pdf" target="_blank" rel="noopener noreferrer">Alternative Lab Locations</a></li>
          </ul>
        </div>
        <p style={{fontWeight: "bold"}}>HealthCare Provider Lab Form</p>
        {(this.props.isSunTrust || this.props.isBBT)
          ? (<p style={{marginLeft: "8px"}}>
              Truist covers the cost of blood work done by LabCorp or a <a href='https://www.mypremisehealth.com' target="_blank" rel="noopener noreferrer">Truist Health Clinic</a>. If you choose to have your lab work done by an approved alternative lab location, 
              Truist will cover the cost based on your medical plan. Opting to obtain your lab work from an unauthorized provider could result in missing labs that would not comply with 
              Peak Health lab requirements. The cost of lab work obtained from an unauthorized provider will be your responsibility.
            </p>)
          : null
        }
        {this.props.isTEL || this.props.isTELSpouse
          ? (<p style={{marginLeft: "8px"}}>
            If you choose to have required labs completed with your healthcare provider, 
            please <a href='/Content/PDF/TELLabInstructionsForm.pdf' target="_blank" rel="noopener noreferrer">download the HealthCare Provider Instructions Form here.</a>
            </p>)
          : null
        }
        <img src='/Content/panels/clipboard-checked.png' alt='Upload Lab Results' style={{float: "left", marginRight: "8px"}}/>
        <p style={{marginTop: "15px"}}>
          Upload Healthcare Provider's lab results here. Peak Health will contact you if they do not meet program requirements. 
          If opting to complete your lab work with an unauthorized provider, please upload your lab results to Peak Health at least one week prior to your nurse visit.
        </p>
        <div style={{textAlign: "center"}}>
          <span className='btn btn-ghost' id='upload-lab-results' style={{ display: "block", margin: "0 auto", width: "200px" }} onClick={this.showFileUpload}>Upload Completed Form</span>  
        </div>
        {(this.props.isTEL || this.props.isTELSpouse)
          ? (<Fragment>
              <p style={{fontWeight: "bold", marginTop: "10px"}}>Required Labs at MD</p>
              <p>Please print and take with you to your MD for required lab panels if you are choosing to get your labs done at your Provider.</p>
              <p>
              {this.props.isTELSpouse
                ? (<Fragment>
                    <a href='/Content/PDF/TELRequiredLabsSelect.pdf' target="_blank" rel="noopener noreferrer">REQUIRED LABS at MD Form</a><br />
                    <a href='/Content/panels/TELVitalSigns.pdf' target="_blank" rel="noopener noreferrer">VITAL SIGNS REQUIRED for Nurse Appointment</a>
                  </Fragment>) 
                : (<a href='/Content/PDF/TELRequiredLabs.pdf' target="_blank" rel="noopener noreferrer">REQUIRED LABS at MD Form</a>)
              }
              </p>
            </Fragment>)
          : null
        }
        {!this.state.showFileUpload ? null :
            <UploadCompletedForm
              onCancel={this.showFileUpload}
            />
        }
      </TemplateCollapsibleCard>
    );
  }
}


class UploadCompletedForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            messageFlavor: null,
            files: []
        };
        this.onPDFDrop = this.onPDFDrop.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
    }

    isFormValid() {
        let warnings = [];
        if (!this.state.files || this.state.files.length === 0) {
            warnings.push("Please select a file to upload");
        }
        if (warnings.length) {
            this.setState({ message: warnings.join(','), messageFlavor: "danger" });
        } else {
            this.setState({ message: null });
        }
        return warnings.length === 0;
    }

    onPDFDrop(acceptedFiles, rejectedFiles) {
        if (rejectedFiles.length) {
            this.setState({ message: 'One or more files were rejected.', messageFlavor: "danger" });
        } else {

            let currentFiles = Object.assign([], this.state.files);
            if (!currentFiles) {
                currentFiles = [];
            }
            let existingFile = _.find(currentFiles, f => { return f.uploadTypeId === labUploadTypeId; });
            if (existingFile) {
                existingFile.content = acceptedFiles[0];
            } else {
                currentFiles.push({ uploadTypeId: labUploadTypeId, content: acceptedFiles[0] });
            }
            this.setState({ files: currentFiles, message: "file(s) pending upload - don't forget to save!", messageFlavor: "warning" });
        }

    }
    handleSave() {
      if (!this.isFormValid()) return;
      let payload = new FormData();
        for (var i = 0; i < this.state.files.length; i++) {
            payload.append("LabResults", (this.state.files[i].uploadTypeId, this.state.files[i].content));
      }
        api.post_form_data('Lab/UploadLabResults', payload).then(r => {
        if (r.data.success === false) {
            this.setState({ message: r.data.message, messageFlavor: "danger" });
        } else {
            this.setState({ message: "Results successfully uploaded", messageFlavor: "success" }, () => setTimeout(() => { this.props.onCancel() }, 3000));
        }
    }).catch(helpers.catchHandler);
    }

    render() {
        return (
            <Modal isOpen={true} toggle={this.props.showFileUpload} backdrop={true} className="modal-lg">
                <ModalHeader>Upload Form</ModalHeader>   
                <ModalBody>
                    {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)}
                    <Row className="mb-2">
                        <Col>
                            <Card>
                                <CardBody className="p-2">
                                    <CardTitle className="text-center">
                                        Drop files or click on the icon below
                                    </CardTitle>
                                      <Dropzone className="col-xs-4"
                                        onDrop={this.onPDFDrop}
                                        accept="application/pdf"
                                      >
                                      {({getRootProps, getInputProps}) => (<section>
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <p style={{ textAlign: 'center' }}><FontAwesomeIcon icon={this.state.files && this.state.files.length ? "check" : "file-upload"} size="6x" /></p>
                                        </div>
                                      </section>)}
                                      </Dropzone>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="expand-md">
                        <Col className="text-right">
                            <ButtonGroup>
                              <Button color="primary" onClick={this.handleSave}>Save</Button>
                              <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
                            </ButtonGroup>
                        </Col>
                        </Row>
                </ModalBody>
            </Modal>
        );
    }
}