import React, { Component } from 'react';
import { Table } from 'reactstrap';

export class PersonalRiskCodes extends Component {
  displayName = "Personal Risk Codes";
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }
  

  render() {
    return (
      <Table size="sm" className="my-0">
        <tbody className="my-0">
          <tr>
            <td width="10%" className="personalRiskCodes topRow">
              A
            </td>
            <td className="topRow">
             Heart Attack
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              B
            </td>
            <td>
              Heart Disease
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              C
            </td>
            <td>
              Elevated Blood Lipids
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              D
            </td>
            <td>
              Elevated Blood Pressure
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              E
            </td>
            <td>
              Stroke
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              F
            </td>
            <td>
              Diabetes
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              G
            </td>
            <td>
              Breast Cancer
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              H
            </td>
            <td>
              Cervical/Uterine/Ovarian Cancer
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              I
            </td>
            <td>
              Colo-Rectal Cancer
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              J
            </td>
            <td>
              Other Cancer
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              K
            </td>
            <td>
              Obesity
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              L
            </td>
            <td>
              Kidney Disease
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              M
            </td>
            <td>
              Gout
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              N
            </td>
            <td>
              Ex-smoker &gt; 1 yr
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              O
            </td>
            <td>
              Inactivity (exercise &lt; 1 x wk)
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              P
            </td>
            <td>
              Stress/Depression/Anxiety
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              Q
            </td>
            <td>
              Back Strain
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              R
            </td>
            <td>
              Inadequate Dietary Habits
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              S
            </td>
            <td>
              No Seat Belt Use
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              T
            </td>
            <td>
              Tobacco Use
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              U
            </td>
            <td>
              Thyroid disorders
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              V
            </td>
            <td>
              Osteoporosis
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              W
            </td>
            <td>
              Osteopenia/Osteoporosis
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              X
            </td>
            <td>
              Other
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              Y
            </td>
            <td>
              Migraine HA's
            </td>
          </tr>
          <tr>
            <td width="10%" className="personalRiskCodes">
              Z
            </td>
            <td>
              None
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}