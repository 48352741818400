import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Col, Row, Card, CardImg, CardBody, Alert, Button, Label, Input, ButtonGroup, Tooltip } from 'reactstrap';
import { PasswordRequirements } from '../components';
import api from '../utils/api';
import helpers from '../utils/helpers';
import storage from "../utils/storageFactory.js";

export class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      redirect: false,
      oldPassword: '',
      newPassword: '',
      newPasswordAgain: '',
      tooltipOpen: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.onUpdatePassword = this.onUpdatePassword.bind(this);
    this.onUpdatePasswordAgain = this.onUpdatePasswordAgain.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  handlePassword(event) {
    this.setState({ oldPassword: event.target.value });
  }

  onUpdatePassword(event) {
    this.setState({ newPassword: event.target.value });
  }

  onUpdatePasswordAgain(event) {
    this.setState({ newPasswordAgain: event.target.value });
  }

  isFormValid() {
    const warnings = [];
    if (!this.state.oldPassword) {
      warnings.push('All password fields required.');
    }
    if (this.state.newPassword !== this.state.newPasswordAgain) {
      warnings.push('The new password and new password confirmation do not match.');
    }
    if (!this.state.newPassword || this.state.newPasswordAgain.length < 8) {
      warnings.push('New password is required to be at least 8 characters long.');
    }
    if (this.state.newPassword === this.state.oldPassword) {
      warnings.push('The original and new passwords must be different.');
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(','),
        messageFlavor: 'danger',
      });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  onCancel() {
    this.props.logout();
    this.setState({ redirect: true });
  }
  
  handleSubmit() {
    if (!this.isFormValid()) return;
    api.post('Public/SetPassword', {
      BearerToken: storage.getItem('token'),
      OldPassword: this.state.oldPassword,
      NewPassword: this.state.newPassword
    }).then(response => {
      if (response.data.success === true) {
        this.setState({
          messageFlavor: 'success', 
          oldPassword: '', 
          newPassword: '', 
          newPasswordAgain: '',
          message: "You have successfully changed your password. We're now redirecting you to the home screen."
        });
        let user = this.props.currentUser;
        user.mustChangePassword = false;
        this.props.setCurrentUser(user);
        setTimeout(() => {
          this.setState({ redirect: true });
        }, 5000);
      } else {
        this.setState({ messageFlavor: 'danger', message: response.data.message });
      }
    }).catch(helpers.catchHandler);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    return (
      <Container fluid>
        <Row className="zIndexOverBackgroundImage expand-md mb-2 pb-0 align-items-center">
          <Col sm="6">
            <Row className="justify-content-center">
              <Card className="m-5" id="logoCard">
                <CardImg src="/Content/Images/peakhealth_logo_2014_large.jpg" alt="Logo" style={{width: "80%"}} />
              </Card>
            </Row>
          </Col>
          <Col sm="6">
            <Card className="m-5">
              <CardBody>
                <div>
                  <Link to="/home">Return to Home</Link>
                </div>
                <div>
                  {this.state.message
                    ? <Alert className={`alert-${this.state.messageFlavor}`}>{this.state.message}</Alert>
                    : null
                  }
                </div>
                <div>
                  <form>
                    <Row className="mt-2 mb-2">
                      <Col>
                        <Label className="label">Current Password</Label>
                        <Input
                          type="password"
                          value={this.state.oldPassword}
                          onChange={this.handlePassword}
                          placeholder="Type Current Password"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <Label className="label">New Password</Label>
                        <div className="ml-2 d-inline">
                          <FontAwesomeIcon icon="info-circle" id="passwordTooltip" />
                        </div>
                        <Tooltip width="100px" placement="right" isOpen={this.state.tooltipOpen} target="passwordTooltip"
                          toggle={() => this.setState({tooltipOpen: !this.state.tooltipOpen})}>
                          <PasswordRequirements />
                        </Tooltip>
                        <Input
                          type="password"
                          value={this.state.newPassword}
                          onChange={this.onUpdatePassword}
                          placeholder="Type New Password"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <Label className="label">Confirm New Password</Label>
                        <Input
                          type="password"
                          value={this.state.newPasswordAgain}
                          onChange={this.onUpdatePasswordAgain}
                          placeholder="Re-Type New Password"
                        />
                      </Col>
                    </Row>
                    <Row className="expand-md mb-2">
                      <Col>
                        <ButtonGroup className="float-right">
                          <Button color="primary" onClick={this.handleSubmit}>
                            Confirm Password Update
                          </Button>
                          <Button color="secondary" onClick={this.onCancel}>
                            Cancel
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
