import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';

export class VisitSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm">
            <thead>
            <tr>
              <th width="40%" className="topRow"></th>
              <th className="topRow">Date</th>
              <th className="topRow">Phase</th>
            </tr>
            </thead>
            <tbody>
              <tr className="visitSummary">
                <td>
                  Program Enroll
                </td>
                <td>
                  {this.props.latestVisit
                    ? this.props.latestVisit.enrollDate
                    : "N/A"}
                </td>
                <td>0</td>
              </tr>
              <tr className="visitSummary">
                <td>
                  Last Evaluation
                </td>
                <td>
                  {this.props.latestVisit
                    ? this.props.latestVisit.lastEvaluationDate
                    : "N/A"}
                </td>
                <td>{this.props.latestVisit
                    ? this.props.latestVisit.currPhase
                    : "0"}</td>
              </tr>
            </tbody>
          </Table>
          <img src='/Content/panels/clipboard-stat.png' alt='Visit History' style={{float:"left", marginRight: "8px"}}/>
          <p style={{marginTop: "20px"}}>Click <a href='/visit'>here</a> to view your visit history.</p>
        </Col>
      </Row>      
    );
  }
}