import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import _ from "lodash";
import constants from "../utils/constants";
export class VisitsByMonthPeakMultiLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.generateRandomBackgroundColor =
      this.generateRandomBackgroundColor.bind(this);

    this.generateBorderColor = this.generateBorderColor.bind(this);
    this.multiLineChartData = this.multiLineChartData.bind(this);
  }

  generateRandomBackgroundColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgba(${red}, ${green}, ${blue}, 0.3)`;
  }

  generateBorderColor(backgroundColor) {
    const colorArray = backgroundColor.split(",");
    const color = colorArray[0] + ", " + colorArray[1] + ", " + colorArray[2];
    return color + ", 3)";
  }

  multiLineChartData() {
    const rowColors = [
      constants.PEAK_COLORS.green,
      constants.PEAK_COLORS.blue,
      constants.PEAK_COLORS.purple,
      constants.PEAK_COLORS.orange,
    ];
    const table = this.props.visitsData;
    const labels = table[0].slice(1, -1); // yrMonths
    const data = table.slice(1, -1); // data rows

    const datasets = _.map(data, (row, rowIndex) => {
      const phase = row[0];
      const counts = row.slice(1, -1);
      const color = rowColors[rowIndex % rowColors.length];
      return {
        type: "line",
        label: "Phase " + phase,
        data: counts,
        backgroundColor: color,
        borderColor: this.generateBorderColor(color),
        borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
        fill: true,
        // add other properties like backgroundColor, borderColor, etc. here
      };
    });

    const multiLineChartData = {
      labels: labels,
      datasets: datasets,
    };

    return multiLineChartData;
  }

  multiLineChartOptions() {
    const options = {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Phase Visits By Month Over Time",
        fontSize: 16,
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Months",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Visits",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    return options;
  }

  render() {
    return (
      <>
        <div>
          <Line
            options={this.multiLineChartOptions()}
            // width={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.width}
            height={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.height}
            data={this.multiLineChartData()}
          />
        </div>
      </>
    );
  }
}
