import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

export class Goals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestVisit: props.latestVisit
    };
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevProps.latestVisit, this.props.latestVisit)) return;
    this.setState({latestVisit: this.props.latestVisit});
  }

  renderRow(prompt, value) {
    return (
    <Row>
      <Col sm="8" className="data-caption">
        {prompt}
      </Col>
      <Col sm="4">
        {value}
      </Col>
    </Row>);
  }

  render() {
    const latestVisit = this.state.latestVisit;
    return (
    <div className="my-0">
    {this.props.showHeader
      ? (<Row className="header-row">
          <Col sm="8">
            Goals
          </Col>
          <Col sm="4">
            (m = maintain)
          </Col>
        </Row>)
      : null
    }
    {this.renderRow("Weight (lbs)", latestVisit.gWeight)}
    {this.renderRow("Blood Pressure (sys / dia)", `${latestVisit.gbpSys} / ${latestVisit.gbpDia}`)}
    {this.renderRow("Heart Rate (beats / min)", latestVisit.ghRate)}
    {this.renderRow("Body Composition Score", latestVisit.gBodyFat)}
    {this.renderRow("Fitness Score", latestVisit.gfeval)}
    {this.renderRow("Total Cholesterol (mg / dL)", (<span>&lt; 200</span>))}
    {this.renderRow("Cholesterol LDL (mg / dL)", (<span>&lt; 100</span>))}
    {this.renderRow("Cholesterol HDL (mg / dL)", (<span>&gt; 39</span>))}
    {this.renderRow("Triglycerides (mg / dL)", (<span>&lt; 150</span>))}
    {this.renderRow("Fasting glucose (mg / dL)", (<span>&lt; 100</span>))}
    </div>
    );
  }
}