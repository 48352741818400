import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import constants from "../utils/constants";

export class EnrollmentsByPhaseStackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.calculatePercentage = this.calculatePercentage.bind(this);
    this.generateRandomBackgroundColor =
      this.generateRandomBackgroundColor.bind(this);
    this.generateBorderColor = this.generateBorderColor.bind(this);
    this.stackedBarChartOptions = this.stackedBarChartOptions.bind(this);
  }

  componentDidMount() {
    this.stackedBarChartData();
  }

  calculatePercentage(dividend, divisor) {
    const percentAsDecimal = dividend / divisor;
    return percentAsDecimal.toFixed(2) * 100;
  }

  generateRandomBackgroundColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgba(${red}, ${green}, ${blue}, 0.3)`;
  }

  generateBorderColor(backgroundColor) {
    const colorArray = backgroundColor.split(",");
    const color = colorArray[0] + ", " + colorArray[1] + ", " + colorArray[2];
    return color + ", 3)";
  }

  stackedBarChartData(data) {
    const labels = [
      "Lifeforce Active",
      "Phase 1",
      "Phase 2",
      "Phase 3",
      "Phase 4",
      "Phase 5",
      "Pending",
    ];

    const list = _.flatMap(data, (item) => {
      return [
        item.lifeforceActive,
        item.phase1,
        item.phase2,
        item.phase3,
        item.phase4,
        item.phase5,
        item.pending,
      ];
    });

    const medicallyEnrolled = _.flatMap(data, (item) => {
      return item.medicallyEnrolled;
    });

    const medicallyEnrolledBackground = this.generateRandomBackgroundColor();
    const medicallyEnrolledBorderColor = this.generateBorderColor(
      medicallyEnrolledBackground
    );
    const participationBackground = this.generateRandomBackgroundColor();
    const participationBorderColor = this.generateBorderColor(
      participationBackground
    );

    const stackedBarChartData = {
      labels: labels,
      datasets: [
        {
          label: "Patricipation",
          data: list,
          backgroundColor: participationBackground,
          borderColor: participationBorderColor,
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
        },
        {
          label: "Medically Enrolled",
          data: [
            medicallyEnrolled[0],
            medicallyEnrolled[0],
            medicallyEnrolled[0],
            medicallyEnrolled[0],
            medicallyEnrolled[0],
            medicallyEnrolled[0],
            medicallyEnrolled[0],
          ],
          backgroundColor: medicallyEnrolledBackground,
          borderColor: medicallyEnrolledBorderColor,
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
        },
      ], // Ensure 'Medically Enrolled' is at the bottom
    };

    return stackedBarChartData;
  }

  stackedBarChartOptions() {
    const options = {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Enrollments By Participation Status",
        fontSize: 16,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: true,
              labelString: "Participation by Status",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: true,
              labelString: "Enrolled Amount",
            },
          },
        ],
      },
    };

    return options;
  }
  render() {
    return (
      <>
        <div>
          <Bar
            data={this.stackedBarChartData(this.props.enrollmentsByPhaseData)}
            width={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.width}
            height={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.height}
            options={this.stackedBarChartOptions()}
          />
        </div>
      </>
    );
  }
}
