import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

export class Goals2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestVisit: props.latestVisit
    };
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevProps.latestVisit, this.props.latestVisit)) return;
    this.setState({latestVisit: this.props.latestVisit});
  }

  renderRow(prompt, value, target) {
    return (
    <Row>
      <Col sm="6" className="data-caption">
        <span style={{marginLeft: "8px"}}>{prompt}</span>
      </Col>
      <Col sm="2">
        {value}
      </Col>
      <Col sm="4">
        {target && (target + '').toUpperCase() === 'M' ? 'Maintain' : target}
      </Col>
    </Row>);
  }

  render() {
    const latestVisit = this.state.latestVisit;
    let bodyFat = latestVisit.bodyFat;
    if (bodyFat && parseFloat(bodyFat)) {
      bodyFat = parseFloat(bodyFat).toFixed(2);
    }
    return (
    <div className="my-0">
      {this.renderRow("Weight (lbs)", latestVisit.weight, latestVisit.gWeight)}
      {/* {this.renderRow("Blood Pressure (sys / dia)", `${latestVisit.bpSys} / ${latestVisit.bpDia}`, `${latestVisit.gbpSys} / ${latestVisit.gbpDia}`)} */}
      {this.renderRow("Blood Pressure (sys / dia)", `${latestVisit.bpSys} / ${latestVisit.bpDia}`, (<span>&#8804;130 / &#8804;85</span>))}
      {this.renderRow("Heart Rate (beats / min)", latestVisit.hRate, latestVisit.ghRate)}
      {this.props.showBodyFat
        ? this.renderRow("% Body Fat", bodyFat, latestVisit.gBodyFat)
        : this.renderRow("Body Composition", latestVisit.bcs, latestVisit.gbcs)
      }
      {this.renderRow("Fitness Score (1-5 [healthiest])", latestVisit.feVal, latestVisit.gfeval)}
      {this.renderRow("Total Cholesterol (mg / dL)", latestVisit.cholTotal, (<span>&lt; 200</span>))}
      {this.renderRow("Cholesterol LDL (mg / dL)", latestVisit.cholLdl, (<span>&lt; 100</span>))}
      {this.renderRow("Cholesterol HDL (mg / dL)", latestVisit.cholHdl, (<span>&gt; 40 for males and &gt; 50 for females</span>))}
      {this.renderRow("Triglycerides (mg / dL)", latestVisit.trig, (<span>&lt; 150</span>))}
      {this.renderRow("Fasting glucose (mg / dL)", latestVisit.glucose, (<span>&lt; 100</span>))}
      {this.renderRow("BMI", latestVisit.bmi, latestVisit.gBMI)}
      {this.renderRow("Waist Circumference (inches)", latestVisit.waist, latestVisit.gWaist)}
    </div>
    );
  }
}