import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import constants from "../utils/constants";

export class VisitsByMonthPeakStackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.generateRandomBackgroundColor =
      this.generateRandomBackgroundColor.bind(this);

    this.generateBorderColor = this.generateBorderColor.bind(this);
    this.stackedBarChartData = this.stackedBarChartData.bind(this);
  }

  generateRandomBackgroundColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgba(${red}, ${green}, ${blue}, 0.3)`;
  }

  generateBorderColor(backgroundColor) {
    const colorArray = backgroundColor.split(",");
    const color = colorArray[0] + ", " + colorArray[1] + ", " + colorArray[2];
    return color + ", 3)";
  }

  stackedBarChartData() {
    const table = this.props.visitsData;
    const labels = table[0].slice(1, -1); // yrMonths
    const phases = _.map(table.slice(1, -1), (row) => row[0]); // phases
    const data = _.map(table.slice(1, -1), (row) => row.slice(1, -1)); // counts

    const datasets = _.map(_.unzip(data), (counts, i) => ({
      label: labels[i],
      data: counts,
      backgroundColor: this.generateRandomBackgroundColor(),
      borderColer: this.generateBorderColor(
        this.generateRandomBackgroundColor()
      ),
      borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
      // add other properties like backgroundColor, borderColor, etc. here
    }));

    const stackedBarChartData = {
      labels: phases,
      datasets: datasets,
    };

    return stackedBarChartData;
  }

  stackedBarChartOptions() {
    const options = {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Phase Visits By Month",
        fontSize: 16,
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Phases",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Visits",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    return options;
  }

  render() {
    return (
      <>
        <Bar
          data={this.stackedBarChartData()}
          width={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.width}
          height={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.height}
          options={this.stackedBarChartOptions()}
        />
      </>
    );
  }
}
