import _ from 'lodash';
import api from './api';

const find_filter = (filterName, filters) => {
  return _.find(filters, x => {
    return x.filterName === filterName;
  });
};

export default {
  //gets a new filters array based on the new filter being sent back to the page from the filter component
  get_filters(state_filters, new_filter) {
    const filterIndex = state_filters.findIndex(
      x => x.filterName === new_filter.filterName
    );
    let filters = [];
    if (filterIndex === -1) {
      filters = [...state_filters, new_filter];
    } else {
      filters = Object.assign([], state_filters, { [filterIndex]: new_filter });
    }
    return filters.filter(
      x =>
        x.value ||
        (x.values && x.values.length && x.values.length > 0) ||
        x.startDate ||
        x.endDate
    );
  },

  transform_filters(filters = []) {
    return filters.reduce(function(acc, item, i) {
      acc[item.filterName] = item.value || item.values;
      return acc;
    }, {});
  },

  save_search(area, sort_dir, sort_field, filters) {
    api
      .post("save_search", {
        area: area,
        serialized_search: {
          sort_field: sort_field,
          sort_dir: sort_dir,
          filters: filters
        }
      })
      .catch(error => error);
  },

  set_saved_filters(area, callBack) {
    api
      .fetch("get_saved_search", { area: area })
      .then(response => {
        const search = response.data.serialized_search;
        callBack(search.sort_field, search.sort_dir, search.filters);
      })
      .catch(error => error);
  },

  get_value(filterName, filters) {
    const filter = find_filter(filterName, filters);
    return filter ? filter.value : "";
  },

  get_values(filterName, filters) {
    const filter = find_filter(filterName, filters);
    return filter ? filter.values : [];
  },

  get_startDate(filterName, filters) {
    const filter = find_filter(filterName, filters);
    return filter ? filter.startDate : null;
  },
  
  get_endDate(filterName, filters) {
    const filter = find_filter(filterName, filters);
    return filter ? filter.endDate : null;
  }
};
