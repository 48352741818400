import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Container, ButtonGroup, Button, Card, CardBody, Form, Col, Row, CardHeader, Input, InputGroup, InputGroupAddon, InputGroupText, Alert } from 'reactstrap';
import api from '../utils/api';
import helpers from '../utils/helpers';

export class Impersonate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      username: "",
      redirectToHome: false
    };
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onSave = this.onSave.bind(this);      
  }
  
  onChangeUsername(e) {
    this.setState({ username: e.target.value });
  }

  onSave(event) {
    event.preventDefault();
    if (this.state.username.trim() === '') {
      this.setState({message: "Either enter a username to impersonate on choose to cancel.", messageFlavor: "danger"})
    }
    api.fetch(`Admin/Impersonate/${this.state.username}`).then(response => {
      if (response.data.success === false) {
        this.setState({ messageFlavor: "danger", message: response.data.message });
      } else {
        this.props.setImpersonation(response.data.user, response.data.user.token, response.data.nextAppt);
        this.setState({ messageFlavor: "success", message: "You are now " + response.data.user.firstName + " " + response.data.user.lastName + " and will be redirected momentarily!" });
        setTimeout(function(component) {
          component.setState({redirectToHome: true});
        }, 3000, this);
      }
    }).catch(helpers.catchHandler);
  };

  render() {
    if (this.state.redirectToHome) {
      return (<Redirect to='/home' />);
    }
    return (
      <Container fluid>
        <Card>
          <CardHeader>Impersonate</CardHeader>
          <CardBody>
            <Row className="mt-3">
              <Col sm={{ size: 4, offset: 4 }}>
                {this.state.message === null
                  ? null
                  : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)
                }
                <Form>
                  <Row className="mb-2">
                    <Col sm="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{width: "160px"}}>Become Username</InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" onChange={this.onChangeUsername} value={this.state.username} maxLength="50"/>
                      </InputGroup>
                    </Col>
                    <Col className="text-right">
                      <ButtonGroup>
                        <button className="btn btn-primary" onClick={this.onSave}>OK</button>
                        <Button color="secondary" tag={Link} to="/home">Cancel</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row> 
          </CardBody>
        </Card>
      </Container>
    );
  };
}
