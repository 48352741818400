import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
//import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

export class SingleDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
    this.changeDate = this.changeDate.bind(this);
  }

  changeDate(d) {
    this.setState({value: d});
    this.props.onChangeCallback({
      date: d
    });
  }

  render() {
    return (
      <DatePicker
        selected={this.state.value}
        onChange={this.changeDate}
        className="form-control"
        popperPlacement="bottom"
        popperModifiers={{flip: {enabled: false}, preventOverflow: { enabled: true, escapeWithReference: false}}}
      />
    );
  }
}