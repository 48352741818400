import React, { Component } from 'react';
import { Button, CardHeader, Card, CardBody, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Badge } from 'reactstrap';
import { Goals } from '../components/';
import { Line } from 'react-chartjs-2';
import classnames from 'classnames';
import api from '../utils/api';
import date_helpers from '../utils/date_helpers';
import helpers from '../utils/helpers';
import _ from 'lodash';

const TABS = {
  VISIT_DETAIL: "1",
  VISIT_HISTORY: "2",
  LAB_HISTORY: "3",
  BODY_FAT: "4",
  CHOL: "5",
  PHASE: "6",
  BLOOD_PRESSURE: "7"  
};

const chartOptionsBodyFat = () => { 
  return {
    title: {
      display: true,
      text: 'Body Fat and Weight',
      fontSize: 20
    },
    scales: {
      yAxes: [{
        id: 'left-side',
        // ticks: {
        //   beginAtZero:true
        // },
        scaleLabel: {
          display: true,
          labelString: 'Weight (Lbs.)',
          fontSize: 18
        }
      },
      {
        id: 'right-side',
        // type: 'linear',
        position: 'right',
        // ticks: {
        //   max: 80,
        //   min: 5
        // },
        scaleLabel: {
          display: true,
          labelString: 'Body Fat %',
          fontSize: 18
        }
      }
      ]            
    }  
  }; 
};

const chartOptionsChol = () => { 
  return {
    title: {
      display: true,
      text: 'Cholesterol, Glucose, Triglycerides',
      fontSize: 20
    },
    scales: {
      yAxes: [{
        id: 'left-side',
        // ticks: {
        //   beginAtZero:true
        // },
        scaleLabel: {
          display: true,
          labelString: 'mg/dL',
          fontSize: 18
        }
      }
      ]            
    }  
  }; 
};

const chartOptionsFitness = () => { 
  return {
    title: {
      display: true,
      text: 'Phase / Fitness',
      fontSize: 20
    },
    scales: {
      yAxes: [{
        id: 'left-side',
        // scaleLabel: {
        //   display: true,
        //   labelString: 'Weight (Lbs.)',
        //   fontSize: 18
        // }
      }
      ]            
    }
  }; 
};

const chartOptionsBP = () => { 
  return {
      title: {
        display: true,
        text: 'Blood Pressure',
        fontSize: 20
      },
      scales: {
      yAxes: [{
        id: 'left-side',
        scaleLabel: {
          display: true,
          labelString: 'mmHg',
          fontSize: 18
        }
      }
      ]            
    }  
  }; 
};

const getSparseVisitData = (visitHistoryList) => {
  return _.map(visitHistoryList, vh => {
    const m = date_helpers.getMomentFromString(vh.visitOn, 'MM/DD/YYYY');
    return {
      visitMoment: m,
      visitTicks: m.valueOf(),
      weight: vh.weight,
      bodyFat: vh.bodyFat,
      trig: vh.trig,
      cholTotal: vh.cholTotal,
      glucose: vh.glucose,
      feVal: vh.feVal,
      phase: vh.phase,
      bpSys: vh.bpSys,
      bpDia: vh.bpDia
    };
  });
};

const getXAxisArrayData = (visitHistoryList) => {
  if (!visitHistoryList || visitHistoryList.length === 0) return [];
  const sparseData = getSparseVisitData(visitHistoryList);
  const maxDateObj = _.last(_.sortBy(sparseData, function(v) { return v.visitTicks; }));
  let d = maxDateObj.visitMoment.startOf('month');
  d.add(2, 'months');
  let xAxisData = [];
  for(var i=14; i > 0; i--) {
    let axisPoint = {
      visitEndOfMonth: d.clone()
    };
    d.subtract(1, 'months');
    axisPoint.visitStartOfMonth = d.clone();
    axisPoint.visitOnString = d.format('MMM, YY');
    xAxisData.push(axisPoint);
  }
  return _.reverse(xAxisData);
};

const getPoints = (xAxisArray, visitHistoryList, dataElementName) => {
  const sparseVisitData = getSparseVisitData(visitHistoryList);
  let yAxisData = [];
  for(var i=0; i<xAxisArray.length; i++) {
    const som = xAxisArray[i].visitStartOfMonth;
    const eom = xAxisArray[i].visitEndOfMonth;
    const dataPoint = _.find(sparseVisitData, v => v.visitMoment.isAfter(som) && v.visitMoment.isBefore(eom));
    // console.log('data point range: ', xAxisArray[i].visitStartOfMonth.format('MM/DD/YY'), xAxisArray[i].visitEndOfMonth.format('MM/DD/YY'), dataPoint);
    if (dataPoint === undefined) {
      yAxisData.push(null);
    } else {
      yAxisData.push(dataPoint[dataElementName]);
    }
  }
  return yAxisData;
};

const dictionaryToCodeDescription = (dict) => {
  let list = [];
  for(var code in dict) {
    list.push({code: code, description: dict[code]});
  }
  return list;
};

const getLabHistoryList = (visitHistoryList) => {
  const groupedByDate = _.chain(visitHistoryList)
    .filter(x => x.labOn !== null)
    .groupBy(x => x.labOn)
    .value();
  let list = [];
  for(var labOn in groupedByDate) {
    list.push(groupedByDate[labOn][0]);
  }
  return _.sortBy(list, x => x.labOn);
};

class VisitDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const latestVisit = this.props.latestVisitDetail;
    return (<div>
      <Row>
        <Col sm="4" className="data-caption">
          Program Enroll Date
        </Col>
        <Col sm="2">
          {latestVisit.enrollDate}
        </Col>
        <Col sm="4" className="data-caption">
          Current Phase
        </Col>
        <Col sm="2" style={{textAlign: "center"}}>
          <Badge color="primary" style={{fontSize: "12pt"}}>{latestVisit.currPhase}</Badge>
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="data-caption">
          Last Evaluation Date
        </Col>
        <Col sm="2">
          {latestVisit.lastEvaluationDate}
        </Col>
        <Col sm="6" style={{fontStyle: "italic", textAlign: "right"}}>
          Phase Scale: 1-5 (5=Best Health)
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="data-caption">
          Heredity Risk *
        </Col>
        <Col sm="2">
          {latestVisit.hCode}
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="data-caption">
          Personal Health Risks **
        </Col>
        <Col sm="2">
          {latestVisit.risk}
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="data-caption">
          Current Medications
        </Col>
        <Col sm="8">
          {latestVisit.medication}
        </Col>
      </Row>
      <Row><Col></Col></Row>
      <Row className="header-row">
        <Col sm="6">
          Health Screenings
        </Col>
        <Col sm="3">
          Completed Date
        </Col>
        <Col sm="3">
          Due Date
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Pap
        </Col>
        <Col sm="3">
          {latestVisit.papCompletedOn}
        </Col>
        <Col sm="3">
          {latestVisit.papDueOn}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Mammogram
        </Col>
        <Col sm="3">
          {latestVisit.mamCompletedOn}
        </Col>
        <Col sm="3">
          {latestVisit.mamDueOn}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Prostate - PSA
        </Col>
        <Col sm="3">
          {latestVisit.prostateCompletedOn}
        </Col>
        <Col sm="3">
          {latestVisit.prostateDueOn}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Digital Rect
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Colon Screening
        </Col>
        <Col sm="3">
          {latestVisit.colonCompletedOn}
        </Col>
        <Col sm="3">
          {latestVisit.colonDueOn}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Physical Exam
        </Col>
        <Col sm="3">
          {latestVisit.physicalExamCompletedOn}
        </Col>
        <Col sm="3">
          {latestVisit.physicalExamDueOn}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Bone Density
        </Col>
        <Col sm="3">
          {latestVisit.boneDensityCompletedOn}
        </Col>
        <Col sm="3">
          {latestVisit.boneDensityDueOn}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Lab
        </Col>
        <Col sm="3">
          {latestVisit.labCompletedOn}
        </Col>
        <Col sm="3">
          {latestVisit.labDueOn}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Dental
        </Col>
        <Col sm="3">
        </Col>
        <Col sm="3">
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Eye
        </Col>
        <Col sm="3">
        </Col>
        <Col sm="3">
        </Col>
      </Row>
      <Row className="header-row">
        <Col>
          Lifestyle Habits
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Tobacco Use / Per Day
        </Col>
        <Col sm="6">
          {latestVisit.tobaccoUsePerDay}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Alcohol User / Per Week
        </Col>
        <Col sm="6">
          {latestVisit.alcoholUsePerWeek}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Activity Type ***
        </Col>
        <Col sm="6">
          {latestVisit.activity}
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="data-caption">
          Activity Frequency
        </Col>
        <Col sm="6">
          {latestVisit.actComm}
        </Col>
      </Row>
      <Goals showHeader={true} latestVisit={latestVisit} />
      <Row className="header-row">
        <Col>
          Current Recommendations
        </Col>
      </Row>
      <Row>
        <Col>
          {latestVisit.recomText}
        </Col>
      </Row>
      </div>)
  }
}

export class Visit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      heredityRiskCodes: {},
      activityCodes: {},
      controllableRiskCodes: {},
      latestVisitDetail: {},
      visitHistoryList: [],
      loaded: false
    };
    this.toggle = this.toggle.bind(this);
    this.onPrint = this.onPrint.bind(this);
    this.getBodyFatChartData = this.getBodyFatChartData.bind(this);
    this.getFitnessChartData = this.getFitnessChartData.bind(this);
    this.getBPChartData = this.getBPChartData.bind(this);
    this.getCholChartData = this.getCholChartData.bind(this);
  }

  componentDidMount() {
    if (this.state.loaded) return;
    api.getUserVisitData().then(data =>{
      this.setState({
        heredityRiskCodes: data.heredityRiskCodes,
        activityCodes: data.activityCodes,
        controllableRiskCodes: data.controllableRiskCodes,
        latestVisitDetail: data.latestVisitDetail,
        visitHistoryList: data.visitHistoryList,
        loaded: true
      });
    }).catch(helpers.catchHandler);
  }
  
  toggle(tab) {
    if (this.state.activeTab === tab) return;
    this.setState({ activeTab: tab });
  }

  onPrint(e) {
    e.preventDefault();
    api.downloadPDF('Visit/RenderVisitReport');
  }

  getBodyFatChartData() {
    const xAxisArray = getXAxisArrayData(this.state.visitHistoryList);
    return {
      labels: _.map(xAxisArray, d => d.visitOnString),
      datasets: [
        {
          label: 'Weight',
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgb(167, 105, 0)",
          borderColor: "rgb(167, 105, 0)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgb(167, 105, 0)",
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'left-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'weight')
        },
        {
          label: 'Body Fat',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'right-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'bodyFat')
        }
      ]
    };
  }

  getCholChartData() {
    const xAxisArray = getXAxisArrayData(this.state.visitHistoryList);
    return {
      labels: _.map(xAxisArray, d => d.visitOnString),
      datasets: [
        {
          label: 'Cholesterol',
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgb(167, 105, 0)",
          borderColor: "rgb(167, 105, 0)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgb(167, 105, 0)",
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'left-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'cholTotal')
        },
        {
          label: 'Glucose',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'left-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'glucose')
        },
        {
          label: 'Triglycerides',
          fill: false,
          lineTension: 0.1,
          backgroundColor: '#000',
          borderColor: '#000',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#000',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'left-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'trig')
        }
      ]
    };
  }

  getFitnessChartData() {
    const xAxisArray = getXAxisArrayData(this.state.visitHistoryList);
    return {
      labels: _.map(xAxisArray, d => d.visitOnString),
      datasets: [
        {
          label: 'Phase',
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgb(167, 105, 0)",
          borderColor: "rgb(167, 105, 0)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgb(167, 105, 0)",
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'left-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'phase')
        },
        {
          label: 'Fitness',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'left-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'feVal')
        }
      ]
    };
  }

  getBPChartData() {
    const xAxisArray = getXAxisArrayData(this.state.visitHistoryList);
    return {
      labels: _.map(xAxisArray, d => d.visitOnString),
      datasets: [
        {
          label: 'Systolic',
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgb(167, 105, 0)",
          borderColor: "rgb(167, 105, 0)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgb(167, 105, 0)",
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'left-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'bpSys')
        },
        {
          label: 'Diastolic',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          spanGaps: true,
          yAxisID: 'left-side',
          data: getPoints(xAxisArray, this.state.visitHistoryList, 'bpDia')
        }
      ]
    };
  }

  render() {
    const latestVisit = this.state.latestVisitDetail;
    return (
      <Row className="d-flex flex-row mt-2">
        <Col sm="12">
          <Card className="homeWidgets mb-2 mt-0 mx-0">
            <CardHeader className="expand-md progressCardTitle">Visit Details</CardHeader>
              <CardBody>
                <Row className="d-flex flex-row mt-2">
                  <Col sm="2" className="pl-2 pr-0">
                    <Nav tabs className="flex-column nav-pills btn-sm">
                     <NavItem className="mb-1 visitTabs">
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.VISIT_DETAIL })}
                        onClick={() => this.toggle(TABS.VISIT_DETAIL)}
                      >
                        Visit Detail
                      </NavLink>
                    </NavItem>
                    <NavItem className="mb-1 visitTabs">
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.VISIT_HISTORY })}
                        onClick={() => this.toggle(TABS.VISIT_HISTORY)}
                      >
                        Visit History
                      </NavLink>
                    </NavItem>
                    <NavItem className="mb-1 visitTabs">
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.LAB_HISTORY })}
                        onClick={() => this.toggle(TABS.LAB_HISTORY)}
                      >
                        Lab History
                      </NavLink>
                    </NavItem>
                    <NavItem className="mb-1 visitTabs">
                      <h5 className="visitTabs mt-3">Graphs</h5>
                    </NavItem>
                    <NavItem className="mb-1 visitTabs">
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.BODY_FAT })}
                        onClick={() => this.toggle(TABS.BODY_FAT)}
                      >
                        BCS / Weight
                      </NavLink>
                    </NavItem>
                    <NavItem className="mb-1 visitTabs">
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.CHOL })}
                        onClick={() => this.toggle(TABS.CHOL)}
                      >
                        Chol / Gluc / Trig
                      </NavLink>
                    </NavItem>
                    <NavItem className="mb-1 visitTabs">
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TABS.PHASE })}
                        onClick={() => this.toggle(TABS.PHASE)}
                      >
                        Phase / Fitness
                      </NavLink>
                    </NavItem>
                    <NavItem className="mb-1 visitTabs">
                      <NavLink
                            className={classnames({ active: this.state.activeTab === TABS.BLOOD_PRESSURE })}
                            onClick={() => this.toggle(TABS.BLOOD_PRESSURE)}
                        >
                        Blood Pressure
                      </NavLink>
                    </NavItem>
                    <NavItem className="mb-1 visitTabs">
                      <Button className="detailsButton mt-3" onClick={this.onPrint}>Print</Button>
                    </NavItem>
                  </Nav>
                </Col>
                <Col sm="10">
                  <TabContent activeTab={this.state.activeTab} className="mt-2">
                    <TabPane tabId={TABS.VISIT_DETAIL}>
                      <Row>
                        <Col sm="8">
                          {latestVisit === null ? null : <VisitDetail latestVisitDetail={latestVisit} /> }
                        </Col>
                        <Col sm={{size:3}} className="pl-5">
                          <Row className="header-row" style={{marginTop: 0}}>
                            <Col>
                              Heredity Risk Codes *
                            </Col>
                          </Row>
                          {dictionaryToCodeDescription(this.state.heredityRiskCodes).map(rc => (
                            <Row key={`hrc-${rc.code}`}>
                              <Col sm="1" className="code-letter">
                                {rc.code}
                              </Col>
                              <Col sm="10">
                                {rc.description}
                              </Col>
                            </Row>
                            ))}
                          <Row className="header-row">
                            <Col>
                              Personal Health Risk Codes **
                            </Col>
                          </Row>
                          {dictionaryToCodeDescription(this.state.controllableRiskCodes).map(rc => (
                            <Row key={`crc-${rc.code}`}>
                              <Col sm="1" className="code-letter">
                                {rc.code}
                              </Col>
                              <Col sm="10">
                                {rc.description}
                              </Col>
                            </Row>
                            ))}
                          <Row className="header-row">
                            <Col>
                              Activity Codes ***
                            </Col>
                          </Row>
                          {dictionaryToCodeDescription(this.state.activityCodes).map(rc => (
                            <Row key={`crc-${rc.code}`}>
                              <Col sm="1" className="code-letter">
                                {rc.code}
                              </Col>
                              <Col sm="10">
                                {rc.description}
                              </Col>
                            </Row>
                            ))}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={TABS.VISIT_HISTORY}>
                      <Row>
                        <Col sm="12">
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                  <th>Visit</th>
                                  <th>Phase</th>
                                  <th>Weight</th>
                                  <th>BP Sys</th>
                                  <th>BP Dia</th>
                                  <th>Heart Rate</th>
                                  <th>BCS</th>
                                  <th>Fitness</th>
                                  <th>Fitness scale 1-5<br/>(5=Best)</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.visitHistoryList.map(v => (
                               <tr key={`vh-${v.id}`}>
                                 <td>{v.visitOn}</td>
                                 <td>{v.phase}</td>
                                 <td>{v.weight}</td>
                                 <td>{v.bpSys}</td>
                                 <td>{v.bpDia}</td>
                                 <td>{v.hRate}</td>
                                 <td>{v.bodyFat}</td>
                                 <td colSpan="2">{v.feVal}</td>
                               </tr>
                            ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={TABS.LAB_HISTORY}>
                      <Row>
                        <Col sm="12">
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th>Lab Date</th>
                                <th>Chol Total</th>
                                <th>Chol Total/HDL Ratio</th>
                                <th>Chol LDL</th>
                                <th>Chol HDL</th>
                                <th>Trig</th>
                                <th>Glucose</th>
                                <th>TSH</th>
                              </tr>
                            </thead>
                            <tbody>
                            {getLabHistoryList(this.state.visitHistoryList).map(lab => (
                              <tr key={`vhl-${lab.id}`}>
                                <td>{lab.labOn}</td>
                                <td>{lab.cholTotal}</td>
                                <td>{lab.cholHdlMetric}</td>
                                <td>{lab.cholLdl}</td>
                                <td>{lab.cholHdl}</td>
                                <td>{lab.trig}</td>
                                <td>{lab.glucose}</td>
                                <td>{lab.tsh}</td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={TABS.BODY_FAT}>
                      <Row>
                        <Col sm={{size:10, offset: 1}}>
                          <Line
                            data={this.getBodyFatChartData()}
                            options={chartOptionsBodyFat()}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={TABS.CHOL}>
                      <Row>
                        <Col sm={{size:10, offset: 1}}>
                          <Line
                            data={this.getCholChartData()}
                            options={chartOptionsChol()}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={TABS.PHASE}>
                      <Row>
                        <Col sm={{size:10, offset: 1}}>
                          <Line
                            data={this.getFitnessChartData()}
                            options={chartOptionsFitness()}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={TABS.BLOOD_PRESSURE}>
                      <Row>
                        <Col sm={{size:10, offset: 1}}>
                          <Line
                            data={this.getBPChartData()}
                            options={chartOptionsBP()}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
