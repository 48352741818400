import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import constants from "../utils/constants";

export class PeakVerticalBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.calculatePercentage = this.calculatePercentage.bind(this);
    this.processCostSavingsData = this.processVerticalBarChartData.bind(this);
    this.generateRandomColor = this.generateRandomColor.bind(this);
  }
  calculatePercentage(dividend, divisor) {
    const percentAsDecimal = dividend / divisor;
    return percentAsDecimal.toFixed(2) * 100;
  }

  generateRandomColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgba(${red}, ${green}, ${blue}, 0.6)`;
  }

  processVerticalBarChartData(costSavingsData) {
    const itemColor = _.map(costSavingsData, (item) => {
      return item.itemColor;
    });

    const borderColor = _.map(costSavingsData, (item) => {
      return item.borderColor;
    });

    const percentage = _.map(costSavingsData, (item) => {
      const percentage = this.calculatePercentage(
        item.improvedValue,
        item.initialValue
      );
      return percentage;
    });

    const labels = _.map(costSavingsData, (item) => {
      return item.riskFactor;
    });

    const verticalPieChartData = {
      labels: labels,
      datasets: [
        {
          label: "Risk Factor Improvement Percentage",
          data: percentage,
          backgroundColor: itemColor,
          borderColor: borderColor,
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
        },
      ],
    };
    return verticalPieChartData;
  }

  render() {
    return (
      <>
        <div>
          <Bar
            data={this.processVerticalBarChartData(this.props.costSavingsData)}
            width={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.width}
            height={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.height}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      max: 100,
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </>
    );
  }
}
