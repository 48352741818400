import { _ } from "core-js";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, } from "reactstrap";

const delay = 5000;

export default function SlideShowBanner(props) {
  const [index, setIndex] = useState(0);
  const timeOutRef = useRef(null);

  function resetTimeout() {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeOutRef.current = setTimeout(() => {
      setIndex((prevIndex) =>
        prevIndex === props.images.length - 1 ? 0 : prevIndex + 1
      );
    }, delay);
    return () => {
      resetTimeout();
    };
  }, [index, props.images.length]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {_.map(props.images, (im, idx) => {
          return (
            <div className="slide" key={idx}>
              <Row className="pl-3">
                <Col xs="5" className="p-0 m-0">
                  <img
                    // height={"121vh"}
                    width={"100%"}
                    alt={im.imageKey}
                    style={{borderRadius: "10px", objectFit: "cover"}}
                    src={`/Content/panels/${im.imageKey}`}
                  ></img>
                </Col>
                <Col xs="7">
                  <Row>
                    <Col className="mt-2">
                      <h1 style={{ textAlign: "center" }}>{im.title}</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p style={{ textAlign: "center", fontStyle: "italic" }}>
                        {im.htmlContent}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>

      <div className="slideshowDots mt-2 mb-3">
        {_.map(props.images, (_, idx) => {
          return (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
}
