import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, Card, CardBody, CardTitle, CardHeader, UncontrolledAlert} from 'reactstrap';
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';
import constants from '../utils/constants';
import Dropzone from 'react-dropzone';
import makeAnimated from 'react-select/animated';
import api from '../utils/api';
import _ from 'lodash';

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  height: 320,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const imgStyle = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

export class LocalImages extends Component {
    constructor(props) {
      super(props);
      this.state = {
          message: null,
          messageFlavor: null,
          selectedImage: null,
          localImageList: [],
          imageKey: null,
          imageName: null,
          image: null
      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
      this.onImageDrop = this.onImageDrop.bind(this); 
      this.onImageKeySelected = this.onImageKeySelected.bind(this);
      this.refreshList = this.refreshList.bind(this);
    }

    componentDidMount() {
      this.refreshList()
    }

    refreshList() {
      api.fetch('Reference/ImagesList').then((result) => {
        this.setState({ localImageList: result.data });
      });
    }
    
    onTextChange(fieldName, e) {
      let panel = Object.assign({}, this.state.panel)
      panel[fieldName] = e.target.value;
      this.setState({ panel: panel });
    }

    handleCancel() {
      this.setState({
        message: null,
        messageFlavor: null,
        image: null
      })
    }

    isFormValid() {
      let warnings =[];
      if(!this.state.image){
        warnings.push("Please provide a PDF to upload")
      }
      if(this.state.imageName < 3){
        warnings.push("your image key is too small")
      }
      if (warnings.length) {
        this.setState({ message: warnings.join(','), messageFlavor:"danger" });
      } else {
        this.setState({ message: null});
      }
      return warnings.length === 0;
    }

    onImageDrop(acceptedFiles, rejectedFiles) {
      this.setState({
        message: null,
        messageFlavor: null
      });

      if(acceptedFiles){
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }));

        let checkLengthError = false;
        _.map(acceptedFiles, file => {
          if(file.name?.length > 32) {
            this.setState({
              message: "File names cannot exceed 32 characters", 
              messageFlavor: "danger"
            });
            checkLengthError = true;
            return;
          }
        });
        if (checkLengthError) return;
        
        let char = "";
        if (
          _.some(acceptedFiles, function (file) {
            char = _.find(constants.badChars, (c) => file.name.indexOf(c) !== -1);
            return !!char;
          })
        ) {
          this.setState({
            message: `File names cannot contain the character ${char}`, 
            messageFlavor: "danger"
          });
          return;
        }
      }
      if (rejectedFiles.length) {
        this.setState({ message: 'One or more files were rejected.', messageFlavor: "danger" });
      } else {
        this.setState({ 
          image: acceptedFiles[0], 
          message: null,
          imageName: acceptedFiles[0].name 
        });
      }
    }
      
    handleSubmit(event) {
      event.preventDefault();
      if (!this.isFormValid()) return;
      let payload = new FormData();
      payload.append('image', this.state.image);
      payload.append('imageName', this.state.imageName)
      api.post_form_data('Home/UploadImage', payload).then(r => {
        if (r.data.success === false) {
          this.setState({
            message: r.data.message, 
            messageFlavor: "danger"
          });
        } else {
          this.refreshList();
          this.setState({
            message: "The Image has been uploaded Successfully", 
            messageFlavor: "success",
            image: null
          });
        }
      }).catch(helpers.catchHandler);
    }

    onImageKeySelected(newImageKey){
      this.setState({ imageKey: newImageKey.label });
    }

    render() {
      const pendingUpload = this.state.image !== null;
      return (
      <Card>
        <CardHeader className="strong-header">
          View Your Local Images on the Right 
        </CardHeader>
        <CardBody>
          {this.state.message === null ? null : (<UncontrolledAlert color={this.state.messageFlavor}>{this.state.message}</UncontrolledAlert>) }
          <Row className="mb-2">
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="text-center">
                    {this.state.image ? 
                    "Your file name will be checked for invalid characters"
                    : "Drop new images below or Click the icon below to choose it from your computer files" }
                  </CardTitle>
                    <Dropzone className="col-xs-4"
                      onDrop={this.onImageDrop}
                      accept="image/jpeg,image/png,image/gif"
                    >
                    {({getRootProps, getInputProps}) => (<section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div style={{ textAlign: 'center' }}>{
                          this.state.image ? 
                            (<div style={thumb} key={this.state.image.name}>
                              <div style={thumbInner}>
                                <img
                                  src={this.state.image.preview}
                                  alt={this.state.imageKey}
                                  style={imgStyle}
                                />
                              </div>
                            </div>)
                        : (<FontAwesomeIcon icon="file-upload" size="6x" />)
                      }
                        </div>
                      </div>
                    </section>)}
                    </Dropzone>
                  </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Row style={{
                width: "100%",
                height: "15%"
              }}>
                <Col>
                Tip: find your image faster by typing a few letters
                <Select
                  closeMenuOnSelect
                  value={this.state.imageKey
                    ? _.find(this.state.localImageList, x => x.label === this.state.imageKey)
                    : null}
                  isMulti={false}
                  components={makeAnimated()}
                  options={this.state.localImageList}
                  onChange={this.onImageKeySelected}
                  placeholder="Select Image"
                />
                </Col>
              </Row>
              <Row>
                <Col className='mt-4'>
                  {this.state.imageKey 
                    ? (<div style={{ textAlign: 'center' }}>
                        <div style={thumb}>
                          <div style={thumbInner}>
                              <img
                                src={`/Content/panels/${this.state.imageKey}`}
                                alt={this.state.imageKey}
                                style={imgStyle}
                              />
                          </div>
                        </div>
                      </div>)
                    : null
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="expand-md">
            <Col>
              <ButtonGroup style={{ float: "left" }} > 
                <Button size="lg" color={pendingUpload ? "primary" : "secondary"} disabled={!pendingUpload} onClick={this.handleSubmit}>Upload</Button>
                {pendingUpload
                ? <Button size="lg" color="danger" onClick={this.handleCancel}>Cancel</Button>
                : null}
              </ButtonGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      );
  }  
}