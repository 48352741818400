import React, { Component } from 'react';
import { Container, Row, Col, Button, Label, Card, CardHeader, CardBody, ButtonGroup, Alert, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import helpers from '../utils/helpers';
import storage from "../utils/storageFactory.js";
import { NotificationFacade } from '../utils/notificationfacade.js';

const promptStyle = { width: "140px" };

export class SMSConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.currentUser,
      isOptedIntoReminderNotifications: false,
      isOptedIntoFeedbackNotifications: false,
      hasAgreedToTermsAndConditions: false,
      canSubmit: false,
      message: null,
      messageFlavor: null,
      errorsOnLoad: true
    };
    this.notificationFacade = new NotificationFacade();
    this.configureBinds();
  }

  configureBinds() {
    this.onSubmit = this.onSubmit.bind(this);
    this.onTextChanged = this.onTextChanged.bind(this);
    this.onCheckBoxChanged = this.onCheckBoxChanged.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    this.notificationFacade
      .GetUserConsentStatus(this.state.user.id)
      .then(r => {
        this.setState({
          isOptedIntoReminderNotifications: r.isOptedIntoReminderNotifications,
          isOptedIntoFeedbackNotifications: r.isOptedIntoFeedbackNotifications,
          hasAgreedToTermsAndConditions: r.hasAgreedToTermsAndConditions,
          canSubmit: !(r.isOptedIntoReminderNotifications && r.hasAgreedToTermsAndConditions)
        })
      }).catch(ex => {
          this.setState({
              message: "Error loading consent status",
              messageFlavor: "danger"
          })
          helpers.catchHandler(ex);
      });
  }

  onTextChanged(e) {
    var controlName = e.target.name;
    switch (controlName) {
      case "mobilePhone":
        this.setState({ 
          user: {
            ...this.state.user,
            mobilePhone: e.target.value
          }
        });
        break;
      default:
        console.error('Unknown text change control: ' + controlName);
        break;
    }
  }

  onCheckBoxChanged(e) {
      this.setState({ [e.target.name]: e.target.checked });
  }

  validate() {
      let errors = [];
      const phone_regex = /^((\+1?|1-)(\s|)|)\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i;
      let user = this.state.user;
      let optedIntoNotifications =
        this.state.isOptedIntoFeedbackNotifications
        || this.state.isOptedIntoReminderNotifications;

      if(!optedIntoNotifications) {
        errors.push("Please make a selection and then submit.");
      }

      if(!user.mobilePhone) {
        errors.push("Mobile phone number is required.");
      } else if(!phone_regex.test(user.mobilePhone)) {
        errors.push("Mobile phone number is invalid.");
      }

      if(optedIntoNotifications && !this.state.hasAgreedToTermsAndConditions) {
        errors.push("Please agree to terms and conditions.");
      }

      if(errors.length > 0) {
        this.setState({message: errors.join('\n'), messageFlavor: "danger"});
      } else {
        this.setState({message: null});
      }

      return (errors.length <= 0);
  }

  onSubmit(e){
    e.preventDefault();

    if(!this.validate()) return;

    let user = this.state.user;

    this.setState({canSubmit: false});

    if(this.state.isOptedIntoReminderNotifications) {
      this.notificationFacade
        .OptIntoAppointmentReminders({
          userId: user.id,
          mobilePhone: user.mobilePhone,
          options: {
            appointmentNotificationsEnabled: this.state.isOptedIntoReminderNotifications,
            feedbackNotificationsEnabled: this.state.isOptedIntoFeedbackNotifications,
            agreedToTermsAndConditions: this.state.hasAgreedToTermsAndConditions
          }
        })
        .then(x => x)
        .then(r => {
          if(r.success === true) {
            let changedUser = this.props.currentUser;
            changedUser.mobilePhone = this.state.user.mobilePhone;
            const existingToken = storage.getItem('token');
            this.props.setCurrentUser(changedUser, existingToken);
            this.setState({ message: "Notification preferences updated successfully!", messageFlavor: "success", canSubmit: false });
          } else {
            this.setState({message: r.msg, messageFlavor: "danger", canSubmit: true});
          }
        })
        .catch(helpers.catchHandler)
    }
  }         

  render() {
    return (
      <Container fluid>
        <Card>
          <CardHeader id="projectDetails" className="card-header">
            <Row className="expand-md">
              <Col>
                <Label className="titleDates">Mobile Opt-In</Label>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>
              {this.state.message.split("\n").map(msg => {
                return <div key="{msg}">{msg}</div>;
              })}
            </Alert>)}

            {/* {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)} */}
            <form onSubmit={this.onSubmit}>
              <fieldset disabled={!this.state.canSubmit}>
                  <Row>
                    <Col>
                      <p>LifeForce is now offering SMS (texting) as a way of keeping you updated about your appointments and recommendations, and even to provide feedback about your appointment.  This solution is administered through the Peak Health Texting Program.</p>
                      <p>To opt-in to SMS, please provide your mobile phone # below and check the boxes for which you would like to receive automated text messages.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputGroup style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={promptStyle}>Mobile Phone #</InputGroupText>
                        </InputGroupAddon>
                        <Input type="tel"
                          name="mobilePhone"
                          id="mobilePhone"
                          value={this.state.user.mobilePhone}
                          onChange={this.onTextChanged}
                          style={{ maxWidth: '200px' }}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>I would like for the Peak Health Texting Program to send texts to:</p>
                      <p>
                        <label className="checkbox" style={{ marginLeft: '20px' }}>
                          <input type="checkbox" name="isOptedIntoReminderNotifications" checked={this.state.isOptedIntoReminderNotifications} onChange={this.onCheckBoxChanged} /> receive information about my wellness program appointments (reminders, recommendations)
                        </label>
                      </p>
                      <p hidden>
                        <label className="checkbox" style={{ marginLeft: '20px' }}>
                          <input type="checkbox" name="isOptedIntoFeedbackNotifications" checked={this.state.isOptedIntoFeedbackNotifications} onChange={this.onCheckBoxChanged} /> have the opportunity to rate my satisfaction with my wellness program appointment
                        </label>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>Please check the following box.</p>
                      <p>
                        <label className="checkbox" style={{ marginLeft: '20px' }}>
                          <input type="checkbox" name="hasAgreedToTermsAndConditions" checked={this.state.hasAgreedToTermsAndConditions} onChange={this.onCheckBoxChanged} /> I agree to the <a href='/Content/PDF/SMS-TermsOfService.pdf' target="_blank" rel="noopener noreferrer">Terms and Conditions</a> of the Peak Health Texting Program.  Message and data rates may apply. Message frequency varies. Reply Help for Help.
                        </label>
                      </p>
                    </Col>
                  </Row>
                  <Row className="expand-md mb-2">
                    <Col sm={{ size: 4 }}>
                      <ButtonGroup>
                        <Button color="primary" disabled={!this.state.canSubmit}>Submit</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
              </fieldset>
            </form>
          </CardBody>
        </Card>
      </Container>
    );
  };
}