import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, CardHeader, Card, CardBody, Modal, ModalHeader, ModalBody, Alert, Input, Form} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DebounceInput } from 'react-debounce-input';
import helpers from '../utils/helpers';
import api from '../utils/api';
import { Pager } from './';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const emptyLocation = {
  id: null,
  name: '',
  address1: '',
  city: '',
  stateAbbr: null,
  zip: '',
  companyId: null,
  timezone: 'Eastern Standard Time',
  restrictByUserList: false
};

const anyCompanyEntry = {label: "(Any)", value: -1};
const EST = {label: "Eastern Standard Time", value: "Eastern Standard Time"};

class EditLocation extends Component {
constructor(props) {
  super(props);
  let location = Object.assign({}, this.props.location);
  location.state = location.stateAbbr
    ? _.find(props.stateList, s => {return s.value === location.stateAbbr; })
    : null;
  location.company = location.companyId 
    ? helpers.resolveValue(null, location.companyId, location.companyName)
    : anyCompanyEntry;
  location.timezone = location.timezone
    ? helpers.resolveValue(null, location.timezone, location.timezone)
    : EST;
  this.state = {
    message: null,
    messageFlavor: null,
    location: location,
    userList: [],
    currentCompanyUser: null,
    timeZoneList: helpers.timeZoneList
  };
  this.handleSubmit = this.handleSubmit.bind(this);
  this.onTextChange = this.onTextChange.bind(this);
  this.onBoolChange = this.onBoolChange.bind(this);
  this.onTimeZoneChange = this.onTimeZoneChange.bind(this);
  this.onCompanyChange = this.onCompanyChange.bind(this);
  this.onStateChange = this.onStateChange.bind(this);
  this.onDeleteLocationUser = this.onDeleteLocationUser.bind(this);
  this.onAddLocationUser = this.onAddLocationUser.bind(this);
  this.onChangeCompanyUser = this.onChangeCompanyUser.bind(this);
  this.onFindCompanyUsers = this.onFindCompanyUsers.bind(this);
}

componentDidMount() {
  if (this.state.location.restrictByUserList && this.state.location.id) {
    api.fetch('Reference/GetLocationUsers/' + this.state.location.id).then(r => { 
      let list = helpers.idNameToValueLabel(r.data);
      this.setState({userList: list});
    }).catch(helpers.catchHandler);
  }
}

onTextChange(event) {
  this.setState({location : {...this.state.location, [event.target.name]: event.target.value }});
}

onBoolChange(event) {
  this.setState({location : {...this.state.location, [event.target.name]: !this.state.location[event.target.name] }});
}

onTimeZoneChange(selection) {
  this.setState({
    location: {
      ...this.state.location,
      timezone: selection
    }
  });
}

onCompanyChange(selection) {
  this.setState({
    location: {
      ...this.state.location,
      company: selection,
      companyId: selection.value
    }
  });
}

onStateChange(selection) {
  this.setState({
    location: {
      ...this.state.location,
      state: selection
    }
  });
}

isFormValid(){
  let warnings =[];
  if(!this.state.location.name || this.state.location.name.length < 0){
    warnings.push("Please provide a valid name")
  }
  if(!this.state.location.address1 || this.state.location.address1.length < 0){
    warnings.push("Please provide a valid address")
  }
  if(!this.state.location.city){
    warnings.push("Please provide a valid city")
  }
  if(!this.state.location.zip){
    warnings.push("Please provide a valid zip")
  }
  if(!this.state.location.timezone) {
    warnings.push("Select a valid time zone");
  }
  if(!this.state.location.company) {
    warnings.push("Select a valid company restriction entry");
  }
  if(!this.state.location.state) {
    warnings.push("Please select a valid state");
  }
  if (warnings.length) {
    this.setState({ message: warnings.join(','), messageFlavor:"danger" });
  } else {
    this.setState({ message: null});
  }
  return warnings.length === 0;
}

onChangeCompanyUser(selection) {
  this.setState({currentCompanyUser: selection});
}

onLocationUserInputChange(newValue) {
  const inputValue = newValue.replace(/\W/g, '');
  // this.setState({ inputValue });
  return inputValue;
}

onFindCompanyUsers(inputValue, callback) { 
  if (!inputValue || !inputValue.length || inputValue.length < 3) return;
  api.post("Admin/FindCompanyUsers", {term: inputValue, companyId: this.state.location.companyId}).then(response => {
      let list = helpers.idNameToValueLabel(response.data);
      this.setState({ companyUserList: list});
      callback(list);
    }).catch(helpers.catchHandler);
}

onDeleteLocationUser(userId) {
  if (this.state.location.id > 0) {
  // if we've been saved then remove it from the server and from the list
    api.post(`Admin/DeleteLocationUser/${this.state.location.id}/${userId}`, {}).then(r => {
      if (r.data.success === false) {
        this.setState({message: r.data.message, messageFlavor: "danger"});
      } else {
        let newList = _.reject(this.state.userList, u => { return u.value === userId; });
        this.setState({userList: newList});
      }
    }).catch(helpers.catchHandler);
  } else {
  // if we're unsaved then just remove it from the list
    let newList = _.reject(this.state.userList, u => { return u.value === userId; });
    this.setState({userList: newList});
  }
}

onAddLocationUser(userId) {
  if (this.state.location.id > 0) {
  // if we've been saved then add it to the server and to the list
    api.put('Admin/LocationUser', {locationId: this.state.location.id, userId: this.state.currentCompanyUser.value }).then(r => {
      if (r.data.success === false) {
        this.setState({message: r.data.message, messageFlavor: "danger"});
      } else {
        let newList = this.state.userList.slice();
        newList.push(this.state.currentCompanyUser);
        this.setState({currentCompanyUser: null, userList: newList});
      }
    }).catch(helpers.catchHandler);
  } else {
    // if we're unsaved then just add it to the list
    let newList = this.state.userList.slice();
    newList.push(this.state.currentCompanyUser);
    this.setState({currentCompanyUser: null, userList: newList});
  }
}

handleSubmit(event) {
  event.preventDefault();
  if (!this.isFormValid()) return;
  let payload = {
    name: this.state.location.name,
    address1: this.state.location.address1,
    city: this.state.location.city,
    zip: this.state.location.zip,
    isConference: this.state.location.isConference,
    companyId: this.state.location.company.value === anyCompanyEntry.value
      ? null
      : this.state.location.company.value,
    companyName: '',
    restrictByUserList: this.state.location.restrictByUserList,
    active: true,
    LocationUserIds: this.state.location.restrictByUserList
      ? _.map(this.state.userList, u => {return u.value;})
      : [],
    timezone: this.state.location.timezone.value,
    stateAbbr: this.state.location.state.value
  };
  if (!payload.id) {
    payload.id = 0;
  }
  api.post('Admin/SaveLocation', payload).then(r => {
    if (r.data.success === false) {
      this.setState({message: r.data.message, messageFlavor: "danger"});
    } else {
      this.props.onSuccess(this.state.location);
    }
  }).catch(helpers.catchHandler);
}

  render() {
    const loc = this.state.location;
    return (
      <div>
        <Modal isOpen={true} toggle={this.props.onCancel} backdrop={true} className="modal-lg">
          <ModalHeader>{this.state.location.id ? 'Edit' : 'Add'} Location</ModalHeader>
          <ModalBody>
          {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>) }
            <Row>
              <Col>
                <Form onSubmit={this.handleSubmit}>
                  <Row className="mb-2">
                    <Col>Name
                      <Input type="text" name="name" id="name"
                        onChange={this.onTextChange}
                        value={loc.name}
                        maxLength="250" />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>Address
                      <Input type="text" name="address1" id="address1"
                        onChange={this.onTextChange}
                        value={loc.address1} 
                        maxLength="250" />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>City
                      <Input type="text" name="city" id="city"
                        onChange={this.onTextChange}
                        value={loc.city} 
                        maxLength="50" />
                    </Col>
                    <Col sm="4">State
                      <Select
                        closeMenuOnSelect
                        value={loc.state}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.props.stateList}
                        onChange={this.onStateChange}
                      />
                    </Col>
                    <Col sm="3">Zip
                      <Input type="text" name="zip" id="zip"
                        onChange={this.onTextChange}
                        value={loc.zip} 
                        maxLength="20" />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>Time zone
                      <Select
                        closeMenuOnSelect
                        value={loc.timezone}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.state.timeZoneList}
                        onChange={this.onTimeZoneChange}
                      />
                    </Col>
                    <Col>Restrict to Company
                      <Select
                        closeMenuOnSelect
                        value={loc.company}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.props.companyList}
                        onChange={this.onCompanyChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                  <Col>
<label className="checkbox">
  <input type="checkbox" name="isConference" onChange={this.onBoolChange} checked={loc.isConference} /> Conference Location
</label>
                  </Col>
                  {loc.company && loc.companyId > 0
                    ? (<Col>
<label className="checkbox">
  <input type="checkbox" name="restrictByUserList" onChange={this.onBoolChange} checked={loc.restrictByUserList} /> Restrict to User List
</label>
                      </Col>)
                    : null
                  }
                  </Row>
                  {loc.restrictByUserList
                    ? (<React.Fragment>
                        <Row className="pt-2">
                          <Col sm="8">
                            <AsyncSelect id="add-location-user-select"
                              placeholder="Search Company Users"
                              loadOptions={_.debounce(this.onFindCompanyUsers, 500)}
                              options={this.state.companyUserList}
                              onInputChange={this.onLocationUserInputChange}
                              value={this.state.currentCompanyUser}
                              onChange={this.onChangeCompanyUser}
                            /> 
                          </Col>
                          <Col>
                          {this.state.currentCompanyUser
                            ? (<Button color="success" onClick={() => this.onAddLocationUser()}>
                                <FontAwesomeIcon icon="plus-circle"/>
                              </Button>)
                            : null
                          }
                          </Col>
                        </Row>
                        <Row style={{width: "95%", lineHeight: "30px"}}>
                          <Col>
                            <div id="user-list-overflow">
                            {this.state.userList.map(u => (
                              <Row key={`cu-${u.value}`}>
                                <Col sm="10">{u.label}</Col>
                                <Col>
                                  <Button color="danger" size="sm" className="xs" onClick={() => this.onDeleteLocationUser(u.value)}>
                                    <FontAwesomeIcon icon='times-circle' />
                                  </Button>
                                </Col>
                              </Row>
                              ))}
                            </div>
                          </Col>
                        </Row>
                       </React.Fragment>)
                    : null
                  }
                  <Row className="expand-md">
                    <Col className="text-right">
                      <ButtonGroup>
                        <input type="submit" value="Save" className="btn btn-primary" />
                        <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export class Locations extends Component {
constructor(props) {
  super(props);
  this.state = {
    message: null,
    messageFlavor: null,
    editLocation: null,
    paged_list: null,
    searchString: "",
    companyList: [],
    stateList: []
  };
  this.refreshList = this.refreshList.bind(this);
  this.reloadList = this.reloadList.bind(this);
  this.onCancel = this.onCancel.bind(this);
  this.locationAdd = this.locationAdd.bind(this);  
  this.onEditLocation = this.onEditLocation.bind(this);
  this.onDeleteLocation = this.onDeleteLocation.bind(this);
  this.onReviveLocation = this.onReviveLocation.bind(this);
}

componentDidMount() {
  const getCompanies = api.fetch('Reference/Companies')
    .then(r => { 
      let list = helpers.idNameToValueLabel(r.data);
      list.unshift(anyCompanyEntry);
      return { companyList: list }; 
    }).catch(helpers.catchHandler);
  const getStateList = api.fetch('Reference/StateList')
    .then(r => { 
      return { 
        stateList: _.map(r.data, x => { return {label: x.name, value: x.abbr}; })
      }; 
    }).catch(helpers.catchHandler);
  Promise.all([getCompanies, getStateList, this.refreshList()]).then(results => {
    let newStatus = {};
    _.each(results, r => Object.assign(newStatus, r));
    this.setState(newStatus);
  }).catch(helpers.catchHandler);
}

locationAdd() {
  this.setState({ editLocation: emptyLocation });
}

onEditLocation(location){
  this.setState({ editLocation: location});
}

onCancel() {
  this.setState({ editLocation: null });
}

reloadList(pageNo) {
  this.refreshList(pageNo).then(chunkOfState => {
    this.setState(chunkOfState);
  }).catch(helpers.catchHandler);
}

onDeleteLocation(locationId) {
  api.post(`Admin/DeleteLocation/${locationId}`, {}).then(r => { 
    if (r.data.success === false) {
      this.setState({message: r.data.message, messageFlavor: "danger"});
    } else {
      this.reloadList();
    }
  }).catch(helpers.catchHandler);
}

onReviveLocation(locationId) {
  api.post(`Admin/ReviveLocation/${locationId}`, {}).then(r => { 
    if (r.data.success === false) {
      this.setState({message: r.data.message, messageFlavor: "danger"});
    } else {
      this.reloadList();
    }
  }).catch(helpers.catchHandler);
}

refreshList(pageNo) {
  return api.post('Reference/Locations', { SearchString: this.state.searchString, PageNo: (pageNo || 1) }).then(r => {
    const result = {
      total_pages: r.data.totalPages,
      page_number: r.data.pageNo,
      list: r.data.list
    };
    return {paged_list: result, editLocation: null };
  }).catch(helpers.catchHandler);
}

render() {                                                  
  return (
  <Card className="m-2">
    <CardHeader className="strong-header">
      <Button className="float-right" color="success" onClick={this.locationAdd}>
        <FontAwesomeIcon icon="plus-circle"/> Location
      </Button>
      Locations
    </CardHeader>
    <CardBody className="pt-0">
      <Row className="row-header expand-md mt-2 mb-2 pb-0">
        <Col sm="9">
          <DebounceInput
            minLength={2}
            maxLength="250"
            name="searchString" id="searchString" 
            placeholder="Search City, State Name"
            value={this.state.searchString}
            debounceTimeout={500}
            onChange={(event) => this.setState({searchString : event.target.value}, this.reloadList)}
          />
        </Col>
      </Row>
      <Row className="row-header expand-md mt-2 mb-2 pb-0">
        <Col sm="4">Name</Col>
        <Col sm="3">City</Col>
        <Col sm="1">State</Col>
        <Col sm="2">Restrict To...</Col>
        <Col sm="2">
        </Col>
      </Row>
    {this.state.paged_list && this.state.paged_list.list && this.state.paged_list.list.map(loc => (
      <Row key={loc.id} className="expand-md mt-2 mb-2 pb-0">
        <Col sm="4">{loc.name}</Col>
        <Col sm="3">{loc.city}<br/>
          <span className="dim-text">{loc.timezone}</span>
        </Col>
        <Col sm="1">{loc.stateAbbr}</Col>
        <Col sm="2">{loc.companyName} {loc.restrictByUserList ? <span className="text-danger" title="Restricted to a specific list of users"><FontAwesomeIcon icon='ban' /></span> : null}
        </Col>
        <Col sm="2">
          <ButtonGroup className="float-right">
            <Button color="dark" onClick={() => this.onEditLocation(loc)} size="sm">
              <FontAwesomeIcon icon='edit' /> Edit
            </Button>
            {loc.active
              ? (<Button color="danger" size="sm" onClick={() => this.onDeleteLocation(loc.id)}>
                  <FontAwesomeIcon icon='times-circle' />
                </Button>)
              : (<Button color="info" size="sm" onClick={() => this.onReviveLocation(loc.id)}>
                  <FontAwesomeIcon icon='recycle' /> Revive
                </Button>)
            }             
          </ButtonGroup>
        </Col>
      </Row>
    ))}
    {this.state.paged_list
      ? (<Pager 
          page_number={this.state.paged_list.page_number} 
          total_pages={this.state.paged_list.total_pages} 
          callBack={(pageNumber) => this.reloadList(pageNumber)} 
        />)
      : null
    }
    </CardBody>
{this.state.editLocation === null ? null :
  <EditLocation 
    onSuccess={() => {
      this.setState({editLocation: null});
      this.reloadList();
    }} 
    onCancel={this.onCancel} 
    companyList={this.state.companyList}
    stateList={this.state.stateList}
    location={this.state.editLocation}
  />
}
  </Card>);
  }
}
