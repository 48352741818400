import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Container, Col, Row, Input, InputGroup, InputGroupText, InputGroupAddon, Alert } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import api from '../utils/api';
import helpers from '../utils/helpers';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

const promptStyle = {width: "160px"};

export class SetChallengeQA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: null,
      answer: "",
      confirmAnswer: "",
      questionList: [],
      message: null,
      messageFlavor: null,
      redirectToHome: false
    };    
    // this.closeModal = this.closeModal.bind(this);
    // this.changeBackdrop = this.changeBackdrop.bind(this);
    this.onSelectQuestion = this.onSelectQuestion.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    api.fetch('Reference/ChallengeQuestions').then(r => {
      const questionList = r.data.map(x => { return {label: x.name, value: x.id}; });
      let newState = {questionList: questionList };
      if (this.props.currentUser && this.props.currentUser.challengeQuestionId) {
        newState.question = _.find(questionList, q => { return q.value === this.props.currentUser.challengeQuestionId; });
      }
      this.setState(newState);
    }).catch(helpers.catchHandler);
  }

  onChangeHandler(event) {
    this.setState(Object.assign({}, this.state, { [event.target.name]: event.target.value }));
  }

  onSelectQuestion(selection) {
    this.setState({ question: selection });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.question === null) {
      this.setState({messageFlavor: "danger", message: "Select a challenge question in order to continue."});
      return;
    }
    if (this.state.answer.trim() === "") {
      this.setState({messageFlavor: "danger", message: "Provide an answer to the selected challege question."});
      return;
    }
    if (this.state.confirmAnswer !== this.state.answer) {
      this.setState({messageFlavor: "danger", message: "Your answer confirmation does not match your answer."});
      return;
    }
    const data = {
      questionId: this.state.question.value,
      answer: this.state.answer
    };
    api.post('Home/SetChallengeAnswer', data).then(r => {
      if (r.data.success === false) {
        this.setState({messageFlavor: "danger", message: r.data.message});
      } else {
        if (this.props.redirectOnSuccess) {
          this.setState({messageFlavor: "success", message: "Your challenge response has been saved and you'll be redirected momentarily.", answer: "", confirmAnswer: ""})
          setTimeout(() => {
            this.props.setChallengeQuestion(this.state.question.value, this.state.question.label);
            this.setState({redirectToHome: true});
          }, 4000);
        } else {
          this.setState({messageFlavor: "success", message: "Your challenge question and response have been updated.", answer: "", confirmAnswer: ""})
          setTimeout(() => {
            this.props.setChallengeQuestion(this.state.question.value, this.state.question.label);
            this.setState({ message: null });
          }, 4000);
        }
      }
    }).catch(helpers.catchHandler);
  }

  render() {
    if (this.state.redirectToHome) {
      return (<Redirect to='/home' />);
    }
    return (
  <Container>
    <Row>
      <Col>
        {this.state.message !== null
          ? <Alert className="ml-3" color={this.state.messageFlavor}>{this.state.message}</Alert>
          : null
        }
          <Row className="mb-2">
            <Col sm="12">
              <p>It is necessary for you to provide a personal answer that we can later use to verify your identity in order to provide enhanced protection of your personal information.
              </p>
              <p>This information is indecipherable, even to Peak, and will only be used to verify
                your identity when you have forgotten your normal password.
              </p>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={promptStyle}>Question</InputGroupText>
                </InputGroupAddon>
                <div className="form-control" style={{padding: 0, borderStyle: "none"}}>
                  <Select
                    closeMenuOnSelect={true}
                    isMulti={false}
                    components={makeAnimated()}
                    options={this.state.questionList}
                    onChange={this.onSelectQuestion}
                    value={this.state.question}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                    onCloseResetsInput={false}
                    classNamePrefix="react-select"
                  />
                </div>
              </InputGroup>            
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm="12">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={promptStyle}>Answer</InputGroupText>
                </InputGroupAddon>
                <Input type="text"
                  name="answer"
                  id="answer"
                  onChange={this.onChangeHandler}
                  value={this.state.answer}
                  placeholder="Answer"
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm="12">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={promptStyle}>Answer (again)</InputGroupText>
                </InputGroupAddon>
                <Input type="text"
                  name="confirmAnswer"
                  id="confirmAnswer"
                  onChange={this.onChangeHandler}
                  value={this.state.confirmAnswer}
                  placeholder="Confirm your answer"
                />
              </InputGroup>            
            </Col>
          </Row>
          <Row className="expand-md">
            <Col className="text-right">
              <Button color="primary" onClick={this.handleSubmit}>
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
</Container>
    );
  }
}
