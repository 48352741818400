import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { TemplateCollapsibleCard } from './';

export class WidgetHealthUpdate extends Component {
  displayName = "Health Update";
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
        <img src='/Content/panels/contact.png' alt='Program Acknowledgement' style={{float: "left", marginRight: "8px"}}/>
        <p>
            If you are new to the program you must <Link to='/survey'>click here to complete</Link> before you are considered enrolled.
        </p>
        <div className='mb-5'>
        {this.props.completedHealthUpdate
          ? (<Fragment>
              <img src='/Content/panels/green-check.jpg' alt='Completed Program Acknowledgement' style={{float: "left", marginRight: "8px", width: "20px"}}/>
              <p>Your program acknowledgement is complete.</p>
            </Fragment>)
          : (<Fragment>
              <img src='/Content/panels/red-excl.png' alt='Missing Health Update' style={{float: "left", marginRight: "8px", width: "20px"}}/>
              <p style={{marginTop: "20px"}}>Your Program Acknowledgement is not yet complete.  Click <Link to='/survey' style={{fontWeight: "bold", fontSize: "14pt"}}>START</Link> to complete it now.</p>
            </Fragment>)
        }
        </div>
      </TemplateCollapsibleCard>
    );
  }
}