import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Card, CardBody, Col, Row, Alert} from 'reactstrap';
import api from '../utils/api';
import helpers from '../utils/helpers';

export class ImpersonateAssignedUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      username: this.props.location.state.username,
      redirectToHome: false,
    };
  }

  componentDidMount() {
    api.post('Admin/Impersonate', { userName: this.state.username }).then(response => {
      if (response.data.success === false) {
        this.setState({ messageFlavor: "danger", message: response.data.message });
      } else {
        this.props.setImpersonation(response.data.user, response.data.user.token, response.data.nextAppt);
        this.setState({ messageFlavor: "success", message: "You are now " + response.data.user.firstName + " " + response.data.user.lastName + " and will be redirected momentarily!" });
        setTimeout(function(component) {
          component.setState({redirectToHome: true});
        }, 3000, this);
      }
    }).catch(helpers.catchHandler);
  }

  render() {
    if (this.state.redirectToHome) {
      return (<Redirect to="/home" />);
    }
    return (
      <Container fluid className="mt-2">
        <Row>
          <Col>
            <h3 className="page-title">Impersonate</h3>
            <Card>
              <CardBody>
                <Row className="mt-3">
                  <Col sm={{ size: 6, offset: 3 }}>
                        {this.state.message === null
                            ? null
                            : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)
                        }
                    </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
