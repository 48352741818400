import React, { Component } from "react";
import { Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class ToggleMetricsChartButton extends Component {
  render() {
    const { toggleFunction, showChart, icon, rotate, flip } = this.props;

    return (
      <Row
        onClick={toggleFunction}
        className="align-content-center align-items-center justify-content-end"
      >
        <div className="mr-2 mb-1" style={{ fontSize: "12px" }}>
          {showChart ? "hide" : "show"}
        </div>
        <FontAwesomeIcon
          icon={icon}
          style={{
            color: showChart ? "black" : "grey",
            transform: `${rotate ? "rotate(90deg)" : ""}${
              flip ? "scaleX(-1)" : ""
            } ${showChart ? "scaleX(-1)" : "scaleX(1)"}`,
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Row>
    );
  }
}
