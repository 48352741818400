import React, { Component } from 'react';
import { Button } from 'reactstrap';
import api from '../utils/api';
import { TemplateCollapsibleCard } from './';

export class WidgetProgressReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  onDownload(e) {
    e.preventDefault();
    api.downloadPDF('Home/RenderProgressReport');
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
        <img src='/Content/Images/award-ribbon.png' alt='Goals' style={{float:"left", marginRight: "8px", width: "100px"}}/>
        <p style={{marginTop: "20px"}}>
          <span>Click <Button className="pt-0" color="light" style={{padding: "4px", fontSize:"14pt", fontWeight: "bold"}} onClick={this.onDownload}>here</Button> to download your progress report.</span>
        </p>
      </TemplateCollapsibleCard>
    );
  }
}