import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col, UncontrolledAlert } from 'reactstrap';
import classnames from 'classnames';
import { UserAdmin, Panel, Companies, Locations, LabLocationPDF, LocalImages } from '../components';
import { Company } from './Company';
// import _ from 'lodash';

const TABS = {
  USERS: "1",
  COMPANIES: "2",
  PANELS: "3",
  LOCATIONS: "4",
  LAB_LOCATION_PDF: "5",
  Images: "6",
};

export class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: TABS.USERS,
      activatePageEdit: false,
      activeCompany: null,
      ssoTypeList: [],
      surveyTypeList: [],
      message: null,
      messageFlavor: null,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className="m-2">
        {this.state.message === null ? null : (
          <UncontrolledAlert color={this.state.messageFlavor}>
            {this.state.message}
          </UncontrolledAlert>
        )}
        <Card className="p-2">
          <Row className="d-flex flex-row mt-2">
            <Col sm="2">
              <Nav tabs className="flex-column nav-pills">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === TABS.USERS,
                    })}
                    onClick={() => {
                      this.toggle(TABS.USERS);
                    }}
                  >
                    Users
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === TABS.COMPANIES,
                    })}
                    onClick={() => {
                      this.toggle(TABS.COMPANIES);
                    }}
                  >
                    Companies
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === TABS.PANELS,
                    })}
                    onClick={() => {
                      this.toggle(TABS.PANELS);
                    }}
                  >
                    Panels
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === TABS.LOCATIONS,
                    })}
                    onClick={() => {
                      this.toggle(TABS.LOCATIONS);
                    }}
                  >
                    Locations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === TABS.LAB_LOCATION_PDF,
                    })}
                    onClick={() => {
                      this.toggle(TABS.LAB_LOCATION_PDF);
                    }}
                  >
                    Lab Locations PDF
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === TABS.Images,
                    })}
                    onClick={() => {
                      this.toggle(TABS.Images);
                    }}
                  >
                    Upload Images
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col sm="10">
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId={TABS.USERS}>
                  <Row>
                    <Col>
                      <UserAdmin
                        currentUser={this.props.currentUser}
                        impersonating={this.props.impersonating}
                        setImpersonation={this.props.setImpersonation}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TABS.COMPANIES}>
                  <Row>
                    <Col>
                      {this.state.activatePageEdit ? (
                        <Company
                          company={this.state.activeCompany}
                          ssoTypeList={this.state.ssoTypeList}
                          surveyTypeList={this.state.surveyTypeList}
                          onExit={() => {
                            const newState = Object.assign(this.state);
                            newState.activatePageEdit =
                              !newState.activatePageEdit;
                            newState.activeCompany = null;
                            newState.ssoList = [];
                            newState.surveyTypeList = [];
                            this.setState(newState);
                          }}
                          onSuccess={(message, messageFlavor) => {
                            const newState = Object.assign(this.state);
                            newState.activatePageEdit =
                              !newState.activatePageEdit;
                            newState.activeCompany = null;
                            newState.ssoList = [];
                            newState.surveyTypeList = [];
                            newState.message = message;
                            newState.messageFlavor = messageFlavor;
                            this.setState(newState);
                          }}
                        />
                      ) : (
                        <Companies
                          setPageEdit={(companyObj, ssoList, surveyList) => {
                            const newState = Object.assign(this.state);
                            newState.ssoTypeList = ssoList;
                            newState.surveyTypeList = surveyList;
                            newState.activeCompany = companyObj;
                            newState.activatePageEdit =
                              !newState.activatePageEdit;
                            this.setState(newState);
                          }}
                        ></Companies>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TABS.PANELS}>
                  <Row>
                    <Col>
                      <Panel />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TABS.LOCATIONS}>
                  <Row>
                    <Col>
                      <Locations />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TABS.LAB_LOCATION_PDF}>
                  <Row>
                    <Col>
                      <LabLocationPDF />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TABS.Images}>
                  <Row>
                    <Col>
                      <LocalImages />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
