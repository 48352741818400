import api from './api';

export class NotificationFacade {

    constructor() {
        this.apiBaseUrl = "Notification"
    }

    GetUserConsentStatus(userId) {
        var url = this.apiBaseUrl + "/GetUserSmsConsentStatus?userId=" + userId;

        let result = {
            isOptedIntoReminderNotifications: false,
            isOptedIntoFeedbackNotifications: false,
            hasAgreedToTermsAndConditions: false,
            notificationsEnabled: false };

        var promise =  new Promise((resolve, reject) => {
            api
            .fetch(url)
            .then(r => {

            if ((r.isAxiosError || false)) {
                throw new Error(r);
            }

            if(r.data === null || r.data === '') {
                resolve(result)
                return;
            }

            result.isOptedIntoReminderNotifications = r.data.appointmentNotificationsEnabled;
            result.isOptedIntoFeedbackNotifications = r.data.feedbackNotificationsEnabled;
            result.hasAgreedToTermsAndConditions = r.data.agreedToTermsAndConditions;
            result.notificationsEnabled = r.data.notificationsEnabled;
            resolve(result);
            })
            .catch(e => {
                reject(e);
            });
        });

        return promise;
    }


  OptOutSMS(userId) {
    var url = this.apiBaseUrl + "/OptOutCommunicationType";

    let payload = {
      CommunicationTypeName: "SMS",
      UserId: userId
    };

    return this.PostToNotificationApi(url, payload);
  }

  OptIntoAppointmentReminders(payload) {
    var url = this.apiBaseUrl + "/OptIntoSmsAppointmentReminders";

    return new Promise((resolve, reject) => {
      api
      .post("Home/UpdateMobilePhone", payload)
      .then(r => {
          if(r.data.success === false) {
            resolve(new Promise((resolve, reject) => resolve({ success: false, msg: r.data.message })));
            return;
          }
         return resolve(this.PostToNotificationApi(url, payload));
      }).catch(e => {
        return resolve(new Promise((resolve, reject) => resolve({ success: false, msg: e.message })));
      })
    });
    }

    GetShortUrls() {
        var url = this.apiBaseUrl + "/GetShortUrls";

        var promise = new Promise((resolve, reject) => {
            api
                .fetch(url)
                .then(r => {
                    resolve(r.data);
                })
                .catch(e => {
                    reject(e);
                });
        });

        return promise;
    }

  PostToNotificationApi(url, payload) {
    let result = { success: false, msg: null };

    var promise =  new Promise((resolve, reject) =>
    {
      api
      .post(url, payload)
      .then(r => {
        if((r.isAxiosError || false)) {
          result.msg = r.message;
          resolve(result);
          return;
        }

        if(r.data.success === true) {
          result.success = true;
        } else {
          result.msg = r.data.message;
        }
        resolve(result);
      })
      .catch(e =>
      {
        result.msg = e.message;
        reject(result);
      });
    });

    return promise;
  }
}

