import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { TemplateCollapsibleCard } from './';

export class WidgetParticipantHTMLText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
        <div dangerouslySetInnerHTML={{__html: this.props.html}}></div>
      </TemplateCollapsibleCard>
    );
  }
}