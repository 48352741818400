import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import storage from "../utils/storageFactory.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../assets/img/PeakHealth_LOGO-HORZ.jpg";

export class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isImpersonating: props.impersonating,
    };
    this.logout = this.logout.bind(this);
    this.stopImpersonating = this.stopImpersonating.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const impersonationToken = storage.getItem("adminToken");
    if (impersonationToken && !this.props.impersonating) {
      this.setState({ isImpersonating: true });
    }
    if (!impersonationToken && this.props.impersonating) {
      this.setState({ isImpersonating: false });
    }
  }

  logout() {
    this.props.logout();
    return <Redirect to="/login" />;
  }

  stopImpersonating() {
    this.props.clearImpersonation();
  }

  render() {
    if (!this.props.currentUser) {
      return (
        <div id="navMenu" className="mx-0">
          <Navbar expand="md">
            <Nav className="ml-auto" navbar>
              <NavItem className="mr-3">
                <NavLink tag={Link} to="/login">
                  Login
                </NavLink>
              </NavItem>
            </Nav>
          </Navbar>
        </div>
      );
    }
    return (
      <div id="navMenu" className="mx-0 mainNavBar">
        <Navbar expand="md">
          <NavbarBrand tag={Link} to="/home" className="p-0 ml-3">
            <img
              id="navImg"
              width={"230px"}
              height={"50px"}
              src={Logo}
              alt="Peak Logo"
            />
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            {/*
            <NavItem className="mr-3">
              <a href="http://peakhealthservices.net/lets-go" target="_blank" className="nav-link" rel="noopener noreferrer">Contact Us</a>
            </NavItem>
          */}
            <NavItem className="mr-3">
              <NavLink href="/home">Home</NavLink>
            </NavItem>
            {this.props.currentUser.isAdmin ||
            this.props.currentUser.isCompanyAdmin ? (
              <NavItem className="mr-3">
                <NavLink href="/metrics-dashboard">Metrics Dashboard</NavLink>
              </NavItem>
            ) : null}
            <NavItem className="mr-3">
              <NavLink href="/account">My Account</NavLink>
            </NavItem>
            {this.props.currentUser.isAdmin ? (
              <NavItem className="mr-3">
                <NavLink href="/admin">Administration</NavLink>
              </NavItem>
            ) : null}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <FontAwesomeIcon icon="user" />{" "}
                {this.props.currentUser.firstName}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={Link} to="/profile">
                  Profile
                </DropdownItem>
                <DropdownItem tag={Link} to="/user-security">
                  Account Security
                </DropdownItem>
                {this.props.currentUser.isAdmin || this.props.impersonating ? (
                  <React.Fragment>
                    <DropdownItem divider />
                    {this.props.impersonating ? (
                      <DropdownItem onClick={this.stopImpersonating}>
                        Stop Impersonating
                      </DropdownItem>
                    ) : (
                      <DropdownItem tag={Link} to="/impersonate">
                        Impersonate
                      </DropdownItem>
                    )}
                  </React.Fragment>
                ) : null}
                <DropdownItem divider />
                <DropdownItem onClick={this.logout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>
      </div>
    );
  }
}
