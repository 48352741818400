import React, { Component } from 'react';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane, Col, Row, Card, CardBody, Form, Alert, Button, Label, Input} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import api from '../utils/api';
import helpers from '../utils/helpers';
import classnames from 'classnames';
// import _ from 'lodash';

const TABS = {
  EMAIL: "1",
  CHALLENGE: "2"
};

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageFlavor: null,
      activeTab: TABS.EMAIL,
      redirect: false,
      // email: props.location && props.location.state ? props.location.state.username : "",
      email: props.username ? props.username : "",
      questionText: null,
      answer: ""
    };
    this.handleEmail = this.handleEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.GetQuestion = this.GetQuestion.bind(this);
    this.toggle = this.toggle.bind(this);
    // console.log('props are ', props)
    // console.log('setCurrentUser is ', this.state.setCurrentUser)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  isFormValid(){
    let warnings = [];
    if (!this.state.email){
      warnings.push("Email address required");
    }
    if (warnings.length) {
      this.setState({
        message: warnings.join(','), 
        messageFlavor: "danger" 
      });
    }
    return warnings.length === 0;
  }

  handleSubmit() {
    if(!this.isFormValid()) return;
    api.post('Public/ForgotPassword', {Username: this.state.email})
      .then ((response) => {
        if (response.data.success === false) {
          this.setState({message: response.data.message, messageFlavor: "danger"});
        } else {
          this.setState({messageFlavor: "success", message: "If we have a username with that address, we've just sent a reset link!"})
          setTimeout(() => {
            this.setState({ redirect: true});
          }, 5000);
        }
      }).catch(helpers.catchHandler);
  }

  handleAnswer(event) {
    this.setState({ answer: event.target.value });
  }

  GetQuestion() {
    api.post('Public/GetQuestion', {Username: this.state.email}).then(r => {
      if (r.data.success === false) {
        this.setState({message: r.data.message, messageFlavor: "danger"});
      } else {
        this.setState({questionText: r.data.questionText});
      }
    }).catch(helpers.catchHandler);
  }

  render(){
      if (this.state.redirect){
        return <Redirect to='/home' />;
      }
      return (
      <Container fluid>
        <Row>
          <Col>
            <h3>Forgot Password</h3>
            {this.state.message !== null
              ? <Alert color={this.state.messageFlavor}>{this.state.message}</Alert>
              : null
            }
            <div>
              <Link to='/login'>Wait, I remembered my password  - take me to log in!</Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === TABS.EMAIL })}
                  onClick={() => { this.toggle(TABS.EMAIL); }}
                >
                Email Me
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabContent activeTab={this.state.activeTab} className="mt-2">
              <TabPane tabId={TABS.EMAIL}>
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col sm={{size: 4, offset: 4}}>
                        <Card>
                          <CardBody>
                            <div>
                              <Form>
                                <Row className="mt-2 mb-2">
                                  <Col>
                                    <Label className="Label">Enter your username and we will send you a reset link.</Label>
                                    <Input type="text"
                                      value={this.state.email}
                                      onChange={this.handleEmail}
                                      placeholder="Username"
                                    />
                                  </Col>
                                </Row>
                                <Button color="primary" onClick={this.handleSubmit} className="float-right">
                                  Send Me A Reset Password Email
                                </Button>
                              </Form>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
      );
    }
}