import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { AccountSummary, TemplateCollapsibleCard } from './';

export class WidgetAccountSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass={'widgetCard'}
      >
        <AccountSummary summaryData={this.props.summaryData} />
      </TemplateCollapsibleCard>
    );
  }
}