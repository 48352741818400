import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';

export class HealthScreenings extends Component {
  displayName = "Health Screenings";
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }
  

  render() {
    return (
      <Row className="my-0">
        <Col sm="12">
          <Table size="sm">
            <thead>
              <th width="40%" className="healthScreenings topRow">Type</th>
              <th className="healthScreenings topRow">Completed Date</th>
              <th className="healthScreenings topRow">Due Date</th>
            </thead>
            <tbody>
              <tr>
                <td width="40%" className="healthScreenings">
                  Pap
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Mammogram
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Prostate PSA
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Digital Rect
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Colon Screening
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Physical Exam
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Bone Density
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Lab
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Dental
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
              <tr>
                <td width="40%" className="healthScreenings">
                  Eye
                </td>
                <td className="healthScreenings">
                  item.completedDate
                </td>
                <td className="healthScreenings">
                  item.dueDate
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}