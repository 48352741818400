import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";
import Sidebar from "react-sidebar";
import _ from "lodash";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaBars } from "react-icons/fa";
// import { faHome, faUserCog } from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink as ReactStrapNavLink,
} from "reactstrap";

// const homeIcon = (
//   <FontAwesomeIcon
//     icon={faHome}
//     className="leftMenuButtons align-self-center"
//   />
// );
// const adminIcon = (
//   <FontAwesomeIcon
//     icon={faUserCog}
//     className="leftMenuButtons align-self-center"
//   />
// );
// const AdminMenuLinks = [
//   {
//     url: "/admin/users",
//     title: "User Administration",
//     //   , icon: adminIcon
//   },
// ];

export default function SidebarMenu(props) {
  // const [homeLink, setHomeLink] = useState("/dashboard/hauler");
  const [links, setLinks] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(props.showOpen ? true : false);
  let location = useLocation();

  useEffect(() => {
    let userLinks = [
      { url: "/home", title: "Account Overview" }      
    ];
    if (props.currentUser && !props.currentUser.hasNoSurvey) {
      userLinks.push({ url: "/survey", title: "Program Acknowledgement" });
    }
    userLinks.push({ url: "/progress-goals", title: "My Progress/Goals" });
    userLinks.push({ url: "/visit", title: "Visit History" });
    userLinks.push({ url: "https://peakhealthservices.net/lets-go", title: "Contact Support", });
    // let homeLinkRoute = "/query";
    // userLinks = userLinks.concat(AdminMenuLinks);
    // setHomeLink(homeLinkRoute);
    setLinks(userLinks);
  }, [props.currentUser]);

  useEffect(() => {
    setSidebarOpen(props.showOpen ? true : false);
  }, [props.showOpen]);

  function toggleSidebar(openOrClose) {
    setSidebarOpen(openOrClose);
  }

  // only used to close side bar from open sidebar Menu link
  const linkItem = (link, index) => {
    if (link.url) {
      return (
        <Row key={`${sidebarOpen ? "open" : "closed"} Link ${index}`}>
          <Col>
            <NavItem
              key={`${sidebarOpen ? "open" : "closed"} Link ${index}`}
              title={link.title}
            >
              {link.title === "Contact Support" ? (
                <a href="https://peakhealthservices.net/lets-go">
                  <Row className="mx-0 my-1 p-0 align-items-center justify-content-center menuButtonColor">
                    <Col xs={sidebarOpen ? "3" : "12"} className="p-0 m-0">
                      {link.icon}
                    </Col>
                    {sidebarOpen && (
                      <Col
                        xs={link.icon ? "9" : "12"}
                        className="text-right linkText"
                      >
                        {link.title}
                      </Col>
                    )}
                  </Row>
                </a>
              ) : (
                <ReactStrapNavLink
                  tag={NavLink}
                  to={link.url}
                  active={location.pathname === link.url}
                  className="p-0"
                  onClick={() => toggleSidebar(!sidebarOpen)}
                >
                  <Row className="mx-0 my-1 p-0 align-items-center justify-content-center menuButtonColor">
                    <Col xs={sidebarOpen ? "3" : "12"} className="p-0 m-0">
                      {link.icon}
                    </Col>
                    {sidebarOpen && (
                      <Col
                        xs={link.icon ? "9" : "12"}
                        className="text-right linkText"
                      >
                        {link.title}
                      </Col>
                    )}
                  </Row>
                </ReactStrapNavLink>
              )}
            </NavItem>
          </Col>
        </Row>
      );
    }
  };
  const menuStyling = classnames(
    {
      stickyLeftMenuOpen: sidebarOpen,
      stickyLeftMenuCollapsed: !sidebarOpen,
    },
    "stickyLeftMenu p-0 m-0 py-2" // the back ground color of the side bar
  );

  let menu = (
    <div className={menuStyling}>
      <div className="topLeftMenuSection">
        <Row
          className="m-0 mb-2 p-0 align-items-center"
          onClick={() => toggleSidebar(!sidebarOpen)}
        >
          <Col xs={sidebarOpen ? "3" : "12"} className="p-0 m-0">
            <FaBars className="barsButton align-self-center menuButtonColor" />
          </Col>
        </Row>
        <Nav vertical={"true"}>
          {/* {linkItem({ url: homeLink, title: "Dashboard", icon: homeIcon }, -1)} */}
          {links && links.length ? (
            <Nav vertical={"true"}>
              {_.map(links, (l, index) => {
                return linkItem(l, index);
              })}
            </Nav>
          ) : null}
        </Nav>
      </div>
    </div>
  );

  return (
    <Sidebar
      sidebar={sidebarOpen && menu} // opened menu
      open={sidebarOpen}
      styles={{
        root: {
          position: "undefined",
        },
        sidebar: {
          position: "fixed",
          background: "white",
          zIndex: 2000,
          minWidth: 250,
        },
        content: {
          position: "undefined",
        },
      }}
      pullRight={false}
      onSetOpen={() => toggleSidebar(!sidebarOpen)} // this allows you to click outside of the side bar and close it
    >
      {!sidebarOpen && menu}
    </Sidebar>
  );
}
