import React, { Component } from "react";
import { HorizontalBar } from "react-chartjs-2";
import _ from "lodash";
import constants from "../utils/constants";
import helpers from "../utils/helpers";
export class AnnualEligibilityPeakStackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.calculatePercentage = this.calculatePercentage.bind(this);
    this.stackedBarChartData = this.stackedBarChartData.bind(this);
    this.stackedBarChartOptions = this.stackedBarChartOptions.bind(this);
  }

  calculatePercentage(dividend, divisor) {
    const percentAsDecimal = dividend / divisor;
    return percentAsDecimal.toFixed(2) * 100;
  }

  stackedBarChartData(enrollmentData) {
    const eligibleBackgroundColors = [
      constants.PEAK_COLORS.green,
      constants.PEAK_COLORS.blue,
    ];

    const eligible = _.map(enrollmentData, (item) => {
      return item.eligible;
    });

    const enrolled = _.map(enrollmentData, (item) => {
      return item.enrolled;
    });

    const labels = _.map(enrollmentData, (item) => {
      return item.reportingPeriod;
    });

    const eligibleDataLength = eligible.length;
    const repeatingEligibleBackgroundColors = Array(eligibleDataLength)
      .fill(eligibleBackgroundColors)
      .flat();

    const repeatingEligibleBorderColors =
      helpers.generateTableAndChartBorderColors(
        repeatingEligibleBackgroundColors
      );

    const stackedBarChartData = {
      labels: labels,
      datasets: [
        {
          label: "Enrolled",
          data: enrolled,
          backgroundColor: constants.PEAK_COLORS.purple,
          borderColor: helpers.generateTableAndChartBorderColorForOne(
            constants.PEAK_COLORS.purple
          ),
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
        },
        {
          label: "Eligible",
          data: eligible,
          backgroundColor: repeatingEligibleBackgroundColors,
          borderColor: repeatingEligibleBorderColors,
          borderWidth: constants.METRICS_DASHBOARD_CHART_DIMENSIONS.borderWidth,
        },
      ],
    };

    return stackedBarChartData;
  }

  stackedBarChartOptions() {
    const options = {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Eligible vs Enrolled Comparison",
        fontSize: 16,
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Enrolled/Eligible",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],

        yAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: "Years",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    return options;
  }

  render() {
    return (
      <>
        <div>
          <HorizontalBar
            data={this.stackedBarChartData(this.props.enrollmentData)}
            width={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.width}
            height={constants.METRICS_DASHBOARD_CHART_DIMENSIONS.height}
            options={this.stackedBarChartOptions()}
          />
        </div>
      </>
    );
  }
}
