import React, { Component } from "react";
import {
  CardTitle,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  Form,
  Col,
  Row,
  CardHeader,
  Input
} from "reactstrap";
import api from "../utils/api";
import helpers from "../utils/helpers";
import Select from "react-select";
import _ from "lodash";
import makeAnimated from "react-select/animated";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constants from "../utils/constants";

const getPropNameFromUploadType = (uploadTypeName) => {
  return (
    uploadTypeName.substring(0, 1).toLowerCase() +
    uploadTypeName.substring(1) +
    "Exists"
  );
};

export class Company extends Component {
  constructor(props) {
    super(props);
    let ssoTypeList = this.props.ssoTypeList;
    let surveyTypeList = this.props.surveyTypeList;
    let company = this.props.company;
    const List = helpers.idNameToValueLabelTemplates(company.emailTemplateList);

    if (!company.healthProgramName) company.healthProgramName = "";
    company.surveyType = helpers.resolveValue(
      null,
      company.typeOfSurvey,
      company.surveyTypeName
    );
    company.ssoType = helpers.resolveValue(
      null,
      company.typeOfSSO,
      company.ssoTypeName
    );
    this.state = {
      message: null,
      messageFlavor: null,
      uploadTypeList: [
        { label: "Lab Form", value: "LabForm" },
        { label: "Informed Consent Form", value: "InformedConsentForm" },
        { label: "Company Release Form", value: "CompanyReleaseForm" },
        { label: "Privacy Disclosure Form", value: "PrivacyDisclosureForm" },
      ],
      uploadType: { label: "Lab Form", value: "LabForm" },
      emailTemplate: null,
      company: company,
      ssoTypeList: ssoTypeList,
      surveyTypeList: surveyTypeList,
      showInput: false,
      emailTemplateTypeList: company.emailTemplateList.length > 0 ? 
      List :
      constants.Email_Template_IDs
      // [
      //   { Id: 1, Name: "", value: 1, label: "Create Appointment" },
      //   { Id: 2, Name: "", value: 2, label: "Resechedule Appointment" },
      //   { Id: 3, Name: "", value: 3, label: "Cancel Appointment" },
      //   { Id: 4, Name: "", value: 4, label: "Calendar Entry Notifications" },
      // ],
    };
    // this.sendE = this.sendE.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onEmailTextChange = this.onEmailTextChange.bind(this);
    this.onBoolChange = this.onBoolChange.bind(this);
    this.onSurveyChange = this.onSurveyChange.bind(this);
    this.onSSOChange = this.onSSOChange.bind(this);
    this.onPDFDrop = this.onPDFDrop.bind(this);
    this.onUploadTypeChange = this.onUploadTypeChange.bind(this);
    this.onEmailTypeChange = this.onEmailTypeChange.bind(this);
    this.placeHolderText = this.placeHolderText.bind(this);
    this.templatePrompt = this.templatePrompt.bind(this);
  }

  // sendE() {
  //   const payload = {
  //     Id: 3233495,
  //     ScheduledAt: currentdate,
  //     OverrodePhaseWarning: true

  //   }
  //   api.post("appt/schedule", payload)
  // }

  templatePrompt() {
    if(!this.state.emailTemplate?.value) return;
    switch (this.state.emailTemplate.value) {
    case 1:
      return (<div><span style={{fontWeight: "bold"}}>Create Appointment:</span> FIRST_NAME, LAST_NAME, SCHEDULED_AT</div>);
    case 2:
      return (<div><span style={{fontWeight: "bold"}}>Reschedule Appointment:</span> FIRST_NAME, LAST_NAME, SCHEDULED_AT, SCHEDULED_AT_NEW</div>);
    case 3:
      return (<div><span style={{fontWeight: "bold"}}>Cancel Appointment:</span> FIRST_NAME, LAST_NAME, SCHEDULED_AT</div>);
    case 4:
      return (<div><span style={{fontWeight: "bold"}}>CalendarEntryNotification:</span> FIRST_NAME, LAST_NAME, SCHEDULED_AT, SCHEDULED_AT_DATE, SCHEDULED_AT_TIME, EVALUATOR_FULL_NAME, LOCATION_NAME, APPT_PHONE, APPT_EMAIL</div>);
    default:
      return "";
    }
  }

  placeHolderText() {
    if(!this.state.emailTemplate?.value) return;
    switch (this.state.emailTemplate.value) {
    case 1:
      return "Default Template: Your appointment scheduled at SCHEDULED_AT has been completed.";
    case 2:
      return "Default Template: Your reschedule of an appointment originally scheduled at SCHEDULED_AT has been completed. Your new, confirmed appointment is at SCHEDULED_AT_NEW";
    case 3:
      return "Default Template: Cancellation of your appointment at SCHEDULED_AT has been completed.";
    case 4:
      return "Default Template: <p>You have a wellness appointment on SCHEDULED_AT_DATE at SCHEDULED_AT_TIME {0:hh:mm tt}.</p> <p>Your appointment is with EVALUATOR_FULL_NAME, LOCATION_NAME.</p> <p>If you have any questions regarding your appointment, please call Peak Health at APPT_PHONE, or email APPT_EMAIL.</p>";
    default:
      return "";
    }
  }
  
  onTextChange(event) {
    this.setState({
      company: {
        ...this.state.company,
        [event.target.name]: event.target.value,
      },
    });
  }
  
  onEmailTextChange(textChange, typeId) {
    let copy = this.state.emailTemplateTypeList.slice()

    const itemIndex = _.findIndex(copy, i => i.value === typeId)
    copy[itemIndex].Name = textChange;
    
    this.setState({
      emailTemplateTypeList: copy
    })
  }

  onBoolChange(event) {
    this.setState({
      company: {
        ...this.state.company,
        [event.target.name]: !this.state.company[event.target.name],
      },
    });
  }

  onSSOChange(selection) {
    this.setState({
      company: {
        ...this.state.company,
        ssoType: selection,
      },
    });
  }

  onSurveyChange(selection) {
    this.setState({
      company: {
        ...this.state.company,
        surveyType: selection,
      },
    });
  }

  onUploadTypeChange(selection) {
    this.setState({ uploadType: selection });
  }

  onEmailTypeChange(selection) {
    this.setState({
      ...this.state,
      showInput: true,
      emailTemplate: selection,
    });
  }

  onPDFDrop(acceptedFiles, rejectedFiles) {
    if (rejectedFiles.length) {
      this.setState({
        message: "One or more files were rejected.",
        messageFlavor: "danger",
      });
    } else {
      let newCompany = Object.assign({}, this.state.company);
      if (!newCompany.files) {
        newCompany.files = [];
      }
      const existingFileIdx = _.findIndex(newCompany.files, (f) => {
        return f.uploadTypeId === this.state.uploadType.value;
      });
      if (existingFileIdx >= 0) {
        newCompany.files[existingFileIdx].content = acceptedFiles[0];
      } else {
        newCompany.files.push({
          uploadTypeId: this.state.uploadType.value,
          content: acceptedFiles[0],
        });
      }
      newCompany[getPropNameFromUploadType(this.state.uploadType.value)] = true;
      let newState = {
        company: newCompany,
        message: null,
      };
      this.setState(newState);
    }
  }

  isFormValid() {
    let warnings = [];
    if (
      !this.state.company.companyNo ||
      this.state.company.companyNo.length < 0
    ) {
      warnings.push("Please provide a valid company #");
    }
    if (!this.state.company.description) {
      warnings.push("Please provide a valid company description");
    }
    if (!this.state.company.surveyType) {
      warnings.push("Select a valid survey type");
    }
    if (!this.state.company.ssoType) {
      warnings.push("Select a valid SSO type");
    }
    // if(!this.state.company.labFormExists && (!this.state.company.files || !_.some(this.state.company.files, f => { return f.uploadTypeId === "LabForm"; }))) {
    //   warnings.push("A lab form template is required.");
    // }
    // if(!this.state.company.informedConsentFormExists && (!this.state.company.files || !_.some(this.state.company.files, f => { return f.uploadTypeId === "InformedConsentForm"; }))) {
    //   warnings.push("An informed consent form is required.");
    // }
    // if(!this.state.company.companyReleaseFormExists && (!this.state.company.files || !_.some(this.state.company.files, f => { return f.uploadTypeId === "CompanyReleaseForm"; }))) {
    //   warnings.push("A company release form is required.");
    // }
    // if(!this.state.company.privacyDisclosureFormExists && (!this.state.company.files || !_.some(this.state.company.files, f => { return f.uploadTypeId === "PrivacyDisclosureForm"; }))) {
    //   warnings.push("A privacy disclosure form is required.");
    // }
    if (warnings.length) {
      this.setState({ message: warnings.join(","), messageFlavor: "danger" });
    } else {
      this.setState({ message: null });
    }
    return warnings.length === 0;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.isFormValid()) return;
    let payload = new FormData();
    let companyId = this.state.company.id;
    let copyOfList = _.cloneDeep(this.state.emailTemplateTypeList);
    _.map(copyOfList, function remove(o) {
      delete o.label;
      delete o.value;
    })
    if (companyId === null || companyId === undefined) companyId = 0;
    payload.append("Id", companyId);
    payload.append("CompanyGroupId", this.state.company.companyGroupId);
    payload.append("CompanyNo", this.state.company.companyNo);
    payload.append("CompanyGroupName", "");
    payload.append("Description", this.state.company.description);
    payload.append("HealthProgramName", this.state.company.healthProgramName);
    payload.append(
      "CanSeeConferenceCalls",
      this.state.company.canSeeConferenceCalls
    );
    payload.append("ConferenceCallOnly", this.state.company.conferenceCallOnly);
    payload.append(
      "ShowWarningOnReschedule",
      this.state.company.showWarningOnReschedule
    );
    payload.append(
      "ShowWarningOnScheduleHorizon",
      this.state.company.showWarningOnScheduleHorizon
    );
    payload.append(
      "CanScheduleInitialAppt",
      this.state.company.canScheduleInitialAppt
    );
    payload.append(
      "SendApptCalendarEntry",
      this.state.company.sendApptCalendarEntry
    );
    payload.append("TypeOfSurvey", this.state.company.surveyType.value);
    payload.append("TypeOfSSO", this.state.company.ssoType.value);
    payload.append("Active", this.state.company.active);
    payload.append("EmailTemplateList", JSON.stringify(copyOfList));
    if (this.state.company.files) {
      for (var i = 0; i < this.state.company.files.length; i++) {
        payload.append(
          this.state.company.files[i].uploadTypeId,
          this.state.company.files[i].content
        );
      }
    }
    api
      .post_form_data("Admin/SaveCompany", payload)
      .then((r) => {
        if (r.data.success === false) {
          this.setState({ message: r.data.message, messageFlavor: "danger" });
        } else {
          let message = 'company detail page successfully updated'
          let messageFlavor = 'success'
          this.props.onSuccess(message, messageFlavor);
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    const c = this.state.company;
    return (
      <div>

        <Card>
          <CardHeader>Edit Company Details</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Form onSubmit={this.handleSubmit}>
                  <Row className="mb-2">
                    <Col sm="8">
                      Company Group Name
                      <Input readOnly value={c.companyGroupName} />
                    </Col>
                    <Col sm="4">
                      Company #
                      <Input
                        type="text"
                        maxLength="50"
                        name="companyNo"
                        id="companyNo"
                        onChange={this.onTextChange}
                        value={c.companyNo}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      Health Program Name
                      <Input
                        type="text"
                        name="healthProgramName"
                        id="healthProgramName"
                        onChange={this.onTextChange}
                        value={c.healthProgramName}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="9">
                      Description
                      <Input
                        type="text"
                        name="description"
                        id="description"
                        onChange={this.onTextChange}
                        value={c.description}
                      />
                    </Col>
                    <Col sm="3">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="active"
                          onChange={this.onBoolChange}
                          checked={c.active}
                        />{" "}
                        Active
                      </label>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="showWarningOnReschedule"
                          onChange={this.onBoolChange}
                          checked={c.showWarningOnReschedule}
                        />{" "}
                        Warn on Reschedule x3
                      </label>
                    </Col>
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="showWarningOnScheduleHorizon"
                          onChange={this.onBoolChange}
                          checked={c.showWarningOnScheduleHorizon}
                        />{" "}
                        Warn on Schedule Horizon
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="canSeeConferenceCalls"
                          onChange={this.onBoolChange}
                          checked={c.canSeeConferenceCalls}
                        />{" "}
                        Conference Calls
                      </label>
                    </Col>
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="conferenceCallOnly"
                          onChange={this.onBoolChange}
                          checked={c.conferenceCallOnly}
                        />{" "}
                        Conference Calls ONLY
                      </label>
                    </Col>
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="canScheduleInitialAppt"
                          onChange={this.onBoolChange}
                          checked={c.canScheduleInitialAppt}
                        />{" "}
                        Schedule Initial Appt
                      </label>
                    </Col>
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="sendApptCalendarEntry"
                          onChange={this.onBoolChange}
                          checked={c.sendApptCalendarEntry}
                        />{" "}
                        Send Appt Calendar Entry
                      </label>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      Survey
                      <Select
                        closeMenuOnSelect
                        value={c.surveyType}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.state.surveyTypeList}
                        onChange={this.onSurveyChange}
                      />
                    </Col>
                    <Col>
                      Single Sign On
                      <Select
                        closeMenuOnSelect
                        value={c.ssoType}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.state.ssoTypeList}
                        onChange={this.onSSOChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="6">
                      Upload File Type
                      <Select
                        closeMenuOnSelect
                        value={this.state.uploadType}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.state.uploadTypeList}
                        onChange={this.onUploadTypeChange}
                      />
                    </Col>
                    <Col>
                      Customize Email Templates
                      <Select
                        closeMenuOnSelect
                        placeholder={"select a email type"}
                        value={this.state.emailTemplate}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.state.emailTemplateTypeList}
                        onChange={this.onEmailTypeChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="pt-3">
                      {this.state.company[
                        getPropNameFromUploadType(this.state.uploadType.value)
                      ] ? (
                        <div className="text-success mt-3">
                          This file HAS been provided.
                        </div>
                      ) : (
                        <div className="text-danger mt-3">
                          The file has NOT been provided.
                        </div>
                      )}
                    </Col>
                    <Col sm="6" className="pt-3">
                      <div>Each specific template has a set of available key words you can use.</div>
                      <div>For example, if your template took this format 
                        <span style={{fontStyle: "italic"}}>"Hello FIRST_NAME your appointment is on SCHEDULED_AT"</span> it will resolve to <span style={{fontStyle: "italic"}}>"Hello users_first_name your appointment is on MM-DD-YYYY hh:mm PM/AM"</span>.
                      </div>
                      {this.templatePrompt()}
                      {this.state.showInput ? (
                        <Input
                          type="textarea"
                          name="emailTemplateText"
                          id="emailTemplateText"
                          placeholder={this.placeHolderText()}
                          onChange={(e) => this.onEmailTextChange(e.target.value, this.state.emailTemplate?.value)}
                          value={this.state.emailTemplate.Name}
                        />
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Card>
                        <CardBody>
                          <CardTitle className="text-center">
                            Drop files or click on the icon below
                          </CardTitle>
                          <Dropzone
                            className="col-xs-4"
                            onDrop={this.onPDFDrop}
                            accept="application/pdf"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <p style={{ textAlign: "center" }}>
                                    <FontAwesomeIcon
                                      icon="file-upload"
                                      size="6x"
                                    />
                                  </p>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="expand-md">
                    <Col className="text-right">
                      <ButtonGroup>
                        <input
                          type="submit"
                          value="Save"
                          className="btn btn-primary"
                        />
                        <Button
                          color="secondary"
                          onClick={() => this.props.onExit()}
                        >
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}
