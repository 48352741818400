import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Button, Row } from "reactstrap";

export class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
    };
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.handleButtonOnClick = this.handleButtonOnClick.bind(this);
  }

  changeStartDate(d) {
    this.setState({ startDate: d });
    this.props.onStartDateChangeCallback(d);
  }

  changeEndDate(d) {
    this.setState({ endDate: d });
    this.props.onEndDateChangeCallback(d);
  }

  handleButtonOnClick() {
    this.props.onUpdateDateRangeCallback({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  }

  render() {
    return (
      <Row className="ml-3 my-3">
        <div>
          <DatePicker
            selected={this.state.startDate}
            selectsStart
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={this.changeStartDate}
            className="form-control"
            popperPlacement="bottom"
            popperModifiers={{
              flip: { enabled: false },
              preventOverflow: { enabled: true, escapeWithReference: false },
            }}
          />
        </div>
        <div className="ml-0 ml-md-3">
          <DatePicker
            selected={this.state.endDate}
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={this.changeEndDate}
            className="form-control"
            popperPlacement="bottom"
            popperModifiers={{
              flip: { enabled: false },
              preventOverflow: { enabled: true, escapeWithReference: false },
            }}
          />
        </div>
        <div>
          <Button className="ml-0 ml-sm-3" onClick={this.handleButtonOnClick}>
            Update
          </Button>
        </div>
      </Row>
    );
  }
}
