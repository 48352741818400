import React, { Component } from "react";
import {
  Alert,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Row,
  Col,
} from "reactstrap";
import api from "../utils/api";
import helpers from "../utils/helpers";
import _ from "lodash";
import moment from "moment";

import {
  PeakSpinner,
  ToggleMetricsChartButton,
  VisitsByPlanYearTable,
  QuarterlyOperationalChangesTable,
  AnnualEligibilityTable,
  AnnualEligibilityPeakStackedBarChart,
  EnrolledByCostCenterTable,
  EnrolledByStateTable,
  DateRangePicker,
  VisitsByMonthTable,
  VisitsByMonthPeakMultiLineChart,
  EnrollmentsByPhaseTable,
  AverageDaysNewHireEnrollmentsTable,
  EnrolledByPhaseDoughnutChart,
  AnnualEligibilityPeakAreaAndLineChart,
} from "../components";

import storage from "../utils/storageFactory.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constants from "../utils/constants.js";

export class MetricsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      messageFlavor: null,
      activeTab: "1",
      showEnrolledByPhaseDoughnutChart: true,
      showAnnualEligibilityHorizontalStackedBarChart: true,
      showAnnualEligibilityAreaAndLineChart: true,
      showVisitsByMonthHorizontalStackedBarChart: true,
      showVisitsByMonthMultiLineChart: true,
      showEnrollmentByPhaseStackedBarChart: true,
      showEnrollmentByPolarAreaChart: true,
      hoverColor: "rgb(0, 0, 255, 0.3)",
      firstTimeModal: false,
      startDate: moment().subtract(1, "years"),
      endDate: moment(),
      selectedCompanyGroupNo: this.props.currentUser.companyGroupNo || 0,
    };
    this.generateRandomColor = this.generateRandomBackgroundColor.bind(this);
    this.generateBorderColor = this.generateBorderColor.bind(this);
    this.handleCompanyGroupChange = this.handleCompanyGroupChange.bind(this);
    this.showFirstVisitMessage = this.showFirstVisitMessage.bind(this);
    this.renderFirstVisitModal = this.renderFirstVisitModal.bind(this);
    this.fetchDashboardData = this.fetchDashboardData.bind(this);
    this.toggle = this.toggleDashboardTab.bind(this);
    this.getRandomInt = this.getRandomInt.bind(this);
    // this.generateCostSavingsData = this.generateCostSavingsData.bind(this);
    this.processVisitsData = this.processVisitsData.bind(this);
    this.validateDates = this.validateDates.bind(this);

    this.handleChartToggle = this.handleChartToggle.bind(this);
    this.renderCompanyGroupDropdown =
      this.renderCompanyGroupDropdown.bind(this);
    this.renderToggleChartButtons = this.renderToggleChartButtons.bind(this);
    this.toggleFirstVisitModal = this.toggleFirstVisitModal.bind(this);
  }

  componentDidMount() {
    this.fetchDashboardData();

    this.showFirstVisitMessage();
    this.processVisitsData();
  }

  validateDates(startDate, endDate) {
    let errors = [];

    if (startDate && endDate) {
      // Convert to Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);

      const diffInYears = end.getFullYear() - start.getFullYear();
      if (diffInYears > 10) {
        errors.push(
          "The date range should not exceed 10 years, please enter a shorter date range."
        );
      }
      if (start > end) {
        errors.push(
          "The start date should not be later than the end date, please enter a valid date range."
        );
      }
    } else {
      errors.push("Please select both start and end dates.");
    }

    return errors;
  }

  fetchDashboardData() {
    const companyGroupNo = this.state.selectedCompanyGroupNo
      ? this.state.selectedCompanyGroupNo
      : this.props.currentUser.companyGroupNo;

    var startDate = this.state.startDate.d
      ? this.state.startDate.d
      : this.state.startDate;
    var endDate = this.state.startDate.d
      ? this.state.endDate.d
      : this.state.endDate;

    const errors = this.validateDates(startDate, endDate);
    if (errors.length > 0) {
      // Display errors
      console.log(errors);
      errors.forEach((error) =>
        this.setState({ message: error, messageFlavor: "danger" })
      );

      setTimeout(() => {
        this.setState({
          message: null,
        });
      }, 5000);
      return;
    }

    this.setState({ loading: true });

    const companyGroups = api
      .fetch("Metrics/GetCompanyGroups")
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;
          return { companyGroupsList: errorMessage };
        } else {
          let list = res.data;
          return { companyGroupsList: list };
        }
      })
      .catch(helpers.catchHandler);

    // const costSavingsMetrics = api
    //   .post("Metrics/GetCostSavingsMetrics", {})
    //   .then((res) => {
    //     let list = res.data;
    //     // console.log(
    //     //   "Cost Savings Metrics Data ============================",
    //     //   list
    //     // );
    //     return { costSavingsMetricsList: list };
    //   })
    //   .catch(helpers.catchHandler);

    const annualEligibilityMetrics = api
      .post("Metrics/GetEnrollmentMetrics", {
        companyGroupId: companyGroupNo,
        after: startDate,
        before: endDate,
      })
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;
          return { enrollmentMetricsList: errorMessage };
        } else {
          let list = res.data;
          return { enrollmentMetricsList: list };
        }
      })
      .catch(helpers.catchHandler);

    const visitsByMonthMetrics = api
      .post("Metrics/GetVisitsByMonthMetrics", {
        companyGroupId: companyGroupNo,
        after: startDate,
        before: endDate,
      })
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;
          return { visitsByMonthMetricsList: errorMessage };
        } else {
          let list = res.data;
          return { visitsByMonthMetricsList: list };
        }
      })
      .catch(helpers.catchHandler);

    const enrollmentsByPhaseMetrics = api
      .post("Metrics/GetEnrollmentsByPhaseMetrics", {
        companyGroupId: companyGroupNo,
        after: startDate,
        before: endDate,
      })
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;

          return { enrollmentsByPhaseList: errorMessage };
        } else {
          let list = res.data;

          return { enrollmentsByPhaseList: list };
        }
      })
      .catch(helpers.catchHandler);

    const newHiresEnrollmentsByMonthMetrics = api
      .post("Metrics/GetNewHireEnrollmentsByMonthMetrics", {
        companyGroupId: companyGroupNo,
        after: startDate,
        before: endDate,
      })
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;
          return { newHiresEnrollmentsByMonthMetricsList: errorMessage };
        } else {
          let list = res.data;
          return { newHiresEnrollmentsByMonthMetricsList: list };
        }
      })
      .catch(helpers.catchHandler);

    const quarterlyOperationalChangeMetrics = api
      .post("Metrics/GetQuarterlyOperationalChangeMetrics", {
        companyGroupId: companyGroupNo,
        after: startDate,
        before: endDate,
      })
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;
          return { quarterlyOperationalChangeMetricsList: errorMessage };
        } else {
          let list = res.data;
          return { quarterlyOperationalChangeMetricsList: list };
        }
      })
      .catch(helpers.catchHandler);

    const visitsByPlanYearMetrics = api
      .post("Metrics/GetVisitsByPlanYearMetrics", {
        companyGroupId: companyGroupNo,
        after: startDate,
        before: endDate,
      })
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;

          return { visitsByPlanYearMetricsList: errorMessage };
        } else {
          let list = res.data;

          return { visitsByPlanYearMetricsList: list };
        }
      })
      .catch(helpers.catchHandler);

    const enrollmentParticipationByCostCenterMetrics = api
      .post("Metrics/GetEnrollmentParticipationByCostCenterMetrics", {
        companyGroupId: companyGroupNo,
        after: startDate,
        before: endDate,
      })
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;

          return {
            enrollmentParticipationByCostCenterMetricsList: errorMessage,
          };
        } else {
          let list = res.data;

          return { enrollmentParticipationByCostCenterMetricsList: list };
        }
      })
      .catch(helpers.catchHandler);

    const enrollmentParticipationByStateMetrics = api
      .post("Metrics/GetEnrollmentParticipationByStateMetrics", {
        companyGroupId: companyGroupNo,
        after: startDate,
        before: endDate,
      })
      .then((res) => {
        if (res.data.success === false) {
          let errorMessage = res.data;

          return { enrollmentParticipationByStateMetricsList: errorMessage };
        } else {
          let list = res.data;

          return { enrollmentParticipationByStateMetricsList: list };
        }
      })
      .catch(helpers.catchHandler);

    Promise.all([
      companyGroups,
      // costSavingsMetrics,
      annualEligibilityMetrics,
      visitsByMonthMetrics,
      newHiresEnrollmentsByMonthMetrics,
      quarterlyOperationalChangeMetrics,
      visitsByPlanYearMetrics,
      enrollmentsByPhaseMetrics,
      enrollmentParticipationByCostCenterMetrics,
      enrollmentParticipationByStateMetrics,
    ])
      .then((results) => {
        let data = {};
        let errors = [];

        _.each(results, (r) => {
          // Get the first property of the result object
          const firstProp = Object.values(r)[0];

          // If the property is an object and success is false, push the message into the errors array
          if (typeof firstProp === "object" && firstProp.success === false) {
            errors.push(firstProp.message);
          }

          Object.assign(data, r);
        });

        // If there are any errors, display them
        if (errors.length > 0) {
          // Join all error messages into a single string and display it
          var errorList = errors;
          this.setState({
            message: errorList,
            messageFlavor: "danger",
          });

          setTimeout(() => {
            this.setState({
              message: null,
            });
          }, 5000);
        } else {
          // If there are no errors, display the success message
          this.setState({
            message: "Metrics retrieved successfully.",
            messageFlavor: "success",
          });
        }

        this.setState({
          companyGroupsData: data.companyGroupsList,
          annualEligibilityData: data.enrollmentMetricsList,
          visitsByMonthData: data.visitsByMonthMetricsList,
          enrollmentsByPhaseData: data.enrollmentsByPhaseList,
          newHiresEnrollmentsByMonthMetricsData:
            data.newHiresEnrollmentsByMonthMetricsList,
          enrollmentParticipationByCostCenterData:
            data.enrollmentParticipationByCostCenterMetricsList,
          enrollmentParticipationByStateData:
            data.enrollmentParticipationByStateMetricsList,
          quarterlyOperationalChangeMetricsData:
            data.quarterlyOperationalChangeMetricsList,
          visitsByPlanYearMetricsData: data.visitsByPlanYearMetricsList,
        });
        setTimeout(() => {
          this.setState({
            message: null,
          });
        }, 5000);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      })
      .catch(helpers.catchHandler);
  }

  processVisitsData() {
    const visitDataToChart = this.state.visitsByMonthData;
    const phases = _.sortBy(_.uniq(_.map(visitDataToChart, "phase")));
    const yrMonths = _.sortBy(
      _.uniq(_.map(visitDataToChart, "yrMonth")),
      (yrMonth) => moment(yrMonth, "YY-MMM").toDate()
    );

    // Create a lookup table for easy access to count values
    const lookup = _.reduce(
      visitDataToChart,
      (result, value) => {
        if (!result[value.phase]) {
          result[value.phase] = {};
        }
        result[value.phase][value.yrMonth] = value.count;
        return result;
      },
      {}
    );

    // Create table rows
    const rows = _.map(phases, (phase) => {
      const cells = _.map(yrMonths, (yrMonth) => lookup[phase][yrMonth] || 0);
      const countTotal = _.sum(cells);
      return [phase, ...cells, countTotal];
    });

    const grandTotals = _.map(_.range(yrMonths.length + 1), (i) =>
      _.sumBy(rows, (row) => row[i + 1])
    );
    const grandTotalRow = ["Grand Total", ...grandTotals];

    // Create table
    const table = [["Phase", ...yrMonths, "Total"], ...rows, grandTotalRow];
    return table;
  }

  checkFirstVisit = (screenName) => {
    const firstVisitKey = `${screenName}_first_visit`;

    // Check if the flag is set in the localStorage
    const isFirstVisit = !storage.getItem(firstVisitKey);

    if (isFirstVisit) {
      // Set the flag in the localStorage
      storage.setItem(firstVisitKey, "visited");
    }

    return isFirstVisit;
  };

  toggleFirstVisitModal = () => {
    this.setState((prevState) => ({
      firstTimeModal: !prevState.firstTimeModal,
    }));
  };

  showFirstVisitMessage() {
    if (this.checkFirstVisit("MetricsDashboard")) {
      this.setState({ firstTimeModal: true });
    } else {
    }
  }

  metricsDashboardFirstTimeModalText = (
    <div>
      <p>
        The new metrics dashboard provides visualizations of various metrics.
        It's designed to give you a quick, comprehensive overview of key data.
      </p>
      <p>
        <strong>Features of the page include:</strong>
      </p>
      <ul>
        <li>
          <strong>Chart Tabs:</strong> These buttons allow you to tab through
          the available data visualizations.
        </li>
        <li>
          <strong>Chart Toggle Buttons:</strong> These buttons allow you to
          toggle different types of charts on and off. You can customize the
          dashboard to show the charts that are most relevant to you.
        </li>
        <li>
          <strong>Date Range Picker:</strong> Select and apply the date range
          you want to view data for.
        </li>
      </ul>
    </div>
  );

  renderFirstVisitModal() {
    return (
      <div>
        {this.state.firstTimeModal && (
          <Modal
            isOpen={this.state.firstTimeModal}
            toggle={this.toggleFirstVisitModal}
            centered={true}
            size="lg"
          >
            <ModalHeader toggle={this.toggleFirstVisitModal}>
              Welcome to the New Metrics Dashboard
            </ModalHeader>
            <ModalBody>{this.metricsDashboardFirstTimeModalText}</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggleFirstVisitModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }

  generateRandomBackgroundColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgba(${red}, ${green}, ${blue}, 0.3)`;
  }

  generateBorderColor(backgroundColor) {
    const colorArray = backgroundColor.split(",");
    const color = colorArray[0] + ", " + colorArray[1] + ", " + colorArray[2];
    return color + ", 3)";
  }

  // generateCostSavingsData() {
  //   const riskFactors = [
  //     "Poor Health",
  //     "Using Tobacco",
  //     "Not Using Seat Belt",
  //     "Excessive Alcohol Use",
  //     "Obese",
  //     "InActive",
  //     "High Total Cholesterol",
  //     "High Blood Pressure",
  //     "Low HDL",
  //   ];

  //   const costSavingsData = riskFactors.map((riskFactor) => {
  //     const initialValue = this.getRandomInt(101, 15000);
  //     const itemColor = this.generateRandomBackgroundColor();
  //     return {
  //       riskFactor,
  //       initialValue,
  //       improvedValue: this.getRandomInt(100, initialValue),
  //       estimatedCostSavings: this.getRandomInt(130000, 22000000),
  //       itemColor: itemColor,
  //       borderColor: this.generateBorderColor(itemColor),
  //       hoverColor: this.state.hoverColor,
  //     };
  //   });

  //   return costSavingsData;
  // }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  toggleDashboardTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleChartToggle = (chartName) => {
    this.setState((prevState) => ({
      [chartName]: !prevState[chartName],
    }));
  };

  renderToggleChartButtons = (
    toggleFunction,
    showChart,
    icon,
    rotate = false,
    flip = false
  ) => {
    return (
      <div
        onClick={toggleFunction}
        className="row align-content-center align-items-center justify-content-end"
      >
        <div className="mr-2 mb-1" style={{ fontSize: "12px" }}>
          {showChart ? "hide" : "show"}
        </div>
        <FontAwesomeIcon
          icon={icon}
          style={{
            color: showChart ? "black" : "grey",
            transform: `${rotate ? "rotate(90deg)" : ""}${
              flip ? "scaleX(-1)" : ""
            } ${showChart ? "scaleX(-1)" : "scaleX(1)"}`,
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </div>
    );
  };

  // company group dropdown functions
  renderCompanyGroupDropdown = () => {
    const companyGroups = this.state.companyGroupsData || [];
    const { currentUser } = this.props;

    return (
      <Row
        className="justify-content-end ml-auto"
        style={{ marginRight: "20px" }}
      >
        <FormGroup key={companyGroups.label}>
          <Input
            type="select"
            name="companyGroup"
            id="companyGroup"
            onChange={this.handleCompanyGroupChange}
          >
            {companyGroups.length > 0 ? (
              <>
                {this.state.selectedCompanyGroupNo ? (
                  <option value={this.state.selectedCompanyGroupNo}>
                    {
                      companyGroups.find(
                        (group) =>
                          group.companyGroupNo ===
                          this.state.selectedCompanyGroupNo
                      ).name
                    }
                  </option>
                ) : (
                  <option value={0}>Select Company Group</option>
                )}
                {_.map(companyGroups, (companyGroup) => (
                  <option
                    key={companyGroup.id}
                    value={companyGroup.companyGroupNo}
                  >
                    {companyGroup.name}
                  </option>
                ))}
              </>
            ) : (
              <option value={currentUser.companyGroupNo}>
                {currentUser.companyName}
              </option>
            )}
          </Input>
        </FormGroup>
      </Row>
    );
  };

  handleCompanyGroupChange(event) {
    const selectedCompanyGroupNo = event.target.value;
    this.setState({ selectedCompanyGroupNo: selectedCompanyGroupNo });
  }

  //date picker functions
  onStartDateChange = (selectedStartDate) => {
    this.setState({ startDate: selectedStartDate });
  };

  onEndDateChange = (selectedEndDate) => {
    this.setState({ endDate: selectedEndDate });
  };

  handleDateRangeChange = () => {
    this.fetchDashboardData();
  };

  accessText = `Access Requirement Not Met: ${constants.APP_ROLE_ACCESS_REQUIREMENTS.companyAdmin.access} or ${constants.APP_ROLE_ACCESS_REQUIREMENTS.systemAdmin.access} Required`;

  render() {
    if (
      this.props.currentUser.isAdmin === false &&
      this.props.currentUser.isCompanyAdmin === false
    ) {
      return (
        <>
          <PeakSpinner on={this.state.loading}>
            <Label className="role-access-metrics-dashboard">
              {this.accessText}
            </Label>
          </PeakSpinner>
        </>
      );
    }

    return (
      <>
        <PeakSpinner on={this.state.loading}>
          {this.renderFirstVisitModal()}

          <div>
            {this.renderCompanyGroupDropdown()}
            {this.state.message === null ? null : Array.isArray(
                this.state.message
              ) ? (
              this.state.message.map((error, index) => (
                <Alert key={index} color={this.state.messageFlavor}>
                  {error}
                </Alert>
              ))
            ) : (
              <Alert color={this.state.messageFlavor}>
                {this.state.message}
              </Alert>
            )}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "1" ? "active" : ""}
                  onClick={() => this.toggleDashboardTab("1")}
                >
                  Annual Eligibility
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "2" ? "active" : ""}
                  onClick={() => this.toggleDashboardTab("2")}
                >
                  Visits By Month
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "3" ? "active" : ""}
                  onClick={() => this.toggleDashboardTab("3")}
                >
                  Enrolled By Phase
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "4" ? "active" : ""}
                  onClick={() => this.toggleDashboardTab("4")}
                >
                  New Hire Enrollments
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "5" ? "active" : ""}
                  onClick={() => this.toggleDashboardTab("5")}
                >
                  Quarterly Operational Changes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "6" ? "active" : ""}
                  onClick={() => this.toggleDashboardTab("6")}
                >
                  Visits By Plan Year
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "7" ? "active" : ""}
                  onClick={() => this.toggleDashboardTab("7")}
                >
                  Enrolled By Cost Center
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "8" ? "active" : ""}
                  onClick={() => this.toggleDashboardTab("8")}
                >
                  Enrolled By State
                </NavLink>
              </NavItem>

              <DateRangePicker
                onStartDateChangeCallback={this.onStartDateChange}
                onEndDateChangeCallback={this.onEndDateChange}
                onUpdateDateRangeCallback={this.handleDateRangeChange}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
              {/*
            {this.state.activeTab === "1" && (
              <div className="ml-auto pr-3">
                {this.renderToggleChartButtons(
                  this.togglePieChart,
                  this.state.showPieChart,
                  "chart-pie"
                )}
                {this.renderToggleChartButtons(
                  this.toggleBarChart,
                  this.state.showBarChart,
                  "chart-bar"
                )}
              </div>
            )} */}
              {this.state.activeTab === "1" && (
                <div className="ml-auto pr-3">
                  <ToggleMetricsChartButton
                    toggleFunction={() =>
                      this.handleChartToggle(
                        "showAnnualEligibilityHorizontalStackedBarChart"
                      )
                    }
                    showChart={
                      this.state.showAnnualEligibilityHorizontalStackedBarChart
                    }
                    icon="chart-bar"
                    rotate={true}
                  />
                  <ToggleMetricsChartButton
                    toggleFunction={() =>
                      this.handleChartToggle(
                        "showAnnualEligibilityAreaAndLineChart"
                      )
                    }
                    showChart={this.state.showAnnualEligibilityAreaAndLineChart}
                    icon="chart-line"
                    flip={true}
                  />
                </div>
              )}

              {this.state.activeTab === "2" && (
                <div className="ml-auto pr-3">
                  <ToggleMetricsChartButton
                    toggleFunction={() =>
                      this.handleChartToggle("showVisitsByMonthMultiLineChart")
                    }
                    showChart={this.state.showVisitsByMonthMultiLineChart}
                    icon="chart-line"
                    flip={true}
                  />
                </div>
              )}
              {this.state.activeTab === "3" && (
                <div className="ml-auto pr-3">
                  <ToggleMetricsChartButton
                    toggleFunction={() =>
                      this.handleChartToggle("showEnrolledByPhaseDoughnutChart")
                    }
                    showChart={this.state.showEnrolledByPhaseDoughnutChart}
                    icon="chart-pie"
                    rotate={true}
                  />
                </div>
              )}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {/* <TabPane tabId="1">
              <div className="row d-lg-flex p-4">
                <div
                  className={
                    this.state.showPieChart || this.state.showBarChart
                      ? "col border rounded shadow p-3"
                      : "col-12 border rounded shadow p-3"
                  }
                >
                  <CostSavingsByRiskFactorTable
                    costSavingsData={this.state.costSavingsData}
                  />
                </div>

                <div className="col d-flex flex-column justify-content-center mt-3 mt-lg-0">
                  {this.state.showPieChart && this.state.activeTab === "1" && (
                    <div className="mb-3 border rounded shadow p-3 ">
                      <PeakPieChart
                        costSavingsData={this.state.costSavingsData}
                      />
                    </div>
                  )}
                  {this.state.showBarChart && this.state.activeTab === "1" && (
                    <div className="border rounded shadow p-3">
                      <PeakVerticalBarChart
                        costSavingsData={this.state.costSavingsData}
                      />
                    </div>
                  )}
                </div>
              </div>
            </TabPane> */}
              <TabPane tabId="1">
                <Row className="p-4">
                  <Col
                    className={
                      this.state
                        .showAnnualEligibilityHorizontalStackedBarChart ||
                      this.state.showAnnualEligibilityAreaAndLineChart
                        ? "col border rounded shadow p-3 mx-2 my-2"
                        : "col-12 border rounded shadow p-3 mx-2 my-2"
                    }
                  >
                    <AnnualEligibilityTable
                      enrollmentData={this.state.annualEligibilityData}
                    />
                  </Col>
                  {(this.state.showAnnualEligibilityHorizontalStackedBarChart ||
                    this.state.showAnnualEligibilityAreaAndLineChart) &&
                    this.state.activeTab === "1" && (
                      <Col className="justify-content-center mx-2 my-2">
                        {this.state
                          .showAnnualEligibilityHorizontalStackedBarChart && (
                          <Row
                            className={`border rounded shadow ${
                              this.state.showAnnualEligibilityAreaAndLineChart
                                ? "mb-3"
                                : ""
                            }`}
                          >
                            <Col>
                              <AnnualEligibilityPeakStackedBarChart
                                enrollmentData={
                                  this.state.annualEligibilityData
                                }
                              />
                            </Col>
                          </Row>
                        )}
                        {this.state.showAnnualEligibilityAreaAndLineChart && (
                          <Row className="border rounded shadow">
                            <Col>
                              <AnnualEligibilityPeakAreaAndLineChart
                                enrollmentData={
                                  this.state.annualEligibilityData
                                }
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    )}
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row className="p-4">
                  <Col
                    className={
                      this.state.showVisitsByMonthMultiLineChart
                        ? "col-6 col-xl border rounded shadow p-3 mx-2 my-2"
                        : "col-12 border rounded shadow p-3 mx-2 my-2"
                    }
                  >
                    <VisitsByMonthTable visitsData={this.processVisitsData()} />
                  </Col>
                  {this.state.showVisitsByMonthMultiLineChart &&
                    this.state.activeTab === "2" && (
                      <Col className="col justify-content-center border rounded shadow p-3 mx-2 my-2">
                        <VisitsByMonthPeakMultiLineChart
                          visitsData={this.processVisitsData()}
                        />
                      </Col>
                    )}
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row className="p-4">
                  <Col
                    className={
                      this.state.showEnrolledByPhaseDoughnutChart
                        ? "col border rounded shadow p-3 mx-2 my-2"
                        : "col-12 border rounded shadow p-3 mx-2 my-2"
                    }
                  >
                    <EnrollmentsByPhaseTable
                      enrollmentsByPhaseData={this.state.enrollmentsByPhaseData}
                    />
                  </Col>
                  {this.state.showEnrolledByPhaseDoughnutChart &&
                    this.state.activeTab === "3" && (
                      <Col className="justify-content-center border rounded shadow p-3 mx-2 my-2">
                        <EnrolledByPhaseDoughnutChart
                          enrollmentsByPhaseData={
                            this.state.enrollmentsByPhaseData
                          }
                        />
                      </Col>
                    )}
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Col className="p-4">
                  <Row className="border rounded shadow p-3">
                    <AverageDaysNewHireEnrollmentsTable
                      avgDaysNewHireEnrollmentsData={
                        this.state.newHiresEnrollmentsByMonthMetricsData
                      }
                      selectedStartDate={this.state.startDate}
                    />
                  </Row>
                </Col>
              </TabPane>
              <TabPane tabId="5">
                <Col className="p-4">
                  <Row className="border rounded shadow p-3">
                    <QuarterlyOperationalChangesTable
                      quarterlyOperationalChangesData={
                        this.state.quarterlyOperationalChangeMetricsData
                      }
                    />
                  </Row>
                </Col>
              </TabPane>
              <TabPane tabId="6">
                <Col className="p-4">
                  <Row className="border rounded shadow p-3">
                    <VisitsByPlanYearTable
                      visitsByPlanYearData={
                        this.state.visitsByPlanYearMetricsData
                      }
                    />
                  </Row>
                </Col>
              </TabPane>
              <TabPane tabId="7">
                <Col className="p-4">
                  <Row className="border rounded shadow p-3">
                    <EnrolledByCostCenterTable
                      enrollmentParticipationByCostCenterData={
                        this.state.enrollmentParticipationByCostCenterData
                      }
                    />
                  </Row>
                </Col>
              </TabPane>
              <TabPane tabId="8">
                <Col className="p-4">
                  <Row className="border rounded shadow p-3">
                    <EnrolledByStateTable
                      enrollmentParticipationByStateData={
                        this.state.enrollmentParticipationByStateData
                      }
                    />
                  </Row>
                </Col>
              </TabPane>
            </TabContent>
          </div>
        </PeakSpinner>
      </>
    );
  }
}
