import React, { Component } from 'react';
import { Button } from 'reactstrap'; // Pagination, PaginationItem, PaginationLink 

const PagerLink = props => (
  <li className="page-item">
    <Button className="page-link" onClick={() => props.callBack()}>
      {props.linkText}
    </Button>
  </li>
);

export class Pager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageNumber: props.page_number
    };
    if (!props.callBack) {
      console.warn('Add a callBack function property on the pager that accepts a page param');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page_number !== this.props.page_number) {
      this.setState({ currentPageNumber: this.props.page_number });
    }
  }

  render() {
    const showPager = (this.props.total_pages > 0);
    const previous = (this.state.currentPageNumber > 1);
    const next = (this.state.currentPageNumber !== this.props.total_pages) && (this.props.total_pages > 1);
    return (
      <div>
        { showPager &&
          (<nav>
            <ul className="pagination">
            {previous &&
              <PagerLink linkText="First" callBack={() => this.props.callBack(1)} />
            }
            {previous &&
              <PagerLink linkText="Previous" callBack={() => this.props.callBack(this.state.currentPageNumber - 1)} />
            }
            <span className="mr-2 ml-2 pt-2">Page {this.state.currentPageNumber} of {this.props.total_pages}</span>
            {next &&
              <PagerLink linkText="Next" callBack={() => this.props.callBack(this.state.currentPageNumber + 1)} />
            }
            {next &&
              <PagerLink linkText="Last" callBack={() => this.props.callBack(this.props.total_pages)} />
            }
            </ul>
          </nav>)
        }
      </div>
    );
  }
}