import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
// import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import {
  Button,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import api from "../utils/api";
import helpers from "../utils/helpers";
import _ from "lodash";
import moment from "moment";
import date_helpers from "../utils/date_helpers";
import L from "leaflet";
import { PeakSpinner } from "../components";
import storage from "../utils/storageFactory.js";

const DATE_FORMAT = "YYYY-MM-DD";
const DATE_TIME_FORMAT = "MMM-DD-YYYY hh:mm a";
const DB_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";

// const TABS = {
//   MAP: "1",
//   CALENDAR: "2"
// };

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("../assets/img/marker-icon-2x.png"),
  iconUrl: require("../assets/img/marker-icon.png"),
  shadowUrl: require("../assets/img/marker-shadow.png"),
});

const longSlotRequiredButUnavailable = (
  availableDaySlotList,
  proposedSlot,
  currentPhase
) => {
  //phase 0 users need to schedule long appointment (1hr) check if thats available for the slot they entered
  if (currentPhase === 0) {
    const nextSlotTime = proposedSlot.scheduledAtMoment
      .clone()
      .add(30, "minutes")
      .format(date_helpers.TIME_FORMAT);
    return (
      _.find(availableDaySlotList, (x) => {
        return (
          x.scheduledAtMoment.format(date_helpers.TIME_FORMAT) === nextSlotTime
        );
      }) === undefined
    );
  }
  return false;
};

const getApptById = (list, apptId) => {
  let slot = _.find(list, (s) => {
    return s.id === apptId;
  });
  slot.scheduledAtMoment = date_helpers.getMomentFromString(
    slot.scheduledAtString,
    DATE_TIME_FORMAT
  );
  return slot;
};

// const TwentyFourHoursOfTicks = 1000*60*60*24;
const getMaxAllowableRescheduleDays = (currentPhase) => {
  if (currentPhase <= 3) {
    return 180;
  } else if (currentPhase === 4) {
    return 240;
  } else {
    return 420;
  }
};

// const getClosestLocations = (locationList, currentUser) => {
//   // TODO: perform geolocation distance sort/take
//   if (!locationList || locationList.length === 0) return [];
//   return _.chain(locationList)
//     .filter(loc => { return loc.position.lng && loc.position.lat;}) // && loc.state === 'FL'; })
//     .value();
// };

const fillSlotGaps = (inList, startTime, endTime, existingAppt, locationId) => {
  let d = date_helpers.getMomentFromString("2000-01-01 " + startTime);
  const e = date_helpers.getMomentFromString("2000-01-01 " + endTime);
  // const existingApptMoment = existingAppt
  //   ? date_helpers.getMomentFromString(existingAppt.scheduledAtString, DATE_TIME_FORMAT)
  //   : null;
  let outList = [];
  for (let i = 0; i < 50; i++) {
    if (i) {
      d = d.clone().add(30, "minutes");
    }
    if (d.isAfter(e)) break;
    const slotTime = d.format(date_helpers.TIME_FORMAT);
    const existing = _.find(inList, (x) => {
      return x.scheduledAtMoment.format(date_helpers.TIME_FORMAT) === slotTime;
    });
    if (existing) {
      existing.isAvailable = true;
      outList.push(existing);
    } else {
      let isMine = false;
      // if (existingApptMoment) {
      //   isMine = d.format(DATE_TIME_FORMAT) === existingApptMoment.format(DATE_TIME_FORMAT)
      //     && existingAppt.locationId === locationId;
      // }
      outList.push({
        id: d.format(DATE_TIME_FORMAT),
        scheduledAt: d.format(DATE_TIME_FORMAT),
        scheduledAtMoment: d,
        isAvailable: false,
        isMine: isMine,
      });
    }
  }
  return outList;
};

const resolveSlotClass = (isAvailable, isMine) => {
  if (isMine) {
    return "dark";
  }
  return isAvailable ? "primary" : "secondary";
};

const availableListByDay = (dateMoment, list) => {
  const dateMomentString = dateMoment.format(DATE_FORMAT);
  return _.filter(list, (x) => {
    return x.scheduledAtMoment.format(DATE_FORMAT) === dateMomentString;
  });
};

const localizer = momentLocalizer(moment);

const WarnTitle = (props) => {
  return (
    <div style={{ fontWeight: "bold", color: "#FF0000", fontSize: "16pt" }}>
      Warning!
    </div>
  );
};

const Warn24 = (props) => {
  return (
    <Row>
      <Col>
        <WarnTitle />
        <div>
          You are attempting to reschedule an evaluation without giving Peak
          Health the required 24 hour notice of cancellation.
          <br />
          If you are sick and not at work on the day of your appointment, your
          manager needs to contact Peak Health at 252-237-5090 or
          appointment@peak-health.net.
        </div>
      </Col>
    </Row>
  );
};

class ScheduleAppointment extends Component {
  constructor(props) {
    super(props);
    const daySlots = _.sortBy(
      fillSlotGaps(
        props.availableAppointmentList,
        "07:00",
        "18:00",
        props.nextAppt,
        props.location.id
      ),
      (x) => x.scheduledAtMoment.format("HH:mm")
    );
    this.state = {
      selectedTimeSlot: null,
      message: null,
      messageFlavor: null,
      confirmApptId: null,
      showPhaseWarning: false,
      warnRescheduleCount: false,
      warnRescheduleTiming: false,
      warn24Hours: false,
      appointmentSlots: daySlots,
      confirmingRescheduleDetails: null,
    };
    this.proceed = this.proceed.bind(this);
    this.doScheduleAppt = this.doScheduleAppt.bind(this);
    this.doRescheduleAppt = this.doRescheduleAppt.bind(this);
    this.proceed = this.proceed.bind(this);
    this.nevermind = this.nevermind.bind(this);
    this.onSlotClick = this.onSlotClick.bind(this);
  }

  doRescheduleAppt() {
    const appt = getApptById(
      this.state.appointmentSlots,
      this.state.confirmingRescheduleDetails.newApptId
    );
    const oldScheduledAtMoment = date_helpers.getMomentFromString(
      this.props.nextAppt.scheduledAtString,
      DATE_TIME_FORMAT
    );
    api
      .post("Appt/Reschedule", {
        id: this.state.confirmingRescheduleDetails.oldApptId,
        id2: this.state.confirmingRescheduleDetails.newApptId,
        scheduledAt: oldScheduledAtMoment.format(DB_DATE_TIME_FORMAT),
        scheduledAt2: appt.scheduledAtMoment.format(DB_DATE_TIME_FORMAT),
        overrodePhaseWarning: this.state.showPhaseWarning,
      })
      .then((r) => {
        if (r.data.success === false) {
          this.setState({ message: r.data.message, messageFlavor: "danger" });
        } else {
          this.setState({
            message: null,
            confirmApptId: null,
            showPhaseWarning: false,
            warnRescheduleCount: false,
            warnRescheduleTiming: false,
            warn24Hours: false,
            confirmingRescheduleDetails: null,
          });
          storage.setItem("nextAppt", JSON.stringify(appt));
          this.props.onSuccess(
            "Your appointment has been rescheduled. You will receive an email with a calendar reminder momentarily."
          );
        }
      })
      .catch(helpers.catchHandler);
  }

  doScheduleAppt(id) {
    const appt = getApptById(this.state.appointmentSlots, id);
    api
      .post("Appt/Schedule", {
        id: id,
        id2: null,
        scheduledAt: appt.scheduledAtMoment.format(DB_DATE_TIME_FORMAT),
        scheduledAt2: null,
        overrodePhaseWarning: this.state.showPhaseWarning,
      })
      .then((r) => {
        if (r.data.success === false) {
          this.setState({ message: r.data.message, messageFlavor: "danger" });
        } else {
          this.setState({
            message: null,
            confirmApptId: null,
            showPhaseWarning: false,
            warnRescheduleCount: false,
            warnRescheduleTiming: false,
            warn24Hours: false,
          });
          delete appt.scheduledAtMoment;
          storage.setItem("nextAppt", JSON.stringify(appt));
          this.props.onSuccess(
            "Your appointment has been booked.  You will receive an email with a calendar reminder momentarily."
          );
        }
      })
      .catch(helpers.catchHandler);
  }

  onSlotClick(id) {
    const proposedSlot = getApptById(this.state.appointmentSlots, id);
    const u = this.props.currentUser;
    if (proposedSlot.scheduledAtMoment.isBefore(moment())) {
      this.setState({
        message: "You may not change an appointment in the past.",
        messageFlavor: "danger",
      });
      return;
    }
    if (
      longSlotRequiredButUnavailable(
        this.state.appointmentSlots,
        proposedSlot,
        u.currentPhaseNo
      )
    ) {
      this.setState({
        message:
          "An hour long appointment is required and this slot cannot accommodate that duration.  Please select another time.",
        messageFlavor: "danger",
      });
      return;
    }
    let warn24Hours = false;
    if (proposedSlot.scheduledAtMoment.isBefore(moment().add(1, "days"))) {
      warn24Hours = true;
    }
    if (this.props.nextAppt) {
      // REscheduling
      let warnRescheduleTiming = false;
      if (u.showWarningOnScheduleHorizon) {
        const existingMoment = date_helpers.getMomentFromString(
          this.props.nextAppt.scheduledAtString,
          DATE_TIME_FORMAT
        );
        if (
          proposedSlot.scheduledAtMoment.diff(existingMoment, "days") >
          getMaxAllowableRescheduleDays(u.currentPhaseNo)
        ) {
          warnRescheduleTiming = true;
        }
      }
      const warnRescheduleCount =
        u.showWarningOnReschedule && u.changeCountSinceLastVisit >= 2;
      if (warnRescheduleCount || warnRescheduleTiming || warn24Hours) {
        this.setState({
          confirmApptId: id,
          showPhaseWarning: warnRescheduleCount || warnRescheduleTiming,
          warnRescheduleCount: warnRescheduleCount,
          warnRescheduleTiming: warnRescheduleTiming,
          warn24Hours: warn24Hours,
        });
      } else {
        this.setState({
          confirmingRescheduleDetails: {
            oldApptId: this.props.nextAppt.id,
            newApptId: id,
          },
        });
      }
    } else {
      if (!u.canScheduleInitialAppt) {
        this.setState({
          message:
            "You may not schedule your initial appointment.  Please contact Peak Health at (252)237-5090 or appointment@peak-health.net for assistance.",
          messageFlavor: "danger",
        });
        return;
      }
      if (warn24Hours) {
        this.setState({
          confirmApptId: id,
          warn24Hours: warn24Hours,
        });
      } else {
        this.doScheduleAppt(id);
      }
    }
  }

  proceed() {
    if (this.props.nextAppt) {
      this.setState({
        confirmingRescheduleDetails: {
          oldApptId: this.props.nextAppt.id,
          newApptId: this.state.confirmApptId,
        },
      });
    } else {
      this.doScheduleAppt(this.state.confirmApptId);
    }
  }

  nevermind() {
    this.setState({
      warnRescheduleCount: false,
      warnRescheduleTiming: false,
      warn24Hours: false,
      confirmApptId: null,
      confirmingRescheduleDetails: null,
    });
  }

  render() {
    const showingWarnings =
      this.state.warnRescheduleCount ||
      this.state.warnRescheduleTiming ||
      this.state.warn24Hours;
    return (
      <Modal isOpen toggle={this.props.onCancel} backdrop>
        <ModalHeader toggle={this.props.onCancel} charCode="X">
          {this.props.appointmentDate.format("MMMM DD, YYYY")}
          <div
            style={{
              color: "#FF0000",
              fontStyle: "italic",
              fontSize: "0.9rem",
            }}
          >
            The below times are {this.props.locationTimezone}.
          </div>
        </ModalHeader>
        <ModalBody
          className="ml-2 mr-2"
          style={{ height: "500px", overflow: "auto" }}
        >
          {this.state.message === null ? null : (
            <Alert color={this.state.messageFlavor}>{this.state.message}</Alert>
          )}
          {this.state.confirmingRescheduleDetails ? (
            <Fragment>
              <Row>
                <Col>
                  You have requested a reschedule to the following appointment,
                  would you like to continue?
                  <div className="my-2">
                    {this.props.nextAppt.scheduledAtString} at{" "}
                    {this.props.nextAppt.locationName} with{" "}
                    {this.props.nextAppt.evaluatorFullName}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ButtonGroup className="float-right">
                    <Button color="primary" onClick={this.doRescheduleAppt}>
                      <span>Yes, Reschedule It</span>
                    </Button>
                    <Button color="secondary" onClick={this.nevermind}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Fragment>
          ) : null}
          {!this.state.confirmingRescheduleDetails && showingWarnings ? (
            <Alert color="warning">
              {this.state.warn24Hours ? <Warn24 /> : null}
              {/*this.state.warnRescheduleCount
                  ? (<div>
                      <WarnTitle />
                      This is your third consecutive reschedule, which will result in your cost center being charged a rescheduling fee.
                      The reason for the rescheduling is not considered.
                     </div>)
                  : null
                  */}
              {this.state.warnRescheduleTiming ? (
                <div>
                  <WarnTitle />
                  You are attempting to reschedule an appointment outside of the
                  program guidelines.
                  <br />
                  If you chose to continue, your phase will be reduced to Phase
                  1.
                </div>
              ) : null}
              <div>
                <ButtonGroup className="float-right">
                  <Button color="primary" onClick={this.proceed}>
                    <span>Yes, Do It</span>
                  </Button>
                  <Button color="secondary" onClick={this.nevermind}>
                    Nevermind
                  </Button>
                </ButtonGroup>
              </div>
            </Alert>
          ) : null}
          {!this.state.confirmingRescheduleDetails &&
            !showingWarnings &&
            this.state.appointmentSlots.map((a) => (
              <Row key={`aslt-${a.id}`}>
                <Col sm="3">
                  <span style={{ width: "95px" }}>
                    {a.scheduledAtMoment.format(date_helpers.TIME_FORMAT)}
                  </span>
                </Col>
                <Col>
                  <Button
                    style={{ width: "100%", borderRadius: 0 }}
                    color={resolveSlotClass(a.isAvailable, a.isMine)}
                    disabled={!a.isAvailable}
                    onClick={
                      a.isAvailable ? () => this.onSlotClick(a.id) : null
                    }
                  >
                    {a.isMine
                      ? "My Appointment"
                      : a.isAvailable
                      ? "Available"
                      : "Not Available"}
                  </Button>
                </Col>
              </Row>
            ))}
        </ModalBody>
        <ModalFooter>
          <Button className="float-right" onClick={this.props.onCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export class AppointmentCalendar extends Component {
  constructor(props) {
    super(props);
    const canSeeMap = !props.currentUser.conferenceCallOnly;
    const nextAppt = helpers.getNextAppt();
    const userCanSchedule = // props.currentUser.currentPhaseNo > 0
      props.currentUser.canScheduleInitialAppt ||
      (nextAppt !== null && nextAppt !== undefined);
    this.state = {
      message: null,
      messageFlavor: null,
      zoom: 13,
      lastNavigateDate: moment().startOf("month"),
      eventList: [],
      locationsList: [],
      availableAppointmentList: [],
      selectedLocation: null,
      selectedEventDate: null,
      showAvailableAppointments: false,
      mapCenter: null,
      warn24Hours: false,
      showWarningMessage: false,
      canSeeMap: canSeeMap,
      nextAppt: nextAppt,
      userCanSchedule: userCanSchedule,
      redirectToHome: false,
      loading: false,
    };
    this.mapRef = null;
    this.onSelectLocation = this.onSelectLocation.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.appointmentScheduleSuccess =
      this.appointmentScheduleSuccess.bind(this);
    this.onMapLocationPicked = this.onMapLocationPicked.bind(this);
    this.cancelAppt = this.cancelAppt.bind(this);
    this.doCancelAppt = this.doCancelAppt.bind(this);
    this.nevermind = this.nevermind.bind(this);
    this.onCalendarNavigate = this.onCalendarNavigate.bind(this);
    this.refreshAvailable = this.refreshAvailable.bind(this);
  }

  componentDidMount() {
    const getLocations = api
      .post("Appt/AppointmentLocations", {
        ConferenceOnly: this.props.currentUser.conferenceCallOnly,
        NonConferenceOnly: !this.props.currentUser.canSeeConferenceCalls,
        State: this.props.currentUser.state,
      })
      .then((r) => {
        const locations = _.chain(r.data)
          // .filter(x => x.latitude && x.longitude) // a multitude of invalid/wild locations excluded with this
          .map((x) => {
            const isTelephonic = x.isConference ? " TELEPHONIC" : "";
            let label = `${x.city}, ${x.stateAbbr || ""} - ${
              x.name
            }${isTelephonic}`;
            return {
              id: x.id,
              label: label,
              name: x.name,
              value: x.id,
              city: x.city,
              state: x.stateAbbr,
              timezone: x.timezone,
              position: { lat: x.latitude, lng: x.longitude },
              restrictUsers: x.restrictByUserList,
            };
          })
          .sortBy((x) => x.label)
          .value();
        return { locationsList: locations };
      })
      .catch(helpers.catchHandler);

    const getUserAppt = api
      .fetch(`Appt/${this.props.currentUser.id}`)
      .then((r) => {
        const na = r.data.length ? r.data[0] : null;
        return {
          nextAppt: na,
          userCanSchedule:
            this.props.currentUser.canScheduleInitialAppt || na !== null,
        };
      })
      .catch(helpers.catchHandler);

    Promise.all([getLocations, getUserAppt])
      .then((results) => {
        let newStatus = {};
        _.each(results, (r) => Object.assign(newStatus, r));
        if (newStatus.nextAppt && !this.state.selectedLocation) {
          newStatus.selectedLocation = _.find(
            newStatus.locationsList,
            (x) => x.id === newStatus.nextAppt.locationId
          );
          this.setState(newStatus, this.refreshAvailable);
        } else {
          this.setState(newStatus);
        }
      })
      .catch(helpers.catchHandler);
  }

  doCancelAppt() {
    confirmAlert({
      title: "Confirm Cancellation",
      message:
        "You have requested a cancellation of your appointment, would you like to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const scheduledAtMoment = date_helpers.getMomentFromString(
              this.state.nextAppt.scheduledAtString,
              DATE_TIME_FORMAT
            );
            api
              .post("Appt/Cancel", {
                id: this.state.nextAppt.id,
                scheduledAt: scheduledAtMoment.format(DB_DATE_TIME_FORMAT),
              })
              .then((r) => {
                if (r.data.success === false) {
                  this.setState({
                    message: r.data.message,
                    messageFlavor: "danger",
                  });
                } else {
                  this.setState({
                    message:
                      "Your appointment is now cancelled. You will be redirected to the home page momentarily.",
                    messageFlavor: "success",
                    warn24Hours: false,
                    showWarningMessage: false,
                  });
                  storage.removeItem("nextAppt");
                  setTimeout(() => {
                    this.setState({ redirectToHome: true });
                  }, 4000);
                }
              })
              .catch(helpers.catchHandler);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  cancelAppt() {
    let newState = { warn24Hours: false, showWarningMessage: true };
    const scheduledAtMoment = date_helpers.getMomentFromString(
      this.state.nextAppt.scheduledAtString,
      DATE_TIME_FORMAT
    );
    if (scheduledAtMoment.isBefore(moment().add(1, "days"))) {
      newState.warn24Hours = true;
    }
    this.setState(newState);
  }

  nevermind() {
    this.setState({ showWarningMessage: false, warn24Hours: false });
  }

  refreshAvailable(locationId = null, fromDate = null) {
    if (!locationId && this.state.selectedLocation) {
      locationId = this.state.selectedLocation.value;
    }
    if (!locationId) return;
    if (!fromDate && this.state.lastNavigateDate) {
      fromDate = this.state.lastNavigateDate; // new Date(this.state.lastNavigateDate).getTime();
    }
    const url = fromDate
      ? `Appt/LocationsAvailableAppointments/${locationId}/${date_helpers
          .getMomentFromString(fromDate)
          .format(DATE_FORMAT)}`
      : `Appt/LocationsAvailableAppointments/${locationId}`;
    this.setState({ loading: true });
    api
      .fetch(url)
      .then((r) => {
        const list = _.map(r.data, (x) => {
          x.scheduledAtMoment = date_helpers.getMomentFromString(
            x.scheduledAtString,
            DATE_TIME_FORMAT
          );
          return x;
        });
        let events = [];
        const groupedByDate = _.groupBy(list, function (e) {
          return e.scheduledAtMoment.format(DATE_FORMAT);
        });
        for (const dt in groupedByDate) {
          const dayList = groupedByDate[dt];
          events.push({
            id: dt,
            start: _.min(dayList, (x) => x.scheduledAt).scheduledAtMoment,
            end: _.max(dayList, (x) => x.scheduledAt).scheduledAtMoment,
            count: dayList.length,
            title: `${dayList.length} Open`,
            locationId: locationId,
            isAvailable: true,
          });
        }
        this.setState({
          availableAppointmentList: list,
          eventList: events,
        });
        if (this.mapRef) {
          try {
            this.mapRef.contextValue.map._container.click();
            // the react leaflet API does not support this:
            // this.mapRef.leafletElement.options.leaflet.map.closePopup();
          } catch (err) {
            console.warn(
              "failed to re-center map upon location selection from the dropdown",
              err
            );
          }
        }
      })
      .catch(helpers.catchHandler)
      .finally(() => this.setState({ loading: false }));
  }

  onCalendarNavigate(d) {
    const newNavDate = moment(d).startOf("month");
    this.setState({ lastNavigateDate: newNavDate }, this.refreshAvailable);
  }

  onSelectLocation(selection) {
    this.setState({
      selectedLocation: selection,
      mapCenter: selection.position,
    });
    this.refreshAvailable(selection.value);
  }

  onSelectEvent(e) {
    this.setState({
      showAvailableAppointments: true,
      selectedAppointmentDate: e.start,
    });
  }

  onCancel() {
    this.setState({ showAvailableAppointments: false });
  }

  appointmentScheduleSuccess(message) {
    this.setState({
      message: message,
      messageFlavor: "success",
      showAvailableAppointments: false,
    });
    setTimeout(() => {
      this.setState({ redirectToHome: true });
    }, 4000);
  }

  onMapLocationPicked(selection) {
    this.onSelectLocation(selection);
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to="/home" />;
    }
    const newDate = this.state.lastNavigateDate.toDate();

    return (
      <PeakSpinner on={this.state.loading}>
        <Card>
          <CardHeader>Appointments</CardHeader>
          <CardBody>
            {this.state.showAvailableAppointments ? (
              this.state.userCanSchedule ? (
                <ScheduleAppointment
                  appointmentDate={this.state.selectedAppointmentDate}
                  availableAppointmentList={availableListByDay(
                    this.state.selectedAppointmentDate,
                    this.state.availableAppointmentList
                  )}
                  onCancel={this.onCancel}
                  currentUser={this.props.currentUser}
                  onSuccess={this.appointmentScheduleSuccess}
                  nextAppt={this.state.nextAppt}
                  location={this.state.selectedLocation}
                  locationTimezone={this.state.selectedLocation.timezone}
                />
              ) : (
                <Alert color="danger">
                  <Row>
                    <Col style={{ fontWeight: "bold", fontSize: "16pt" }}>
                      Self-Schedule Not Permitted
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      Your first appointment must be scheduled by Peak. You can
                      contact Peak Health at 252-237-5090 or
                      appointment@peak-health.net.
                    </Col>
                    <Col>
                      <ButtonGroup className="float-right">
                        <Button color="primary" onClick={this.onCancel}>
                          OK
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Alert>
              )
            ) : null}
            <Row>
              <Col>
                {this.state.message === null ? null : (
                  <Alert color={this.state.messageFlavor}>
                    {this.state.message}
                  </Alert>
                )}
                {this.state.showWarningMessage ? (
                  <Fragment>
                    <Alert color="info">
                      <div>
                        Are you sure you want to cancel and not reschedule your
                        appointment?
                      </div>
                      {this.props.currentUser.showWarningOnScheduleHorizon ? (
                        <span style={{ fontWeight: "bold" }}>
                          If you do not reschedule within 60 days of your
                          original appointment date, you will be dropped from
                          the program and will lose all incentives.
                        </span>
                      ) : null}
                    </Alert>
                    <Alert color="warning">
                      {this.state.warn24Hours ? <Warn24 /> : null}
                      <Row>
                        <Col>
                          <ButtonGroup className="float-right">
                            <Button color="primary" onClick={this.doCancelAppt}>
                              Yes, Cancel It
                            </Button>
                            <Button color="secondary" onClick={this.nevermind}>
                              Nevermind
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </Alert>
                  </Fragment>
                ) : (
                  <Row className="mb-2">
                    {this.state.nextAppt ? (
                      <Col>
                        Your{" "}
                        {this.state.nextAppt.isTelephonic
                          ? "telephonic"
                          : "onsite"}{" "}
                        appointment is scheduled for{" "}
                        {this.state.nextAppt.scheduledAtString}. (Select a
                        different appointment date and time below to{" "}
                        <span style={{ color: "green", fontWeight: "bold" }}>
                          reschedule
                        </span>
                        .) If you need to cancel, you may do so by selecting{" "}
                        <Button
                          onClick={this.cancelAppt}
                          color="danger"
                          size="sm"
                          className="xs"
                          style={{ marginLeft: "6px" }}
                        >
                          cancel
                        </Button>
                        .
                      </Col>
                    ) : (
                      <Fragment>
                        {this.props.currentUser.canScheduleInitialAppt ? (
                          <Col>
                            You have no existing appointments to reschedule,
                            please select an original appointment slot.
                          </Col>
                        ) : (
                          <Col>
                            You have no existing appointments to reschedule.
                          </Col>
                        )}
                      </Fragment>
                    )}
                  </Row>
                )}
                <Row className="mb-2">
                  <Col sm="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          Select Appointment Location/Nurse
                        </InputGroupText>
                      </InputGroupAddon>
                      <div
                        className="form-control"
                        style={{
                          padding: 0,
                          borderStyle: "none",
                          backgroundColor: "#fff",
                          zIndex: 1049,
                        }}
                      >
                        <Select
                          closeMenuOnSelect={true}
                          isMulti={false}
                          components={makeAnimated()}
                          options={this.state.locationsList}
                          onChange={this.onSelectLocation}
                          value={this.state.selectedLocation}
                          onBlurResetsInput={false}
                          onSelectResetsInput={false}
                          onCloseResetsInput={false}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mb-0">
                  <Col sm="12" className="pb-1">
                    <span
                      style={{
                        color: "secondary",
                        fontStyle: "italic",
                        fontSize: "10pt",
                      }}
                    >
                      The locations available to you are based on your profile.
                      To change your location or nurse, please select an
                      alternate location in the Location/Nurse dropdown box. To
                      request an alternate location, contact Peak Health at
                      (252)237-5090 or{" "}
                      <a href="mailto:appointment@peak-health.net">
                        appointment@peak-health.net
                      </a>
                      .
                    </span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="12">
                    <Calendar
                      events={this.state.eventList}
                      views={[Views.MONTH]}
                      defaultView={Views.MONTH}
                      style={{ minHeight: "70vh" }}
                      step={32}
                      selectable
                      showMultiDayTimes
                      defaultDate={newDate}
                      onSelectEvent={this.onSelectEvent}
                      onNavigate={this.onCalendarNavigate}
                      min={
                        new Date(
                          newDate.getFullYear(),
                          newDate.getMonth(),
                          newDate.getDate(),
                          0
                        )
                      }
                      // max={new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23)}
                      localizer={localizer}
                    />
                    {/*
  components={{ timeSlotWrapper: ColoredDateCellWrapper }}
*/}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PeakSpinner>
    );
  }
}
