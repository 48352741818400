import React, { Component } from 'react';
import { Redirect} from 'react-router-dom';
import { Form, Button, ButtonGroup, Card, CardBody, Col, Row, Table, Badge, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditUser, EditUserPassword, FilterBoolean, FilterSet, Pager, SortHeader, FilterText, FilterSelect } from './';
import helpers from '../utils/helpers';
import api from '../utils/api';
import filter_helpers from '../utils/filter_helpers';
import _ from 'lodash';

const truncateRoleList = function(roles) {
  return roles.length > 18
  ? roles.substr(0, 18) + "..."
  : roles;
};

const emptyUser = { 
  id: null, firstName: '', middleName: '', lastName: '', email: '', username: '', active: true, city: '', state: '',
  defaultLocationId: null, defaultLocation: null, companyId: null, companyNo: '', company: null,
  currentPhaseNo: 0, roles: [], mustChangePassword: false, password: '', roleInformation: ''
};

export class UserAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleList: [],
      filter_role_list: [],
      paged_list: {
        list: [],
        page_number: 1,
        total_pages: 0
      },
      editingUser: null,
      editingUserPassword: null,
      sort_field: 'lastName',
      sort_dir: 'asc',
      added_count: 0,
      filters: [],
      message: null,
      messageFlavor: null,
      locationList: [],
      companyList: [],
      clearValue: false,
      redirectToHome: false
    };
    this.showAdd = this.showAdd.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.onCancelDialog = this.onCancelDialog.bind(this);
    this.setPage = this.setPage.bind(this);
    this.doSort = this.doSort.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.currentFilterValue = this.currentFilterValue.bind(this);
    this.impersonate = this.impersonate.bind(this);
  }

  componentDidMount() {
    this.refreshList();
    api.fetch('reference/roles')
      .then(response => {
        const role_list = _.chain(response.data)
          .filter(r => {return r.id > 0; })
          .map(r => { return { value: r.id, label: r.name }; })
          .value();
        let filter_role_list = role_list.slice();
        filter_role_list.unshift({value: null, label: '(Any)'});
        this.setState({
          roleList: role_list,
          filter_role_list: filter_role_list
        });
      }).catch(helpers.catchHandler);
    api.fetch('reference/locations')
      .then(response => this.setState({locationList: helpers.idNameToValueLabel(response.data)}))
      .catch(helpers.catchHandler);
    api.fetch('reference/companies')
      .then(response => this.setState({companyList: helpers.idNameToValueLabel(response.data)}))
      .catch(helpers.catchHandler);
  }

  onCancelDialog() {
    this.setState({ editingUser: null, editingUserPassword: null });
  }

  setPage(page) {
    this.refreshList(null, null, null, page);
  }

  refreshList(sort_field, sort_direction, filters, pageNo, extraProps = null) {
    const context = this;
    const resolvedFilters = filters || this.state.filters;
    let criteria = filter_helpers.transform_filters(resolvedFilters);
    criteria.PageNo = pageNo || 1;
    // sort_field: sort_field || this.state.sort_field,
    // sort_dir: sort_direction || this.state.sort_dir,
    api.post('Admin/Users', criteria)
      .then(r => {
        let newState = {
          paged_list: {
            list: r.data.userList,
            page_number: r.data.pageNo,
            total_pages: r.data.totalPages
          },
          // sort_field: sort_field || this.state.sort_field,
          // sort_dir: sort_direction || this.state.sort_dir,
          filters: resolvedFilters,
          editingUser: null,
          message: null
         };
        if (extraProps) {
          newState = Object.assign(newState, extraProps);
        }
        context.setState(newState);
      }).catch(helpers.catchHandler);
  }

  doSort(field, direction) {
    this.refreshList(field, direction);
  }

  filterChange(changed_filter) {
    const filters = filter_helpers.get_filters(this.state.filters, changed_filter);
    this.setState({ filters: filters, clearValue: false });
    this.refreshList(null, null, filters);
  }

  clearFilters() {
    const filters = [];
    this.setState({ filters: filters, clearValue: true });
    this.refreshList(null, null, filters);
  }

  showAdd(e) {
    this.setState({
      editingUser: emptyUser
    });
  }

  unlock(id) {
    api.post(`Admin/UnlockUser/${id}`).then(r => {
      if (r.data.success === false) {
        this.setState({message: r.data.message, messageFlavor: "danger"});
      } else {
        this.refreshList(null, null, null, 1, {message: 'Unlocked successfully', messageFlavor: 'success'});
      }
    }).catch(helpers.catchHandler);
  }

  forcePasswordChange(id) {
    api.post(`Admin/SetMustChangePassword/${id}`).then(r => {
      if (r.data.success === false) {
        this.setState({message: r.data.message, messageFlavor: "danger"});
      } else {
        this.refreshList(null, null, null, 1, {message: 'This person will be required to change their password upon next logon', messageFlavor: 'success'});
      }
    }).catch(helpers.catchHandler);
  }

  showEdit(u, e) {
    this.setState({ editingUser: u });
  }

  showEditPassword(u, e) {
    this.setState({ editingUserPassword: u})
  }

  handleDelete(id, e) {
    api.post(`Admin/ToggleUserActive/${id}`).then(r => {
      if (r.data.success === false) {
        this.setState({message: r.data.message, messageFlavor: "danger"});
      } else {
        this.refreshList();
      }
    }).catch(helpers.catchHandler);
  }

  handleRevive(id, e) {
    api.post(`Admin/ToggleUserActive/${id}`).then(r => {
      if (r.data.success === false) {
        this.setState({message: r.data.message, messageFlavor: "danger"});
      } else {
        this.refreshList();
      }
    }).catch(helpers.catchHandler);
  }

  currentFilterValue(name) {
    const filterElement = this.state.filters.find(f => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return '';
  }

  impersonate(username) {
    api.fetch(`Admin/Impersonate/${username}`).then(response => {
      if (response.data.success === false) {
        this.setState({ messageFlavor: "danger", message: response.data.message });
      } else {
        this.props.setImpersonation(response.data.user, response.data.user.token, response.data.nextAppt);
        this.setState({ messageFlavor: "success", message: "You are now " + response.data.user.firstName + " " + response.data.user.lastName + " and will be redirected momentarily!" });
        setTimeout(function(component) {
          component.setState({redirectToHome: true});
        }, 3000, this);
      }
    }).catch(helpers.catchHandler);
  };

  render() {
    if (this.state.redirectToHome) {
      return (<Redirect to='/home' />);
    }
    return (
      <Card className="m-2">
        <CardBody>
          {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)}
          <Row className="expand-md mt-2 mb-2 pb-0">
            <Col sm="12">
              <Button color="success" className='float-right' onClick={this.showAdd}>
                <FontAwesomeIcon icon="plus-circle" /> User
              </Button>
              <FilterSet filters={this.state.filters}>
                <Form>
                <Row>
                  <Col>
                    <FilterText filterName="name" displayName="Name"
                      value={this.currentFilterValue('name')} onChangeCallback={this.filterChange} />
                  </Col>
                  <Col>
                    <FilterSelect filterName="MemberOfRole" displayName="Roles"
                      value={this.currentFilterValue('MemberOfRole')}
                      onChangeCallback={this.filterChange}
                      options={this.state.filter_role_list}
                      // defaultValues={filter_any_item}
                      />
                  </Col>
                  <Col>
                    <FilterBoolean filterName="ActiveOnly" displayName="Active Only" yesOnly={true}
                      value={this.currentFilterValue('ActiveOnly')} onChangeCallback={this.filterChange}
                      clearValue={this.state.clearValue} />
                  </Col>
                  <Col>
                    <Button color="dark" className='clear-filters float-right' onClick={this.clearFilters}>
                      <FontAwesomeIcon icon="trash" /> Clear Filters
                    </Button>
                  </Col>
                </Row>
                </Form>
              </FilterSet>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              {this.state.paged_list.list &&
                <div>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th>
                          <SortHeader displayName="Username" field="username" sortDir={this.state.sort_dir}
                            sorted={this.state.sort_field === 'username'} callBack={this.doSort} />
                        </th>
                        <th>
                          <SortHeader displayName="Name" field="last_name" sortDir={this.state.sort_dir}
                            sorted={this.state.sort_field === 'last_name'} callBack={this.doSort} />
                        </th>
                        <th>
                          <SortHeader displayName="Default Location" field="default_location_name" sortDir={this.state.sort_dir}
                            sorted={this.state.sort_field === 'default_location_name'} callBack={this.doSort} />
                        </th>
                        <th>Status &amp; Roles</th>
                        <th width="15%" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.paged_list.list.map(t => (
                        <tr key={t.id} className="data-row">
                          <td>{t.username}<br/>
                            <Badge color="info">Phase {t.currentPhaseNo}</Badge>
                          </td>
                          <td>{t.firstName} {t.middleName} {t.lastName}<br/>
                            <span style={{fontStyle: 'italic'}}>{t.email}</span>
                          </td>
                          <td>{t.city} {t.state}<br/>
                            {t.defaultLocationName}
                          </td>
                          <td>
                            {t.lastLoggedOn === '-'
                              ? <div style={{fontStyle: "italic"}} className="text-warning">Never logged on</div>
                              : <div>Logged On {t.lastLoggedOn}</div>
                            }
                            {t.passwordResetRequested 
                              ? <div><Badge color="warning">Reset Pending!</Badge></div> 
                              : null
                            }
                            {t.mustChangePassword 
                              ? <div><Badge color="warning">Password Change Required!</Badge></div> 
                              : null
                            }
                            {t.failedAttemptCount > 0
                              ? <div>
                                  <span className="text-danger" style={{marginRight: "4px"}}>{t.failedAttemptCount} failed attempt(s)</span>
                                  {t.isLockedOut 
                                    ? <span>
                                        <Badge color="warning">Locked Out!</Badge>
                                      </span>
                                    : null
                                  }
                                </div>
                              : null
                            }
                            {t.deactivatedAt 
                              ? <div className="text-danger"><FontAwesomeIcon icon='times-circle' size='2x' title="Deactivated" /></div>
                              : null
                            }
                            <div title={t.roleInformation} style={{fontStyle: 'italic'}}>{truncateRoleList(t.roleInformation)}</div>
                          </td>
                          <td className="text-right">
                            <ButtonGroup>
                              {t.isLockedOut 
                                ? <Button color="secondary" onClick={() => this.unlock(t.id)} size="sm" title="Unlock">
                                    <FontAwesomeIcon icon='unlock' />
                                  </Button>
                                : null
                              }
                              {t.mustChangePassword
                                ? null
                                : <Button color="warning" onClick={() => this.forcePasswordChange(t.id)} size="sm" title="Force Password Change">
                                    <FontAwesomeIcon icon='key' />
                                  </Button>
                              }
                              {this.props.currentUser.isAdmin
                                ? <Button color="info" onClick={this.showEditPassword.bind(this, t)} size="sm" title="Change Password">
                                    <FontAwesomeIcon icon='edit' />
                                  </Button>
                                : null
                              }
                              {this.props.currentUser.isAdmin
                                ? <Button color="primary" onClick={() => this.impersonate(t.username)} size="sm" title="Impersonate">
                                    <FontAwesomeIcon icon='mask' />
                                  </Button>
                                : null
                              }
                              <Button color="dark" onClick={this.showEdit.bind(this, t)} size="sm">
                                <FontAwesomeIcon icon='edit' /> Edit
                              </Button>
                              {t.deactivatedAt
                                ? (<Button color="info" size="sm" onClick={this.handleRevive.bind(this, t.id)}>
                                    <FontAwesomeIcon icon='recycle' /> Revive
                                  </Button>)
                                : (<Button color="danger" size="sm" onClick={this.handleDelete.bind(this, t.id)}>
                                    <FontAwesomeIcon icon='times-circle' />
                                  </Button>)
                              }
                            </ButtonGroup>
                          </td>
                        </tr>))
                      }
                    </tbody>
                  </Table>
                  <Pager {...this.state.paged_list} callBack={this.setPage} />
                </div>
                }
            </Col>
            {this.state.editingUser
              ? (<EditUser 
                  onSuccess={this.refreshList} 
                  onCancel={this.onCancelDialog}
                  currentUser={this.props.currentUser}
                  user={this.state.editingUser}
                  roleList={this.state.roleList} 
                  companyList={this.state.companyList} 
                  locationList={this.state.locationList}
                />)
              : null
            }
            {this.state.editingUserPassword
              ? (<EditUserPassword 
                  onSuccess={this.refreshList} 
                  onCancel={this.onCancelDialog}
                  currentUser={this.props.currentUser}
                  user={this.state.editingUserPassword}
                />)
              : null
            }
          </Row>
        </CardBody>
      </Card>
    );
  }
}
