import React, { Component } from 'react';
import { Card, CardBody, CardImg } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import { TemplateCollapsibleCard } from './';

export class WidgetImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  render() {
    return (
      <Card className="homeWidgets mb-2 mt-0 mx-0" style={{borderStyle: "none"}}>
        <CardBody style={{padding: "0"}}>
          {this.props.imageKey
            ? <CardImg top width="100%" src={`/Content/panels/${this.props.imageKey}`} alt={this.props.imageKey} className="m-0" />
            : null
          }
        </CardBody>
      </Card>
    );
  }
}