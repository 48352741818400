import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, CardHeader, Card, CardBody, Table, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input, Form, CardTitle} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { SortHeader } from './';
import helpers from '../utils/helpers';
import api from '../utils/api';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const emptyCompany = {
  id: null,
  companyNo:'',
  description: '',
  companyGroupId: null,
  companyGroupName: '',
  labFormExists: false,
  companyReleaseFormExists: false,
  informedConsentFormExists: false,
  privacyDisclosureFormExists: false,
  canSeeConferenceCalls: false,
  conferenceCallOnly: false,
  showWarningOnReschedule: false,
  showWarningOnScheduleHorizon: false,
  canScheduleInitialAppt: true,
  sendApptCalendarEntry: true,
  typeOfSurvey: null,
  typeOfSSO: null,
  active: true
};

const transformCompanyList = (list) => {
  return _.map(list, c => {
    c.labFormExists = (c.labForm !== "");
    c.companyReleaseFormExists = (c.companyReleaseForm !== "");
    c.privacyDisclosureFormExists = (c.privacyDisclosureForm !== "");
    c.informedConsentFormExists = (c.informedConsentForm !== "");
    c.labForm = null;
    c.companyReleaseForm = null;
    c.privacyDisclosureForm = null;
    c.informedConsentForm = null;
    return c;
  });
};

const getPropNameFromUploadType = (uploadTypeName) => {
  return uploadTypeName.substring(0, 1).toLowerCase() + 
    uploadTypeName.substring(1) + 
    "Exists";
};

export class Companies extends Component {
constructor(props) {
  super(props);
  this.state = {
    message: null,
    messageFlavor: null,
    editGroup: null,
    editCompany: null,
    ssoTypeList: [],
    surveyTypeList: [],
    companyGroupList: []
  };
  this.refreshList = this.refreshList.bind(this);
  this.reloadList = this.reloadList.bind(this);
  this.onCancel=this.onCancel.bind(this);
  this.companyAdd = this.companyAdd.bind(this);  
  this.onEditCompany = this.onEditCompany.bind(this);
  this.onDeleteCompany = this.onDeleteCompany.bind(this);
  this.onReviveCompany = this.onReviveCompany.bind(this);
  this.expandCompanyGroup = this.expandCompanyGroup.bind(this);
  this.onESditCompany = this.onESditCompany.bind(this);
}


onESditCompany(c) {
  let sso = this.state.ssoTypeList;
  let survey = this.state.surveyTypeList;
  this.props.setPageEdit(c, sso, survey);
}


componentDidMount() {
  const getSSOTypes = api.fetch('Reference/SSOTypes')
    .then(r => { 
      return { 
        ssoTypeList: helpers.idNameToValueLabel(r.data) 
      }; 
    }).catch(helpers.catchHandler);
  const getSurveyTypes = api.fetch('Reference/SurveyTypes')
    .then(r => { 
      return { 
        surveyTypeList: helpers.idNameToValueLabel(r.data)  
      }; 
    }).catch(helpers.catchHandler);
  Promise.all([getSSOTypes, getSurveyTypes, this.refreshList()]).then(results => {
    let newStatus = {};
    _.each(results, r => Object.assign(newStatus, r));
    this.setState(newStatus);
  }).catch(helpers.catchHandler);
}

companyGroupEdit(group){
  this.setState({ editGroup: group });
}

companyAdd(group){
  let company = Object.assign({}, emptyCompany);
  company.companyGroupId = group.id;
  company.companyGroupName = group.name;
  this.setState({ editCompany: company });
}

onEditCompany(company){
  this.setState({ editCompany: company});
}

onCancel() {
  // this.reloadList(this.state.editCompany ? this.state.editCompany.id : null);
  this.setState({ editCompany: null, editGroup: null });
}

reloadList(expandCompanyGroupId = null) {
  this.refreshList()
    .then(chunkOfState => {
      this.setState(chunkOfState, () => {
        // console.log('expanding ' + expandCompanyGroupId)
        if (expandCompanyGroupId) {
          this.toggleGroupInfo(expandCompanyGroupId);
        }
      });
    }).catch(helpers.catchHandler);
}

onDeleteCompany(companyId, companyGroupId) {
  api.post(`Admin/DeleteCompany/${companyId}`, {}).then(r => { 
    if (r.data.success === false) {
      this.setState({message: r.data.message, messageFlavor: "danger"});
    } else {
      this.expandCompanyGroup(companyGroupId);
    }
  }).catch(helpers.catchHandler);
}

onReviveCompany(companyId, companyGroupId) {
  api.post(`Admin/ReviveCompany/${companyId}`).then(r => { 
    if (r.data.success === false) {
      this.setState({message: r.data.message, messageFlavor: "danger"});
    } else {
      this.expandCompanyGroup(companyGroupId);
    }
  }).catch(helpers.catchHandler);
}

refreshList() {
  return api.fetch('Admin/CompanyGroups').then(r => {
    const list = r.data.map(g => {
      g.expanded = false;
      return g;
    });
    return {companyGroupList: list, editCompany: null, editGroup: null };
  }).catch(helpers.catchHandler);
}

expandCompanyGroup(companyGroupId) {
  const groupIndex = _.findIndex(this.state.companyGroupList, function(x) { return x.id === companyGroupId; });
  let list = this.state.companyGroupList.slice();
  api.fetch(`Reference/CompaniesByGroup/${companyGroupId}`).then(response => {
    list[groupIndex].companyList = transformCompanyList(response.data);
    list[groupIndex].expanded = true;
    this.setState({companyGroupList: list});
  }).catch(helpers.catchHandler);
}

toggleGroupInfo(companyGroupId){
  const groupIndex = _.findIndex(this.state.companyGroupList, function(x) { return x.id === companyGroupId; });
  let list = this.state.companyGroupList.slice();
  if (list[groupIndex].expanded) {
    list[groupIndex].expanded = false;
    this.setState({companyGroupList: list});
  } else {
    this.expandCompanyGroup(companyGroupId);
  }
}
  
render() {                                                  
  return (
  <Card className="m-2">
    <CardHeader className="strong-header">
      <Button color="success" className="float-right" onClick={() =>this.companyGroupEdit({id: null, name: ''})}>
        <FontAwesomeIcon icon='plus-circle' /> Group
      </Button>
      Company Group
    </CardHeader>
    <CardBody>
    {this.state.companyGroupList.map(g => (
      <React.Fragment key={g.id}>
        <Row className="expand-md mt-2 mb-2 pb-0">
          <Col sm="8" className="group-title">{g.name}</Col>
          <Col> 
            <ButtonGroup className="float-right">
              <Button outline color="secondary" size="sm" onClick={() =>this.companyGroupEdit(g)}>
                <FontAwesomeIcon icon="edit"/> Edit Group
              </Button>
              <Button color={g.expanded ? "secondary" : "dark"} size="sm"  onClick={() => this.toggleGroupInfo(g.id)}>
                <FontAwesomeIcon icon={g.expanded ? "minus-circle" : "plus-circle"} /> Companies
              </Button> 
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
          {g.expanded 
            ? <CompanyList 
                group={g} 
                companyAdd={this.companyAdd}
                onEditCompany={this.onEditCompany} 
                onReviveCompany={this.onReviveCompany} 
                onDeleteCompany={this.onDeleteCompany}
                onESditCompany={this.onESditCompany} 
              />
            : null
          }
          </Col>
        </Row>
       </React.Fragment>
    ))}  
    </CardBody>
{this.state.editGroup === null ? null :
  <EditCompanyGroup 
    onSuccess={this.reloadList} 
    onCancel={this.onCancel}
    group={this.state.editGroup}
  />
}
{this.state.editCompany === null ? null :
  <EditCompany 
    onSuccess={this.reloadList} 
    onCancel={this.onCancel} 
    ssoTypeList={this.state.ssoTypeList}
    surveyTypeList={this.state.surveyTypeList}
    company={this.state.editCompany}
  />
}
  </Card>
    );
  }
}

class CompanyList extends Component {
constructor(props) {
  super(props);
  this.state = {};
}

render() {
  const g = this.props.group;
  return (
    <Table striped hover size="sm">
    <thead>
    <tr>
      <th>Company</th>
      <th className="text-center">Active</th>
      <th>Survey</th>
      <th>SSO</th>
      <th>Appointment Switches</th>
      <th>
        <Button className="float-right" color="success" size="sm" onClick={() => this.props.companyAdd(g)}>
          <FontAwesomeIcon icon="plus-circle"/> Company
        </Button>
      </th>
    </tr>
    </thead>
    <tbody>
    {g.companyList.map(c => (
      <tr className="data-row" key={c.id}>
        <td>
          <div>{c.companyNo}</div>
          <span className="dim-text">{c.description}</span>
        </td>
        <td className="text-center">{c.active ? <FontAwesomeIcon className="text-success" icon='check-circle' size="2x" /> : null}</td>
        <td>{c.surveyTypeName}</td>
        <td>{c.ssoTypeName}</td>
        <td>
          {c.canSeeConferenceCalls ? <span className="company-switch" title='Can see conference calls'><FontAwesomeIcon icon='phone' size='2x' /></span> : null}
          {c.conferenceCallOnly ? <span className="company-switch success" title='Conference calls Only'><FontAwesomeIcon icon='phone' size='2x' /></span> : null}
          {c.showWarningOnReschedule ? <span className="company-switch" title='Warn on reschedule 3 times'><FontAwesomeIcon icon='calendar-times' size='2x' /></span> : null}
          {c.showWarningOnScheduleHorizon ? <span className="company-switch" title='Warn on schedule beyond forecast horizon'><FontAwesomeIcon icon='exclamation-triangle' size='2x' /></span> : null}
          {c.canScheduleInitialAppt ? <span className="company-switch" title='Can schedule initial appointment'><FontAwesomeIcon icon='clock' size='2x' /></span> : null}
          {c.sendApptCalendarEntry ? <span className="company-switch" title='Send appointment calendar entry'><FontAwesomeIcon icon='calendar-alt' size='2x' /></span> : null}
        </td>
        <td className="text-right">
          <ButtonGroup>
            {/* <Button color="dark" onClick={() => this.props.onEditCompany(c)} size="sm">
              <FontAwesomeIcon icon='edit' /> Edit
            </Button> */}
            <Button color="dark" onClick={() => this.props.onESditCompany(c)} size="sm">
              <FontAwesomeIcon icon='edit' /> Edit
            </Button>
            {c.active
              ? (<Button color="danger" size="sm" onClick={() => this.props.onDeleteCompany(c.id, c.companyGroupId)}>
                  <FontAwesomeIcon icon='times-circle' />
                </Button>)
              : (<Button color="info" size="sm" onClick={() => this.props.onReviveCompany(c.id, c.companyGroupId)}>
                  <FontAwesomeIcon icon='recycle' /> Revive
                </Button>)
            }             
          </ButtonGroup>
        </td>
      </tr>
      ))}
      </tbody>
    </Table>);
  }
}

class EditCompany extends Component {
constructor(props) {
  super(props);
  let company = this.props.company;
  if(!company.healthProgramName) company.healthProgramName = '';
  company.surveyType = helpers.resolveValue(null, company.typeOfSurvey, company.surveyTypeName);
  company.ssoType = helpers.resolveValue(null, company.typeOfSSO, company.ssoTypeName);
  this.state = {
    message: null,
    messageFlavor: null,
    uploadTypeList: [
      {label: "Lab Form", value: "LabForm"},
      {label: "Informed Consent Form", value: "InformedConsentForm"},
      {label: "Company Release Form", value: "CompanyReleaseForm"},
      {label: "Privacy Disclosure Form", value: "PrivacyDisclosureForm"}
    ],
    uploadType: {label: "Lab Form", value: "LabForm"},
    company: company
  };
  this.handleSubmit = this.handleSubmit.bind(this);
  this.onTextChange = this.onTextChange.bind(this);
  this.onBoolChange = this.onBoolChange.bind(this);
  this.onSurveyChange = this.onSurveyChange.bind(this);
  this.onSSOChange = this.onSSOChange.bind(this);
  this.onPDFDrop = this.onPDFDrop.bind(this);
  this.onUploadTypeChange = this.onUploadTypeChange.bind(this);
}

onTextChange(event) {
  this.setState({company : {...this.state.company, [event.target.name]: event.target.value }});
}

onBoolChange(event) {
  this.setState({company : {...this.state.company, [event.target.name]: !this.state.company[event.target.name] }});
}

onSSOChange(selection) {
  this.setState({
    company: {
      ...this.state.company,
      ssoType: selection
    }
  });
}

onSurveyChange(selection) {
  this.setState({
    company: {
      ...this.state.company,
      surveyType: selection
    }
  });
}

onUploadTypeChange(selection) {
  this.setState({ uploadType: selection });
}

onPDFDrop(acceptedFiles, rejectedFiles) {
  if (rejectedFiles.length) {
    this.setState({ message: 'One or more files were rejected.', messageFlavor: "danger" });
  } else {
    let newCompany = Object.assign({}, this.state.company);
    if (!newCompany.files) {
      newCompany.files = [];
    }
    const existingFileIdx = _.findIndex(newCompany.files, f => { return f.uploadTypeId === this.state.uploadType.value; });
    if (existingFileIdx >= 0) {
      newCompany.files[existingFileIdx].content = acceptedFiles[0];
    } else {
      newCompany.files.push({uploadTypeId: this.state.uploadType.value, content: acceptedFiles[0]});
    }
    newCompany[getPropNameFromUploadType(this.state.uploadType.value)] = true;
    let newState = { 
      company: newCompany, 
      message: null 
    };
    this.setState(newState);
  }
}

isFormValid(){
  let warnings =[];
  if(!this.state.company.companyNo || this.state.company.companyNo.length < 0){
    warnings.push("Please provide a valid company #")
  }
  if(!this.state.company.description){
    warnings.push("Please provide a valid company description")
  }
  if(!this.state.company.surveyType) {
    warnings.push("Select a valid survey type");
  }
  if(!this.state.company.ssoType) {
    warnings.push("Select a valid SSO type");
  }
  // if(!this.state.company.labFormExists && (!this.state.company.files || !_.some(this.state.company.files, f => { return f.uploadTypeId === "LabForm"; }))) {
  //   warnings.push("A lab form template is required.");
  // }
  // if(!this.state.company.informedConsentFormExists && (!this.state.company.files || !_.some(this.state.company.files, f => { return f.uploadTypeId === "InformedConsentForm"; }))) {
  //   warnings.push("An informed consent form is required.");
  // }
  // if(!this.state.company.companyReleaseFormExists && (!this.state.company.files || !_.some(this.state.company.files, f => { return f.uploadTypeId === "CompanyReleaseForm"; }))) {
  //   warnings.push("A company release form is required.");
  // }
  // if(!this.state.company.privacyDisclosureFormExists && (!this.state.company.files || !_.some(this.state.company.files, f => { return f.uploadTypeId === "PrivacyDisclosureForm"; }))) {
  //   warnings.push("A privacy disclosure form is required.");
  // }
  if (warnings.length) {
    this.setState({ message: warnings.join(','), messageFlavor:"danger" });
  } else {
    this.setState({ message: null});
  }
  return warnings.length === 0;
}

handleSubmit(event) {
  event.preventDefault();
  if (!this.isFormValid()) return;
  let payload = new FormData();
  let companyId = this.state.company.id;
  if (companyId === null || companyId === undefined)
    companyId = 0;
  payload.append('Id', companyId);
  payload.append('CompanyGroupId', this.state.company.companyGroupId);
  payload.append('CompanyNo', this.state.company.companyNo);
  payload.append('CompanyGroupName', '');
  payload.append('Description', this.state.company.description);
  payload.append('HealthProgramName', this.state.company.healthProgramName);
  payload.append('CanSeeConferenceCalls', this.state.company.canSeeConferenceCalls);
  payload.append('ConferenceCallOnly', this.state.company.conferenceCallOnly);
  payload.append('ShowWarningOnReschedule', this.state.company.showWarningOnReschedule);
  payload.append('ShowWarningOnScheduleHorizon', this.state.company.showWarningOnScheduleHorizon);
  payload.append('CanScheduleInitialAppt', this.state.company.canScheduleInitialAppt);
  payload.append('SendApptCalendarEntry', this.state.company.sendApptCalendarEntry);
  payload.append('TypeOfSurvey', this.state.company.surveyType.value);
  payload.append('TypeOfSSO', this.state.company.ssoType.value);
  payload.append('Active', this.state.company.active);
  if (this.state.company.files) {
    for (var i = 0; i < this.state.company.files.length; i++) {
      payload.append(this.state.company.files[i].uploadTypeId, this.state.company.files[i].content);
    }
  }
  api.post_form_data('Admin/SaveCompany', payload).then(r => {
    if (r.data.success === false) {
      this.setState({message: r.data.message, messageFlavor: "danger"});
    } else {
      this.props.onSuccess(this.state.company.companyGroupId);
    }
  }).catch(helpers.catchHandler);
}

  render() {
    const c = this.state.company;
    return (
      <div>
        <Modal isOpen={true} toggle={this.props.onCancel} backdrop={true} className="modal-lg">
          <ModalHeader>{this.state.company.id ? 'Edit' : 'Add'} Company</ModalHeader>
          <ModalBody>
          {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>) }
            <Row>
              <Col>
                <Form onSubmit={this.handleSubmit}>
                <Row className="mb-2">
                    <Col sm="8">Company Group Name
                      <Input readOnly value={c.companyGroupName} />
                    </Col>
                    <Col sm="4">Company #
                      <Input type="text" maxLength="50" name="companyNo" id="companyNo"
                        onChange={this.onTextChange}
                        value={c.companyNo} />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>Health Program Name
                      <Input type="text" name="healthProgramName" id="healthProgramName"
                        onChange={this.onTextChange}
                        value={c.healthProgramName} />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="9">Description
                      <Input type="text" name="description" id="description"
                        onChange={this.onTextChange}
                        value={c.description} />
                    </Col>
                    <Col sm="3">
<label className="checkbox">
  <input type="checkbox" name="active" onChange={this.onBoolChange} checked={c.active} /> Active
</label>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
<label className="checkbox">
  <input type="checkbox" name="showWarningOnReschedule" onChange={this.onBoolChange} checked={c.showWarningOnReschedule} /> Warn on Reschedule x3
</label>
                    </Col>
                    <Col>
<label className="checkbox">
  <input type="checkbox" name="showWarningOnScheduleHorizon" onChange={this.onBoolChange} checked={c.showWarningOnScheduleHorizon} /> Warn on Schedule Horizon
</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
<label className="checkbox">
  <input type="checkbox" name="canSeeConferenceCalls" onChange={this.onBoolChange} checked={c.canSeeConferenceCalls} /> Conference Calls
</label>
                    </Col>
                    <Col>
<label className="checkbox">
  <input type="checkbox" name="conferenceCallOnly" onChange={this.onBoolChange} checked={c.conferenceCallOnly} /> Conference Calls ONLY
</label>
                    </Col>
                    <Col>
<label className="checkbox">
  <input type="checkbox" name="canScheduleInitialAppt" onChange={this.onBoolChange} checked={c.canScheduleInitialAppt} /> Schedule Initial Appt
</label>
                    </Col>
                    <Col>
<label className="checkbox">
  <input type="checkbox" name="sendApptCalendarEntry" onChange={this.onBoolChange} checked={c.sendApptCalendarEntry} /> Send Appt Calendar Entry
</label>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>Survey
                      <Select
                        closeMenuOnSelect
                        value={c.surveyType}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.props.surveyTypeList}
                        onChange={this.onSurveyChange}
                      />
                    </Col>
                    <Col>Single Sign On
                      <Select
                        closeMenuOnSelect
                        value={c.ssoType}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.props.ssoTypeList}
                        onChange={this.onSSOChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="6">
                      Upload File Type
                        <Select
                          closeMenuOnSelect
                          value={this.state.uploadType}
                          isMulti={false}
                          components={makeAnimated()}
                          options={this.state.uploadTypeList}
                          onChange={this.onUploadTypeChange}
                        />
                    </Col>
                    <Col className="pt-3">
                      {this.state.company[getPropNameFromUploadType(this.state.uploadType.value)]
                        ? <div className="text-success mt-3">This file HAS been provided.</div>
                        : <div className="text-danger mt-3">The file has NOT been provided.</div>
                      }
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Card>
                        <CardBody>
                          <CardTitle className="text-center">
                            Drop files or click on the icon below
                          </CardTitle>
                            <Dropzone className="col-xs-4"
                              onDrop={this.onPDFDrop}
                              accept="application/pdf"
                            >
                            {({getRootProps, getInputProps}) => (<section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p style={{ textAlign: 'center' }}><FontAwesomeIcon icon="file-upload" size="6x" /></p>
                              </div>
                            </section>)}
                            </Dropzone>
                          </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="expand-md">
                    <Col className="text-right">
                      <ButtonGroup>
                        <input type="submit" value="Save" className="btn btn-primary" />
                        <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

class EditCompanyGroup extends Component {
constructor(props){
  super(props);
  this.state={
    message: null,
    messageFlavor: null,
    id: props.group.id,
    name: props.group.name
  };
  this.onNameChange = this.onNameChange.bind(this);
  this.handleSubmitGroupName=this.handleSubmitGroupName.bind(this);
}

onNameChange(event){
  this.setState({ name: event.target.value });
}

handleSubmitGroupName(event) {
  event.preventDefault();
  if (!this.state.name) {
    this.setState({ message: "Group name is required", messageFlavor: "danger" });
    return;
  } else {
    this.setState({ message: null });
  }
  api.post('Admin/SaveCompanyGroup', {Id: (this.state.id ? this.state.id : 0), Name: this.state.name}).then(r => {
    if (r.data.success === false) {
      this.setState({ message: r.data.message, messageFlavor: "danger" });
    } else {
      this.props.onSuccess();
    }
  }).catch(helpers.catchHandler);
}

render() {
  return (
    <div>
      <Modal isOpen={true} toggle={this.props.onCancel} backdrop={true}>
      <ModalHeader>{this.state.id ? `Edit ${this.state.name}` : 'Add Group'}</ModalHeader>
      <ModalBody>
        {this.state.message === null ? null : (<Alert color={this.state.messageFlavor}>{this.state.message}</Alert>)}
        <Row className="mb-2">
          <Col sm="10">Company Group Name
            <Input type="text" name="name" id="name"
              onChange={this.onNameChange} 
              value={this.state.name}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col className="text-right">
            <ButtonGroup>
              <Button color="primary" onClick={this.handleSubmitGroupName}>Save</Button>
              <Button color="secondary" onClick={this.props.onCancel} >Close</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </ModalFooter>
      </Modal>
    </div>);
  }
}