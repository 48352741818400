import React, { Component } from 'react';
// import { Row } from 'reactstrap';
// import { Link } from 'react-router-dom';
import { TemplateCollapsibleCard } from './';
import LongText from './LongText';

export class WidgetHTMLText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse
    };
  }

  render() {
    return (
      <TemplateCollapsibleCard
        widgetIcon={['fas', this.props.iconKey]}
        displayName={this.props.title}
        collapse={this.state.collapse}
        headerClass="widgetCard">
        {/* <div className="col-sm-12" dangerouslySetInnerHTML={{__html: this.props.html}}></div> */}
        <LongText
        content={this.props.html}
        limit={715}
        />
      </TemplateCollapsibleCard>
    );
  }
}