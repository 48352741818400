import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  Row,
  Col,
  Alert,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import api from "../utils/api";
import { NotificationFacade } from "../utils/notificationfacade.js";
import helpers from "../utils/helpers";
import _ from "lodash";

export class EditUser extends Component {
  constructor(props) {
    super(props);
    let user = Object.assign({}, props.user);
    if (!user.company) {
      user.company = { label: user.companyNo, value: user.companyId };
    }
    if (!user.defaultLocation) {
      user.defaultLocation = {
        label: user.defaultLocationName,
        value: user.defaultLocationId,
      };
    }
    if (!user.city) user.city = "";
    if (!user.state) user.state = "";
    user.active = user.deactivatedAt === null;
    user.roles =
      user.roleInformation === ""
        ? []
        : user.roleInformation.split(",").map((role_name) => {
            const matchedRole = _.find(
              props.roleList,
              (r) => r.label === role_name
            );
            if (matchedRole) {
              return { value: matchedRole.value, label: role_name };
            }
            return null;
          });
    this.state = {
      user: user,
      message: null,
      location_list: props.locationList,
      role_list: props.roleList,
      company_list: props.companyList,
      smsNotificationsEnabled: { value: null, changed: false, error: null },
    };
    this.notificationFacade = new NotificationFacade();
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onBoolChange = this.onBoolChange.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeRoles = this.onChangeRoles.bind(this);
    this.saveUser = this.saveUser.bind(this);
  }
  /*
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({modal: this.props.showModal});
    }
    if (prevProps.roleList !== this.props.roleList) {
      this.setState({role_list: this.props.roleList});
    }
    if (prevProps.locationList !== this.props.locationList) {
      this.setState({location_list: this.props.locationList});
    }
    if (prevProps.itemId !== this.props.itemId) {
      if (this.props.itemId < 0 || this.props.itemId === null) {
        this.setState({ user: emptyUser});
      } else {
        api.fetch(`Admin/User/${this.props.itemId}`).then(r => {
            let u = response.data;
            u.roles = u.roleInformation.split(',').map(role_name => {
              const role_id = _.find(this.state.role_list, r => r.label === role_name).value;
              return {value: role_id, label: role_name};
            });
            u.defaultLocation = {value: u.defaultLocationId, label: u.defaultLocationName};
            context.setState({ user: u });
          }).catch(helpers.catchHandler);
      }
    }
  }
*/

  componentDidMount() {
    this.notificationFacade
      .GetUserConsentStatus(this.state.user.id)
      .then((r) => {
        if (r == null || r.notificationsEnabled == null)
          throw new Error("Result value missing or unexpected");
        this.setState({
          smsNotificationsEnabled: {
            value: r.notificationsEnabled,
            changed: false,
            error: null,
          },
        });
      })
      .catch((ex) => {
        this.setState({
          smsNotificationsEnabled: { value: false, changed: false, error: ex },
          message: "Error loading SMS notification status",
        });
        helpers.catchHandler(ex);
      });
  }

  closeModal() {
    this.props.onCancel();
  }

  onTextChange(e) {
    let u = this.state.user;
    u[e.target.name] = e.target.value;
    this.setState({ user: u });
  }

  onBoolChange(e) {
    if (e.target.name === "smsNotificationsEnabled") {
      this.setState({
        smsNotificationsEnabled: {
          ...this.state.smsNotificationsEnabled,
          value: e.target.checked,
          changed: true,
        },
      });
    } else {
      let u = this.state.user;
      u[e.target.name] = e.target.checked;
      this.setState({ user: u });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    // let payload = Object.assign({}, this.state.user);
    // payload.currentPhaseNo = parseInt(payload.currentPhaseNo);
    // if (isNaN(payload.currentPhaseNo) || payload.currentPhaseNo < 0 || payload.currentPhaseNo > 5) {
    //   this.setState({message: "Enter a valid, current phase (0-5)"});
    //   return false;
    // }
    // payload.roles = payload.roles.map(r => r.value);
    // delete payload.defaultLocation;
    // delete payload.company;
    if (
      isNaN(parseInt(this.state.user.currentPhaseNo)) ||
      parseInt(this.state.user.currentPhaseNo) < 0 ||
      parseInt(this.state.user.currentPhaseNo) > 5
    ) {
      this.setState({ message: "Enter a valid, current phase (0-5)" });
      return false;
    }

    let payload = {
      Id: this.state.user.id,
      FirstName: this.state.user.firstName,
      Middle: this.state.user.middleName,
      LastName: this.state.user.lastName,
      Email: this.state.user.email,
      MobilePhone: this.state.user.mobilePhone,
      Username: this.state.user.username,
      CurrentPhaseNo: parseInt(this.state.user.currentPhaseNo),
      Active: this.state.user.active,
      Roles: this.state.user.roles.map((r) => r.value),
      DefaultLocationId: this.state.user.defaultLocationId,
      CompanyId: this.state.user.companyId,
      City: this.state.user.city,
      State: this.state.user.state,
      MustChangePassword: this.state.user.mustChangePassword,
      InitialPassword: this.state.user.password,
    };

    if (
      this.state.smsNotificationsEnabled.changed &&
      !this.state.smsNotificationsEnabled.error
    ) {
      if (this.state.smsNotificationsEnabled.value) {
        this.setState({
          message: "User must provide consent to enable SMS notifications.",
        });
      } else {
        this.notificationFacade
          .OptOutSMS(this.state.user.id)
          .then((r) => {
            if (r.success === false) {
              this.setState({ message: r.msg });
            } else {
              this.setState({ smsNotificationsEnabled: false });
              this.saveUser(payload);
            }
          })
          .catch(helpers.catchHandler);
      }
    } else {
      this.saveUser(payload);
    }
  }

  saveUser(payload) {
    api
      .post("Admin/SaveUser", payload)
      .then((r) => {
        if (r.data.success === false) {
          this.setState({ message: r.data.message });
        } else {
          this.props.onSuccess();
        }
      })
      .catch(helpers.catchHandler);
  }

  onChangeCompany(selection) {
    this.setState({
      user: {
        ...this.state.user,
        companyId: selection.value,
        companyNo: selection.label,
        company: selection,
      },
    });
  }

  onChangeLocation(selection) {
    this.setState({
      user: {
        ...this.state.user,
        defaultLocationId: selection.value,
        defaultLocation: selection,
      },
    });
  }

  onChangeRoles(selections) {
    this.setState({
      user: {
        ...this.state.user,
        roles: selections,
      },
    });
  }

  render() {
    const u = this.state.user;
    return (
      <div>
        <Modal
          isOpen={true}
          toggle={this.closeModal}
          backdrop={true}
          className="modal-lg"
        >
          <ModalHeader>{u.id ? "Edit" : "Add"} User</ModalHeader>
          <ModalBody>
            {this.state.message === null ? null : (
              <Alert color="danger">{this.state.message}</Alert>
            )}
            <Row>
              <Col>
                <Form onSubmit={this.handleSubmit}>
                  <Row className="mb-2">
                    <Col>
                      Username
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        onChange={this.onTextChange}
                        value={u.username}
                      />
                    </Col>
                    <Col sm="4">
                      Current Phase
                      <Input
                        type="text"
                        name="currentPhaseNo"
                        id="currentPhaseNo"
                        onChange={this.onTextChange}
                        value={u.currentPhaseNo}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      First Name
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        onChange={this.onTextChange}
                        value={u.firstName}
                      />
                    </Col>
                    <Col>
                      Middle Name
                      <Input
                        type="text"
                        name="middleName"
                        id="middleName"
                        onChange={this.onTextChange}
                        value={u.middleName}
                      />
                    </Col>
                    <Col>
                      Last Name
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        onChange={this.onTextChange}
                        value={u.lastName}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      Email
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        onChange={this.onTextChange}
                        value={u.email}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      Mobile Phone
                      <Input
                        type="tel"
                        name="mobilePhone"
                        id="mobilePhone"
                        onChange={this.onTextChange}
                        value={u.mobilePhone}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {u.id ? null : (
                      <Col>
                        Initial Password
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          onChange={this.onTextChange}
                        />
                      </Col>
                    )}
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="mustChangePassword"
                          onChange={this.onBoolChange}
                          checked={u.mustChangePassword}
                        />{" "}
                        Must Change Password
                      </label>
                    </Col>
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="active"
                          onChange={this.onBoolChange}
                          checked={u.active}
                        />{" "}
                        Active
                      </label>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="smsNotificationsEnabled"
                          onChange={this.onBoolChange}
                          disabled={
                            this.state.smsNotificationsEnabled.value == null ||
                            this.state.smsNotificationsEnabled.error != null
                          }
                          checked={
                            this.state.smsNotificationsEnabled.value ?? false
                          }
                        />{" "}
                        Enable SMS Notifications
                      </label>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      City
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        onChange={this.onTextChange}
                        value={u.city}
                      />
                    </Col>
                    <Col>
                      State
                      <Input
                        type="text"
                        name="state"
                        id="state"
                        onChange={this.onTextChange}
                        value={u.state}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      Company
                      {this.props.currentUser.isAdmin ? (
                        <Select
                          closeMenuOnSelect={true}
                          value={u.company}
                          isMulti={false}
                          components={makeAnimated()}
                          options={this.state.company_list}
                          onChange={this.onChangeCompany}
                        />
                      ) : (
                        <Input
                          type="text"
                          readonly="readonly"
                          value={this.props.currentUser.companyNo}
                        />
                      )}
                    </Col>
                    <Col>
                      Default Location
                      <Select
                        closeMenuOnSelect={true}
                        value={u.defaultLocation}
                        isMulti={false}
                        components={makeAnimated()}
                        options={this.state.location_list}
                        onChange={this.onChangeLocation}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      Roles
                      <Select
                        closeMenuOnSelect={false}
                        value={u.roles}
                        isMulti={true}
                        components={makeAnimated()}
                        options={this.state.role_list}
                        onChange={this.onChangeRoles}
                      />
                    </Col>
                  </Row>
                  <Row className="expand-md">
                    <Col className="text-right">
                      <ButtonGroup>
                        <input
                          type="submit"
                          value="Save"
                          className="btn btn-primary"
                        />
                        <Button color="secondary" onClick={this.closeModal}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
