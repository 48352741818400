import React, { Component } from "react";
import { Table } from "reactstrap";
import constants from "../utils/constants";
import _ from "lodash";

export class AnnualEligibilityTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.calculateEnrollmentPercentage =
      this.calculateEnrollmentPercentage.bind(this);
    this.enrollmentTableContent = this.enrollmentTableContent.bind(this);
  }

  calculateEnrollmentPercentage(enrolled, eligible) {
    const percentAsDecimal = enrolled / eligible;
    const percentage = percentAsDecimal * 100;
    return percentage.toFixed(0);
  }

  enrollmentTableContent() {
    const rowColors = [constants.PEAK_COLORS.green, constants.PEAK_COLORS.blue];
    const enrollmentDataToChart = this.props.enrollmentData;
    const tableContent = _.map(enrollmentDataToChart, (item, index) => {
      const backgroundColor = rowColors[index % rowColors.length];
      return (
        <tr
          key={index}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              constants.PEAK_COLORS.purple)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = backgroundColor)
          }
          style={{
            backgroundColor: backgroundColor,
            color: "white",
          }}
        >
          <td className="text-left">
            <strong>{item.reportingPeriod}</strong>
          </td>
          <td className="text-center">
            {Number(item.eligible).toLocaleString()}
          </td>
          <td className="text-center">
            {Number(item.enrolled).toLocaleString()}
          </td>
          <td className="text-center">
            {this.calculateEnrollmentPercentage(item.enrolled, item.eligible)}%
          </td>
        </tr>
      );
    });

    return tableContent;
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <th className="text-left">Reporting Period</th>
                <th className="text-center"># Eligible</th>
                <th className="text-center"># Enrolled</th>
                <th className="text-center">% Enrolled</th>
              </tr>
            </thead>
            <tbody>{this.enrollmentTableContent()}</tbody>
          </Table>
        </div>
      </>
    );
  }
}
